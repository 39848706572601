import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import LoadingBtn from "./LoadingButton";
import { makeStyles } from "@material-ui/styles";

export default function CaseDialog({ title, handleCancel, handleContinue, handleSaveAsDraft, handleSaveAsLive, continueButton, error, status }) {
    const classes = useStyles()

    return (
        <Dialog fullWidth maxWidth={continueButton ? "md" : "sm"} open={true} onClose={handleCancel}>
            <div className={classes.header}>
                <DialogTitle id="alert-dialog-title">
                    <p>{title}</p>
                </DialogTitle>
                <div style={{padding: 15}}>
                    <CloseIcon  onClick={handleCancel}/>
                </div>
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p className={classes.description}>Do you want to:</p>
                    {error?.candidate &&
                        <div style={{ paddingBottom: 10 }}>
                            <p className={classes.error}>Case info:</p>
                            {error?.jobPosition && <li className={classes.error}><p className={classes.errorText}>Job-position cannot be empty</p></li>}
                            {error?.firstName && <li className={classes.error}><p className={classes.errorText}>First name cannot be empty</p></li>}
                            {error?.lastName && <li className={classes.error}><p className={classes.errorText}>Last name cannot be empty</p></li>}
                            {error?.email && <li className={classes.error}><p className={classes.errorText}>Email should be correct format</p></li>}
                        </div>
                    }

                    {error?.questions &&
                        <div>
                            <p className={classes.error}>Questions|Rating Points:</p>
                            <li className={classes.error}><p className={classes.errorText}>All references should have atleast 1 question</p></li>
                        </div>
                    }

                    {error?.references &&
                        <div>
                            <p className={classes.error}>References:</p>
                            <li className={classes.error}><p className={classes.errorText}>{error?.refErrorText}</p></li>
                        </div>
                    }
                </DialogContentText> 
            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <div className={classes.buttons}>
                    {status !== 'live' &&
                        <div className={classes.button}>
                            <LoadingBtn fullWidth style={{ backgroundColor: 'green' }} onClick={handleSaveAsDraft}>Save as draft case</LoadingBtn>
                        </div>
                    }
                    <div className={classes.button}>
                        <LoadingBtn fullWidth onClick={handleSaveAsLive}>
                            {status !== 'live' ? 'Save as live case' : 'Save changes'}
                        </LoadingBtn>
                    </div>
                    {continueButton &&
                        <div className={classes.button}>
                            <LoadingBtn fullWidth style={{ backgroundColor: 'red' }} onClick={handleContinue}>Close without saving</LoadingBtn>
                        </div>
                    }
                </div>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    description: {
        '@media (max-width: 1024px)': {
            fontSize: 15,
        },
        paddingBottom: 10
    },
    error: {
        color: 'red',
    },
    errorText: {
        color: 'red',
        display: 'inline',
        fontSize: 12
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        justifyContent: 'space-between',
        '@media (max-width: 1024px)': {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    buttons: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        margin: 10,
        '@media (max-width: 1024px)': {
            display: 'none',
        },
    },
    buttonsSmall: {
        display: 'flex',
        margin: 10,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '@media (min-width: 1024px)': {
            display: 'none',
            
        },
    },
    button: {
        flex: 1,
        marginRight: 10,
        marginLeft: 10,
        '@media (max-width: 1024px)': {
            width: '100%',
            marginBottom: 5,
            margin: 5,
        },
        '@media (min-width: 1024px)': {
            '&:first-child': {
                paddingRight: 10,
            },
            '&:last-child': {
                paddingLeft: 10,
            },
        },
    },

})