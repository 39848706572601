import React, { useState, useRef, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreIcon from '@material-ui/icons/ExpandMore'
import LessIcon from '@material-ui/icons/ExpandLess'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import '../sass/components/custom-menulist.scss';
import { Colors } from '../styles/globalStyles';
import LoadingBtn from './LoadingButton';

const BoostrapButton = styled(Button)(({ theme }) => ({
    '& .MuiButton-root': {
        padding: '0px',
    },
}));


const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    iconButtonContainer: {
        height: 30,
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        borderRadius: 5,
        cursor: 'pointer',
    },
    dropdown: {
        zIndex: 10,
    },
    fullWidth: {
        minWidth: '60%',
        maxWidth: '90%',
        zIndex: 10,
    },
    menuItemContent: {
        padding: '10px 5px',
        width: '100%',
    },
    answersContent: {
        display: 'flex',
        padding: '5px 0px',
    },
    answerIndexCounter: {
        width: 30,
        padding: '0px 30px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    answerTitleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
})

const CustomMenuList = ({ label, title, elements, onSelect, iconButton, style, withKey, subMenu, disabled, error, fullWidth, ratingPoints, selectedScale, selectedTheme, handleSelectAnswers, selected }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const classes = useStyles();

    const auth = useSelector(state => state.auth)
    const t = (txt) => (txt)

    const handleToggle = () => {
        if(!disabled) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        if (typeof event === 'string') {
            onSelect(event)
        }

        if (event.title !== undefined && typeof event === 'object') {
            onSelect(event)
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);
    
    const checkIfSelected = (element) => {
        return selected === element ? true : false 
    }

    return (
        <div className={classes.container} >
            {iconButton &&
                <div ref={anchorRef} onClick={handleToggle} className={classes.iconButtonContainer} style={{ backgroundColor: (auth?.user?.company?.branding?.colors ? auth?.user?.company?.branding?.colors : Colors.mainBlue), }}>
                    <Tooltip title={t("Add reference")}>
                        <AddIcon fontSize="small" />
                    </Tooltip>
                </div>
            }
            {!iconButton &&
                <BoostrapButton disabled={selectedScale?.type === 'Open'} className="custom-menulist-button" ref={anchorRef} onClick={handleToggle} disableElevation>
                    <div className="custom-menulist-header">
                        <p style={{ color: error ? 'red' : null}} className="custom-menulist-label">{label}</p>
                        <p className="custom-menulist-title">{title}</p>
                        <div className="custom-menulist-icon" style={{ position: 'absolute', right: 0, height: '100%', alignItems: 'center' }}>
                            {open ? <LessIcon /> : <MoreIcon />}
                        </div>
                    </div>
                </BoostrapButton>
            }
            <Popper className={fullWidth ? classes.fullWidth : classes.dropdown} hidden={selectedScale?.type === 'Open'} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformorigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper style={{ height: subMenu && '50%', }} >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList style={{ maxHeight: 400, overflowY: 'auto'  }} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {subMenu && selectedScale?.type !== 'Open' && selectedScale?.title !== 'Yes/No' && 
                                        <div>
                                            <MenuItem
                                                className={classes.dropdown}
                                                // TODO - REMOVE THIS???????
                                                // anchorOrigin={{
                                                //     vertical: 'bottom',
                                                //     horizontal: 'center',
                                                // }}
                                                transformorigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                disableRipple
                                                onClick={() => handleSelectAnswers()}
                                                style={{ borderBottom: '1px solid #000', cursor: 'auto', }}
                                            >
                                                <div style={{ padding: '30px 5px', width: '100%'}}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <p style={{marginRight: 10, fontWeight: 500}}>Create new answers</p>
                                                        <LoadingBtn onClick={() => handleSelectAnswers()}>
                                                            <p>Select</p>
                                                        </LoadingBtn>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        </div>
                                    }
                                    {elements?.map((element, index) => {
                                        const selected = checkIfSelected(withKey ? element.title : element)
                                        return (
                                            !subMenu ? <MenuItem key={withKey ? `${element.title}+${index}` : `${element}+${index}`} onClick={() => handleClose(element)}> <span style={{fontWeight: selected && '600'}}>{withKey ? t(element.title) : t(element)}</span></MenuItem>
                                                : (
                                                    <div key={withKey ? `${element.title}+${index}` : `${element}+${index}`}>
                                                        <MenuItem
                                                            className={classes.dropdown}
                                                             // TODO - REMOVE THIS???????
                                                            // anchorOrigin={{
                                                            //     vertical: 'bottom',
                                                            //     horizontal: 'center',
                                                            // }}
                                                            transformorigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                            onClick={() => handleClose(element)}
                                                            style={{ borderBottom: index !== elements.length - 1 ? '1px solid #000' : '1px solid #fff' }}
                                                        >
                                                            <div className={classes.menuItemContent}>
                                                                {selectedScale?.title !== 'Yes/No' &&
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <p style={{ marginRight: 10 }}>{withKey ? selectedTheme ? `Set of answers` : t(element?.title) : t(element)}</p>
                                                                        <LoadingBtn onClick={() => handleSelectAnswers(element, true)}>
                                                                            <p>Select</p>
                                                                        </LoadingBtn>
                                                                    </div>
                                                                }

                                                                <div style={{ display: selectedScale?.type === 'RatingPoints' ? 'flex' : 'inherit', justifyContent: 'space-between' }}>
                                                                    {element.answers?.map((answer, index) => {
                                                                        return (
                                                                            <div className={classes.answersContent}  style={{ borderBottom: index !== element?.answers?.length -1 ? `1px dotted ${Colors.mainBlue}` : 'none', }}>
                                                                                <div className={classes.answerIndexCounter}>
                                                                                    <p>{`${index + 1}.`}</p>
                                                                                </div>
                                                                                <div className={classes.answerTitleContainer}>
                                                                                    <p style={{whiteSpace: 'pre-line'}}>{answer.title}</p>
                                                                                </div>
                                                                            </div>
                                                                            )
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    </div>
                                                )
                                        )
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>

    )
}

export default CustomMenuList
