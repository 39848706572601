import React, { useEffect, useState } from 'react'
import DCNavigation from '../../components/DCNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStep, setCase } from '../../redux/slices/createCaseSlice'
import Preview from '../../components/Preview'
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/client'
import DCLoader from '../../components/DCLoader'
import moment from 'moment';
import Status from './Status'
import { CASE_BY_ID } from '../../graphql/mutations'

function ViewClosedCase({ match }) {
    const [caseId, setCaseId] = useState();

    const { activeStep, case: caseState } = useSelector(state => state.createCase)
    const auth = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth)
    const [selectedLanguage, setSelectedLanguage] = useState(auth?.user?.company?.settings?.languages[auth?.user?.company?.settings?.defaultQALanguage]);
    
    const dispatch = useDispatch()
    const classes = useStyles();

    const { data, loading, refetch } = useQuery(CASE_BY_ID, { variables: { id: caseId }, fetchPolicy: 'cache-and-network' });

    const t = (str) => str;

    useEffect(() => {
        setCaseId(match?.params?.id)
    }, [match, data])

    useEffect(() => {
        const formattedCase = {
            ...data?.case,
            deadline: {
                answer: `${data?.case?.deadline?.answer} hours`,
                listing: `${data?.case?.deadline?.listing} hours`
            },
            deleteDate: moment(parseInt(data?.case?.deleteDate)).valueOf(),
        }

        dispatch(setCase(formattedCase))
    }, [data])

    /**
     * Validate on page changes
     */
    const handlePageChange = (item) => {
        return dispatch(setActiveStep(item))
    }


    if(loading) {
        return <DCLoader />
    }

    const renderNavigationCaseClosed = (step) => {
        switch(step) {
            case 0:
                return (
                    <Preview
                        caseState={caseState}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        isClosed={true}
                    />
                )
            case 1:
                return (
                    <Status
                        refetchCase={refetch}
                        caseInfo={caseState}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        isClosed={true}
                    />
                )
            default: 
                return (
                    <Preview
                        caseState={caseState}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        isclosed={true}
                    />
                )
        }
    };

    return (
        <div style={{ maxHeight: 'calc(100vh - 106px)', overflow: 'hidden'}}>
            <div className={classes.contentContainer}>
                <DCNavigation 
                    setActiveStep={(index) => handlePageChange(index)}
                    activeStep={activeStep} 
                    type="Case"
                    isClosed={true}
                />
            </div>

            <div className={classes.contentContainer}>
                {user.role === 'TemplateOnly'
                    ? (
                        <div>
                            {renderNavigationCaseClosed(activeStep)}
                        </div>
                    )
                    : (
                        <div>
                            {renderNavigationCaseClosed(activeStep)}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles({

})

export default ViewClosedCase;
