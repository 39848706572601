import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import TemplateDialog from "./TemplateDialog";

export function TemplatePrompt({ when, onSaveClick, onCancel, error, setError }) {
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    const history = useHistory();

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);
    
    const handleSave = useCallback(async (status) => {  
        if (onSaveClick) {
            try {
                const canRoute = await Promise.resolve(onSaveClick(status));
                if (canRoute) {
                    history.block(() => {});
                    history.push(currentPath);
                }
                handleContinue()
            } catch(err) {
                // TODO: add error handling
                console.log(err)
            }
        }
    }, [onSaveClick]);

    const handleContinue = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setError({})
        setShowPrompt(false);
    }, [onCancel]);
    

    const handleCancel = () => {
        setError({})
        setShowPrompt(false);
    };

    return (
        showPrompt && 
            <TemplateDialog 
                continueButton={true}
                handleCancel={handleCancel} 
                handleSave={handleSave} 
                handleContinue={handleContinue} 
                title="You are about to close this Template."
                error={error}
            />
    )
        
}
