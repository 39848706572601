import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles';

export default function ClosedPage() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h3 className={classes.description}>{t('Sorry for any inconvenience - but the case has been closed and your candidate reference is no longer needed')}</h3>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex', 
        alignItems: 'center', 
        paddingTop: '5vh', 
        flexDirection: 'column'
    },
    description: {
        paddingTop: 20, 
        fontWeight: '400',
        textAlign: 'center'
    }
})
