import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../styles/globalStyles';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    root: {
        backgroundColor: props => props.color,
        color: '#fff !important',
        minWidth: '170px !important',
        fontSize: '14px !important',
    },
    text: {
        color: '#fff !important'
    }
})

export default function CustomButton({ title, background, color, size, onClick }) {
    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;
    const classes = useStyles({color: branding?.colors ? (branding?.colors + '!important') : (Colors.mainBlue + '!important') });

    return (
        <Button variant="text" className={classes.root} onClick={(e) => onClick(e)}>{title}</Button>
    )
}
