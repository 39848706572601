import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { VALIDATE_PASSWORD_TOKEN, UPDATE_PASSWORD } from '../../graphql/mutations';

import { useDispatch } from 'react-redux';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import { isValidPassword, comparePasswords } from '../../utils/validators'
import LoadingBtn from '../../components/LoadingButton';

const ResetPassword = (props) => {
    const { token } = props.match.params;

    const [password, setPassword] = useState('');
    const [errorCompare, setErrorCompare] = useState(false);
    const [errorValid, setErrorValid] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [validatePassword] = useMutation(VALIDATE_PASSWORD_TOKEN);
    const [updatePassword] = useMutation(UPDATE_PASSWORD);

    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        validatePassword({ variables: { token } }).then(res => {
            setValidated(true);
            setIsLoading(false);
        }).catch(err => {
            setValidated(false)
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
      if(password !== '' && passwordConfirm !== '')
        setErrorCompare(!comparePasswords(password, passwordConfirm))
    }, [password, passwordConfirm]);

    const submit = () => {
        if(errorCompare) {
            return dispatch(triggerFlashMessage({ type: 'error', title: 'Passwords does not match'}));
        }
        if(isValidPassword(password)){
			updatePassword({ variables: { token, password } }).then(res => {
				dispatch(triggerFlashMessage({ type: 'success', title: 'Succesfully updated password'}));
				props.history.push('/')
			}).catch(err => {
				dispatch(triggerFlashMessage({ type: 'error', title: 'Failed to updated password'}));
			});
          	
			setErrorValid(false)
        } else {
          	setErrorValid(true)  
          	dispatch(triggerFlashMessage({ type: 'error', title: 'Invalid password'}))
        };
    }

    return (
        <div className={classes.container}>
            {!isLoading && validated &&
                <div>
                    <h1>Reset password</h1>
                    <div>
                      	<TextField 
						  	type="password" 
							placeholder="New password" 
							label="New Password" 
							value={password} 
							fullWidth
							onChange={(e) => setPassword(e.target.value)} 
							autoFocus
                            error={errorCompare} 
							style={{ marginTop: 30 }} 
							size="small" 
							variant="outlined" 
							InputLabelProps={{ shrink: true }}
						/>
                    </div>
                    <div>
                      	<TextField 
							type="password" 
							placeholder="Confirm password" 
							label="Confirm Password" 
							fullWidth
							value={passwordConfirm} 
							onChange={(e) => setPasswordConfirm(e.target.value)} 
                            error={errorCompare} 
							style={{ marginTop: 40 }} 
							size="small"
							variant="outlined" 
							InputLabelProps={{ shrink: true }}
						/>
                    </div>
                    <div className={classes.buttonContainer}>
                      	<LoadingBtn fullWidth  onClick={(e) => submit(e)}>Reset password</LoadingBtn>
                    </div>
					{errorValid && 
						<div className={classes.errorContainer}>
							<p className={classes.errorText}>* Password must be at least 8 characters long</p>
						</div>
					}
                </div>
            }
            {!isLoading && !validated &&
                <div>
                    <h1>The token has expired</h1>
                    <p className={classes.expireText} onClick={() => props.history.push('/forgot-password')}>Request new password?</p>
                </div>
            }
        </div>
    )
}

export default ResetPassword;

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(90vh - 110px)',
    },
	buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20,
        width: '100%'
    },
	errorContainer: {
		color: 'red', 
		paddingTop: '20px', 
		position: 'relative'
	},
	errorText: {
		position: 'absolute', 
		fontSize: 12
	},
	expireText: {
		cursor: 'pointer',
		textAlign: 'center',
		marginTop: 20
	},
})
