import React, { useState, useRef } from 'react'
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreIcon from '@material-ui/icons/ExpandMore'
import LessIcon from '@material-ui/icons/ExpandLess'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';


/**
 * Used for CUSTOM dropdown menu
 * Examples would be Themes and Category select in Q&A Manager / Create case questions
 */
const CustomDropdownMenu = ({ label, title, elements, onSelect, withKey, elementKey, noMenu, children, selected, style, disabled, hideIcon = false }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const { t } = useTranslation();
    const classes = useStyles();


    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (event) => {
        onSelect(event)
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const checkIfSelected = (element) => {
        return selected === element ? true : false 
    }

    return (
        <div style={style} className={classes.container} >
            <Button disableRipple={disabled} style={style} fullWidth ref={anchorRef} onClick={() => disabled ? {} : handleToggle()}>
                <div style={style} className={classes.buttonHeaderContainer}>
                    <p className={classes.buttonHeaderLabel}>{label}</p>
                    <div className={classes.buttonHeaderTitleContainer}>
                        {/* the background color (title) is used in branding to show the selected company color */}
                        {noMenu && <div className={classes.colorBox} style={{ backgroundColor: title }} />}
                        <p className={classes.buttonHeaderTitle}>{title}</p>
                    </div>
                    <div className={classes.buttonHeaderIcon}>
                        {!hideIcon ? open ? <LessIcon /> : <MoreIcon /> : null}
                    </div>
                </div>
            </Button>
            <Popper style={{ paddingRight: 30, zIndex: 10 }} open={open} anchorEl={anchorRef.current} placement={'bottom-end'} transition>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open}>
                                    {elements?.map((element, index) => {
                                        const selected = checkIfSelected(withKey ? element[elementKey] : element)
                                        if(!withKey && !element.selected && !children) {
                                          return (
                                            <MenuItem key={withKey ? element[elementKey] : element} style={{ fontWeight: selected && 600 }} onClick={() => handleSelect(element)}>
                                                {element}
                                            </MenuItem>
                                          );
                                        }
                                        return (
                                            <MenuItem key={withKey ? element[elementKey] : element} style={{ fontWeight: selected && 600 }} onClick={() => handleSelect(element)}>
                                                {withKey ? t(element[elementKey]) : t(element)}
                                                {element?.selected && <span className={classes.selectedCounterText}>{` (${element?.selected})`}</span>}
                                                {children}
                                            </MenuItem>
                                        )
                                    })}

                                    {noMenu && children}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>

    )
}

export default CustomDropdownMenu

const useStyles = makeStyles({
    container: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center'
    },
    buttonHeaderContainer: {
        minHeight: '50px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        paddingLeft: '20px',
    },
    buttonHeaderLabel: {
        fontSize: 12,
        textTransform: 'capitalize',
    },
    buttonHeaderTitle: {
        textTransform: 'capitalize',
        paddingLeft: 10,
        color: '#000'
    },
    buttonHeaderIcon: {
        position: 'absolute',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        right: 12,
    },
    buttonHeaderTitleContainer : { 
        display: 'flex', 
        alignItems: 'center'
    },
    colorBox: {
        height: 15, 
        width: 15, 
        borderRadius: '50%', 
	},
    selectedCounterText: {
        paddingLeft: 5,
    },
})
