import { createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

export const answerQuestionsSlice = createSlice({
    name: 'answerQuestions',
    initialState: {
        case: {
            jobPosition: 'Marketing Manager',
            stakeholders: [],
            references: [],
            deadline: {
                listing: '48 hours',
                answer: '48 hours'
            },
            deleteDate: moment().toString(),
            candidate: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                mobile: '',
                preferedLanguage: '',
            },
            candidateAnswer: false,
            selectedQuestions: []
        }
    },
    reducers: {
        setStakeholders: (state, action) => {
            state.case.stakeholders = [...action.payload]
        },
        setReferences: (state, action) => {
            state.case.references = [...action.payload]
        },
        setDeadlineListing: (state, action) => {
            state.case.deadline.listing = action.payload;
        },
        setDeadlineAnswer: (state, action) => {
            state.case.deadline.answer = action.payload;
        },
        setCandidate: (state, action) => {
            state.candidate = action.payload
        },
        updateCandidate: (state, action) => {
            state.case.candidate[action.payload.key] = action.payload.value
        },
        setDeleteDate: (state, action) => {
            state.case.deleteDate = action.payload
        },
        setSelectedQuestions: (state, action) => {
            state.case.selectedQuestions = [...action.payload]
        },
        setJobPosition: (state, action) => {
            state.case.jobPosition = action.payload
        },
        setCandidateAnswer: (state, action) => {
            state.case.candidateAnswer = !state.case.candidateAnswer
        },
        setReferenceQuestions: (state, action) => {
            state.case.references = action.payload
        },
        setCase: (state, action) => {
            state.case = action.payload
        },
        setQuestionInputAnswer: (state, action) => {
            const obj = {
                question: state.case.references[0].questions[action.payload.index].question,
                answer: {
                    title: action.payload.value
                }
            }
            state.case.references[0].questions[action.payload.index] = obj;
        },
        setQuestionElaborationAnswer: (state, action) => {
            state.case.references = [action.payload]
        },
        setQuestionMultiAnswer: (state, action) => {
            const title = 
                state.case.references[0].questions[action.payload.index].answer && state.case.references[0].questions[action.payload.index].answer.title 
                ? state.case.references[0].questions[action.payload.index].answer.title : null;
            const obj = {
                question: state.case.references[0].questions[action.payload.index].question,
                answer: {
                    answers: action.payload.value,
                    title: title,
                }
            }
            state.case.references[0].questions[action.payload.index] = obj;
        },
        setQuestionSlideAnswer: (state, action) => {
            state.case.references = action.payload;
        },
        setQuestionPrioAnswer: (state, action) => {
            const title = 
                state.case.references[0].questions[action.payload.index].answer && state.case.references[0].questions[action.payload.index].answer.title 
                ? state.case.references[0].questions[action.payload.index].answer.title : null;
            const obj = {
                question: state.case.references[0].questions[action.payload.index].question,
                answer: {
                    answers: action.payload.value,
                    title: title,
                }
            }
            state.case.references[0].questions[action.payload.index] = obj;
        }
    }
});

export const {
    setStakeholders,
    setReferences,
    setDeadlineListing,
    setDeadlineAnswer,
    setCandidate,
    setDeleteDate,
    setSelectedQuestions,
    setJobPosition,
    setCandidateAnswer,
    setCandidateFirstName,
    updateCandidate,
    setReferenceQuestions,
    setCase,
    setQuestionInputAnswer,
    setQuestionMultiAnswer,
    setQuestionSlideAnswer,
    setQuestionPrioAnswer,
    setQuestionElaborationAnswer,
} = answerQuestionsSlice.actions


export default answerQuestionsSlice.reducer;