import { useMutation, useQuery } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DCUser from '../../compo../../components/DCUser';
import CustomAlertDialog from '../../components/CustomAlertDialog';
import CustomDropdownMenu from '../../components/CustomDropdownMenu';
import CustomLoadingButton from '../../components/CustomLoadingButton';
import DCList from '../../components/DCList';
import EmailModal from '../../components/EmailModal';
import LoadingBtn from '../../components/LoadingButton';
import { COMPANY_USERS, DELETE_USER, INVITE_USER, UPDATE_COMPANY, UPDATE_USER } from '../../graphql/mutations';
import i18n from '../../i18n';
import { setDefaultDeleteDate, setLanguage, setQALanguage } from '../../redux/slices/authSlice';
import { setQuestionsLanguage, triggerFlashMessage } from '../../redux/slices/globalSlice';
import { Colors } from '../../styles/globalStyles';
import {
    defaultDeleteDateOptions,
    prettyUserRoles,
    prettyUserRolesSuper,
    userRoles,
    userRolesSuper,
} from '../../utils/constansts';

import { validateEmail } from '../../utils/validators';

function Profile({ company, companyRefetch }) {
    const [companyInput, setCompanyInput] = useState();
    const [isDeleting, setIsDeleting] = useState();
    const [deleteId, setDeleteId] = useState();
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [inviteRole, setInviteRole] = useState('');
    const [inviteRoleUI, setInviteRoleUI] = useState('');
    const [companyUsers, setCompanyUsers] = useState([]);

    const [updateCompany, { loading }] = useMutation(UPDATE_COMPANY);
    const [deleteUser] = useMutation(DELETE_USER);
    const [updateUser] = useMutation(UPDATE_USER);
    const [inviteUser] = useMutation(INVITE_USER);
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);

    const t = str => str;
    const classes = useStyles({ color: company?.branding?.colors });

    const { user } = auth;

    const companyUsersQuery = useQuery(COMPANY_USERS, {
        variables: { companyId: user.company._id },
    });

    const onSave = () => {
        delete companyInput.questionHideFilterList;
        updateCompany({ variables: { input: companyInput } })
            .then(res => {
                const newLanguageIndex = res?.data?.updateCompany?.settings?.defaultLanguage;
                const newLanguage = res?.data?.updateCompany?.settings?.languages[newLanguageIndex];
                const newQALanguageIndex = res?.data?.updateCompany?.settings?.defaultQALanguage;
                const newQALanguage = res?.data?.updateCompany?.settings?.languages[newQALanguageIndex];

                dispatch(setLanguage(newLanguageIndex));
                dispatch(setQALanguage(newQALanguageIndex));
                dispatch(setQuestionsLanguage(newQALanguage));
                i18n.changeLanguage(newLanguage?.shortName);
                dispatch(setDefaultDeleteDate(res?.data?.updateCompany?.defaultDeleteDate));

                companyRefetch();
                dispatch(
                    triggerFlashMessage({
                        type: 'success',
                        title: t('Succesfully updated company'),
                    })
                );
                companyUsersQuery.refetch();
            })
            .catch(err => {
                dispatch(
                    triggerFlashMessage({
                        type: 'error',
                        title: t('Failed to update company'),
                    })
                );
            });
    };

    useEffect(() => {
        setCompanyInput({
            ...company,
            defaultDeleteDate: company?.defaultDeleteDate || 90,
        });
    }, [company]);

    useEffect(() => {
        setCompanyUsers(companyUsersQuery?.data?.usersByCompany);
    }, [companyUsersQuery]);

    const onChange = e => {
        setCompanyInput({
            ...companyInput,
            [e.target.name]: e.target.value,
        });
    };

    const handleDelete = () => {
        deleteUser({ variables: { id: deleteId } })
            .then(res => {
                companyUsersQuery.refetch();
                companyRefetch();
                dispatch(
                    triggerFlashMessage({
                        type: 'success',
                        title: t('Succesfully deleted user'),
                    })
                );
            })
            .catch(err => {
                dispatch(
                    triggerFlashMessage({
                        type: 'error',
                        title: t('Failed to delete user'),
                    })
                );
            });
    };

    const onDelete = (e, id) => {
        e.stopPropagation();
        setDeleteId(id);
        setIsDeleting(true);
    };

    const openCloseModal = (role, index) => {
        if (role === 'SuperAdmin') setInviteRole('SuperAdmin');
        if (role === 'AccountAdmin') setInviteRole('AccountAdmin');
        if (role === 'CaseDesign') setInviteRole('CaseDesign');
        if (role === 'TemplateOnly') setInviteRole('TemplateOnly');
        if (role !== '')
            user.role === 'SuperAdmin'
                ? setInviteRoleUI(prettyUserRolesSuper[index + 1])
                : setInviteRoleUI(prettyUserRoles[index]);
        setOpen(!open);
    };

    const sendEmail = () => {
        if (validateEmail(email)) {
            setIsSendingEmail(true);
            let emailInput = {
                company: company?._id,
                email: email,
                role: inviteRole,
            };
            if (user.role === 'SuperAdmin' || user.role === 'AccountAdmin') {
                emailInput = {
                    company: company?._id,
                    email: email,
                    role: inviteRole,
                    sender: `${user.firstName} ${user.lastName}`,
                };
            }
            inviteUser({ variables: emailInput })
                .then(res => {
                    setIsSendingEmail(false);
                    setEmail('');
                    setEmailError(false);
                    setOpen(!open);
                    setInviteRole('');
                    dispatch(
                        triggerFlashMessage({
                            type: 'success',
                            title: t('Succesfully send invitation'),
                        })
                    );
                })
                .catch(e => {
                    if (e?.graphQLErrors[0]?.extensions?.exception?.code === 403) {
                        setIsSendingEmail(false);
                        return dispatch(
                            triggerFlashMessage({
                                type: 'error',
                                title: t(`E-mail is already used by another user`),
                            })
                        );
                    }
                    setIsSendingEmail(false);
                    dispatch(
                        triggerFlashMessage({
                            type: 'error',
                            title: t('Failed to send email'),
                        })
                    );
                });
        } else setEmailError(true);
    };

    const companyHasChanges = e => {
        return !_.isEqual(company, companyInput);
    };

    const handleDefaultDeleteDateChange = name => {
        const newInput = {
            ...companyInput,
            defaultDeleteDate: name,
        };
        setCompanyInput(newInput);
    };

    const handleLanguageQAChange = name => {
        const foundIndex = companyInput?.settings?.languages?.findIndex(e => e.fullName === name);

        const newInput = {
            ...companyInput,
            settings: {
                ...companyInput?.settings,
                defaultQALanguage: foundIndex,
            },
        };
        setCompanyInput(newInput);
    };

    const getUsersByRole = role => {
        return companyUsers?.filter(u => u?.role === role);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} className="account-profile-container">
            <EmailModal open={open} handleClose={() => console.log('')}>
                <div>
                    <h2>{inviteRoleUI}</h2>
                    <div style={{ margin: '20px 0px' }}>
                        <TextField
                            error={emailError}
                            id="standard-basic"
                            fullWidth={true}
                            label="Email"
                            variant="standard"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CustomLoadingButton
                            aria-label="send"
                            title="Send"
                            onClick={sendEmail}
                            loading={isSendingEmail}
                        />
                        <CustomLoadingButton aria-label="cancel" title="Cancel" onClick={() => openCloseModal('')} />
                    </div>
                </div>
            </EmailModal>
            <div style={{ width: '70%', paddingBottom: '10%' }}>
                <DCList expandIcon className={classes.listContainer} title={t('Company')}>
                    <div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('Name')}
                                autoComplete="new-password"
                                name="name"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.name ? companyInput?.name : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('Address')}
                                autoComplete="new-password"
                                name="address"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.address ? companyInput?.address : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('Address2')}
                                autoComplete="new-password"
                                name="address2"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.address2 ? companyInput?.address2 : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('Zip Code')}
                                autoComplete="new-password"
                                name="zipCode"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.zipCode ? companyInput?.zipCode : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('City')}
                                autoComplete="new-password"
                                name="city"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.city ? companyInput?.city : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('Country')}
                                autoComplete="new-password"
                                name="country"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.country ? companyInput?.country : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('Phone')}
                                autoComplete="new-password"
                                name="phone"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.phone ? companyInput?.phone : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField
                                fullWidth
                                label={t('EAN')}
                                autoComplete="new-password"
                                name="EAN"
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.EAN ? companyInput?.EAN : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: { autoComplete: 'off' },
                                }}
                            />
                        </div>
                        <div className={classes.selectorContainer}>
                            <div
                                style={{
                                    flex: 1,
                                    borderRight: '1px dashed #000',
                                }}
                            >
                                <CustomDropdownMenu
                                    selected={
                                        companyInput?.settings?.languages[companyInput?.settings?.defaultQALanguage]
                                            ?.fullName
                                    }
                                    onSelect={e => handleLanguageQAChange(e)}
                                    label={t('Default Language (Q&A)')}
                                    title={
                                        companyInput?.settings?.languages[companyInput?.settings?.defaultQALanguage]
                                            ?.fullName
                                    }
                                    elements={auth?.user?.company?.settings?.languages?.map(l => l.fullName)}
                                />
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    borderRight: '1px dashed #000',
                                }}
                            >
                                <CustomDropdownMenu
                                    selected={companyInput?.defaultDeleteDate}
                                    onSelect={e => handleDefaultDeleteDateChange(e)}
                                    label={t('Default delete date (days)')}
                                    title={companyInput?.defaultDeleteDate}
                                    elements={defaultDeleteDateOptions}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <CustomDropdownMenu
                                    disabled
                                    hideIcon
                                    style={{ cursor: 'default' }}
                                    label={t('Database (Q&A)')}
                                    title="Dooblecheck database"
                                />
                            </div>
                        </div>
                        {user.role === 'SuperAdmin' && (
                            <div
                                style={{
                                    backgroundColor: Colors.table1,
                                    borderTop: '1px dashed #000',
                                }}
                                className={classes.flexDropdownItem}
                                onClick={() => openCloseModal('SuperAdmin', -1)}
                            >
                                <p>{t('Invite new user with Super-Admin-Access')}</p>
                                <div className="account-dropdown-icon">
                                    <Tooltip title="Send invite">
                                        <IconButton aria-label="email" onClick={() => openCloseModal('SuperAdmin', -1)}>
                                            <MailIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        <div
                            style={{
                                backgroundColor: Colors.table1,
                                borderTop: '1px dashed #000',
                            }}
                            className={classes.flexDropdownItem}
                            onClick={() => openCloseModal('AccountAdmin', 0)}
                        >
                            <p>{t('Invite new user with Account-Admin-Access')}</p>
                            <div className="account-dropdown-icon">
                                <Tooltip title="Send invite">
                                    <IconButton aria-label="email" onClick={() => openCloseModal('AccountAdmin', 0)}>
                                        <MailIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{ backgroundColor: Colors.table1 }}
                            className={classes.flexDropdownItem}
                            onClick={() => openCloseModal('CaseDesign', 1)}
                        >
                            <p>{t('Invite new user with Case-Design-Access')}</p>
                            <div className="account-dropdown-icon">
                                <Tooltip title="Send invite">
                                    <IconButton aria-label="email" onClick={() => openCloseModal('CaseDesign', 1)}>
                                        <MailIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{ backgroundColor: Colors.table1 }}
                            className={classes.flexDropdownItem}
                            onClick={() => openCloseModal('TemplateOnly', 2)}
                        >
                            <p>{t('Invite new user with Template-Only-Access')}</p>
                            <div className="account-dropdown-icon">
                                <Tooltip title="Send invite">
                                    <IconButton aria-label="email" onClick={() => openCloseModal('TemplateOnly', 2)}>
                                        <MailIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }}
                            className="dropdown-input-item submit"
                        >
                            {companyHasChanges() && (
                                <LoadingBtn loading={loading} onClick={() => onSave()}>
                                    {t('Save')}
                                </LoadingBtn>
                            )}
                        </div>
                    </div>
                </DCList>

                {(user?.role === 'SuperAdmin' ? userRolesSuper : userRoles)?.map((role, index) => {
                    return (
                        <DCList
                            key={role}
                            expandIcon
                            className={classes.listContainer}
                            title={(user.role === 'SuperAdmin' ? userRolesSuper : userRoles)[index]}
                        >
                            {getUsersByRole(role)?.length === 0 && (
                                <p
                                    style={{
                                        paddingTop: 20,
                                        paddingBottom: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    No users found
                                </p>
                            )}
                            {companyUsers &&
                                getUsersByRole(role)?.map(foundUser => {
                                    return (
                                        <div key={foundUser?._id}>
                                            <CustomAlertDialog
                                                id={foundUser?._id}
                                                open={isDeleting}
                                                handleCancel={() => setIsDeleting(false)}
                                                handleClose={() => setIsDeleting(false)}
                                                handleAccept={id => handleDelete(id)}
                                                alertTitle="Delete"
                                                alertText={t('Are you sure you want to delete?')}
                                                okText="Delete"
                                            />
                                            <DCList
                                                leftIcon={() => (
                                                    <DeleteIcon
                                                        onClick={e => onDelete(e, foundUser._id)}
                                                        style={{
                                                            color: Colors.mainBlue,
                                                        }}
                                                        fontSize="small"
                                                    />
                                                )}
                                                expandIcon
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: Colors.table1,
                                                    marginTop: 5,
                                                    '&:hover': {
                                                        outline: 'none',
                                                    },
                                                    minHeight: 50,
                                                }}
                                                title={`${foundUser?.firstName} ${foundUser?.lastName}`}
                                            >
                                                <DCUser
                                                    onSave={updateUser}
                                                    setCompanyUsers={setCompanyUsers}
                                                    users={companyUsers}
                                                    user={foundUser}
                                                    refetch={() => companyUsersQuery.refetch()}
                                                />
                                            </DCList>
                                        </div>
                                    );
                                })}
                        </DCList>
                    );
                })}
            </div>
        </div>
    );
}

export default Profile;

const useStyles = makeStyles({
    dropdownItem: {
        paddingTop: 20,
    },
    selectorContainer: {
        border: `1px dashed ${Colors.mainBlue}`,
        display: 'flex',
        marginTop: 20,
        marginBottom: 20,
    },
    flexDropdownItem: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        borderBottom: `1px dashed ${Colors.mainBlue}`,
        alignItems: 'center',
        paddingLeft: 10,
        borderLeft: `7px solid ${Colors.mainBlue}`,
        borderRight: `1px dashed ${Colors.mainBlue}`,
        fontWeight: '500',
        cursor: 'pointer',
    },
    listContainer: {
        backgroundColor: props => (props.color ? props.color : `${Colors.mainBlue} !important`),
        color: '#fff',
        display: 'flex !important',
        justifyContent: 'center !important',
        marginTop: 5,
        minHeight: 50,
        cursor: 'pointer',
    },
});
