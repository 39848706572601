import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { useDispatch } from 'react-redux';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import { UPDATE_REFERENCE_CONSENT } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { Colors } from '../../styles/globalStyles';
import LanguageChange from '../../components/LanguageChange';
import LoadingBtn from '../../components/LoadingButton';
import Countdown from 'react-countdown';
import moment from 'moment';
import PrivacyPolicy from '../general/PrivacyPolicy';

function ReferenceConsent({ setCurrentStep, foundCase }) {
    const [wasAccepted, setWasAccepted] = useState(false); // used to check if first time
    const [isAccepted, setIsAccepted] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const dispatch = useDispatch();

    const [updateReferenceConsent] = useMutation(UPDATE_REFERENCE_CONSENT);

    const { t } = useTranslation();

    const classes = useStyles();

    useEffect(() => {
        if(foundCase?.references[0]?.consentGiven) {
            setIsAccepted(true)
            setWasAccepted(true)
        }
    }, [foundCase])

    const handleNext = () => {
        if (!isAccepted) {
            return dispatch(triggerFlashMessage({ type: 'error', title: t("Please accept conditions to continue") }))
        }
        updateReferenceConsent({ variables: {  
            caseId: foundCase._id, 
            referenceId: foundCase.references[0]?._id,
            consentGiven: true
        }}).then(res => {
            setCurrentStep(wasAccepted ? 2 : 1)
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        })
    }

    return (
        <div className={classes.wrapper}>
            <PrivacyPolicy 
                open={showPrivacyPolicy} 
                setOpen={(e) => setShowPrivacyPolicy(e)} company={foundCase?.company} 
            />
            <div className={classes.container}>
                <div className={classes.topBarContainer}>
                    <LanguageChange company={foundCase?.company} />   
                    <div className={classes.deadlineContainer}>
                        <LoadingBtn disabled style={ {minWidth: 150 }}>
                            <p className={classes.deadlineTitle}>{t("DEADLINE")}</p>
                        </LoadingBtn>
                        <div className={classes.counterContainer}>
                            <Countdown
                                daysInHours={true}
                                date={moment(parseInt(foundCase?.references[0]?.listingMailDate?.find(d => d !== null))).add(foundCase?.deadline?.answer, 'hours')}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.contentInnerContainer}>
                        <div className={classes.title}>
                            {t("Thank you for your valuable reference regarding your collaboration with ")}
                            <span style={{whiteSpace: 'nowrap'}}>{`${foundCase?.candidate?.firstName} ${foundCase?.candidate?.lastName}`}</span>
                        </div>
                        <div className={classes.description}>
                            {t("The reference is made in full compliance with applicable legislation and GDPR regulations. For more information, please read the ")} 
                            <p onClick={() => setShowPrivacyPolicy(true)} className={classes.privacyPolicyText}>{t("Privacy Policy")}</p>
                        </div>
                        <div>
                            <FormControl component="fieldset" >
                                <FormControlLabel 
                                    className={classes.radioButtonText} 
                                    onClick={(e) => setIsAccepted(!isAccepted)} 
                                    value="accept" 
                                    control={<Radio style={{ color: Colors.mainBlue }} checked={isAccepted} />} label={t("I accept the above terms")} 
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className={classes.bottomBarContainer}>
                    <LoadingBtn onClick={handleNext}>{t('Next')}</LoadingBtn>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    wrapper: {
        height: 'calc(100vh - 106px)', 
        display: 'flex', 
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'column', 
        height: '100%', 
        width: '70%', 
        borderLeft: '1px dashed black', 
        borderRight: '1px dashed black',
        padding: '0px 70px',
        '@media (max-width: 480px)': {
            width: '100%',
            padding: '0px 15px'
        }
    },
    topBarContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 480px)': {
            zoom: 0.7,
            alignItems: 'flex-start',
            paddingTop: 20 
        }
    },
    deadlineContainer: {
        display: 'flex',
        justifyContent: 'center', 
        position: 'relative'
    },
    deadlineTitle: {
        color: 'white',
        fontWeight: '500',
        fontSize: 15,
    },
    counterContainer: {
        position: 'absolute', 
        top: 40
    }, 
    contentContainer: {
        flex: 6, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'flex-start',
        marginTop: 100,
        '@media (max-width: 480px)': {
            alignItems: 'flex-start',
            marginTop: 0
        },
    },
    contentInnerContainer: {
        width: '50%',
        '@media (max-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 480px) and (max-width: 1100px)': {
            width: '70%'
        }
    },
    title: {
        textAlign: 'left',
        paddingBottom: '50px',
        fontWeight: '500',
        fontSize: '18px',
        
        '@media (max-width: 480px)': {
            textAlign: 'left',
            fontSize: 15,
            paddingBottom: '20px',
        },
    },
    description: {
        paddingBottom: '30px',
        '@media (max-width: 480px)': {
            paddingBottom: '20px',
        },
    },
    radioButtonText: {
        '@media (max-width: 480px)': {
            '& .MuiTypography-root': {
                fontSize: 15,
            },
        },
    },
    bottomBarContainer: {
        flex: 1, 
        justifyContent: 'center', 
        alignItems: 'center', 
        display: 'flex'
    },
    privacyPolicyText: {
        color: '#1700ff', 
        display: 'inline', 
        cursor: 'pointer'
    },
});

export default ReferenceConsent
