import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../styles/globalStyles';
import { useDispatch } from 'react-redux';
import { setQuestionElaborationAnswer } from '../redux/slices/answerQuestions';
import { useTranslation } from 'react-i18next';
import { getLanguageLocal } from '../utils/getLanguage';
import { notAnsweredTranslation, otherBodyTranslations, otherHeaderTranslations } from '../utils/constansts';

const useStyles = makeStyles({
    container: {
        width: '100%',
        paddingTop: '30px',
        '@media (max-width: 480px)': {
            zoom: 0.8,
        },
    },
    containerNoPad: {
        width: '100%',
        '@media (max-width: 480px)': {
            zoom: 0.8,
        },
    },
    label: {
        marginBottom: '20px', 
        fontSize: '20px', 
        fontWeight: '500', 
        marginLeft: '10px',
        '@media (max-width: 480px)': {
            marginBottom: 10,
            marginLeft: '5px',
        },
    },
    input:{
        width: '100%',
        paddingTop: '50px',

        '& .MuiTextField-root': {
            borderColor: props => props.color ? props.color :`${Colors.mainBlue} !important`,
        },
    },
    inputLabel: {
        color: props => props.color ? props.color :`${Colors.mainBlue} !important`,
    },
    notchedOutline: {
        borderColor: `${Colors.secondaryBlue} !important`
    },
});

export default function QuestionAnswerOther({ questionIndex, selectedLanguage, reference, showAnswers, isReport, questionId,  removePadding = false, isClosed = false }) {
    const classes = useStyles({color: Colors.mainBlue + '!important'});
    const dispatch = useDispatch();
    const [text, setText] = useState('')

    const { t } = useTranslation();

    useEffect(() => {
        if(isReport) {
            const foundQuestion = reference?.questions?.find(e => e?.question?._id === questionId);
            const txt = foundQuestion?.answer?.title;
            if((!txt || txt === '')) {
                if(selectedLanguage) {
                    const translation = notAnsweredTranslation?.translations?.find(e => e.language === selectedLanguage.shortName);
                    setText(translation?.name)
                } else {
                    setText('Not answered')
                }
            } else {
                setText(txt)
            }
        } else {
            let txt = reference?.questions[questionIndex]?.answer?.title;
            if((!txt || txt === '') && showAnswers && isReport) {
                const translation = notAnsweredTranslation?.translations?.find(e => e.language === selectedLanguage.shortName);
                setText(translation?.name)
            } else if(txt && showAnswers) {
                setText(txt);
            } else if(!txt && showAnswers) {
                setText('')
            }
        }
    }, [])

    const handleChange = () => {
        const newRef = {
            ...reference,
            questions: reference?.questions?.map((q, index) => {
                if(index === questionIndex) {
                    return {
                        ...q,
                        answer: {
                            ...q.answer,
                            title: text
                        }
                    }
                }
                return q
            })
        }

        dispatch(setQuestionElaborationAnswer(newRef))
    }

    return (
        <div className={removePadding ? classes.containerNoPad : classes.container}>
            <p className={classes.label}>{selectedLanguage ? getLanguageLocal(otherHeaderTranslations, selectedLanguage)?.name : t("Other")}</p>
            <TextField
                className={classes.input}
                id="outlined-textarea"
                label={isReport ? !isClosed ? `${reference?.firstName} ${reference?.lastName} (${reference?.type})` : reference?.type : selectedLanguage ? getLanguageLocal(otherBodyTranslations, selectedLanguage)?.name : t('Write here')}
                placeholder={t("Your answer")}
                onChange={(e) => setText(e.target.value)}
                value={text}
                multiline
                minRows={3}
                variant="outlined"
                onBlur={(e) => handleChange()}
                InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.inputLabel,
                        filled: classes.inputLabel,
                    },
                }}
            />
        </div>
    )
}
