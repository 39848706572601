import { useMutation, useQuery } from '@apollo/client';
import { TextField } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../components/Button';
import CustomSearchBar from '../../components/CustomSearchBar';
import EnhancedTable from '../../components/Table';
import { ALL_CASES, ALL_TEMPLATES, DELETE_CASE, DELETE_TEMPLATE } from '../../graphql/mutations';
import { setGuidePath, triggerFlashMessage } from '../../redux/slices/globalSlice';
import store from '../../redux/store';
import '../../sass/components/table.scss';
import { formatDate } from '../../utils/constansts';

import RoleContainer from '../../helpers/RoleContainer';
import { clearCase } from '../../redux/slices/createCaseSlice';
import { validateRole } from '../../utils/validators';

const dateLanguage = {
    en: enLocale,
    da: daLocale,
};

export default function Dashboard({ history }) {
    const globalState = useSelector(state => state.global);
    const authenticatedUser = store.getState().auth.user;

    const [templateList, setTemplateList] = useState([]);
    const [caseList, setCaseList] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [filteredCases, setFilteredCases] = useState([]);
    const [startDateTemplatesFilter, setStartDateTemplatesFilter] = useState(new Date());
    const [endDateTemplatesFilter, setEndDateTemplatesFilter] = useState(new Date());
    const [startDateCasesFilter, setStartDateCasesFilter] = useState(new Date());
    const [endDateCasesFilter, setEndDateCasesFilter] = useState(new Date());

    const t = str => str;

    const dispatch = useDispatch();

    const { data: casesData, refetch: caseRefetch } = useQuery(ALL_CASES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            companyId: authenticatedUser.company._id,
            userId: authenticatedUser._id,
            role: authenticatedUser.role,
        },
    });
    const { data: templatesData, refetch: templatesRefetch } = useQuery(ALL_TEMPLATES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            companyId: authenticatedUser.company._id,
            role: authenticatedUser.role,
        },
    });
    const [deleteTemplate] = useMutation(DELETE_TEMPLATE);
    const [deleteCase] = useMutation(DELETE_CASE);

    useEffect(() => {
        caseRefetch();
        templatesRefetch();
        dispatch(setGuidePath('dashboard'));
    }, []);

    useEffect(() => {
        setTemplateList(templatesData?.templates);
        setFilteredTemplates(templatesData?.templates);
        setCaseList(casesData?.cases);
        setFilteredCases(casesData?.cases);
    }, [casesData, templatesData]);

    useEffect(() => {
        dispatch(clearCase());
        caseRefetch();
        templatesRefetch();
    }, [history.location]);

    const templateHeaders = [
        { _id: 'createdAt', numeric: false, disablePadding: false, label: t('Date') },
        { _id: 'creator', numeric: false, disablePadding: false, label: t('Administrator') },
        { _id: 'jobPosition', numeric: false, disablePadding: false, label: t('Job-position') },
        { _id: 'templateName', numeric: false, disablePadding: false, label: t('Name of Template') },
        { _id: 'status', numeric: false, disablePadding: false, label: t('Status') },
    ];
    const caseHeaders = [
        { _id: 'createdAt', numeric: false, disablePadding: false, label: t('Date') },
        { _id: 'creator', numeric: false, disablePadding: false, label: t('Case-Owner') },
        { _id: 'jobPosition', numeric: false, disablePadding: false, label: t('Job-position') },
        { _id: 'candidate', numseric: false, disablePadding: false, label: t('Candidate') },
        { _id: 'status', numeric: false, disablePadding: false, label: t('Status') },
    ];

    useEffect(() => {
        dateTemplatesFilter();
    }, [startDateTemplatesFilter, endDateTemplatesFilter]);

    const dateTemplatesFilter = () => {
        if (templateList.length > 0) {
            setFilteredTemplates(
                templateList?.filter(a => {
                    let aDate = moment(parseInt(a.createdAt)).startOf('day');
                    let startDate = moment(startDateTemplatesFilter).startOf('day');
                    let endDate = moment(endDateTemplatesFilter).startOf('day');
                    return aDate.isSameOrAfter(startDate) && aDate.isSameOrBefore(endDate);
                })
            );
        }
    };
    useEffect(() => {
        dateCasesFilter();
    }, [startDateCasesFilter, endDateCasesFilter]);

    const dateCasesFilter = () => {
        if (caseList.length > 0) {
            setFilteredCases(
                caseList.filter(a => {
                    let aDate = moment(parseInt(a.createdAt)).startOf('day');
                    let startDate = moment(startDateCasesFilter).startOf('day');
                    let endDate = moment(endDateCasesFilter).startOf('day');
                    return aDate.isSameOrAfter(startDate) && aDate.isSameOrBefore(endDate);
                })
            );
        }
    };

    const handleDeleteTemplate = async id => {
        try {
            await deleteTemplate({ variables: { id } });
            const newTemplates = filteredTemplates.filter(t => t._id !== id);
            setFilteredTemplates(newTemplates);
            dispatch(triggerFlashMessage({ type: 'success', title: 'Template succesfully deleted' }));
        } catch (err) {
            dispatch(triggerFlashMessage({ type: 'error', title: 'Failed to delete template' }));
        }
    };

    const handleDeleteCase = async id => {
        try {
            await deleteCase({ variables: { id } });
            const newCases = filteredCases.filter(c => c._id !== id);
            setFilteredCases(newCases);
            dispatch(triggerFlashMessage({ type: 'success', title: 'Case succesfully deleted' }));
        } catch (err) {
            dispatch(triggerFlashMessage({ type: 'error', title: 'Failed to delete case' }));
        }
    };

    const handleCopyTemplate = async ({ _id }) => {
        history.push({
            pathname: '/create-case',
            search: `copy=template&templateId=${_id}`,
        });
    };

    const handleTemplateClick = async row => {
        history.push('/update-template/' + row?._id);
    };

    const handleCaseClick = fCase => {
        if (fCase.status === 'closed') {
            history.push('/view-closed/' + fCase?._id);
        } else {
            history.push('/update-case/' + fCase?._id);
        }
    };

    const handleCopyCase = async ({ _id }) => {
        history.push({
            pathname: '/create-case',
            search: `copy=case&caseId=${_id}`,
        });
    };

    const handleNewTemplate = () => {
        history.push('/create-template');
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-table-container">
                <div className="dashboard-table">
                    <div className="Dashboard-Search-Bar">
                        <div className="Search-Bar-Header">{t('TEMPLATES')}</div>
                        <div className="Custom-Search-Bar-Container">
                            <CustomSearchBar rows={templateList} onFilter={setFilteredTemplates} />
                        </div>
                        <div className="Search-Bar-Picker-Container">
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={dateLanguage[globalState?.language?.shortName]}
                            >
                                <div className="Desktop-Date-Picker-From">
                                    <DesktopDatePicker
                                        label={t('From')}
                                        inputFormat={formatDate(true)}
                                        value={startDateTemplatesFilter}
                                        showDaysOutsideCurrentMonth={true}
                                        ignoreInvalidInputs={true}
                                        onChange={newValue => {
                                            setStartDateTemplatesFilter(newValue);
                                        }}
                                        renderInput={params => <TextField size="small" {...params} helperText={null} />}
                                    />
                                </div>
                                <div>
                                    <DesktopDatePicker
                                        label={t('To')}
                                        inputFormat={formatDate(true)}
                                        value={endDateTemplatesFilter}
                                        showDaysOutsideCurrentMonth={true}
                                        ignoreInvalidInputs={true}
                                        onChange={newValue => {
                                            setEndDateTemplatesFilter(newValue);
                                        }}
                                        renderInput={params => <TextField size="small" {...params} helperText={null} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                        <div style={{ display: 'flex', marginLeft: 50 }}>
                            <RoleContainer user={authenticatedUser} roles={['SuperAdmin', 'AccountAdmin']}>
                                <CustomButton title={t('+ NEW TEMPLATE')} onClick={() => handleNewTemplate()} />
                            </RoleContainer>
                        </div>
                    </div>
                    <EnhancedTable
                        isTemplate={true}
                        showDeleteButton={true}
                        showCopyButton={true}
                        tableHeaders={templateHeaders}
                        tableData={filteredTemplates?.map(data => {
                            return data;
                        })}
                        canDelete={['SuperAdmin', 'AccountAdmin']}
                        handleDelete={handleDeleteTemplate}
                        handleCopy={handleCopyTemplate}
                        canEdit={validateRole(authenticatedUser, ['SuperAdmin', 'AccountAdmin'])}
                        handleClick={
                            validateRole(authenticatedUser, ['SuperAdmin', 'AccountAdmin', 'CaseDesign'])
                                ? handleTemplateClick
                                : () => {}
                        }
                    />
                </div>
            </div>
            <div className="dashboard-table-container">
                <div className="dashboard-table">
                    <div className="Dashboard-Search-Bar">
                        <div className="Search-Bar-Header">{t('CASES')}</div>
                        <div className="Custom-Search-Bar-Container">
                            <CustomSearchBar rows={caseList} onFilter={setFilteredCases} />
                        </div>
                        <div className="Search-Bar-Picker-Container">
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={dateLanguage[globalState?.language?.shortName]}
                            >
                                <div className="Desktop-Date-Picker-From">
                                    <DesktopDatePicker
                                        label={t('From')}
                                        inputFormat={formatDate(true)}
                                        value={startDateCasesFilter}
                                        showDaysOutsideCurrentMonth={true}
                                        ignoreInvalidInputs={true}
                                        onChange={newValue => {
                                            setStartDateCasesFilter(newValue);
                                        }}
                                        renderInput={params => <TextField size="small" {...params} helperText={null} />}
                                    />
                                </div>
                                <div>
                                    <DesktopDatePicker
                                        label={t('To')}
                                        inputFormat={formatDate(true)}
                                        value={endDateCasesFilter}
                                        showDaysOutsideCurrentMonth={true}
                                        ignoreInvalidInputs={true}
                                        onChange={newValue => {
                                            setEndDateCasesFilter(newValue);
                                        }}
                                        renderInput={params => <TextField size="small" {...params} helperText={null} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                        <div style={{ display: 'flex', marginLeft: 50 }}>
                            <RoleContainer
                                user={authenticatedUser}
                                roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign']}
                            >
                                <CustomButton title={t('+ NEW CASE')} onClick={() => history.push('/create-case')} />
                            </RoleContainer>
                        </div>
                    </div>
                    <EnhancedTable
                        showDeleteButton={true}
                        showCopyButton={true}
                        tableHeaders={caseHeaders}
                        handleClick={handleCaseClick}
                        tableData={filteredCases?.map(data => {
                            let dataFormat = {
                                _id: data._id,
                                createdAt: data.createdAt,
                                creator: data.creator,
                                jobPosition: data.jobPosition,
                                candidate: data.candidate,
                                status: data.status,
                                candidateName: data.candidateName,
                                creatorName: data.creatorName,
                            };
                            return dataFormat;
                        })}
                        canDelete={['SuperAdmin']}
                        handleDelete={handleDeleteCase}
                        canEdit={validateRole(authenticatedUser, [
                            'SuperAdmin',
                            'AccountAdmin',
                            'CaseDesign',
                            'TemplateOnly',
                        ])}
                        handleCopy={handleCopyCase}
                        caseData={filteredCases}
                    />
                </div>
            </div>
        </div>
    );
}
