import store from "../redux/store";
import moment from 'moment';
import { getLanguageLocal } from "../utils/getLanguage";

export const formatCase = (caseState, currentUser, currentState, isTemplateString, references) => {
    let variables = Object.assign({}, caseState);

    variables.creator = currentUser?._id;
    variables.company = currentUser?.company?._id;
    variables.deleteDate = String(store.getState().createCase.case.deleteDate)        

    const isEdit = currentState === 'Edit';
    const isCopy = currentState === 'Copy';
    const isTemplate = isTemplateString === 'Template';
    
    if(!isEdit) {
        variables.references = references?.map(ref => {
            const newRef = { ...ref };
            delete newRef?._id; 
            return newRef;
        });
    } else {
        variables.references = references?.map(ref => {
            if(typeof ref?._id !== 'string'){
                const newRef = {...ref}
                delete newRef?._id;
                return newRef
            } else {
                return ref
            }
        })
    } 

    const deadlineListing = variables?.deadline?.listing;
    const deadlineAnswer = variables?.deadline?.answer

    variables.deadline = Object.assign({}, {
        listing: parseInt(deadlineListing.split(' ')[0]),
        answer: parseInt(deadlineAnswer.split(' ')[0])
    });

    if(isEdit || isCopy) {
        variables.stakeholders = variables?.stakeholders?.map(stakeholder => {
            if(!stakeholder?.user) return { user: stakeholder?._id, inviteMailDate: new Date() } 
            else return { user: stakeholder?.user?._id, inviteMailDate: stakeholder?.inviteMailDate }
        })
    } else {
        variables.stakeholders = variables?.stakeholders?.map(stakeholder => ({ user: stakeholder._id }))
    }

    variables.selectedQuestions = variables.selectedQuestions.map(question => {
        const formatted = Object.assign({}, question);
        delete formatted?.new;
        return formatted
    })

    if(isTemplate && (!isCopy && !isEdit)) {
        delete variables?._id;
        delete variables.candidate;
        delete variables.candidateAnswer
    } 

    if(isTemplate && isEdit) {
        delete variables.candidate;
        delete variables.candidateAnswer
        delete variables.deleteDate;
    }

    if(!isTemplate && !isEdit) {
        delete variables?._id;
    }

    if(!isTemplate && isEdit) {
        delete variables?.createdAt
    }
    if(!isTemplate){
        variables.sendCandidateInvite = false;
    }

    return variables;
}

export const formatCreateCase = (caseState, currentUser, isUpdate = false) => {
    let variables = Object.assign({}, caseState);

    variables.deleteDate = variables?.deleteDate ? String(variables?.deleteDate) : String(moment().add('90', 'd').valueOf())

    const deadlineListing = variables?.deadline?.listing;
    const deadlineAnswer = variables?.deadline?.answer;
    variables.deadline = Object.assign({}, {
        listing: parseInt(deadlineListing.split(' ')[0]),
        answer: parseInt(deadlineAnswer.split(' ')[0])
    });

    variables.creator = currentUser?._id;
    variables.company = currentUser?.company?._id;

    if(!isUpdate) {
        variables.references = variables?.references?.map(({ _id, ...rest }) => rest);
    } else {
        variables.references = variables?.references?.map(ref => {
            if(typeof ref?._id === "number") {
                const newRef = Object.assign({}, ref);
                delete newRef?._id;
                return newRef;
            } else {
                return ref;
            }
        })
    }

    variables.references = variables.references?.map(ref => {
        const withTheme = formatCaseWithTheme(ref?.questions);
        const ordered = formatCaseOrder(withTheme);

        let newQuestions = [];
        ordered?.forEach(element => {
            newQuestions?.push(...element?.questions?.map(q => ({ question: q?.question, answer: q?.answer })))
        });

        return {
            ...ref,
            questions: newQuestions
        }
    })

    variables.stakeholders = variables?.stakeholders?.map(stakeholder => {
        if(stakeholder?.user) {
            return {
                user: stakeholder?.user?._id,
                inviteMailDate: stakeholder?.inviteMailDate
            }
        }

        return {
            user: stakeholder?._id
        }
    })

    return variables
}

export const formatCaseWithTheme = (arr) => {
    return arr?.map(el => ({
        ...el,
        id: el?.question?._id,
        content: getLanguageLocal(el?.question?.theme, { shortName: 'en' })?.name
    }))
}

export const formatCaseOrder = (arr) => {
    const uniqueElements = new Set(arr?.map(el => el.content))
    const uniqueArray = Array.from(uniqueElements);

    const formatted = uniqueArray.map(e => ({
        e,
        id: e,
        questions: arr.filter(question => question.content === e),
    }));


    const introQuestions = formatted.filter((obj) => obj?.e === 'Intro & Qualifying');
    const restQuestions = formatted.filter((obj) => obj?.e !== 'Outro' && obj?.e !== 'Intro & Qualifying');
    const outroQuestions = formatted.filter((obj) => obj?.e === 'Outro');

    const merged = [].concat(introQuestions).concat(restQuestions).concat(outroQuestions);
    return merged;
}

export const replaceQuestionString = (string, name, jobPosition, theme, isClosed = false) => {
    let newStr = string;
    if(name && !isClosed) {
        newStr = newStr?.replace("[Kandidat]", name)
        ?.replace("[Candidate]", name)
        ?.replace("[Kandidat fornavn]", name)
        ?.replace("[Candidate first name]", name)
    }

    if(jobPosition) {
        newStr = newStr?.replace('[Job-position]', jobPosition);
    }

    if(theme) {
        newStr = newStr?.replace('[Theme]', theme)
        ?.replace('[Tema]', theme);
    }

    return newStr;
}

export const replaceRatingPointString = (string, name, jobPosition, theme, isClosed = false) => {
    let newStr = string;
    if(name && !isClosed) {
        newStr = newStr?.replace("[Kandidat] ", name + '\n')
        ?.replace("[Candidate]" , name + '\n')
        ?.replace("[Kandidat fornavn] ", name + '\n')
        ?.replace("[Candidate first name] ", name + '\n')
    }

    if(jobPosition) {
        newStr = newStr?.replace('[Job-position]', jobPosition);
    }

    if(theme) {
        newStr = newStr?.replace('[Theme]', theme)
        ?.replace('[Tema]', theme);
    }

    return newStr;
}

export const formatTemplate = (caseState, currentUser, isUpdate = false) => {
    let variables = Object.assign({}, caseState);

    delete variables.deleteDate;
    delete variables.candidate;

    const deadlineListing = variables?.deadline?.listing;
    const deadlineAnswer = variables?.deadline?.answer;
    variables.deadline = Object.assign({}, {
        listing: parseInt(deadlineListing.split(' ')[0]),
        answer: parseInt(deadlineAnswer.split(' ')[0])
    });

    variables.creator = currentUser?._id;
    variables.company = currentUser?.company?._id;

    variables.references = variables?.references?.map(({ _id, ...rest }) => rest);
    if(isUpdate) {
        variables.stakeholders = variables?.stakeholders?.map(stakeholder => {
            if(!stakeholder?.user) return { user: stakeholder?._id, inviteMailDate: new Date() } 
            else return { user: stakeholder?.user?._id, inviteMailDate: stakeholder?.inviteMailDate }
        })
    } else {
        variables.stakeholders = variables?.stakeholders?.map(stakeholder => ({ user: stakeholder._id }))
    }

    variables.references = variables.references?.map(ref => {
        const withTheme = formatCaseWithTheme(ref?.questions);
        const ordered = formatCaseOrder(withTheme);

        let newQuestions = [];
        ordered?.forEach(element => {
            newQuestions?.push(...element?.questions?.map(q => ({ question: q?.question })))
        });

        return {
            ...ref,
            questions: newQuestions
        }
    })

    return variables
}

export const sortThemes = (themes) => {
    themes?.sort(function(a, b){
        if(a.title < b.title) { return -1; }
        if(a.title > b.title) { return 1; }
        return 0;
    })

    let sortArray = themes?.filter(t => t?.title?.includes('Intro'));
    let outroObj = {}
    themes?.forEach(t => {
        if(t?.title?.includes('Outro')) outroObj = t;
        else !t?.title?.includes('Intro') && sortArray.push(t);
    })
    outroObj !== {} && sortArray?.push(outroObj)

    return sortArray;
}

export const formatReferenceAnswer = (reference, questionIndex, answers, title) => {
    return {
        ...reference,
        questions: reference?.questions?.map((q, index) => {
            if(index === questionIndex) {
                return {
                    ...q,
                    answer: {
                        ...q?.answer,
                        answers: answers ? answers : q?.answer?.answers,
                        title: title !== null ? title : q?.answer?.title
                    }
                }
            }

            return q
        })
    }
}

export const formatReferenceAnswer2 = (reference, questionId, answers, title) => {
    return {
        ...reference,
        questions: reference?.questions?.map((q, index) => {
            if(q?.question?._id === questionId) {
                return {
                    ...q,
                    answer: {
                        ...q?.answer,
                        answers: answers ? answers : q?.answer?.answers,
                        title: title !== null ? title : q?.answer?.title
                    }
                }
            }

            return q
        })
    }
}

export const checkReferenceHasAnswer = (ref) => {
    return ref?.questions?.some(question => question?.answer !== null);
}

export const checkAllReferencesHasAnswer = (references) => {
    const answered = references?.map(ref => {
        return ref?.questions?.some(question => question?.answer !== null);
    })

    let num = 0;

    answered.forEach(e => {
        if(e === true) {
            num++
        }
    });

    return num >= 2;
}
