import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import LanguageChange from '../../components/LanguageChange';
import LoadingBtn from '../../components/LoadingButton';
import Countdown from 'react-countdown';
import moment from 'moment';

function ReferenceIntro({ setCurrentStep, foundCase }) {
    const { t } = useTranslation();

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.topBarContainer}>
                    <LanguageChange company={foundCase?.company} />   
                    <div className={classes.deadlineContainer}>
                        <LoadingBtn
                            disabled
                            style={{minWidth: 150}}
                        >
                            <p className={classes.deadlineTitle}>{t("DEADLINE")}</p>
                        </LoadingBtn>
                        <div className={classes.counterContainer}>
                            <Countdown
                                daysInHours={true}
                                date={moment(parseInt(foundCase?.references[0]?.listingMailDate?.find(d => d !== null))).add(foundCase?.deadline?.answer, 'hours')}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.contentInnerContainer}>
                        <p className={classes.title}>
                            {t('Please provide your reference based solely on your own personal assessment, observations and experience of working with ')}
                            <span style={{whiteSpace: 'nowrap'}}>{`${foundCase?.candidate?.firstName} ${foundCase?.candidate?.lastName}`}</span>
                        </p>
                    </div>
                </div>
                <div className={classes.bottomBarContainer}>
                    <LoadingBtn onClick={() => setCurrentStep(2)}>{t('Next')}</LoadingBtn>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    wrapper: {
        height: 'calc(100vh - 106px)', 
        display: 'flex', 
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'column', 
        height: '100%', 
        width: '70%', 
        borderLeft: '1px dashed black', 
        borderRight: '1px dashed black',
        padding: '0px 70px',
        '@media (max-width: 480px)': {
            width: '100%',
            padding: '0px 15px'
        }
    },
    topBarContainer: {
        flex: 1, 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 480px)': {
            zoom: 0.7,
            alignItems: 'flex-start',
            paddingTop: 20 
        }
    },
    deadlineContainer: {
        display: 'flex',
        justifyContent: 'center', 
        position: 'relative'
    },
    deadlineTitle: {
        color: 'white',
        fontWeight: '500',
        fontSize: 15,
    },
    counterContainer: {
        position: 'absolute', 
        top: 40
    }, 
    contentContainer: {
        flex: 6, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'flex-start',
        marginTop: 100,
        '@media (max-width: 480px)': {
            alignItems: 'flex-start',
            marginTop: 0
        }
    },
    contentInnerContainer: {
        width: '50%',
        '@media (max-width: 480px)': {
            width: '90%'
        }
    },
    title: {
        textAlign: 'center',
        paddingBottom: '50px',
        fontWeight: '500',
        fontSize: '18px',
        '@media (max-width: 480px)': {
            textAlign: 'left',
            fontSize: 15,
            paddingBottom: '20px',
        },
    },
    bottomBarContainer: {
        flex: 1, 
        justifyContent: 'center', 
        alignItems: 'center', 
        display: 'flex'
    },
});

export default ReferenceIntro
