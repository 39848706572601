import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    container: {
        width: '40%',
        alignItems: 'center',
    },
    label: {
        marginBottom: '20px', 
        fontSize: '14px', 
        fontWeight: '500', 
        margin: '0px 10px',
    },
    inputLabel: {
        marginBottom: '20px', 
        fontWeight: '500', 
        margin: '0px 10px',
        fontSize: '20px !important',
        '&.Mui-disabled::before': {
            borderBottom: '0px solid !important'
        },
    },
});

export default function QuestionAnwserDisplayName({ label, value }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <p className={classes.label}>{label}</p>
            <p className={classes.inputLabel}>{value}</p>
        </div>
    )
}
