import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const createCaseSlice = createSlice({
	name: 'createCase',
	initialState: {
		activePage: 'Case info',
		activeState: '',
		isTemplate: '',
		activeStep: 0,
		pageHistory: [],
		submitted: false,
		theme: { _id: null, title: 'All'},
		category: { _id: null, title: 'All'},
		selectedRef: {},
		references: [],
		referencesQuestions: {
			refIndex: '',
			questions: []
		},
		case: {
			jobPosition: '',
			stakeholders: [],
			references: [],
			deadline: {
				listing: '48 hours',
				answer: '48 hours'
			},
			deleteDate: '',
			candidate: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				mobile: '',
				preferedLanguage: '',
			},
			templateName: ''
		},
		caseCopy: {}
	},
	reducers: {
	    setActivePage: (state, action) => {
            state.activePage = action.payload
		},
		setActiveState: (state, action) => {
			state.activeState = action.payload
		},
		setTheme: (state, action) => {
			state.theme = action.payload;
		},
		setCategory: (state, action) => {
			state.category = action.payload
		},
		setStakeholders: (state, action) => {
			state.case.stakeholders = [...action.payload]
		},
		setReferences: (state, action) => {
			state.case.references = [].concat(action.payload)
		},
		setReferences2: (state, action) => {
			// const introQuestions = action.payload?.filter((questionObj) => questionObj.question.theme && questionObj.question.theme.translations.find(t => t.language === 'en').name === 'Intro & Qualifying')
			// const restQuestions = action.payload?.filter((questionObj) => questionObj.question.theme && questionObj.question.theme.translations.find(t => t.language === 'en').name !== 'Outro' && questionObj.question.theme.translations.find(t => t.language === 'en').name !== 'Intro & Qualifying')
			// const outroQuestions = action.payload?.filter((questionObj) => questionObj.question.theme && questionObj.question.theme.translations.find(t => t.language === 'en').name === 'Outro')
			
			state.references = [].concat(action.payload)
		},
		addQuestion: (state, action) => {
			state.case.selectedQuestions = state.case.selectedQuestions.concat(action.payload)
		},
		setDeadlineListing: (state, action) => {
			state.case.deadline.listing = action.payload;
		},
		setDeadlineAnswer: (state, action) => {
			state.case.deadline.answer = action.payload;
		},
		setCandidate: (state, action) => {
			state.case.candidate = action.payload
		},
		updateCandidate: (state, action) => {
			state.case.candidate[action.payload.key] = action.payload.value
		},
		setDeleteDate: (state, action) => {
			state.case.deleteDate = action.payload
		},
		setSelectedQuestions: (state, action) => {
			state.case.selectedQuestions = action.payload;
		},
		setJobPosition: (state, action) => {
			state.case.jobPosition = action.payload
		},
		setCandidateAnswer: (state, action) => {
			state.case.candidateAnswer = !state.case.candidateAnswer
		},
		setReferenceQuestions: (state, action) => {
			state.case.references = action.payload
		},
		setCase: (state, action) => {
			state.case = { ...action.payload }
		},
		setTemplateName: (state, action) => {
			state.case.templateName = action.payload
		},
		setIsTemplate: (state, action) => {
			state.isTemplate = action.payload
		},
		setCaseSelectedRef: (state, action) => {
			state.selectedRef = action.payload
		},
		setSubmitted: (state, action) => {
			state.submitted = true;
		},
		setPageHistory: (state, action) => {
			state.pageHistory = action.payload
		},
		setActiveStep: (state, action) => {
			state.activeStep = action.payload
		},
		clearCase: (state, action) => {
			state.case = {
				jobPosition: '',
				stakeholders: [],
				references: [],
				deadline: {
					listing: '48 hours',
					answer: '48 hours'
				},
				deleteDate: moment().add(90, 'd').valueOf(),
				candidate: {
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					mobile: ''
				},
				candidateAnswer: false,
			}
			state.activeStep = 0;
		},
		setCaseCopy: (state, action) => {
			state.caseCopy = action.payload
		}
	}
});

export const {
  setActivePage,
  setActiveState,
  setTheme,
  setCategory,
  setStakeholders,
  setReferences,
  setReferences2,
  setDeadlineListing,
  setDeadlineAnswer,
  setCandidate,
  setDeleteDate,
  setSelectedQuestions,
  setJobPosition,
  setCandidateAnswer,
  setCandidateFirstName,
  updateCandidate,
  setReferenceQuestions,
  setReferencesQuestions,
  setCase,
  clearCase,
  setTemplateName,
  setIsTemplate,
  setCaseSelectedRef,
  addQuestion,
  setSubmitted,
  setPageHistory,
  setActiveStep,
  setCaseCopy
} = createCaseSlice.actions


export default createCaseSlice.reducer;