import { gql } from '@apollo/client'

export const LOGIN_NOW = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email password: $password){
            _id,
            token,
            firstName,
            lastName,
            email,
            company {
                _id
                branding{
                    colors
                    logo{
                        name
                        base64
                    }
                }
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultQALanguage
                    defaultLanguage
                },
                questionHideFilterList
            },
            role
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($email: String!) {
        resetPassword(email: $email){
            message
            token
        }
    }
`;

export const VALIDATE_PASSWORD_TOKEN = gql`
    mutation validatePassword($token: String!) {
        validatePassword(token: $token){
            message
        }
    }
`;

export const VALIDATE_INVITE = gql`
    mutation validateInvite($token: String!) {
        validateInvite(token: $token){
            token,
            role,
            company{
                _id
            },
            email,
        }
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation updatePassword($token: String!, $password: String!) {
        updatePassword(token: $token, password: $password){
            message
        }
    }
`;

export const INVITE_USER = gql`
    mutation inviteUser($company: String, $email: String, $role: String, $sender: String) {
        inviteUser(company: $company, email: $email, role: $role, sender: $sender){
            message
        }
    }
`;


export const COMPANY_BY_ID = gql`
    query company($id: String!) {
        company(id: $id){
            _id
            name
            address
            address2
            city
            zipCode
            country
            phone
            EAN
            settings {
                languages {
                    shortName
                    fullName
                    englishName
                }
                defaultLanguage
                defaultQALanguage
            }
            defaultLanguage
            defaultDeleteDate
            defaultDatebase
            branding {
                logo {
                    base64
                    name
                }
                useDefault
                colors
            }
            questionHideFilterList
        }
    }
`;

export const UPDATE_COMPANY = gql`
    mutation updateCompany($input: CompanyInput!) {
        updateCompany(input: $input) {
            _id
            settings {
                defaultLanguage
                defaultQALanguage
                languages {
                    shortName
                    fullName
                }
            }
        }
    }
`;

export const UPDATE_DRAFT_CASE = gql`
    mutation updateCaseDraft($input: CaseInput!) {
        updateCaseDraft(input: $input) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                    defaultQALanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                answerListDate
                answerDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            }
            createdAt
            status
        }
    }
`;

export const UPDATE_CASE = gql`
    mutation updateCase($input: CaseInput!) {
        updateCase(input: $input) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                    defaultQALanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                answerListDate
                answerDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`;

export const UPDATE_LIVE_CASE = gql`
    mutation updateCaseLive($input: CaseInput!) {
        updateCaseLive(input: $input) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                    defaultQALanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                answerListDate
                answerDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`;

export const UPDATE_TEMPLATE = gql`
    mutation updateTemplate($input: TemplateInput!) {
        updateTemplate(input: $input) {
            _id
        }
    }
`;

export const UPDATE_LISTING = gql`
    mutation updateListing($input: CaseInput) {
        updateListing(input: $input) {
            _id
        }
    }
`;

export const UPDATE_REFERENCE = gql`
    mutation updateReference($caseId: String, $referenceId: String, $firstName: String, $lastName: String, $email: String, $phone: String, $mobile: String, $language: String, $linkedIn: String) {
        updateReference(caseId: $caseId, referenceId: $referenceId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, mobile: $mobile, language: $language, linkedIn: $linkedIn) {
            _id
        }
    }
`;

export const LIST_REFERENCE = gql`
    mutation listReference($caseId: String, $referenceId: String, $firstName: String, $lastName: String, $email: String, $phone: String, $mobile: String, $language: String, $linkedIn: String) {
        listReference(caseId: $caseId, referenceId: $referenceId, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, mobile: $mobile, language: $language, linkedIn: $linkedIn) {
            _id
        }
    }
`;


export const UPDATE_REFERENCE_CONSENT = gql`
    mutation updateReferenceConsent($caseId: String, $referenceId: String, $consentGiven: Boolean) {
        updateReferenceConsent(caseId: $caseId, referenceId: $referenceId, consentGiven: $consentGiven) {
            _id
        }
    }
`;



export const COMPANY_ADMINISTRATORS = gql`
    query companyAdministrators($companyId: String!) {
        companyAdministrators(companyId: $companyId) {
            _id
            firstName
            lastName
            email
            phone
            mobile
            role
            title
            createdAt
        }
    }
`

export const USER_BY_ID = gql`
    query user($id: String!) {
        user(id: $id) {
            _id,
            token,
            firstName,
            lastName,
            email,
            company {
                _id
                defaultDeleteDate
                branding {
                    useDefault,
                    logo {
                        name,
                        base64
                    },
                    colors
                }
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultQALanguage
                    defaultLanguage
                },
                questionHideFilterList
            },
            role
        }
    }
`
export const GET_USERS = gql`
    query users {
        users {
            _id,
            firstName,
            lastName,
            title,
            phone,
            mobile,
            email,
            role,
        }
    }
`

export const COMPANY_CASE_OWNERS = gql`
    query companyCaseOwners($companyId: String!) {
        companyCaseOwners(companyId: $companyId) {
            _id
            firstName
            lastName
            email
            phone
            mobile
            role
            title
            createdAt
        }
    }
`
export const COMPANY_USERS = gql`
    query usersByCompany($companyId: String!) {
        usersByCompany(companyId: $companyId) {
            _id
            firstName
            lastName
            email
            phone
            mobile
            role
            title
            createdAt
        }
    }
`

export const CREATE_USER = gql`
    mutation register($input: UserInput!) {
        register(input: $input) {
            _id
            token
        }
    }
`

export const DELETE_USER = gql`
    mutation deleteUser($id: String!) {
        deleteUser(id: $id) {
            _id
        }
    }
`

export const UPDATE_USER = gql`
    mutation updateUser($input: UserInput!) {
        updateUser(input: $input) {
            _id
        }
    }
`

export const ALL_CASES = gql`
    query ($companyId: String!, $userId: String, $role: String) {
        cases(companyId: $companyId, userId: $userId, role: $role) {
            _id
            jobPosition
            createdAt
            creator {
                _id
                firstName
                lastName
            }
            creatorName
            jobPosition
            candidate {
                firstName
                lastName
                email
                mobile
                phone
                listingMailDate
                halftimeDates {
                    send
                    dates
                }
                deadlineDates {
                    send
                    dates
                }
            }
            deadline {
                listing
                answer
            }
            candidateName
            status
            references {
                email
                listingMailDate
                listingMailSend
                answerStatus
                halftimeDates {
                    send
                    dates
                }
                deadlineDates {
                    send
                    dates
                }
                answerStatus
            }
        }
    }
`

export const ALL_INVOICE_CASES = gql`
    query ($companyId: String!, $userId: String, $role: String) {
        cases(companyId: $companyId, userId: $userId, role: $role) {
            _id
            jobPosition
            createdAt
            deleteDate
            company {
                name
            }
            creator {
                _id
                firstName
                lastName
            }
            creatorName
            jobPosition
            candidate {
                firstName
                lastName
            }
            status
            references {
                type
                answerStatus
                answerDate
            }
        }
    }
`

export const CASE_BY_ID = gql`
    query($id: String!) {
        case(id: $id) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                    defaultQALanguage
                }
                branding {
                    useDefault
                    logo {
                        base64
                        name
                    }
                }
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
                halftimeDates {
                    send
                    dates
                }
                deadlineDates {
                    send
                    dates
                }
                preferedLanguage
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                listedDate
                consentGiven
                consentIP
                language
                answerStatus
                answerDate
                answerListDate
                halftimeDates {
                    send
                    dates
                }
                deadlineDates {
                    send
                    dates
                }
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                        theme {
                            translations {
                                language
                                name
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`

export const TEMPLATE_BY_ID = gql`
    query($id: String!) {
        template(id: $id) {
            _id
            templateName
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            status
            selectedQuestions {
                question {
                    _id
                    duration
                    elaboration
                    translations {
                        language
                        name
                    }
                    company {
                        _id
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                                description
                            }
                        }
                    }
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                        theme {
                            translations {
                                language
                                name
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
        }
    }
`

export const GET_FULL_CASE_BY_REFERENCE_ID = gql`
    query($caseId: String!, $referenceId: String!) {
        caseByReference(caseId: $caseId, referenceId: $referenceId) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                    defaultQALanguage
                }
                branding {
                    useDefault
                    logo {
                        base64
                        name
                    }
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    company {
                        _id
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                listingMailSend
                listingMailDate
                consentGiven
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                consentGiven
                consentIP
                language
                pageNumber
                answerStatus
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                        theme {
                            translations {
                                language
                                name
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`
export const GET_CASE_BY_REFERENCE_ID = gql`
    query($caseId: String!, $referenceId: String!) {
        caseByReference(caseId: $caseId, referenceId: $referenceId) {
            _id
            jobPosition
            deadline {
                listing
                answer
            }
            candidate {
                firstName
                lastName
                ipAddress
            }
            references {
                _id
                type
                email
                firstName
                lastName
                consentGiven
                consentIP
                language
                listingMailDate
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                    defaultQALanguage
                }
                branding {
                    logo {
                        name
                        base64
                    }
                }
            }
        }
    }
`

export const ALL_TEMPLATES = gql`
    query($companyId: String!, $role: String){
        templates(companyId: $companyId, role: $role) {
            _id
            createdAt
            creator {
                firstName
                lastName
            }
            creatorName
            jobPosition
            templateName
            status
        }
    }
`

export const DELETE_TEMPLATE = gql`
    mutation deleteTemplate($id: String!) {
        deleteTemplate(id: $id) {
            _id
        }
    }
`

export const DELETE_CASE = gql`
    mutation deleteCase($id: String!) {
        deleteCase(id: $id) {
            _id
        }
    }
`

export const GET_THEMES = gql`
    query themes($companyId: String!) {
        themes(companyId: $companyId) {
            _id,
            translations {
                language
                name
            }
    
        }
    }
`

export const GET_CATEGORIES = gql`
    query categories($companyId: String!) {
        categories(companyId: $companyId) {
            _id
            translations {
                language
                name
            }
    
        }
    }
`

export const GET_QUESTIONS = gql`
    query questions($companyId: String!, $themeId: String, $categoryId: String, $hidden: Boolean) {
        questions(companyId: $companyId, themeId: $themeId, categoryId: $categoryId, hidden: $hidden) {
            _id
            translations {
                language
                name
            }
            company {
                _id
                name
                questionHideFilterList
            }
            elaboration
            scale {
                _id
                choices
                translations {
                    language
                    name
                }
                type
            }
            theme {
                _id
                translations {
                    language
                    name
                }
            }
            answer {
                translations {
                    language
                    name
                    answers {
                        title
                        description
                    }
                }
                type
            }
            duration
            hidden
            createdAt
        }
    }
`

export const GET_SCALES = gql`
    query scales($companyId: String!) {
        scales(companyId: $companyId) {
            _id,
            choices
            maxAnswers
            translations {
              language
              name
            }
            type
        }
    }
`

export const GET_ANSWERS = gql`
    query answers($companyId: String!, $type: String) {
        answers(companyId: $companyId, type: $type) {
            _id
            translations {
              language
              name
              answers {
                title
              }
            }
            type
        }
    }
`

export const ADD_QUESTION = gql`
    mutation addQuestion($category: String, $theme: String, $company: String, $scale: String, $answer: String, $translations: [TranslationModelInput], $type: String, $duration: Int) {
        addQuestion(input: {
            scale: $scale
            category: $category
            company: $company
            theme: $theme
            translations: $translations
            answer: $answer
            type: $type
            duration: $duration
        }) {
            _id
            translations {
                language
                name
            }
            company {
                _id
                name
            }
            elaboration
            scale {
                _id
                choices
                translations {
                    language
                    name
                }
                type
            }
            theme {
                _id
                translations {
                    language
                    name
                }
            }
            answer {
                translations {
                    language
                    name
                    answers {
                        title
                        description
                    }
                }
                type
            }
            duration
        }
    }
`
export const ADD_ANSWER = gql`
    mutation addAnswer($translations: [TranslationModelInput], $company: String, $type: String ) {
        addAnswer(input: {
            translations: $translations
            company: $company
            type: $type
        }) {
            _id
            translations {
                language
                name
                answers {
                    _id
                    title
                    description
                }
            }
            company {
                _id
                name
            }
            type
        }
    }
`

export const HIDE_QUESTION = gql`
    mutation updateQuestion($id: String!, $hidden: Boolean) {
        updateQuestion(input: {
            _id: $id
            hidden: $hidden
        }) {
            _id
        }
    }
`

export const UPDATE_HIDE_QUESTION_Filter = gql`
    mutation updateHideQuestion($id: String!, $questionID: String) {
        updateHideQuestion(input: {
            _id: $id
            questionID: $questionID
        }) {
            _id
        }
    }
`

export const SET_DEFAULT_ELABORATION = gql`
    mutation updateQuestion($id: String!, $elaboration: Boolean) {
        updateQuestion(input: {
            _id: $id
            elaboration: $elaboration
        }) {
            _id
        }
    }
`

export const SET_QUESTION_ELABORATION = gql`
    mutation updateQuestion($id: String!, $elaboration: Boolean) {
        updateQuestion(input: {
            _id: $id
            elaboration: $elaboration
        }) {
            _id
        }
    }
`

export const INVITE_CANDIDATE = gql`
mutation inviteCandidate($stakeholders: [StakeholderInput], $company: String, $jobPosition: String, $deadline: DeadlineInput, $deleteDate: String, $candidate: CandidateInput, $creator: String, $references: [ReferenceInput], $selectedQuestions: [SelectedQuestionModelInput], $candidateAnswer: Boolean, $status: String) {
        inviteCandidate(input: {
            stakeholders: $stakeholders
            company: $company
            jobPosition: $jobPosition
            deadline: $deadline
            deleteDate: $deleteDate
            candidate: $candidate
            creator: $creator,
            references: $references
            selectedQuestions: $selectedQuestions
            candidateAnswer: $candidateAnswer
            status: $status
        }) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`

export const ADD_CASE = gql`
    mutation addCase($stakeholders: [StakeholderInput], $company: String, $jobPosition: String, $deadline: DeadlineInput, $deleteDate: String, $candidate: CandidateInput, $creator: String, $references: [ReferenceInput], $selectedQuestions: [SelectedQuestionModelInput], $candidateAnswer: Boolean, $status: String, $sendCandidateInvite: Boolean) {
        addCase(input: {
            stakeholders: $stakeholders
            company: $company
            jobPosition: $jobPosition
            deadline: $deadline
            deleteDate: $deleteDate
            candidate: $candidate
            creator: $creator,
            references: $references
            selectedQuestions: $selectedQuestions
            candidateAnswer: $candidateAnswer
            status: $status
            sendCandidateInvite: $sendCandidateInvite
        }) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                        
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                title
                                description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`
export const ADD_CASE_DRAFT = gql`
    mutation addCaseDraft($stakeholders: [StakeholderInput], $company: String, $jobPosition: String, $deadline: DeadlineInput, $deleteDate: String, $candidate: CandidateInput, $creator: String, $references: [ReferenceInput], $selectedQuestions: [SelectedQuestionModelInput], $candidateAnswer: Boolean, $status: String, $sendCandidateInvite: Boolean) {
        addCaseDraft(input: {
            stakeholders: $stakeholders
            company: $company
            jobPosition: $jobPosition
            deadline: $deadline
            deleteDate: $deleteDate
            candidate: $candidate
            creator: $creator,
            references: $references
            selectedQuestions: $selectedQuestions
            candidateAnswer: $candidateAnswer
            status: $status
            sendCandidateInvite: $sendCandidateInvite
        }) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                        
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`

export const ADD_CASE_LIVE = gql`
    mutation addCaseLive($stakeholders: [StakeholderInput], $company: String, $jobPosition: String, $deadline: DeadlineInput, $deleteDate: String, $candidate: CandidateInput, $creator: String, $references: [ReferenceInput], $selectedQuestions: [SelectedQuestionModelInput], $candidateAnswer: Boolean, $status: String, $sendCandidateInvite: Boolean) {
        addCaseLive(input: {
            stakeholders: $stakeholders
            company: $company
            jobPosition: $jobPosition
            deadline: $deadline
            deleteDate: $deleteDate
            candidate: $candidate
            creator: $creator,
            references: $references
            selectedQuestions: $selectedQuestions
            candidateAnswer: $candidateAnswer
            status: $status
            sendCandidateInvite: $sendCandidateInvite
        }) {
            _id
            jobPosition
            deleteDate
            deadline {
                listing
                answer
            }
            company {
                _id
                settings {
                    languages {
                        shortName
                        fullName
                    }
                    defaultLanguage
                }
            }
            selectedQuestions {
                question {
                    _id
                    translations {
                        language
                        name
                    }
                    scale {
                        _id
                        choices
                        translations {
                            language
                            name
                        }
                        type
                    }
                    theme {
                        _id
                        translations {
                            language
                            name
                        }
                        
                    }
                    answer {
                        translations {
                            language
                            name
                            answers {
                                title
                            }
                        }
                    }
                    elaboration
                }
                recipients
            }
            stakeholders {
                user {
                    _id
                    email
                    firstName
                    lastName
                }
                inviteMailDate
            }
            candidate {
                firstName
                lastName
                email
                phone
                mobile
                consentGiven
                listingMailSend
                listingMailDate
            }
            references {
                _id
                type
                email
                firstName
                lastName
                phone
                mobile
                linkedIn
                consent
                listingMailSend
                listingMailDate
                consentGiven
                consentIP
                language
                questions {
                    answer {
                        answers
                        title
                    }
                    question {
                        _id
                        elaboration
                        duration
                        translations {
                            language
                            name
                        }
                        answer {
                            translations {
                                language
                                name
                            }
                            type
                        }
                        scale {
                            _id
                            choices
                            translations {
                                language
                                name
                            }
                            type
                        }
                        answer {
                            translations {
                                name
                                language
                                answers {
                                    title
                                    description
                                }
                            }
                        }
                    }
                }
            }
            creator {
                firstName
                lastName
                role
                email
            },
            createdAt
            status
        }
    }
`

export const ADD_TEMPLATE = gql`
    mutation addTemplate($input: TemplateInput) {
        addTemplate(input: $input) {
            _id
        }
    }
`

export const RESEND_LISTING = gql`
    mutation resendListing($caseId: String, $referenceId: String) {
        resendListing(
            caseId: $caseId
            referenceId: $referenceId
        ) {
            _id
        }
    }
`

export const RESEND_LISTING_HALFTIME = gql`
    mutation resendListingHalftime($caseId: String, $referenceId: String) {
        resendListingHalftime(
            caseId: $caseId
            referenceId: $referenceId
        ) {
            _id
        }
    }
`

export const RESEND_LISTING_DEADLINE = gql`
    mutation resendListingDeadline($caseId: String, $referenceId: String) {
        resendListingDeadline(
            caseId: $caseId
            referenceId: $referenceId
        ) {
            _id
        }
    }
`

export const RESEND_CANDIDATE = gql`
    mutation resendCandidate($caseId: String) {
        resendCandidate(
            caseId: $caseId
        ) {
            _id
        }
    }
`

export const RESEND_CANDIDATE_HALFTIME = gql`
    mutation resendCandidateHalftime($caseId: String) {
        resendCandidateHalftime(
            caseId: $caseId
        ) {
            _id
        }
    }
`

export const RESEND_CANDIDATE_DEADLINE = gql`
    mutation resendCandidateDeadline($caseId: String) {
        resendCandidateDeadline(
            caseId: $caseId
        ) {
            _id
        }
    }
`
export const REFERENCE_ANSWER = gql`
    mutation referenceAnswer($caseId: String, $referenceId: String, $answerStatus: Boolean, $questions: [QuestionAnswerWrapperInput], $pageNumber: Int){
      referenceAnswer(caseId: $caseId, referenceId: $referenceId, answerStatus: $answerStatus, questions: $questions, pageNumber: $pageNumber)
    }
`
export const UPDATE_TEXTEDITOR = gql`
    mutation updateTextEditor($input: TextEditorInput){
      updateTextEditor(input: $input) {
          _id
      }
    }
`

export const DELETE_QUESTION = gql`
    mutation deleteQuestion($id: String!) {
        deleteQuestion(id: $id) {
            _id
        }
    }
`

export const ADD_COMPANY = gql`
    mutation addCompany($input: CompanyInput!) {
        addCompany(input: $input) {
            _id
            name
            address
            address2
            zipCode
            city
            country
            phone
            EAN
            defaultDeleteDate
            settings {
                defaultLanguage
                defaultQALanguage
                languages {
                    shortName
                    fullName
                }
            }
        }
    }
`

export const DELETE_COMPANY = gql`
    mutation deleteCompany($id: String!) {
        deleteCompany(id: $id) {
            _id
        }
    }
`
