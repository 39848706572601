import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import { setReferenceQuestions } from '../redux/slices/answerQuestions';
import QuestionAnswerElaboration from './QuestionAnswerElaboration';
import { Colors } from '../styles/globalStyles';
import { useTranslation } from 'react-i18next';
import { formatReferenceAnswer } from '../services/caseService';
import { validateQuestionAnswerOther, validateQuestionAnswerTitle } from '../utils/validators';
import QuestionAnswerOther from './QuestionAnswerOther';
import { referenceAnswerOrderTranslation } from '../utils/constansts';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';

export default function QuestionsAnswerPrio({ title, answers, max, questionIndex, enabled, elaboration, selectedLanguage, references, isReport, showAnswers, questionId, isClosed = false }) {
    const [activeReferences, setActiveReferences] = useState();
    const [otherAnswerIndex, setOtherAnswerIndex] = useState();
    const [dontKnowIndex, setDontKnowIndex] = useState();

    const classes = useStyles({color: Colors.mainBlue + '!important'});

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const CheckComponent = () => {
        return <div className={classes.checkContainer} />
    }

    const CheckedComponent = ({ title }) => {
        return (
            <div className={classes.checkedContainer}>
                <p style={{ fontWeight: 'bold', fontSize: 12}}>{title}</p>
            </div>
        )
    }

    const formatNewAnswers = (answers, index, answer) => {
        const foundIndex = answers?.indexOf(index);
        const newAnswers = [].concat(answers)
        newAnswers[foundIndex] = answer
        return newAnswers
    }

    const handleChange = (checked, index, answers, ref, isDontKnow) => {
        if(isDontKnow && checked) {
            const newAnswers = [index, -1, -1]
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers)
            dispatch(setReferenceQuestions([newReference]))
        } else if(isDontKnow && !checked) {
            const newAnswers = [-1, -1, -1]
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers)
            dispatch(setReferenceQuestions([newReference]))
        } else if(checked && answers && answers?.filter(a => parseInt(a) !== -1)?.length === 3) {
            dispatch(triggerFlashMessage({ type: 'error', title: t(`You can not choose more than {{max}}`, { max }) }))
        } else if((checked && !answers) || (checked && dontKnowIndex && answers?.some(a => a === dontKnowIndex))) {
            const newAnswers = [index, -1, -1]
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers)
            dispatch(setReferenceQuestions([newReference]))
        } else if((!checked && !answers?.includes(-1)) || (!checked && answers?.includes(-1)) ) {
            const newAnswers = formatNewAnswers(answers, index, -1)
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers)
            dispatch(setReferenceQuestions([newReference]))
        } else if(checked) {
            const newAnswers = formatNewAnswers(answers, -1, index)
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers)
            dispatch(setReferenceQuestions([newReference]))
        } else {
            dispatch(triggerFlashMessage({ type: 'error', title: t(`You can not choose more than {{max}}`, { max }) }))
        }
    }

    const getQuestionState = (answers, index, ref) => {
        if(isReport) {
            const foundQuestion = ref?.questions?.find(e => e?.question?._id === questionId);
            const newAnswers = [].concat(foundQuestion?.answer?.answers);
            const checked = newAnswers?.includes(index)
            const priority = checked ? newAnswers?.indexOf(index) + 1 : null;
            return { checked, priority }
        } else {
            const newAnswers = [].concat(answers);
            const checked = newAnswers?.includes(index)
            const priority = checked ? newAnswers?.indexOf(index) + 1 : null;
            return { checked, priority }
        }
    }

    useEffect(() => {
        if(isReport) {
            setActiveReferences(references?.filter(ref => {
                return ref?.listingMailSend && ref?.questions?.some(e => e?.question?._id === questionId)
            })) 
        } else {
            setActiveReferences(references)
        }
    }, [references])

    useEffect(() => {
        answers?.forEach((answer, index) => {
            if(validateQuestionAnswerOther(answer?.title)){
                setOtherAnswerIndex(index)
            } else if (!validateQuestionAnswerTitle(answer?.title)) {
                setDontKnowIndex(index)
            }
        })
    }, [answers])

    const getReferenceAnswerText = () => {
        return referenceAnswerOrderTranslation?.translations?.find(e => e.language === selectedLanguage?.shortName)?.name;
    }

    return (
        <div className={classes.breakAvoid}>
            <p className={classes.title}>{makeUnderlineQuestion(title)}</p>
            {isReport &&
                <div>
                    <p className={classes.referenceAnswerOrderText}>{getReferenceAnswerText()}</p>
                    <div className={classes.referenceAnswerContainer}>
                        {activeReferences?.map((ref, index) => {
                            return (
                                <p key={index} className={classes.referenceAnswer}>
                                    <span style={{ fontWeight: '500', paddingRight: index === 0 ? 12 : 9 }}>{index + 1}.</span>
                                    {!isClosed ? (
                                        <>
                                            {`${ref?.firstName} ${ref?.lastName} `}
                                            <span>{`(${ref?.type})`}</span>
                                        </>
                                    ) : (
                                        <span>{`${ref?.type}`}</span>
                                    )}
                                </p>
                            )    
                        })}
                    </div>
                </div>
            }
  
            <div className={classes.answerContainer}>
                <div style={{ marginTop: 10 }}>
                    <div>
                        {answers?.map((answer, index) => {
                            const isDontKnow = !validateQuestionAnswerTitle(answer?.title);
                            return (
                                <div key={index} className={classes.questionContainer}>
                                    {activeReferences?.map((ref, refIndex) => {
                                        const questionState = getQuestionState(ref?.questions[questionIndex]?.answer?.answers, index, ref)

                                        return (
                                            <div className={classes.checkboxContainer}>
                                                {isReport && <p style={{ fontWeight: '600'}}>{index === 0 && refIndex + 1}</p>}
                                                <Checkbox 
                                                    checked={showAnswers && questionState?.checked} 
                                                    onChange={(e) => enabled && handleChange(e.target.checked, index, ref?.questions[questionIndex]?.answer?.answers, ref, isDontKnow)} 
                                                    icon={<CheckComponent />} 
                                                    checkedIcon={<CheckedComponent title={questionState?.priority} />} 
                                                /> 
                                            </div>
                                        ) 
                                    })}

                                    <div className={index === 0 ? classes.answersContainerTop : classes.answersContainer}>
                                        <p className={classes.answerTitle}>{answer?.title}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {activeReferences?.map(ref => {
                        if(otherAnswerIndex && ref?.questions[questionIndex]?.answer?.answers?.some(a => a === otherAnswerIndex)){
                            return <QuestionAnswerOther showAnswers={showAnswers} reference={ref} selectedLanguage={selectedLanguage} enabled={enabled} questionIndex={questionIndex} isReport={isReport} isClosed={isClosed} />
                        } else if(elaboration && !ref?.questions[questionIndex]?.answer?.answers?.some(a => a === otherAnswerIndex)){
                            return <QuestionAnswerElaboration questionId={questionId} isReport={isReport} showAnswers={showAnswers} reference={ref} selectedLanguage={selectedLanguage} enabled={enabled} questionIndex={questionIndex} isClosed={isClosed} />
                        } else return null
                    })}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    title: {
        marginBottom: '10px',
        fontSize: '20px',
        fontWeight: '500',
        fontStyle: 'italic',
        '@media (max-width: 480px)': {
            fontSize: 15,
        },
    },
    checkedContainer: {
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        border: '1px solid #000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: props => props.color ? props.color : Colors.mainBlue
    },
    checkContainer: {
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        border: '1px solid #000',
        color: Colors.mainBlue
    },
    answersContainer: { 
        width: '95%',
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 480px)': {
            minHeight: 'unset',
        },
    },
    answersContainerTop: {
        width: '95%',
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 480px)': {
            minHeight: 'unset',
        },
    },
    answerTitle: {
        paddingLeft: 5,
        fontWeight: 500,
        fontSize: 18,
        '@media (max-width: 480px)': {
            fontSize: 13,
        },
    },
    breakAvoid: {
        '@media print': {
            breakInside: 'avoid'
        }
    },
    questionContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        minHeight: '50px', 
        backgroundColor: '#F2F7Fb', 
        borderTop: '1px solid #000',
        '&:last-child': {
            borderBottom: '1px solid #000'
        },
        '@media (max-width: 480px)': {
            minHeight: 'unset',
        },
    },
    referenceAnswerOrderText: {
        fontWeight: '500',
        paddingLeft: 10
    },
    referenceAnswerContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'flex-start',
        maxHeight: 100, 
        flexWrap: 'wrap',
        '@media (max-width: 480px)': {
            zoom: 0.8,
        },
    },
    referenceAnswer: {
        flex: 1, 
        paddingLeft: 10, 
        paddingRight: 10, 
        marginTop: 10
    },
    answerContainer: {
        display: 'flex', 
        flexDirection: 'column'
    },
    checkboxContainer: {
        padding: 5, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        flexDirection: 'column', 
        position: 'relative',
        '@media (max-width: 480px)': {
            padding: 0,
        },
    }
});
