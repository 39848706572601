import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import moment from 'moment';
import { formatDate } from '../../utils/constansts';
import DownloadDialog from '../../components/DownloadDialog'
import Candidate from './Candidate';
import Stakeholders from './Stakeholders';
import { setCandidate, setReferences } from '../../redux/slices/createCaseSlice';
import { useDispatch } from 'react-redux';
import Reference from './Reference';
import { RESEND_CANDIDATE, RESEND_CANDIDATE_DEADLINE, RESEND_CANDIDATE_HALFTIME, RESEND_LISTING, RESEND_LISTING_DEADLINE, RESEND_LISTING_HALFTIME } from '../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';

function Status({ caseInfo, refetchCase, selectedLanguage, setSelectedLanguage, isClosed = false }) {
    const classes = useStyles();

    const dispatch = useDispatch()
    const t = (str) => str;

    const [resendCandidate] = useMutation(RESEND_CANDIDATE);
    const [resendCandidateHalftime] = useMutation(RESEND_CANDIDATE_HALFTIME);
    const [resendCandidateDeadline] = useMutation(RESEND_CANDIDATE_DEADLINE);
    const [resendListing] = useMutation(RESEND_LISTING);
    const [resendListingHalftime] = useMutation(RESEND_LISTING_HALFTIME);
    const [resendListingDeadline] = useMutation(RESEND_LISTING_DEADLINE);

    const handleCandidateChange = (key, value) => {
        dispatch(setCandidate({ ...caseInfo?.candidate, [key]: value }))
    }

    const handleResendCandidate = () => {
        resendCandidate({ variables: {  caseId: caseInfo?._id, } }).then(res => {
            if(res.data.resendCandidate) {
                refetchCase()
                dispatch(triggerFlashMessage({ type: "success", title: 'Invitation mail to candidate has been resend' }))
            }
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        });
    }

    const handleResendCandidateHalftime = () => {
        resendCandidateHalftime({ variables: {  caseId: caseInfo?._id, } }).then(res => {
            if(res.data.resendCandidateHalftime) {
                refetchCase()
                dispatch(triggerFlashMessage({ type: "success", title: 'Halftime reminder mail to candidate has been resend' }))
            }
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        });
    }

    const handleResendCandidateDeadline = () => {
        resendCandidateDeadline({ variables: {  caseId: caseInfo?._id, } }).then(res => {
            if(res.data.resendCandidateDeadline) {
                refetchCase()
                dispatch(triggerFlashMessage({ type: "success", title: 'Deadline reminder mail to candidate has been resend' }))
            }
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        });
    }
    const handleResendListing = (refId) => {
        resendListing({ variables: {  caseId: caseInfo?._id, referenceId: refId } }).then(res => {
            if(res.data.resendListing) {
                refetchCase()
                dispatch(triggerFlashMessage({ type: "success", title: 'Invitation mail to reference has been resend' }))
            }
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        });
    }

    const handleResendListingHalftime = (refId) => {
        resendListingHalftime({ variables: {  caseId: caseInfo?._id, referenceId: refId } }).then(res => {
            if(res.data.resendListingHalftime) {
                refetchCase()
                dispatch(triggerFlashMessage({ type: "success", title: 'Halftime reminder mail to reference has been resend' }))
            }
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        });
    }

    const handleResendListingDeadline = (refId) => {
        resendListingDeadline({ variables: {  caseId: caseInfo?._id, referenceId: refId } }).then(res => {
            if(res.data.resendListingDeadline) {
                refetchCase()
                dispatch(triggerFlashMessage({ type: "success", title: 'Deadline reminder mail to reference has been resend' }))
            }
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        });
    }

    const handleReferenceChange = (id, key, value) => {
        const newReferences = caseInfo?.references?.map(ref => {
            if(ref?._id === id) {
                return {
                    ...ref,
                    [key]: value
                }
            } else {
                return ref;
            }
        })
        dispatch(setReferences(newReferences))
    };

    return (
        <div className={classes.container}>
            <div style={{ paddingRight: '15%', paddingLeft: '15%', maxHeight: 'calc(100vh - 106px)', overflowY: 'auto' }}>
                <Card className={classes.cardContainer}>
                    <div>
                        <div className={classes.headerContainer}>
                            <p className={classes.headerTitleLarge}>{`${t(`Job-position`)}:`}</p>
                            <p>{`${caseInfo?.jobPosition}`}</p>
                        </div>
                        <div className={classes.headerContainer}>
                            <p className={classes.headerTitleSmall}>{`${t(`Creation date`)}:`}</p>
                            <p>{moment(parseInt(caseInfo?.createdAt)).format(formatDate())}</p>
                        </div>
                        <div className={classes.headerContainer} >
                            <p className={classes.headerTitleSmall}>{`${t(`Delete date`)}:`}</p>
                            <p>{`${moment(parseInt(caseInfo?.deleteDate)).format(formatDate())}`}</p>
                        </div>
                    </div>
                    <DownloadDialog
                        references={caseInfo?.references}
                        caseInfo={caseInfo}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        isClosed={isClosed}
                    />
                </Card>
                <div style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex'}}>
                        <Candidate 
                            refetchCase={refetchCase}
                            caseInfo={caseInfo} 
                            handleCandidate={handleCandidateChange}
                            handleResendCandidateHalftime={() => handleResendCandidateHalftime()}
                            handleResendCandidateDeadline={() => handleResendCandidateDeadline()}
                            handleResendCandidate={() => handleResendCandidate()} 
                            handleChangeCaseInfo={() => console.log('clicked')} 
                            setShowSave={() => console.log('clicked')} 
                            showSave={true} 
                            handleCancelEdit={() => console.log('clicked')} 
                            caseCopy={Object.assign({}, caseInfo)} 
                            isCancel={false} 
                            isClosed={isClosed}
                        />
                        <Stakeholders
                            caseInfo={caseInfo} 
                            handleChangeCaseInfo={() => console.log('clicked')} 
                            setShowSave={() => console.log('clicked')} 
                            showSave={true} 
                            handleCancelEdit={() => console.log('clicked')} 
                            caseCopy={{}} 
                            isCancel={true} 
                        />    
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', marginBottom: 150 }}>
                        {caseInfo?.references?.map((ref, index) => {
                            return (
                                <div key={ref?._id} style={{ width: 'calc(50% - 10px)', paddingRight: index % 2 === 0 ? 10 : 0, paddingLeft: index % 2 === 0 ? 0 : 10 , display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Reference
                                        caseInfo={caseInfo}
                                        handleReference={handleReferenceChange}
                                        index={index}
                                        reference={ref}
                                        handleResendListing={handleResendListing}
                                        handleResendListingHalftime={handleResendListingHalftime}
                                        handleResendListingDeadline={handleResendListingDeadline}
                                        isClosed={isClosed}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};

const useStyles = makeStyles({
    container: {},
    cardContainer: {
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerContainer: {
        display: 'flex',
        marginTop: 10
    },
    headerTitleLarge: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingRight: 10
    },
    headerTitleSmall: {
        fontSize: 14,
        fontWeight: 'bold',
        paddingRight: 10
    },
})

export default Status
