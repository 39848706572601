import { React } from 'react';

import mapsImage from "../../assets/images/dooblecheckMaps.png"

export default function Contact() {
    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', height: 'calc(100vh - 106px)'}}>
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', }}>
            <div style={{display: 'flex', flexDirection: 'column' }}>
                    <h1 style={{padding: 10, paddingBottom: 30}}>
                        Contact
                    </h1>
                    <p style={{padding: 10}}>
                        DOOBLECHECK APS
                    </p>
                    <p style={{padding: 10, textAlign: 'left'}}>
                        V/ Flemming Hesthaven
                    </p>
                    <p style={{padding: 10, cursor: 'pointer', textAlign: 'left'}} onClick={() => window.open('mailto:fh@dooblecheck.dk')}>
                        M: fh@dooblecheck.dk
                    </p>
                    <p style={{padding: 10, textAlign: 'left'}}>
                        <a href="tel:+4561638019">T: +45 6163 8019</a>
                    </p>
                    <p style={{padding: 10, textAlign: 'left'}}>
                        CVR.nr. 28279353
                    </p>
                </div>
                </div>
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-start'}}>
                <img alt='' style={{ maxHeight: 700 }} className="img" src={mapsImage} />
            </div>
        </div>
    )
};
