export const Colors = {
    mainBlue: '#003E55',
    secondaryBlue: '#006487',
    thirdBlue: '#B3C7CE',
    lightBlue: '#F2F7Fb',
    grey: '#D1CDC6',
    red: '#D84627',
    yellow: '#DCB03B',
    green: '#799778',
    lightGrey: '#e0e0e0',
    table1: '#DAE3F3',
    table2: '#f5f5f5'
};

export const Typography = {
    mainFamily: 'Lato',
    secondaryFamily: 'Montserrat'
}

export const FontSize = {
    max: '24px',
    large: '20px',
    medium: '16px',
    small: '12px',
    extraSmall: '10px' 
}
