import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import CustomDropdownMenu from './CustomDropdownMenu';
import { useTranslation } from 'react-i18next';
import { GET_THEMES, GET_CATEGORIES, GET_QUESTIONS } from '../graphql/mutations';
import { useQuery } from '@apollo/client';
import { getLanguageLocal, getLanguageLocalQuestionsFilter } from '../utils/getLanguage';
import { Colors } from '../styles/globalStyles';
import LanguageChange from './LanguageChange';
import { ListItem, FormControl, Radio } from '@material-ui/core';
import { setReferences } from '../redux/slices/createCaseSlice';
import moment from 'moment';
import AddRatingPoint from './AddRatingPoint';
import AddQuestion from './AddQuestion';
import LoadingBtn from './LoadingButton';
import { replaceQuestionString, sortThemes } from '../services/caseService';
import { openAnswerTranslations } from '../utils/constansts';
import { validateRatingPointTheme } from '../utils/validators';
import CustomBadge from './CustomBadge';
import QuestionDropdown from './QuestionDropdown';
import DCLoader from './DCLoader';

function Questions({ caseState, category, theme, selectedLanguage, setSelectedLanguage }) {  
    const [themes, setThemes] = useState();
    const [categories, setCategories] = useState();
    const [questions, setQuestions] = useState();
    const [uniqueReferences, setUniqueReferences] = useState();

    var skipQuestionIndex = 0;

    const [selectedTheme, setSelectedTheme] = useState();
    const [selectedCategory, setSelectedCategory] = useState({ _id: null, title: 'All'})

    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showRatingModal, setShowRatingModal] = useState(false);

    const [referenceWidth, setReferenceWidth] = useState();

    const [shownId, setShownId] = useState()

    const dispatch = useDispatch()
    const classes = useStyles();
    const { t } = useTranslation();
    const auth = useSelector(state => state.auth);

    const radioItemRef = useRef();

    const { data: themeData, loading: themesLoading } = useQuery(GET_THEMES, { variables: { companyId: auth?.user?.company?._id } });
    const { data: categoryData, loading: categoriesLoading } = useQuery(GET_CATEGORIES, { variables: { companyId: auth?.user?.company?._id } });
    const { data: questionsData, refetch: refetchQuestions } = useQuery(GET_QUESTIONS, {
        variables: {
            companyId: auth?.user?.company?._id,
            themeId: selectedTheme?._id,
            categoryId: selectedCategory?._id,
            hidden: true
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        setUniqueReferences([...new Set(caseState?.references?.map(ref => ref?.type))])
    }, [caseState?.references, questionsData])

    useEffect(() => {
        const selectedLanguage = themeData?.themes?.map(e => ({ ...e, title: getLanguageLocal(e, { shortName: 'en' })?.name }))
        const sorted = sortThemes(selectedLanguage)
        setThemes(sorted);
        sorted && setSelectedTheme(sorted[0])
    }, [themeData]);

    useEffect(() => {
        const formattedCategories = categoryData?.categories?.map(e => ({ ...e, title: getLanguageLocal(e, { shortName: 'en' })?.name }));
        setCategories(formattedCategories)
    }, [categoryData]);
    
    useEffect(() => {
        setQuestions(questionsData?.questions)
    }, [questionsData])
    
    const removeQuestion = (currentRef, question) => {
        const questionsCopy = [].concat(currentRef?.questions);
        const removeIndex = questionsCopy?.map(item => item?.question?._id).indexOf(question?._id);
        
        questionsCopy?.splice(removeIndex, 1);
        return {
            ...currentRef,
            questions: questionsCopy
        }
    }

    const addQuestion = (currentRef, question) => { 
        return {
            ...currentRef,
            questions: currentRef?.questions ? currentRef?.questions?.concat({ question }) : [].concat({ question })
        }  
    }

    const checkIfSelected = (_id) => {
        const references = caseState?.references?.map(ref => {
            return ref?.questions?.some(q => q?.question?._id === _id)
        });

        return references?.some(r => r === true);
    }

    const checkIfReferenceSelected = (type, _id) => {
        const references = caseState?.references?.map(ref => {
            return {
                selected: ref?.questions?.some(q => q?.question?._id === _id),
                type: ref?.type
            }
        });

        const foundReference = references?.filter(ref => ref?.type === type);
        return foundReference?.some(r => r?.selected === true);
    }


    const handleSelect = (question) => {
        if(checkIfSelected(question?._id)) {
            const references = caseState?.references?.map(reference => {
                return removeQuestion(reference, question)
            })

            return dispatch(setReferences(references))
        } 

        const references = caseState?.references?.map(reference => {
            return addQuestion(reference, question)
        })

        dispatch(setReferences(references))
    }

    const getDuration = (reference) => {
        const foundRef = caseState?.references?.find(ref => ref?.type === reference);
        let seconds = 0;
        foundRef?.questions?.forEach(e => {
            seconds += e?.question?.duration
        })

        const formatted = moment.utc(seconds*1000).format('mm:ss');
        return formatted
    }

    const handleReferenceSelect = (type, question) => {
        if(checkIfReferenceSelected(type, question?._id)) {
            const references = caseState?.references?.map(reference => {
                if(reference?.type === type) {
                    return removeQuestion(reference, question)
                }
                
                return reference;
            });

            dispatch(setReferences(references))
        } else {
            const references = caseState?.references?.map(reference => {
                if(reference?.type === type) {
                    return addQuestion(reference, question)
                }
                
                return reference;
            }); 

            dispatch(setReferences(references))
        }
    }

    useEffect(() => {
        setReferenceWidth(radioItemRef?.current?.clientWidth * uniqueReferences?.length)
    }, [radioItemRef, uniqueReferences, window, caseState?.references, questionsData])

    const RenderOpenQuestion = () => {
        return (
            <div style={{ padding: 20, borderBottom: '1px dashed #000' }}>
                <p style={{ fontSize: '14px', fontWeight: '500' }}>{getLanguageLocal(openAnswerTranslations, selectedLanguage)?.name}</p>
            </div>
        )
    }

    const RenderListQuestion = ({ question }) => {
        return (
            <div style={{ padding: 0, paddingBottom: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {getLanguageLocal(question?.answer, selectedLanguage)?.answers?.map((answer) => {
                        return (
                            <ListItem style={{ background: '#fff', borderBottom: '1px dashed #000', minHeight: 40 }} button>
                                <div style={{ width: '100%' }}>
                                    <p style={{ fontSize: '14px', fontWeight: '500'}}>{answer.title}</p>
                                </div>
                            </ListItem>
                        )
                    })}
                </div>
            </div>
        )
    }

    const checkIfElaboration = (_id) => {
        const references = caseState?.references?.map(ref => {
            const foundQuestion = ref?.questions?.find(q => q?.question?._id === _id);
            if(foundQuestion?.question?.elaboration) {
                return true;
            } else {
                return false;
            }
        })
    
        return references?.some(e => e === true);
    }

    const handleElaboration = (question) => {
        if(question?.scale?.type === 'Open') {
            return
        }
        const references = caseState?.references?.map(ref => {
            const questions = ref?.questions?.map(q => {
                if(q?.question?._id === question?._id) {
                    return {
                        ...q,
                        question: {
                            ...q?.question,
                            elaboration: !q?.question?.elaboration
                        }
                    }
                } else {
                    return q;
                }
            })

            return {
                ...ref,
                questions
            }
        })

        dispatch(setReferences(references))
    }

    const checkIfDisabled = (question) => {
        if(!checkIfSelected(question?._id)) {
            return true;
        } else if(question?.scale?.type === 'Open') {
            return true;
        }

        return false;
    }

    const getFormattedThemes = () => {
        let array = [];
        caseState?.references?.forEach((ref) => {
            if(ref?.questions) {
                array = [...array, ...ref?.questions]
            }
        });
        let newArray = [];
        array?.forEach(q => {
            if(!newArray?.find(newq => newq?.question?._id === q?.question?._id)){
                newArray?.push(q)
            }
        })
        newArray = newArray?.map(q => ({name: getLanguageLocal(q?.question?.theme, {shortName: 'en'})?.name}))

        return [{ _id: null, title: t("All") }, ...themes]?.map(theme => {
            let amount = theme?.title === 'All' ? newArray?.length : newArray?.filter((t) => t?.name === theme?.title)?.length;
            return {
                ...theme,
                selected: amount > 0 ? amount : null
            }
        })
    }

    const RenderBadge = (isNew, isCompany) => {
        return <CustomBadge title={isNew && isCompany ? 'New + C' : isNew ? 'New' : 'C' } className={classes.newBadge} /> 
    }

    useEffect(() => {
        skipQuestionIndex = 0;
    }, [questionsData])

    if([themesLoading, categoriesLoading]?.some(e => e === true)) {
        return <DCLoader />
    }

    return (
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <div style={{ display: 'flex'}}>
                    <div className={classes.selectors}>
                        <div className={classes.selector}>
                            <CustomDropdownMenu
                                selected={t(selectedTheme?.title)}
                                withKey 
                                elementKey="title" 
                                onSelect={(e) => setSelectedTheme(e)} 
                                title={t(selectedTheme?.title)} 
                                label={t("Select Theme")} 
                                elements={themes && getFormattedThemes()} 
                            />
                        </div>
                        <div className={classes.selector}>
                            <CustomDropdownMenu
                                selected={t(selectedCategory?.title)}
                                withKey 
                                elementKey="title" 
                                onSelect={(e) => setSelectedCategory(e)} 
                                title={t(selectedCategory?.title)} 
                                label={t("Select Method")} 
                                elements={categories && [{ _id: null, title: 'All' }, ...categories]} 
                            />
                        </div>
                    </div>
                    <div className={classes.durationRight}>
                        <div style={{ marginLeft: 20, height: 50, width: referenceWidth, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff'}}>
                            <div style={{ flex: 1}} />
                            <div style={{ flex: 1, backgroundColor: Colors.mainBlue, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20 }}>
                                <p style={{ fontWeight: '400'}}>Duration</p>
                            </div>
                            <div  style={{ flex: 1}} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: referenceWidth, marginTop: 10, position: 'absolute', bottom: -20 }}>
                            {uniqueReferences?.map(ref => {
                                return (
                                    <div style={{ flex: 1, textAlign: 'center', paddingBottom: 5 }}>
                                        <p style={{ fontWeight: '600' }}>{getDuration(ref)}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 15 }}>
                    <div className={classes.questions}>
                        <div style={{ height: 60, width: '100%', backgroundColor: Colors.mainBlue, justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                            <p style={{ paddingLeft: 20, color: '#fff', fontWeight: 'bold' }}>{selectedCategory?.title === 'All' ? 'Questions | Rating Points' : selectedCategory?.title}</p>
                            <LanguageChange 
                                useLanguage={true}
                                handleLanguage={setSelectedLanguage}
                                language={selectedLanguage}
                                company={auth?.user?.company} 
                            />
                        </div>
                    </div>
                    <div className={classes.duration}>
                        <div style={{ flex: 1, textAlign: 'center' }}>
                            <p>Select</p>
                        </div>
                        <div style={{ flex: 1, textAlign: 'center' }}>
                            <p>Elaboration</p>
                        </div>
                        {uniqueReferences?.map(ref => {
                            return (
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    <p>{ref}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className={classes.scrollContainer}>
                    {questions?.map((question, index) => {
                        const diff = moment().diff(moment(parseInt(question.createdAt)), 'minutes');
                        const days = 60;

                        const isNew = diff < days;
                        const isCompany = question?.company?._id === auth?.user?.company?._id;
                        const everySecond = (index + skipQuestionIndex) % 2 === 0;
                        if(!getLanguageLocalQuestionsFilter(question, selectedLanguage)?.name) {
                            skipQuestionIndex += 1;
                            return null;
                        }

                        return (
                            <div style={{ display: 'flex'}}>
                                <div className={classes.questionContainer}>   
                                    <QuestionDropdown 
                                        shownId={shownId}
                                        setShownId={setShownId}
                                        currentIndex={index}
                                        customBadge={(isNew || isCompany) ? () => RenderBadge(isNew, isCompany) : null}
                                        customHeader={question?.scale?.type === 'RatingPoints' ? (title, show, setShow) => {
                                            return (
                                                <ListItem style={{ padding: 0, margin: 0 }} className={classes.button} onClick={() => null}>
                                                    <div className={classes.questionHeaderContainer}>
                                                        {(isNew || isCompany) && <div className={classes.newRatingPoint}><p style={{color: 'white', fontSize: 12}}>{isNew && isCompany ? 'New + C' : isNew ? 'New' : 'C' }</p></div>}
                                                        {getLanguageLocal(question.answer, selectedLanguage)?.answers?.map((answer, index) => {
                                                            return (
                                                                <div className={classes.questionHeaderElement}>
                                                                    <p style={{ fontSize: 18, fontWeight: '500'}}>{answer?.title}</p>
                                                                    <p style={{ fontSize: 14, marginTop: 5 }}>{answer?.description}</p>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </ListItem>
                                            )
                                        } : null}
                                        expandIcon={question?.scale?.type === 'RatingPoints' ? false : true}
                                        style={everySecond ? { backgroundColor: Colors.table1, paddingRight: 115 } : { backgroundColor: Colors.table2, paddingRight: 115 }} 
                                        title={question?.scale?.type === 'RatingPoints' 
                                            ? getLanguageLocal(question.answer, selectedLanguage)?.name 
                                            : replaceQuestionString(getLanguageLocal(question, selectedLanguage)?.name, caseState?.candidate?.firstName, caseState?.jobPosition)
                                        }
                                    >
                                        {question?.scale?.type === 'Open' && <RenderOpenQuestion question={question} />}
                                        {/* {question?.scale?.type === 'RatingPoints' && <RenderRatingPoints question={question} />} */}
                                        {question?.scale?.type === 'Multiple' && <RenderListQuestion question={question} />}
                                        {question?.scale?.type === 'ProsCons' && <RenderListQuestion question={question} />}
                                        {question?.scale?.type === 'Prio' && <RenderListQuestion question={question} />}
                                        {/* {question?.scale?.type !== 'Prio' && question?.scale?.type !== 'Multiple' && question?.scale?.type !== 'ProsCons' && <div style={{ borderBottom: '1px dashed #000', marginBottom: 5 }} />} */}
                                    </QuestionDropdown>
                                </div>
                                <div style={{ flex: 3, display: 'flex', justifyContent: 'space-between', alignItems: question?.scale?.type === 'RatingPoints' ? 'center' : 'flex-start' }}>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FormControl onClick={() => handleSelect(question)} >
                                            <Radio style={{ color: Colors.mainBlue  }} checked={checkIfSelected(question?._id)} />
                                        </FormControl>
                                    </div>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {checkIfDisabled(question)}
                                        <FormControl disabled={checkIfDisabled(question)} onClick={() => handleElaboration(question)} >
                                            <Radio disabled={checkIfDisabled(question)} style={{ color: !checkIfDisabled(question) ? Colors.mainBlue : Colors.grey }} checked={checkIfElaboration(question?._id)} />
                                        </FormControl>
                                    </div>
                                    {uniqueReferences?.map((ref, index) => {
                                        return (
                                            <div ref={radioItemRef} style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}>
                                                <FormControl onClick={() => handleReferenceSelect(ref, question)} >
                                                    <Radio style={{ color: Colors.mainBlue  }} checked={checkIfReferenceSelected(ref, question?._id)} />
                                                </FormControl>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            
                        )
                    })}

                    {questions?.length === 0 &&
                        <div style={{ display: 'flex'}}>
                            <div style={{ flex: 4}}>
                                <p style={{ textAlign: 'center', paddingTop: 20, paddingBottom: 20 }}>No {selectedCategory?.title === 'All' ? 'Questions | Rating Points' : selectedCategory?.title} found</p>
                            </div>
                            <div style={{ flex: 3}}>
                            </div>
                        </div>
                    }
                            
                </div>
                <AddQuestion selectedLanguage={selectedLanguage} selectedC={selectedCategory} selectedT={selectedTheme} refetch={refetchQuestions} themes={themes} setShowModal={setShowQuestionModal} showModal={showQuestionModal} />
                <AddRatingPoint selectedLanguage={selectedLanguage} selectedC={selectedCategory} selectedT={selectedTheme} refetch={refetchQuestions} themes={themes} setShowModal={setShowRatingModal} showModal={showRatingModal} />

                <div className={classes.addButton}>
                    <div style={{ flex: 4, display: 'flex', justifyContent: 'space-between'}}>
                        {/* TODO - FIND LØSNING TIL VED FORSKELLIGE SPROG */}
                        {(selectedCategory?.title === 'All' || selectedCategory?.title !== 'Rating Points') &&
                            <LoadingBtn onClick={() => setShowQuestionModal(true)}>
                                New Question
                            </LoadingBtn>
                        }
                        {(selectedCategory?.title === 'All' || selectedCategory?.title === 'Rating Points') && validateRatingPointTheme(selectedTheme?.title) &&
                            <LoadingBtn onClick={() => setShowRatingModal(true)}>
                                New Rating Point
                            </LoadingBtn>
                        }
                    </div>
                    <div style={{ flex: 3}} />
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
    },
    innerContainer: {
        width: '70%',
        paddingBottom: '10%',
        position: 'relative',
        '@media (max-width: 1024px)': {
            width: '100%',
            paddingLeft: '10px',
            paddingRight: '10px',
        },
    },
    selectors: {
        flex: 4,
        display: 'flex',
        flexDirection: 'row',
        borderTop: `1px dashed ${Colors.mainBlue}`,
        borderBottom: `1px dashed ${Colors.mainBlue}`,
        position: 'relative',
        '&:last-child': {
            borderLeft: 'none'
        }
    },
    questions: {
        flex: 4,
    },
    durationRight: {
        flex: 3,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        marginBottom: 1,
        position: 'relative'
    },
    duration: {
        flex: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginBottom: 1,
        paddingBottom: 15,
    },
    selector: {
        flex: 1,
        borderLeft: `1px dashed ${Colors.mainBlue}`,
        '&:first-child': {
            borderLeft: `1px dashed ${Colors.mainBlue}`
        },
        '&:last-child': {
            borderRight: `1px dashed ${Colors.mainBlue}`
        }
    },
    addButton: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 4,
        marginTop: 10
    },
    button: {
        cursor: 'pointer',
        minHeight: 45,
        display: 'flex',
        justifyContent: 'space-between',
    },
    questionHeaderContainer: {
        width: '100%',
        justifyContent: 'space-between',
        display: 'flex',
        borderBottom: '1px dashed #000',
        
    },
    questionHeaderElement: {
        flex: 1,
        textAlign: 'center',
        backgroundColor: Colors.mainBlue, 
        minHeight: 80,
        margin: '15px 1px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 10,
        color: '#fff',
        position: 'relative',
    },
    newRatingPoint: {
        position: 'absolute',
        right: '10px',
        top: '5px',
        borderRadius: '5px',
        border: '1px solid #fff',
        padding: '5px',
        backgroundColor: Colors.mainBlue,
        zIndex: 3,
    },
    scrollContainer: {
        maxHeight: 'calc(100vh - 450px)',
        overflowY: 'overlay',
    },
    questionContainer: { 
        flex: 4,
        margin: 1,
        position: 'relative',
    },
    newBadge: {
        position: 'absolute',
        right: 50,
    },
})

export default Questions

