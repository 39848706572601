import _ from 'lodash'
import store from "../redux/store";
import moment from 'moment';

export const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email?.trim()).toLowerCase());
};

export const comparePasswords = (password, repeatedPassword) => {
  	return password === repeatedPassword;
};

export const isValidPassword = (pw) => {
	if (pw.trim().length < 8) return false;
	else return true;
};

export const inputValidator = (input) => !_.isEmpty(input);

export const validateUser = (user) => {
	const errors = {};
	// validate candidate
	if(user?.firstName?.trim() === '') {
		errors.firstName = true
		errors.user = true
	};
	if(user?.lastName?.trim() === '') {
		errors.lastName = true
		errors.user = true
	};
	if(!validateEmail(user?.email?.trim())) { 
		errors.email = true
		errors.user = true 
	};

	return errors;
}

export const validateCaseInfo = (isTemplate) => {
	// Validate job position
	const errors = {};
	const caseState = store.getState().createCase.case;

	if(caseState.jobPosition?.trim() === '') errors.jobPosition = true;

	if(!isTemplate) {
		// validate candidate
		if(caseState.candidate?.firstName?.trim() === '') {
			errors.firstName = true
			errors.candidate = true
		};
		if(caseState.candidate?.lastName?.trim() === '') {
			errors.lastName = true
			errors.candidate = true
		};
		if(!validateEmail(caseState.candidate?.email?.trim())) { 
			errors.email = true
			errors.candidate = true 
		};
	} 

	// Validate Date
	if(moment(caseState?.deleteDate).isSameOrBefore(moment(new Date()).startOf('day'))) {
		errors.date = true;
	}

	// Validate references
	if(caseState?.references?.length === 0) errors.references = true;

	return errors;
};

export const validatePageChange = (activeStep, item, isTemplate) => {
    let errors = {};
    let errorIndex;

    if(activeStep === 0)  {
        errors = validateCaseInfo(isTemplate);
        if(!_.isEmpty(errors)) errorIndex = 0
    }

    if(activeStep === 1 && item !== 0) {
        errors = validateQuestions();
        if(!_.isEmpty(errors)) errorIndex = 1
    }

    if(activeStep === 0 && item === 2) {
        /* Validate case info */
        errors = validateCaseInfo(isTemplate);
        if(!_.isEmpty(errors)) errorIndex = 0

        /* validate questions */ 
        if(_.isEmpty(errors)) {
            errors = validateQuestions();
            if(!_.isEmpty(errors)) errorIndex = 1;
        }
    }

    return {
        errors,
        errorIndex
    }
}

export const validateLiveCase = (caseState) => {
	let errors = {};

	if(caseState.jobPosition?.trim() === '') {
		errors.jobPosition = true;
		errors.caseInfo = true;
	}

	if(caseState.candidate?.firstName?.trim() === '') {
		errors.firstName = true
		errors.candidate = true
	};

	if(caseState.candidate?.lastName?.trim() === '') {
		errors.lastName = true
		errors.candidate = true
	};

	if(!validateEmail(caseState.candidate?.email?.trim())) { 
		errors.email = true
		errors.candidate = true 
	};

	if(caseState.references.length === 0) {
		errors.references = true;
		errors.refErrorText = 'Should include atleast 1 reference';	
	}

	const filtered = caseState?.references?.filter(ref => ref?.email !== null && ref?.email !== undefined);
	const formatted = filtered?.map(ref => ref?.email);
	const validated = formatted?.map(email => {
		if(!validateEmail(email)) {
			return true;
		}
		return false
	});

	if(validated?.some(status => status === true)) {
		errors.references = true;
		errors.refErrorText = 'References cannot have invalid email'
	}

	if(!_.isEmpty(validateQuestions())) {
		errors.questions = true
	}

	return errors;
}

export const validateCompleteTemplate = (caseState, isUpdate = false) => {
	let errors = {};

	if(caseState.jobPosition?.trim() === '') {
		errors.jobPosition = true;
		errors.caseInfo = true;
	} 

	if(caseState.references.length === 0) {
		errors.references = true;
		errors.refErrorText = 'Should include atleast 1 reference';	
	}

	const filtered = caseState?.references?.filter(ref => ref?.email !== null && ref?.email !== undefined);
	const formatted = filtered?.map(ref => ref?.email);
	const validated = formatted?.map(email => {
		if(!validateEmail(email)) {
			return true;
		}

		return false
	});

	if(validated?.some(status => status === true)) {
		errors.references = true
		errors.refErrorText = 'References cannot have invalid email'
	}

	if(!_.isEmpty(validateQuestions())) {
		errors.questions = true
	}

	return errors;
}

export const validateQuestions = () => {
	const errors = {};
	const caseState = store.getState().createCase.case;
	const referenceErrors = [];
	caseState?.references?.map(ref => {
		if(ref?.questions?.length === 0 || ref?.questions === undefined) {
			referenceErrors.push(true)
		}
		return null
	})

	if(referenceErrors.length > 0 && referenceErrors.some((e) => e)) errors.questions = true;
	return errors;
}

export const validateNewQuestion = (titles, scaleId, answersId, scaleType, selectedTheme) => {
	const errors = {};

	titles.map((title, index) => {
		if(title?.name.replace('[Candidate]', '').replace('[Kandidat]', '').replace('[Theme]', '').replace('[Tema]', '').replace('(multiple answers possible)', '').replace('(flere svar muligt)', '').trim() === '' || title?.name === undefined) {
			errors[`${index}`] = true
		}
		return null
	})

	if(scaleId === '' || scaleId === undefined) errors.scale = true;
	if((answersId === '' || answersId === undefined) && scaleType !== 'Open') errors.answers = true;
	if(selectedTheme === undefined) errors.theme = true;

	return errors;
}

export const validateRatingPointTheme = (themeTitle) => {
	return themeTitle !== 'Intro & Qualifying' && themeTitle !== 'Intro & Kvalificering' && themeTitle !== 'Outro'
}

export const validateQuestionAnswerTitle = (title) => {
	return title !== "Don't know" && title !== 'Ved ikke' && title !== "Don't recall" && title !== 'Husker ikke'
}

export const validateNewAnswerTitle = (title) => {
	return title !== "Don't know" && title !== 'Ved ikke' && title !== "Don't recall" && title !== 'Husker ikke' && title !== 'Other' && title !== 'Andet'
}

export const validateQuestionAnswerOther = (title) => {
	return title === 'Andet' || title === 'Other'
}

export const validateNewRatingPoint = (selectedTheme, ratingPoint) => {
	const errors = {};
	let rpError = [];
	let hasRPError = false;

	if(selectedTheme === undefined || !selectedTheme) errors.theme = true;
	ratingPoint?.answer?.translations.forEach((translation, index) => {
		rpError.push({
			title1: false,
			title2: false,
			des1: false,
			des2: false
		})
		if(!translation?.answers[0]) {
			rpError[index].title1 = true
			rpError[index].des1 = true
			hasRPError = true;
		} else {
			if(!translation?.answers[0]?.title || translation?.answers[0]?.title.trim() === ''){
				rpError[index].title1 = true
				hasRPError = true;
			}
			if(!translation?.answers[0]?.description || translation?.answers[0]?.description.trim() === ''){
				rpError[index].des1 = true
				hasRPError = true;
			}
		}
		if(!translation?.answers[1]) {
			rpError[index].title2 = true
			rpError[index].des2 = true
			hasRPError = true;
		} else {
			if(!translation?.answers[1]?.title || translation?.answers[1]?.title.trim() === ''){
				rpError[index].title2 = true
				hasRPError = true;
			}
			if(!translation?.answers[1]?.description || translation?.answers[1]?.description.trim() === ''){
				rpError[index].des2 = true
				hasRPError = true;
			}
		}
	})

	if(hasRPError) {
		errors.ratingPoint = [...rpError]

		let isValid = false;

		rpError?.map(error => {
			if(Object.values(error).every(item => item === false)) {
				isValid = true;
			}
			return null
		})
		errors.isValid = isValid
	}

	return errors;
}

export const validateReference = (firstName, lastName, email) => {
	const errors = {};
	if(firstName?.trim() === '' || firstName === null || firstName === undefined) errors.firstName = true;
	if(lastName?.trim() === ''|| lastName === null || lastName === undefined) errors.lastName = true;
	if(!validateEmail(email?.trim())) errors.email = true;
	return errors;
}

export const validateRole = (user, roles) => {
	return roles?.includes(user?.role)
}

export const validateMobileNumber = (value) => {
	const mobile = value.trim();
	if(mobile === '') {
		return false;
	}
	if(mobile.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
		if(mobile.match(/\d/g).length < 8) return false;
		return true;
	}

	return false
};
