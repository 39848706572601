import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles';

export default function ThanksPage({ match, location }) {
    const [description, setDescription] = useState('');
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    useEffect(() => {
        i18n.changeLanguage(location?.state?.language);

        if(location?.state?.reVisit) {
            setDescription('Your reference is already registered - thank you once again')
        } else {
            setDescription('We appreciate your answer')
        }
    }, [])

    return (
        <div className={classes.container}>
            <h1>{t('Thank you')}</h1>
            <h2 className={classes.description}>{t(description)}.</h2>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex', 
        alignItems: 'center', 
        paddingTop: '5vh', 
        flexDirection: 'column'
    },
    description: {
        paddingTop: 20, 
        fontWeight: '400',
        textAlign: 'center'
    }
})
