import React from 'react'
import { makeStyles } from '@material-ui/styles';

function RoleContainer({ user, roles, strict, children, id, isGuide }) {
    const classes = useStyles();
    
    if(strict) {
        return (
            roles?.includes(user?.role) && id === user?._id
                ? children
                : <div className={classes.container}>{children}</div>
        )
    } else {
        return (
            roles?.includes(user?.role)
                ? children
                : <div className={isGuide ? classes.containerGuide : classes.container}>{children}</div>
        )
    }
}

const useStyles = makeStyles({
    container: {
        visibility: 'hidden'
    },
    containerGuide: {
        display: 'none'
    },
})

export default RoleContainer
