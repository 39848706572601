import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../styles/globalStyles';

function CustomBadge({ className, title }) {
    const classes = useStyles();
    
    return (
        <div className={`${classes.container} ${className}`}>
            <p>{title}</p>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        padding: '5px 10px', 
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: Colors.mainBlue, 
        borderRadius: 13, 
        fontSize: 10,
        fontWeight:'bold',
        color: '#fff',
    }
})

export default CustomBadge
