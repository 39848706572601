import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete, IconButton, TextField, Tooltip } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail'
import DeleteIcon from '@material-ui/icons/Delete'

import { Colors } from '../../styles/globalStyles';
import LoadingBtn from '../../components/LoadingButton';
import CustomDropdownMenu from '../../components/CustomDropdownMenu';
import { ADD_COMPANY, COMPANY_USERS, DELETE_COMPANY, DELETE_USER, INVITE_USER, UPDATE_COMPANY, UPDATE_USER } from '../../graphql/mutations';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import EmailModal from '../../components/EmailModal';
import CustomLoadingButton from '../../components/CustomLoadingButton';
import { defaultDeleteDateOptions, prettyUserRoles, userRoles } from '../../utils/constansts';
import { validateEmail } from '../../utils/validators';
import DCList from '../../components/DCList';
import CustomAlertDialog from '../../components/CustomAlertDialog';
import DCUser from '../../components/DCUser';
import { updateOwnCompanyInfo } from '../../redux/slices/authSlice';

function Company({ allCompanies, refetchAllCompanies }) {
    const [companyInput, setCompanyInput] = useState();
    const [companyInputCopy, setCompanyInputCopy] = useState();
    const [inviteRole, setInviteRole] = useState('');
    const [inviteRoleUI, setInviteRoleUI] = useState('');
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState();
    const [value, setValue] = useState({name: ''});
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [showCompany, setShowCompany] = useState(true);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [deleteId, setDeleteId] = useState();
    const [isDeleting, setIsDeleting] = useState();
    const [isDeletingComapny, setIsDeletingComapny] = useState(false);
    const [openConfirmDeleteCompany, setOpenConfirmDeleteCompany] = useState(false);
    const [confirmDeleteCompany, setConfirmDeleteComapny] = useState('');

    const [inviteUser] = useMutation(INVITE_USER);
    const [updateUser] = useMutation(UPDATE_USER);
    const [deleteUser] = useMutation(DELETE_USER);
    const [updateCompany] = useMutation(UPDATE_COMPANY);
    const [deleteCompany] = useMutation(DELETE_COMPANY);

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const { user } = auth;

    const [addCompany] = useMutation(ADD_COMPANY);
    const companyUsersQuery = useQuery(COMPANY_USERS, { variables: { companyId: selectedCompany?._id } })

    const t = (str) => str;
    const classes = useStyles({ color: Colors.mainBlue });

    const handleSelectCompany = (company) => {
        setSelectedCompany(company);
        setCompanyInput({
            ...company,
            defaultDeleteDate: company?.defaultDeleteDate || 90
        });
        setCompanyInputCopy({
            ...company,
            defaultDeleteDate: company?.defaultDeleteDate || 90
        });
    };

    const onSave = () => {
        if(allCompanies?.find(comp => comp?.name === companyInput?.name)) return dispatch(triggerFlashMessage({ type: 'error', title: t("Company name already registered") }));
        if(allCompanies?.find(comp => comp?.EAN === companyInput?.EAN)) return dispatch(triggerFlashMessage({ type: 'error', title: t("Company EAN already registered") }));
        setLoading(true);
        addCompany({ variables: {input: companyInput} }).then(res => {
            const company = {...res?.data?.addCompany};
            handleSelectCompany(company);
            refetchAllCompanies();
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully added company") }));
        }).catch(err => {
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }));
        });
    };

    const onUpdate = () => {
        if(allCompanies?.find(comp => comp?.name === companyInput?.name && comp?._id !== selectedCompany?._id)) return dispatch(triggerFlashMessage({ type: 'error', title: t("Company name already registered") }));
        if(allCompanies?.find(comp => comp?.EAN === companyInput?.EAN && comp?._id !== selectedCompany?._id)) return dispatch(triggerFlashMessage({ type: 'error', title: t("Company EAN already registered") }));

        setLoading(true);
        updateCompany({ variables: { input: companyInput } }).then(res => {
            refetchAllCompanies();
            setSelectedCompany();
            setValue({name: ''});
            setCompanyInput({...user?.company});
            companyInput?._id === user?.company?._id && dispatch(updateOwnCompanyInfo(companyInput))
            setLoading(false);
            dispatch(triggerFlashMessage({ type: "success", title: t("Succesfully updated company") }));
        }).catch(err => {
            setLoading(false);
            dispatch(triggerFlashMessage({ type: "error", title: t("Failed to update company") }));
        });
    };

    const handleDeleteUser = () => {
        deleteUser({ variables: { id: deleteId } }).then(res => {
            companyUsersQuery.refetch()
            dispatch(triggerFlashMessage({ type: "success", title: t("Succesfully deleted user") }))
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Failed to delete user") }))
        });
    };

    const handleCloseDeleteCompany = () => {
        setConfirmDeleteComapny('');
        setOpenConfirmDeleteCompany(false);
    };

    const handleDeleteCompany = () => {
        setIsDeletingComapny(true);
        deleteCompany({ variables: { id: selectedCompany._id } }).then(res => {
            refetchAllCompanies();
            setValue({name: ''});
            setIsDeletingComapny(false);
            handleCloseDeleteCompany();
            dispatch(triggerFlashMessage({ type: "success", title: t("Succesfully deleted Company") }))
        }).catch(err => {
            setIsDeletingComapny(false);
            dispatch(triggerFlashMessage({ type: "error", title: t("Failed to delete company") }))
        });
    };

    const getRequiredText = () => {
        let array = [];
        if(!checkEmpty(companyInput?.name)) array.push('Name');
        if(!checkEmpty(companyInput?.address)) array.push('Address');
        if(!checkEmpty(companyInput?.zipCode)) array.push('Zip Code');
        if(!checkEmpty(companyInput?.city)) array.push('City');
        if(!checkEmpty(companyInput?.country)) array.push('Country');
        if(!checkEmpty(companyInput?.phone)) array.push('Phone');
        if(!checkEmpty(companyInput?.EAN)) array.push('EAN');
        return array.join(', ');
    };

    const checkEmpty = (input) => {
        return !_.isEmpty(input?.trim(''));
    };

    const checkCompanyInput = () => {
        return checkEmpty(companyInput?.name) && checkEmpty(companyInput?.address) && checkEmpty(companyInput?.zipCode) && checkEmpty(companyInput?.city) && checkEmpty(companyInput?.country) && checkEmpty(companyInput?.phone) && checkEmpty(companyInput?.EAN);
    };

    const onChange = (e) => {
        setCompanyInput({
            ...companyInput,
            [e.target.name]: e.target.value
        });
    };

    const handleLanguageQAChange = (name) => {
        const foundIndex = companyInput?.settings?.languages?.findIndex(e => e.fullName === name);
        const newInput = {
            ...companyInput,
            settings: {
                ...companyInput?.settings,
                defaultQALanguage: foundIndex
            }
        };
        setCompanyInput(newInput);
    };

    const handleDefaultDeleteDateChange = (name) => {
        const newInput = {
            ...companyInput,
            defaultDeleteDate: name
        };
        setCompanyInput(newInput);
    };

    const sendEmail = () => {
        if(validateEmail(email)){
            setIsSendingEmail(true);
            let emailInput = { company: selectedCompany?._id, email: email, role: inviteRole};
            if(user.role === 'SuperAdmin' || user.role === 'AccountAdmin') {
                emailInput = { company: selectedCompany?._id, email: email, role: inviteRole, sender: `${user.firstName} ${user.lastName}`};
            }
            inviteUser({ variables: emailInput }).then(res => {
                setIsSendingEmail(false);
                setEmail('');
                setEmailError(false);
                setOpen(!open);
                setInviteRole('');
                dispatch(triggerFlashMessage({ type: "success", title: t("Succesfully send invitation") }));
            }).catch(e => {
                if(e?.graphQLErrors[0]?.extensions?.exception?.code === 403) {
                    setIsSendingEmail(false);
                    return dispatch(triggerFlashMessage({ type: "error", title: t(`E-mail is already used by another user`) }));
                }
                setIsSendingEmail(false);
                dispatch(triggerFlashMessage({ type: "error", title: t("Failed to send email") }));
            })
        } else setEmailError(true);
    };

    const checkInputError = (inputName) => {
        if(selectedCompany && companyInput && companyInput[inputName]) {
            return allCompanies?.find(comp => comp[inputName] === companyInput[inputName] && comp?._id !== selectedCompany?._id)
        } else if(companyInput && companyInput[inputName]) {
            return allCompanies?.find(comp => comp[inputName] === companyInput[inputName])
        }
        return false;
    };

    const companyHasChanges = () => {
        return !_.isEqual(companyInput, companyInputCopy);
    }

    const getUsersByRole = (role) => {
        return companyUsers?.filter(u => u?.role === role)
    }

    const openCloseModal = (role, index) => {
        if(!selectedCompany?._id) return;
        if(role === 'AccountAdmin') setInviteRole('AccountAdmin');
        if(role === 'CaseDesign') setInviteRole('CaseDesign');
        if(role === 'TemplateOnly') setInviteRole('TemplateOnly');
        if(role !== '') setInviteRoleUI(prettyUserRoles[index]);
        setOpen(!open);
    };

    const onDelete = (e, id) => {
        e.stopPropagation();
        setDeleteId(id)
        setIsDeleting(true)
    }

    useEffect(() => {
        const company = allCompanies?.find(comp => comp?._id === value?._id);
        if(company) {
            handleSelectCompany(company);
        } else {
            setSelectedCompany();
            setCompanyInput({
                defaultDeleteDate: 90,
                settings: user?.company?.settings
            });
            setCompanyInputCopy({
                defaultDeleteDate: 90,
                settings: user?.company?.settings
            });
        };
    }, [value]);

    useEffect(() => {
        setCompanyUsers(companyUsersQuery?.data?.usersByCompany)
    }, [companyUsersQuery])

    return (
        <div className={classes.container}>
            <EmailModal open={open} handleClose={() => console.log('')} >
                <div>
                    <h2>{inviteRoleUI}</h2>
                    <div className={classes.emailError}>
                        <TextField error={emailError} id="standard-basic" fullWidth={true} label="Email" variant="standard" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className={classes.emailButtonContainer}>
                        <CustomLoadingButton aria-label="send" title="Send" onClick={sendEmail} loading={isSendingEmail} />
                        <CustomLoadingButton aria-label="cancel" title="Cancel" onClick={() => openCloseModal('')} />
                    </div>
                </div>
            </EmailModal>
            <EmailModal open={openConfirmDeleteCompany}>
                <div>
                    <h2>{`Delete company ${selectedCompany?.name}`}</h2>
                    <div className={classes.emailError}>
                        Write the word DELETE to confirm and continue
                    </div>
                    <div className={classes.emailError}>
                        <TextField value={confirmDeleteCompany} id="standard-basic" fullWidth={true} label="confirm" variant="standard" onChange={(e) => setConfirmDeleteComapny(e.target.value)} />
                    </div>
                    <div className={classes.emailButtonContainer}>
                        <LoadingBtn style={confirmDeleteCompany !== 'DELETE' ? {color: 'white', backgroundColor: 'grey'} : {color: 'white', backgroundColor: 'red'}} disabled={confirmDeleteCompany !== 'DELETE'} aria-label="Delete" title="Delete" onClick={handleDeleteCompany} loading={isDeletingComapny} >
                            Delete
                        </LoadingBtn>
                        <LoadingBtn aria-label="cancel" onClick={handleCloseDeleteCompany} >
                            Cancel
                        </LoadingBtn>
                    </div>
                </div>
            </EmailModal>
            <div className={classes.contentContainer}>
                <div className={classes.caseSelectorCompSearch}>
                    <Autocomplete
                        value={value}
                        onChange={(e, newVal) => {setValue(newVal)}}
                        inputValue={inputValue}
                        onInputChange={(e, newInputVal) => {setInputValue(newInputVal)}}
                        options={allCompanies}
                        getOptionLabel={(option) => option?.name}
                        className={classes.searchAutoComplete}
                        freeSolo
                        renderInput={(params) => 
                            <TextField
                                {...params}
                                label="Company, search or choose from drop-down"
                                variant="standard"
                                InputProps={{ 
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                className={classes.textFieldBorder}
                            />
                        }
                    />
                </div>
                <DCList useParentShow={true} parentShow={showCompany} setParentShow={setShowCompany} expandIcon className={classes.listContainer} title={t('Company')}>
                    <div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth 
                                label={t('Name')} 
                                autoComplete="new-password" 
                                name="name"
                                error={checkInputError('name')}
                                variant="outlined" 
                                onChange={onChange} 
                                value={companyInput?.name ? companyInput?.name : ''} 
                                InputProps={{ autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth  
                                label={t('Address')} 
                                autoComplete="new-password" 
                                name="address" 
                                variant="outlined" 
                                onChange={onChange} 
                                value={companyInput?.address ? companyInput?.address : ''}
                                InputProps={{ autoComplete: 'new-password', form: { autoComplete: 'off' }} }
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth  
                                label={t('Address2')}
                                autoComplete="new-password" 
                                name="address2" 
                                variant="outlined" 
                                onChange={onChange} 
                                value={companyInput?.address2 ? companyInput?.address2 : ''} 
                                InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off' } }}    
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth 
                                label={t('Zip Code')} 
                                autoComplete="new-password" 
                                name="zipCode" 
                                variant="outlined" 
                                onChange={onChange}
                                value={companyInput?.zipCode ? companyInput?.zipCode : ''} 
                                InputProps={{ autoComplete: 'new-password', form: { autoComplete: 'off', }} } 
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth 
                                label={t('City')} 
                                autoComplete="new-password" 
                                name="city" 
                                variant="outlined" 
                                onChange={onChange}  
                                value={companyInput?.city ? companyInput?.city : ''} 
                                InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth 
                                label={t('Country')} 
                                autoComplete="new-password" 
                                name="country" 
                                variant="outlined" 
                                onChange={onChange} 
                                value={companyInput?.country ? companyInput?.country : ''} 
                                InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth
                                label={t('Phone')} 
                                autoComplete="new-password" 
                                name="phone" 
                                variant="outlined" 
                                onChange={onChange} 
                                value={companyInput?.phone ? companyInput?.phone : ''} 
                                InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                            />
                        </div>
                        <div className={classes.dropdownItem}>
                            <TextField 
                                fullWidth  
                                label={t('EAN')} 
                                autoComplete="new-password" 
                                name="EAN"
                                error={checkInputError('EAN')}
                                variant="outlined"
                                onChange={onChange}
                                value={companyInput?.EAN ? companyInput?.EAN : ''} 
                                InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                            />
                        </div>
                        <div className={classes.selectorContainer}>
                            <div className={classes.defaultQALanguage}>
                                <CustomDropdownMenu
                                    selected={companyInput?.settings?.languages[companyInput?.settings?.defaultQALanguage]?.fullName}
                                    onSelect={(e) => handleLanguageQAChange(e)}
                                    label={t('Default Language (Q&A)')}
                                    title={companyInput?.settings?.languages[companyInput?.settings?.defaultQALanguage]?.fullName}
                                    elements={auth?.user?.company?.settings?.languages?.map(l => l.fullName)}
                                />
                            </div>
                            <div className={classes.defaultQALanguage}>
                                <CustomDropdownMenu
                                    selected={companyInput?.defaultDeleteDate}
                                    onSelect={(e) => handleDefaultDeleteDateChange(e)}
                                    label={t('Default delete date (days)')}
                                    title={companyInput?.defaultDeleteDate}
                                    elements={defaultDeleteDateOptions}
                                />
                            </div>
                            <div className={classes.defaultQADatabase}>
                                <CustomDropdownMenu
                                    disabled
                                    hideIcon
                                    style={{cursor: 'default'}}
                                    label={t('Database (Q&A)')}
                                    title='Dooblecheck database'
                                />
                            </div>
                        </div>
                        {selectedCompany?._id && <div className={classes.sendInviteContainer}>
                            <div className={`${classes.flexDropdownItem} ${classes.dashTopBorder}`} onClick={() => openCloseModal('AccountAdmin', 0)}>
                                <p>{t('Invite new user with Account-Admin-Access')}</p>
                                <div className="account-dropdown-icon">
                                    <Tooltip title={!selectedCompany?._id ? 'Select a company' : 'Send invite'}>
                                        <IconButton aria-label="email" onClick={() => openCloseModal('AccountAdmin', 0)}>
                                            <MailIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={classes.flexDropdownItem} onClick={() => openCloseModal('CaseDesign', 1)}>
                                <p>{t('Invite new user with Case-Design-Access')}</p>
                                <div className="account-dropdown-icon">
                                    <Tooltip title={!selectedCompany?._id ? 'Select a company' : 'Send invite'}>
                                        <IconButton aria-label="email" onClick={() => openCloseModal('CaseDesign', 1)}>
                                            <MailIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className={classes.flexDropdownItem} onClick={() => openCloseModal('TemplateOnly', 2)}>
                                <p>{t('Invite new user with Template-Only-Access')}</p>
                                <div className="account-dropdown-icon">
                                    <Tooltip title={!selectedCompany?._id ? 'Select a company' : 'Send invite'}>
                                        <IconButton aria-label="email" onClick={() => openCloseModal('TemplateOnly', 2)}>
                                            <MailIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>}
                        {companyHasChanges() && <div className={`dropdown-input-item submit ${classes.submitButtonContainer}`}>
                            <Tooltip title={!checkCompanyInput() ?   `${getRequiredText()} is required` : ''}>
                                <div>
                                    <LoadingBtn disabled={!checkCompanyInput()} style={checkCompanyInput() ? { backgroundColor: Colors.mainBlue, color: 'white' } : { backgroundColor: Colors.grey, color: 'black' }} onClick={selectedCompany ? onUpdate : onSave} loading={loading} >{t('Save')}</LoadingBtn>
                                </div>
                            </Tooltip>
                        </div>}
                    </div>
                </DCList>

                

                {selectedCompany?._id && userRoles?.map((role, index) => {
                    return (
                        <DCList key={role} expandIcon className={classes.listContainer} title={prettyUserRoles[index]}>
                            {getUsersByRole(role)?.length === 0 && <p className={classes.noUsers}>No users found</p>} 
                            {companyUsers && getUsersByRole(role)?.map((foundUser => {
                                return (
                                    <div key={foundUser?._id}>
                                        <CustomAlertDialog id={foundUser?._id} open={isDeleting} handleCancel={() => setIsDeleting(false)} handleClose={() => setIsDeleting(false)} handleAccept={(id) => handleDeleteUser(id)} alertTitle="Delete" alertText={t("Are you sure you want to delete?")} okText="Delete" /> 
                                        <DCList leftIcon={() => <DeleteIcon onClick={(e) => onDelete(e, foundUser._id)} style={{ color: Colors.mainBlue }} fontSize="small" />} expandIcon style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.table1, marginTop: 5, '&:hover': { outline: 'none'}, minHeight: 50 }} title={`${foundUser?.firstName} ${foundUser?.lastName}`}>
                                            <DCUser 
                                                onSave={updateUser} 
                                                setCompanyUsers={setCompanyUsers} 
                                                users={companyUsers} 
                                                user={foundUser} 
                                                refetch={() => companyUsersQuery.refetch()}
                                            />
                                        </DCList>
                                    </div>
                                )
                            }))}
                        </DCList>
                    )
                })}

                {selectedCompany?._id && <div style={{width: '100%', marginTop: 50}}>
                    <Tooltip title={'Warning! This will delete the company and everything related to the company (users, cases, templates etc.)'}>
                        <div>
                            <LoadingBtn style={{ backgroundColor: 'red', color: 'white' }} fullWidth onClick={() => setOpenConfirmDeleteCompany(true)} >{t('DELETE COMPANY')}</LoadingBtn>
                        </div>
                    </Tooltip>
                </div>}
            </div>
        </div>
    )
}

export default Company

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    emailError: {
        margin: '20px 0px',
    },
    emailButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    contentContainer: {
        width: '70%',
        paddingBottom: '10%',
    },
    dropdownItem: {
        paddingTop: 20,
    },
    selectorContainer: {
        border: `1px dashed ${Colors.mainBlue}`,
        display: 'flex', 
        marginTop: 20, 
        marginBottom: 20,
    },
    defaultQALanguage: {
        flex: 1,
        borderRight: '1px dashed #000',
    },
    defaultQADatabase: {
        flex: 1,
        borderLeft: '1px dashed #fff',
    },
    sendInviteContainer: {
        margin: '20px 0px',
    },
    caseSelectorCompSearch : {
        width: '99,9%',
        border: `1px dashed ${Colors.mainBlue}`,
        minHeight: '55px',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        padding: '6px 8px',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    searchAutoComplete: {
        width: '100%',
        marginLeft: '20px',
        marginTop: '5px',
        textColor: 'red',
    },
    textFieldBorder: {
        paddingLeft: '10px',
        '& input:valid + fieldset': {
            borderStyle: 'dashed',
            borderColor: 'black',
            borderRadius: 0,
        },
        '& input:valid:focus + fieldset': {
            borderWidth: 1,
            borderColor: 'black',
        },
    },
    listContainer: {
        backgroundColor: props => props.color ? props.color : `${Colors.mainBlue} !important`,
        color: '#fff',
        display: 'flex !important', 
        justifyContent: 'center !important', 
        marginTop: 5,
        minHeight: 50,
        cursor: 'pointer'
    },
    flexDropdownItem: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 50,
        backgroundColor: Colors.table1,
        borderBottom: `1px dashed ${Colors.mainBlue}`,
        alignItems: 'center',
        paddingLeft: 10,
        borderLeft: `7px solid ${Colors.mainBlue}`,
        borderRight: `1px dashed ${Colors.mainBlue}`,
        fontWeight: '500',
        cursor: 'pointer',
    },
    dashTopBorder: {
        borderTop: '1px dashed #000',
    },
    submitButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    noUsers: {
        paddingTop: 20,
        paddingBottom: 20,
        textAlign: 'center',
    },
});
