import { IconButton, Tooltip } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { replaceQuestionString, replaceRatingPointString } from '../services/caseService';
import { Colors } from '../styles/globalStyles';
import {
    candidateNameTranslations,
    candidateTranslations,
    questionUnavailableTranslation,
    referenceNameTranslations,
} from '../utils/constansts';
import { getLanguageLocal, getLanguageLocalQuestionsFilter } from '../utils/getLanguage';
import DCQuestionInput from './DCQuestionInput';
import LanguageChange from './LanguageChange';
import QuestionAnwserDisplayName from './QuestionAnswerDisplayName';
import QuestionAnswerMultipleChoice from './QuestionAnswerMultipleChoice';
import QuestionAnswerProsCons from './QuestionAnswerProsCons';
import QuestionsAnswerPrio from './QuestionsAnswerPrio';
import QuestionsAnswerSlider from './QuestionsAnswerSlider';

function QuestionsPreview({
    open = false,
    handleClose,
    caseInfo,
    reference,
    style,
    showAnswers,
    combined,
    selectedLanguage,
    setSelectedLanguage,
    isClosed = false,
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const { company } = useSelector(state => state.auth.user);

    const [combinedQuestions, setCombinedQuestions] = useState();

    useEffect(() => {
        if (combined) {
            const combinedQ = caseInfo?.references?.map(ref => {
                return ref?.questions.map(q => q.question);
            });

            const merged = [].concat.apply([], combinedQ);
            const uniqueObjArray = [...new Map(merged.map(item => [item['_id'], item])).values()];
            const comnbinedQ = uniqueObjArray.map(e => ({ question: e }));
            setCombinedQuestions(comnbinedQ);
        } else {
            setCombinedQuestions(reference?.questions);
        }
    }, [combined, reference, open]);

    const getReferences = questionId => {
        if (combined) {
            return caseInfo?.references?.filter(ref => {
                return ref?.listingMailSend && ref?.questions.some(e => e?.question?._id === questionId);
            });
        } else {
            return [reference];
        }
    };

    const getComponent = (type, questionObj, index) => {
        if (!getLanguageLocalQuestionsFilter(questionObj?.question, selectedLanguage)) {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p className={classes.title}>
                        {getLanguageLocal(questionUnavailableTranslation, selectedLanguage)?.name}
                    </p>
                </div>
            );
        }
        if (type === 'RatingPoints') {
            return (
                <QuestionsAnswerSlider
                    title={replaceRatingPointString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        caseInfo?.candidate?.firstName,
                        caseInfo?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    answers={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    questionIndex={index}
                    enabled={false}
                    question={questionObj}
                    elaboration={questionObj?.question?.elaboration}
                    selectedLanguage={selectedLanguage}
                    showAnswers={showAnswers}
                    isReport={combined}
                    references={combined ? caseInfo?.references : [reference]}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                />
            );
        } else if (type === 'Open' && getLanguageLocal(questionObj?.question, selectedLanguage)?.name) {
            return (
                <DCQuestionInput
                    showAnswers={showAnswers}
                    questionId={questionObj?.question?._id}
                    references={getReferences(questionObj?.question?._id)}
                    label={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        caseInfo?.candidate?.firstName,
                        caseInfo?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    selectedLanguage={selectedLanguage}
                    isClosed={isClosed}
                />
            );
        } else if (type === 'Prio') {
            return (
                <QuestionsAnswerPrio
                    title={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        caseInfo?.candidate?.firstName,
                        caseInfo?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    answers={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    max={questionObj?.question?.scale?.choices}
                    isReport={combined}
                    references={combined ? caseInfo?.references : [reference]}
                    showAnswers={showAnswers}
                    enabled={false}
                    questionIndex={index}
                    elaboration={questionObj?.question?.elaboration}
                    selectedLanguage={selectedLanguage}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                />
            );
        } else if (type === 'Multiple') {
            console.log('questionObj', questionObj);
            return (
                <QuestionAnswerMultipleChoice
                    title={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage).name,
                        caseInfo?.candidate?.firstName,
                        caseInfo?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    options={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    questionIndex={index}
                    enabled={false}
                    elaboration={questionObj?.question?.elaboration}
                    selectedLanguage={selectedLanguage}
                    references={combined ? caseInfo?.references : [reference]}
                    showAnswers={showAnswers}
                    isReport={combined}
                    max={questionObj?.question?.scale?.choices}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                    is5PointRating={questionObj?.question?.scale?.translations?.some(t => t.name === '5 point rating')}
                />
            );
        } else if (type === 'ProsCons') {
            return (
                <QuestionAnswerProsCons
                    isReport={combined}
                    references={combined ? caseInfo?.references : [reference]}
                    showAnswers={showAnswers}
                    enabled={false}
                    duration={questionObj?.question?.duration}
                    selectedLanguage={selectedLanguage}
                    questionIndex={index}
                    title={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage).name,
                        caseInfo?.candidate?.firstName,
                        caseInfo?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    max={3}
                    answers={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Modal open={open} onClose={() => console.log('close')}>
            <div>
                <div style={style} className={classes.Container}>
                    <div style={{ position: 'relative' }}>
                        <div className={classes.PreviewHeader}>
                            <Tooltip className={classes.TooltipContainer} title="Close View">
                                <IconButton onClick={() => handleClose(false)}>
                                    <Close style={{ color: '#fff' }} />
                                </IconButton>
                            </Tooltip>
                            <h1 className={classes.Header}>{showAnswers ? t('Report') : t('View')}</h1>
                        </div>
                        <div className={classes.CandidateInviteContainer}>
                            <div style={{ paddingLeft: 20, paddingTop: 20 }}>
                                <LanguageChange
                                    useLanguage={true}
                                    handleLanguage={setSelectedLanguage}
                                    language={selectedLanguage}
                                    company={company}
                                />
                            </div>

                            <div className={classes.ContentContainer}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: '50px',
                                        width: '100%',
                                    }}
                                >
                                    {combined ? (
                                        <QuestionAnwserDisplayName />
                                    ) : (
                                        <QuestionAnwserDisplayName
                                            label={reference?.type}
                                            value={
                                                reference?.firstName && !isClosed
                                                    ? `${reference?.firstName} ${reference?.lastName}`
                                                    : getLanguageLocal(referenceNameTranslations, selectedLanguage)
                                                          ?.name
                                            }
                                        />
                                    )}
                                    <QuestionAnwserDisplayName
                                        label={getLanguageLocal(candidateTranslations, selectedLanguage)?.name}
                                        value={
                                            caseInfo?.candidate?.firstName && !isClosed
                                                ? `${caseInfo?.candidate?.firstName} ${caseInfo?.candidate?.lastName}`
                                                : getLanguageLocal(candidateNameTranslations, selectedLanguage)?.name
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '90%', pointerEvents: 'none' }}>
                                    {combinedQuestions?.map((questionObj, index) => {
                                        return (
                                            <div key={questionObj?._id} style={{ marginTop: 0 }}>
                                                {getComponent(questionObj?.question?.scale?.type, questionObj, index)}
                                                <div className={classes.linebreak} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default QuestionsPreview;

const useStyles = makeStyles({
    Container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '80%',
        width: '70%',
        backgroundColor: '#fff',
        transform: 'translate(-50%, -50%)',
        overflowY: 'scroll',
    },
    title: {
        fontSize: '20px',
        fontWeight: '500',
        textAlign: 'center',
        fontStyle: 'italic',
    },
    CandidateInviteContainer: {
        position: 'relative',
        height: '100%',
    },
    ContentContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '50px 70px',
        background: '#fff',
    },
    TooltipContainer: {
        position: 'absolute !important',
        right: '10px',
        backgroundColor: `${Colors.mainBlue} !important`,
    },
    PreviewHeader: {
        backgroundColor: Colors.mainBlue,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        top: 0,
        width: '100%',
        zIndex: 2,
    },
    Header: {
        color: '#fff',
        fontWeight: '400',
    },
    linebreak: {
        height: '1px',
        borderTop: `4px dashed ${Colors.mainBlue}`,
        marginTop: '50px',
        marginBottom: '50px',
    },
});
