import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Modal, Box, Tooltip, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/globalStyles';

function PrivacyPolicy({ open = false, setOpen }) {
    const classes = useStyles();
    const {i18n} = useTranslation()

    return (
        <Modal style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} open={open} onClose={() => null}>
            <Box className={classes.container}>
                <div>
                    <div className={classes.topContainer} >
                        <Tooltip  title="Close">
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon style={{ color: '#fff' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {i18n?.language === 'da' &&
                        <div className={classes.contentContainer}>
                            <div>
                                <h1>Persondatapolitik</h1>
                                <p className={classes.marginTop}>I denne privatlivspolitik kan du læse om, hvordan DOOBLECHECK ApS (DOOBLECHECK) behandler de oplysninger du afgiver, når du besøger en af vores hjemmesider og besvarer et reference-spørgeskema.</p>
                                <p className={classes.marginTop}>Hvis du har spørgsmål til vores behandling, kan du altid kontakte os på <a style={{ color: 'blue' }} href="mailto:support@dooblecheck.com">support@dooblecheck.com</a></p>
                            </div>
                            <div className={classes.section}>
                                <h4>1. Hvordan behandler DOOBLECHECK personoplysninger om dig?</h4>
                                <p className={classes.marginTop}>Oplysninger og data afgivet i forbindelse med din besvarelse af et reference-spørgeskema vil variere alt efter, hvilken relation du har til kandidaten og de emner som den rekrutterende virksomhed ønsker afdækket. </p>
                                <p className={classes.marginTop}>DOOBLECHECK operer som databehandler på vegne af den rekrutterende virksomhed som er dataansvarlig ift. dine afgivne informationer og besvarelser og behandler alene personoplysninger med det formål, at kvalificere kandidatens kandidatur.</p>
                                <p className={classes.marginTop}>DOOBLECHECK indsamler oplysninger ved, at du selv angiver dem når du besvarer et reference-spørgeskema. Endvidere indsamles enkelte oplysninger passivt som din IP-adresse, oplysninger om din browser og tidspunkt for dit besøg på vores hjemmeside.</p>
                            </div>
                            <div className={classes.section}>
                                <h4 className={classes.marginTop}>1.1. Hvis du udfylder vores spørgeskemaer</h4>
                                <p className={classes.marginTop}>Det er altid frivilligt at besvare et DOOBLECHECK reference-spørgeskema. Man kan stoppe besvarelsen på ethvert tidspunkt, hvis der er et spørgsmål, man ikke ønsker at besvare.</p>
                                <p className={classes.underline}>Hvorfor indsamler vi oplysningerne?</p>
                                <p>Formålet med indsamling og behandling af oplysninger er at kvalificere et givent kandidatur og indgå i den rekrutterende virksomheds søgen efter den rette nye medarbejder.</p>
                                <p className={classes.underline}>Hvordan har vi lov til at indsamle oplysningerne?</p>
                                <p>DOOBLECHECK operer som databehandler på vegne af den rekrutterende virksomhed som er dataansvarlig ift. dine afgivne informationer og besvarelser som endvidere sker i overensstemmelse med kandidatens samtykke.</p>
                                <p className={classes.underline}>Hvilke oplysninger indsamles og hvor de stammer fra?</p>
                                <p>Når du deltager i DOOBLECHECK’s spørgeskemaundersøgelser indsamler vi både passive og aktive oplysninger som nævnt ovenfor. De aktive oplysninger er dine svar på spørgsmål, som stilles i pågældende undersøgelse. Disse oplysninger vil som regel ikke kunne identificere dig.</p>
                                <p className={classes.underline}>Hvor længe opbevares oplysningerne?</p>
                                <p>Alle oplysninger slettes ved afslutning af den pågældende rekrutteringsproces.</p>
                                <p className={classes.underline}>Hvem deles oplysningerne med?</p>
                                <p>Dine besvarelser bliver delt med den rekrutterende virksomhed samt kandidaten – såfremt dit samtykke herom foreligger.</p>
                            </div>
                            <div className={classes.section}>
                                <h4 className={classes.marginTop}>1.2. Hvis du besøger vores hjemmeside</h4>
                                <p className={classes.marginTop}>Når du besøger DOOBLECHECK’s hjemmesider, efterlader du dig elektroniske spor, som opbevares af DOOBLECHECK</p>
                                <p className={classes.underline}>Hvorfor indsamler vi oplysningerne?</p>
                                <p>Formålet med indsamling af oplysningerne er at generere statistik om, hvordan siderne bruges, samt logføring af sikkerhedshensyn. Formålet med at indsamle IP-adresser, browser-information og tidspunkt for besøg på vores sider er statistik, datasikkerhed og forbedring af websider.</p>
                                <p className={classes.underline}>Hvordan har vi lov til at indsamle oplysningerne?</p>
                                <p>Hvis du accepterer brugen af cookies, er retsgrundlaget for vores behandling af dine personoplysninger dit samtykke, jf. databeskyttelsesforordningens artikel 6, stk. 1, litra a. Du kan til enhver tid trække dit samtykke til behandlingen tilbage ved at ændre dine cookieindstillinger.
                                    Øvrige oplysninger der indsamles, behandles på grundlag af vores legitime interesser i, at vores hjemmeside fungerer som den skal, og at der opretholdes et passende niveau af sikkerhed på siden, jf. databeskyttelsesforordningens artikel 6, stk. 1, litra f.
                                </p>
                                <p className={classes.underline}>Hvilke oplysninger anvendes, og hvor de stammer fra?</p>
                                <p>Din IP-adresse, browser-information og tidspunkt for besøg på vores sider, og hvilke sider du har besøgt.</p>
                                <p className={classes.underline}>Hvor længe opbevares oplysningerne?</p>
                                <p>Oplysningerne opbevares indtil den pågældende rekrutteringsproces er afsluttet.</p>
                            </div>
                            <div className={classes.section}>
                                <h4 className={classes.marginTop}>2. Hvordan beskytter DOOBLECHECK de oplysninger, der indsamles om dig?</h4>
                                <p className={classes.marginTop}>For DOOBLECHECK er det vigtigt at vores processer er klare, åbne og transparente. Du er derfor altid velkommen til at kontakte os med spørgsmål.
                                    DOOBLECHECK behandler altid oplysninger om dig i overensstemmelse med EU’s databeskyttelsesforordning (GDPR) og den danske databeskyttelseslov - samlet betegnet Persondatalovgivning. Formålet med denne lovgivning er at beskytte personers privatliv, og Datatilsynet fører i Danmark tilsyn med, at reglerne overholdes.
                                </p>
                            </div>
                            <div className={classes.section}>
                                <h4 className={classes.marginTop}>3. Hvor opbevares og behandles oplysningerne?</h4>
                                <p className={classes.marginTop}>DOOBLECHECK hoster ikke selv sine IT-systemer. I stedet benytter vi en certificeret Hosting partner til at opbevare personoplysningerne, beskrevet i denne privatlivspolitik.</p>
                                <p className={classes.marginTop}>DOOBLECHECK har indgået databehandleraftaler med disse leverandører. Aftalerne fastsætter de forpligtelser en databehandleren skal overholde, når denne foretager opbevaring og behandling af dine personoplysninger på vegne af DOOBLECHECK. Databehandleren kan således kun lovligt behandle oplysninger om dig for at opfylde de formål, som DOOBLECHECK bestemmer.</p>
                            </div>
                            <div className={classes.section}>
                                <h4 className={classes.marginTop}>4. Dine rettigheder</h4>
                                <p className={classes.marginTop}>Du ret til at få indsigt i personoplysninger, som DOOBLECHECK opbevarer om dig, og få berigtiget disse, hvis de er forkerte. Du kan gøre dine rettigheder gældende, ved at kontakte os på support@dooblecheck.com. Læs mere om dine rettigheder på Datatilsynets hjemmeside, hvor du også finder information om dine muligheder for at klage til Datatilsynet.</p>
                            </div>
                            <div className={classes.section}>
                                <h4 className={classes.marginTop}>5. Opdatering</h4>
                                <p className={classes.marginTop}>DOOBLECHECK evaluerer og opdaterer løbende denne privatlivspolitik.</p>
                            </div>
                            <p className={classes.marginTop}>Senest opdateret: 03.10.2021</p>
                        </div>
                    }

                    {i18n?.language === 'en' &&
                        <div className={classes.contentContainer}>
                            <div>
                                <h1>Privacy policy</h1>
                                <p className={classes.marginTop}>In this Privacy Policy you can read about how DOOBLECHECK ApS (DOOBLECHECK) processes the information you provide when you visit one of our websites and answer a reference questionnaire.</p>
                                <p className={classes.marginTop}>If you have any questions about our processing, you can always contact us at <a style={{ color: 'blue' }} href="mailto:support@dooblecheck.com">support@dooblecheck.com</a></p>
                            </div>
                            <div className={classes.section}>
                                <h4>1. How does DOOBLECHECK process personal data about you?</h4>
                                <p className={classes.marginTop}>Information and data registered while answering a reference questionnaire, will vary depending on your relationship with the candidate and the issues that the recruiting company wishes to uncover.</p>
                                <p className={classes.marginTop}>DOOBLECHECK operates as a data processor on behalf of the recruiting company, which is the data controller in relation to the information and answers you provide, and processes personal data solely for the purpose of qualifying the candidate's candidacy.</p>
                                <p className={classes.marginTop}>DOOBLECHECK collects information by you providing it when you answer a reference questionnaire. In addition, some information is collected passively, such as your IP address, information about your browser and the time of your visit to our website.</p>
                            </div>
                            <div className={classes.section}>
                                <h4>1.1. If you complete our questionnaires</h4>
                                <p className={classes.marginTop}>Answering a DOOBLECHECK reference questionnaire is always voluntary. You can stop at any time if there is a question you do not want to answer. </p>
                                <p className={classes.underline}>Why do we collect the information?</p>
                                <p>The purpose of collecting and processing information is to qualify a given candidature and to contribute to the recruiting company's search for the right new employee.</p>
                                <p className={classes.underline}>How are we allowed to collect the information?</p>
                                <p>DOOBLECHECK operates as a data processor on behalf of the recruiting company which is the data controller with respect to your information provided and responses which are furthermore made in accordance with the candidate's consent.</p>
                                <p className={classes.underline}>What information is collected and where does it originate?</p>
                                <p>When you participate in DOOBLECHECK's questionnaire surveys, we collect both passive and active information as mentioned above. The active information is your answers to questions asked in the survey in question. This information will generally not identify you.</p>
                                <p className={classes.underline}>How long is the information kept?</p>
                                <p>All information is deleted at the end of the recruitment process concerned.</p>
                                <p className={classes.underline}>With whom is the information shared?</p>
                                <p>Your answers will be shared with the recruiting company and the candidate - subject to your consent.</p>
                            </div>
                            <div className={classes.section}>
                                <h4>1.2. If you visit our website</h4>
                                <p className={classes.marginTop}>When you visit DOOBLECHECK's websites, you leave behind electronic traces which are stored by DOOBLECHECK.</p>
                                <p className={classes.underline}>Why do we collect the information?</p>
                                <p>The purpose of collecting the information is to generate statistics on how the pages are used, as well as logging for security purposes. The purpose of collecting IP addresses, browser information and time of visit to our pages is statistical, data security and website improvement.</p>
                                <p className={classes.underline}>How are we allowed to collect the information?</p>
                                <p>If you consent to the use of cookies, the legal basis for our processing of your personal data is your consent as set out in Article 6(1)(a) of the GDPR. You can withdraw your consent to the processing at any time by changing your cookie settings.</p>
                                <p className={classes.underline}>What information is used and where does it come from?</p>
                                <p>Your IP address, browser information and time of visit to our pages and which pages you have visited.</p>
                                <p className={classes.underline}>How long is the data kept?</p>
                                <p>The data is kept until the relevant recruitment process is completed.</p>
                            </div>
                            <div className={classes.section}>
                                <h4>2. How does DOOBLECHECK protect the information collected about you?</h4>
                                <p className={classes.marginTop}>It is important to DOOBLECHECK that our processes are clear, open, and transparent. You are therefore always welcome to contact us with any questions.</p>
                                <p className={classes.marginTop}>DOOBLECHECK always processes information about you in accordance with the EU Data Protection Regulation (GDPR) and the Danish Data Protection Act - collectively referred to as the Personal Data Act. The purpose of this legislation is to protect the privacy of individuals, and the Danish Data Protection Authority supervises compliance with the rules.</p>
                            </div>
                            <div className={classes.section}>
                                <h4>3. Where is the data stored and processed?</h4>
                                <p className={classes.marginTop}>DOOBLECHECK does not host its IT systems itself. Instead, we use a certified hosting partner to store the personal data described in this privacy policy.</p>
                                <p className={classes.marginTop}>DOOBLECHECK has entered into data processing agreements with these suppliers. The agreements set out the obligations a data processor must comply with when it stores and processes your personal data on behalf of DOOBLECHECK. The data processor can thus only lawfully process data about you to fulfil the purposes determined by DOOBLECHECK.</p>
                            </div>
                            <div className={classes.section}>
                                <h4>4. Your rights</h4>
                                <p className={classes.marginTop}>You have the right to access the personal information that DOOBLECHECK holds about you and to have it corrected if it is incorrect. You can exercise your rights by contacting us at support@dooblecheck.com. Read more about your rights on the website of the Data Protection Authority, where you can also find information about your possibilities to complain to the Data Protection Authority.</p>
                            </div>
                            <div className={classes.section}>
                                <h4>5. Update</h4>
                                <p className={classes.marginTop}>DOOBLECHECK regularly evaluates and updates this privacy policy.</p>
                            </div>
                            <p className={classes.marginTop}>Last updated: 03.10.2021</p>
                        </div>
                    }
                </div>
            </Box>
        </Modal>
    )
}

const useStyles = makeStyles({
    container: {
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        maxHeight: '60%', 
        overflowY: 'auto',
        backgroundColor: '#fff', 
        transform: 'translate(-50%, -50%)',
        '@media (min-width: 1025px)': {
            width: '50%',
        },
        '@media (max-width: 1024px) and (min-width: 481px)': {
            width: '80%',
            maxHeight: '75%',
        },
        '@media (max-width: 480px)': {
            width: '100%',
            maxHeight: '85%',
        },
    },
    topContainer: {
        position: 'sticky',
        top: -1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 70,
        backgroundColor: Colors.mainBlue,
        paddingLeft: 10,
        paddingRight: 10,
        '@media (max-width: 1024px)': {
            height: 40,
        },
    },
    contentContainer: {
        padding: '5%',
    },
    section: {
        marginTop: 20,
    },
    marginTop: {
        marginTop: 10,
    },
    underline: {
        marginTop: 20,
        textDecoration: 'underline',
    },
})

export default PrivacyPolicy
