import reactStringReplace from 'react-string-replace';

const underlineFunc = (match, index, offset) => <span style={{ textDecoration: 'underline' }}>{match}</span>

export const makeUnderlineQuestion = (string) => {
    const professionalEN = reactStringReplace(string, 'professional', underlineFunc)

    const professionalDA = reactStringReplace(professionalEN, 'faglige', underlineFunc)

    const personalEN = reactStringReplace(professionalDA, 'personal', underlineFunc)

    const personalDA = reactStringReplace(personalEN, 'personlige', underlineFunc)

    return personalDA;
}
