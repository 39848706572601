import { useQuery } from '@apollo/client';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import dcLogo from '../../assets/images/TheDigitalDooblecheckWithSubTextLogo.jpg';
import DCQuestionInput from '../../components/DCQuestionInput';
import QuestionAnswerMultipleChoice from '../../components/QuestionAnswerMultipleChoice';
import QuestionAnswerProsCons from '../../components/QuestionAnswerProsCons';
import QuestionsAnswerPrio from '../../components/QuestionsAnswerPrio';
import QuestionsAnswerSlider from '../../components/QuestionsAnswerSlider';
import { CASE_BY_ID, COMPANY_BY_ID } from '../../graphql/mutations';
import { replaceQuestionString, replaceRatingPointString } from '../../services/caseService';
import { Colors } from '../../styles/globalStyles';
import { getLanguageLocal } from '../../utils/getLanguage';

function ReferenceReport2({ match }) {
    const [caseId, setCaseId] = useState();
    const [referenceId, setReferenceId] = useState();
    const [report, setReport] = useState();
    const [isClosed, setIsClosed] = useState(false);
    const [company, setCompany] = useState();
    const [reference, setReference] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState({ shortName: 'en' });
    const [isCombined, setIsCombined] = useState();
    const [combinedQuestions, setCombinedQuestions] = useState();
    const [logo, setLogo] = useState(dcLogo);

    const classes = useStyles();

    const { data } = useQuery(CASE_BY_ID, { variables: { id: caseId }, fetchPolicy: 'cache-and-network' });
    const { data: companyData } = useQuery(COMPANY_BY_ID, { variables: { id: report?.company?._id } });

    useEffect(() => {
        setIsCombined(match?.path?.includes('combined'));
    }, [match]);

    useEffect(() => {
        setCaseId(match?.params?.id);
        setReferenceId(match?.params?.refId);
    }, [match?.params]);

    useEffect(() => {
        setReport(data?.case);
        setIsClosed(data?.case?.status === 'closed');
        setCompany(companyData?.company);

        if (!isCombined) {
            const foundRef = data?.case?.references?.find(ref => ref?._id === referenceId);
            setSelectedLanguage({ shortName: foundRef?.language });
            setReference(foundRef);
        } else {
            setReference(data?.case?.references[0]);
        }

        setLogo(companyData?.company?.branding?.logo?.base64 || dcLogo);

        const foundLanguage =
            companyData?.company?.settings?.languages[companyData?.company?.settings?.defaultQALanguage];
        setSelectedLanguage({ shortName: match?.params?.language || foundLanguage });
    }, [data, companyData]);

    useEffect(() => {
        const el = document.getElementById('navigationHeader');
        const el2 = document.getElementById('paddingBreak');
        el?.remove();
        el2?.remove();
    }, []);

    useEffect(() => {
        if (report) {
            const combined = report?.references?.map(ref => {
                return ref?.questions.map(q => q.question);
            });

            const merged = [].concat.apply([], combined);
            const uniqueObjArray = [...new Map(merged.map(item => [item['_id'], item])).values()];
            const comnbinedQ = uniqueObjArray.map(e => ({ question: e }));
            if (!isCombined) {
                const referenceQuestion = reference.questions.map(q => ({ question: q.question }));
                setCombinedQuestions(referenceQuestion);
            } else {
                setCombinedQuestions(comnbinedQ);
            }
        }
    }, [report, reference, isCombined]);

    const getReferences = questionId => {
        if (isCombined) {
            return report?.references?.filter(ref => {
                return ref?.listingMailSend && ref?.questions?.some(e => e?.question?._id === questionId);
            });
        } else {
            return [reference];
        }
    };

    const getComponent = (type, questionObj, index) => {
        if (type === 'RatingPoints') {
            return (
                <QuestionsAnswerSlider
                    title={replaceRatingPointString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        report?.candidate?.firstName,
                        report?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    selectedLanguage={selectedLanguage}
                    questionIndex={index}
                    enabled={false}
                    elaboration={questionObj?.question?.elaboration}
                    answers={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    question={questionObj}
                    candidateName={report?.candidate?.firstName}
                    references={isCombined ? report?.references : [reference]}
                    isReport={isCombined}
                    showAnswers={true}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                />
            );
        } else if (type === 'Open') {
            return (
                <DCQuestionInput
                    showAnswers={true}
                    questionId={questionObj?.question?._id}
                    references={getReferences(questionObj?.question?._id)}
                    label={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        report?.candidate?.firstName,
                        report?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    selectedLanguage={selectedLanguage}
                    isClosed={isClosed}
                />
            );
        } else if (type === 'Prio') {
            return (
                <QuestionsAnswerPrio
                    references={isCombined ? report?.references : [reference]}
                    isReport={isCombined}
                    title={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        report?.candidate?.firstName,
                        report?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    enabled={false}
                    questionIndex={index}
                    elaboration={questionObj?.question?.elaboration}
                    max={questionObj?.question?.scale?.choices}
                    answers={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    showAnswers={true}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                />
            );
        } else if (type === 'Multiple') {
            return (
                <QuestionAnswerMultipleChoice
                    references={isCombined ? report?.references : [reference]}
                    enabled={false}
                    isReport={isCombined}
                    questionIndex={index}
                    reference={reference}
                    title={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage)?.name,
                        report?.candidate?.firstName,
                        report?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    max={questionObj?.question?.scale?.choices}
                    options={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    showAnswers={true}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                    is5PointRating={questionObj?.question?.scale?.translations?.some(t => t.name === '5 point rating')}
                />
            );
        } else if (type === 'ProsCons') {
            return (
                <QuestionAnswerProsCons
                    reference={reference}
                    references={isCombined ? report?.references : [reference]}
                    showAnswers={true}
                    enabled={false}
                    isReport={true}
                    duration={questionObj?.question?.duration}
                    selectedLanguage={selectedLanguage}
                    questionIndex={index}
                    title={replaceQuestionString(
                        getLanguageLocal(questionObj?.question, selectedLanguage).name,
                        report?.candidate?.firstName,
                        report?.jobPosition,
                        getLanguageLocal(questionObj?.question.theme, selectedLanguage)?.name,
                        isClosed
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    max={3}
                    answers={getLanguageLocal(questionObj?.question?.answer, selectedLanguage)?.answers}
                    questionId={questionObj?.question?._id}
                    isClosed={isClosed}
                />
            );
        } else {
            return null;
        }
    };

    const RenderHeader = ({ references }) => {
        return (
            <div className={classes.breakAvoid} style={{ height: 900, overflow: 'hidden', padding: 0 }}>
                <div style={{ height: 1100, textAlign: 'center', position: 'relative', overflow: 'hidden' }}>
                    <div style={{ paddingTop: 0 }}>
                        <img style={{ maxHeight: 120, maxWidth: 300 }} alt="logo" src={logo} />
                        <h1 style={{ color: '#5d5d5d', fontWeight: '500', fontSize: 30, paddingTop: 0 }}>REPORT</h1>
                    </div>
                    <div style={{ paddingTop: 20, paddingLeft: '30%', paddingRight: '30%' }}>
                        <TextField
                            fullWidth
                            value={company?.name ?? ''}
                            label="Company"
                            className={classes.textFieldBorder}
                        />
                    </div>
                    <div className={classes.marginTop}>
                        <TextField
                            className={classes.textFieldBorder}
                            fullWidth
                            value={
                                report?.creator?.firstName || report?.creator?.lastName
                                    ? `${report?.creator?.firstName} ${report?.creator?.lastName}`
                                    : ''
                            }
                            label="Case-owner"
                        />
                    </div>
                    <div className={classes.marginTop}>
                        <TextField
                            className={classes.textFieldBorder}
                            fullWidth
                            value={report?.jobPosition ?? ''}
                            label="Job-position"
                        />
                    </div>
                    <div className={classes.marginTop}>
                        <TextField
                            className={classes.textFieldBorder}
                            fullWidth
                            value={
                                !isClosed && (report?.candidate?.firstName || report?.candidate?.lastName)
                                    ? `${report?.candidate?.firstName} ${report?.candidate?.lastName}`
                                    : '-'
                            }
                            label="Candidate"
                        />
                    </div>
                    <div className={classes.marginTop}>
                        {isCombined &&
                            references?.map(ref => {
                                return (
                                    <TextField
                                        style={{ marginTop: 15 }}
                                        className={classes.textFieldBorder}
                                        fullWidth
                                        value={
                                            !isClosed && (ref?.firstName || ref?.lastName)
                                                ? `${ref?.firstName} ${ref?.lastName}`
                                                : '-'
                                        }
                                        label={ref?.type}
                                    />
                                );
                            })}
                        {!isCombined && (
                            <TextField
                                style={{ marginTop: 10 }}
                                className={classes.textFieldBorder}
                                fullWidth
                                value={
                                    !isClosed && (reference?.firstName || reference?.lastName)
                                        ? `${reference?.firstName} ${reference?.lastName}`
                                        : '-'
                                }
                                label="Reference"
                            />
                        )}
                    </div>
                    <div className={classes.marginTop}>
                        <TextField
                            className={classes.textFieldBorder}
                            fullWidth
                            value={moment().format('YYYY-DD-MM')}
                            label="Date"
                        />
                    </div>
                    <div style={{ marginTop: 20, fontSize: 12 }}>
                        <p>Case-id: {report?._id}</p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <RenderHeader references={report?.references} />
            {combinedQuestions?.map((questionObj, index) => {
                return (
                    <div style={{ paddingRight: 50, paddingLeft: 50, zoom: 0.8 }}>
                        {getComponent(questionObj?.question?.scale?.type, questionObj, index)}
                        {<div className={classes.linebreak} />}
                    </div>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles({
    linebreak: {
        height: '1px',
        borderTop: `4px dashed ${Colors.mainBlue}`,
        marginTop: '50px',
        marginBottom: '50px',
    },
    marginTop: {
        marginTop: 20,
        paddingLeft: '30%',
        paddingRight: '30%',
    },
    breakAvoid: {
        '@media print': {
            breakInside: 'avoid',
        },
    },
    textFieldBorder: {
        '& input:valid + fieldset': {
            borderStyle: 'dashed',
            borderColor: '#808080',
            borderRadius: 0,
            letterSpacing: 0,
        },

        '& input:valid:focus + fieldset': {
            borderWidth: 1,
            borderColor: 'black',
        },
    },
});

export default ReferenceReport2;
