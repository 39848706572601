import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/styles';
import { List, Collapse, Tooltip } from '@material-ui/core';
import { Colors } from '../styles/globalStyles';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { getQuestionsLanguage } from '../utils/getLanguage';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux';
import { setReferences } from '../redux/slices/createCaseSlice';
import CustomAlertDialog from './CustomAlertDialog';
import { replaceQuestionString } from '../services/caseService';
import RoleContainer from '../helpers/RoleContainer';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';

const useStyles = makeStyles({
	root: {
		padding: '0px !important',
		minHeight: '45px',
		textAlign: 'center',
		justifyContent: 'center',
		background: '#fff',
		borderTop: '1px solid #eee',
		color: '#fff',
		display: 'flex',
		cursor: 'pointer',
		'& .item-container': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				padding: '5px 10px 5px 10px', 
				background: props => props.color ? props.color : `${Colors.mainBlue} !important`,
		},
		'& .MuiListItem-root': {
				padding: '0px'
		}
	},
	dropdownItemContainer: {
		width: '100%', 
		textAlign: 'center',
		padding: '5px 50px 5px 50px',
		color: '#000'
	},
	deleteIcon: {
		position: 'absolute', 
		left: 20, 
		fontSize: 20, 
		color: Colors.mainBlue
	},
	questionContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
})

export default function DragAndDrop({ items, setItems, selectedRef, isClosed = false }) {
	const [open, setOpen] = useState();
	const [showDelete, setShowDelete] = useState(false)
	const [deleteId, setDeleteId] = useState(false)

	const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;
    const classes = useStyles({color: branding?.colors + '!important'});

	const caseState = useSelector(state => state.createCase);

	const dispatch = useDispatch()

	useEffect(() => {
		setOpen(new Array(items?.length).fill(false))
	}, [items])
	// a little function to help us with reordering the result
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const grid = 8;

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		padding: isDragging ? '4px 3px 3px 3px' : '0px',
		margin: isDragging ? `0 0 ${grid}px 0` : '0px',

		// change background colour if dragging
		background: isDragging ? "#006487" : "none",
		boxShadow: isDragging ? '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' : 'none',
		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = isDraggingOver => ({
		background: isDraggingOver ? "#B3C7CE" : "#eee",
	});

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const orderedItems = reorder(
			items,
			result.source.index,
			result.destination.index
		);

		setItems(orderedItems)
	}

	const handleOpen = (index) => {
		const arr = open.map((e, i) => {
			if(i === index) {
				return !e
			} else {
				return false
			}
		})
		setOpen(arr)
	}

    const removeQuestion = (questionId) => {
		const currentReference = caseState?.case?.references?.find(ref => ref._id === selectedRef?._id);

        const questionsCopy = [...currentReference?.questions];
        const removeIndex = questionsCopy?.map(item => item?.question?._id).indexOf(questionId);
        
        questionsCopy?.splice(removeIndex, 1);
        return {
            ...currentReference,
            questions: questionsCopy
        }
    }


	const handleDeleteQuestion = (questionId) => {
		const references = caseState?.case?.references?.map(ref => {
			if(ref?.type === selectedRef?.type) {
				return removeQuestion(questionId)
			}

			return ref;
		})

		dispatch(setReferences(references))
		const setOpenStates = open?.map(e => false);
		setOpen(setOpenStates)
	}

	const handleDeleteModal = (id) => {
		setShowDelete(!showDelete)
		setDeleteId(id)
	}

	const DeleteContainer = ({ id }) => (
		<RoleContainer user={user} roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign', 'TemplateOnly']}>
			<DeleteIcon onClick={() => handleDeleteModal(id)} className={classes.deleteIcon} />
		</RoleContainer>
	)

	return (
			<DragDropContext style={{ height: 500 }} onDragEnd={onDragEnd}>
				<CustomAlertDialog
					handleClose={() => setShowDelete(false)}
					handleCancel={() => setShowDelete(false)}
					handleAccept={() => handleDeleteQuestion(deleteId)}
					open={showDelete}
					alertTitle={"Delete"}
					alertText={"Are you sure you want to delete?"}
					okText="Delete"
				/>
				<Droppable droppableId="droppable" key='dragdropcontainerkey'>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{items?.map((item, index) => isClosed || item.id === 'Intro & Qualifying' || item.id === 'Intro & Kvalificering' || item.id === 'Outro' ? (
								<div key={item?.id}>
									<List style={{ marginTop: 10 }} className={classes.root}>
										<ListItem className={classes.root} onClick={() => handleOpen(index)}>
											<div style={{ minHeight: '100%'}} className="item-container">
												{!isClosed &&
													<Tooltip style={{ position: 'absolute', left: 10}} title={`Drag & drop`}>
														<IconButton>
															<DynamicFeedIcon style={{ color: '#fff' }} />
														</IconButton>
													</Tooltip>
												}
												<p>{item.e}</p>
												<p style={{ paddingLeft: 5 }}>{`(${item?.questions?.length})`}</p>
												{open[index] ? <KeyboardArrowDownIcon style={{ position: 'absolute', right: 20 }} /> : <KeyboardArrowUpIcon style={{ position: 'absolute', right: 20 }} />}
											</div>
										</ListItem>
									</List>
									<Collapse in={open[index]} timeout="auto" unmountOnExit>  
										{item.questions.map((question, index) => {
											const everySecond = index % 2 === 0;
											return (
												<ListItem key={question?._id} style={{ background: everySecond ? (branding.color ? branding.color : Colors.lightBlue) : '#e0e0e0'}} className={classes.root}>
													<div className={classes.dropdownItemContainer}>
														<div className={classes.questionContent}>
																<p>
																	{makeUnderlineQuestion(
																		replaceQuestionString(
																			getQuestionsLanguage(question?.question)?.name,
																			caseState?.case?.candidate?.firstName,
																			caseState?.case?.candidate?.lastName,
																			item?.e,
																			isClosed
																		)
																	)}
																</p>
																{!isClosed && <DeleteContainer id={question?.question?._id} />}
															</div>							
													</div>
												</ListItem>
											)
										})}
								
									</Collapse>
								</div>
							) : (
								<Draggable isDragDisabled={item.id === 'Intro & Qualifying' || item.id === 'Intro & Kvalificering' || item.id === 'Outro'} key={item.id} draggableId={item.id} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
										>
											<List style={{ marginTop: 10 }} className={classes.root}>
												<ListItem className={classes.root} onClick={() => handleOpen(index)}>
													<div style={{ minHeight: '100%'}} className="item-container">
														<Tooltip style={{ position: 'absolute', left: 10}} title={`Drag & drop`}>
															<IconButton>
																<DynamicFeedIcon style={{ color: '#fff' }} />
															</IconButton>
														</Tooltip>
														<p>{item.e}</p>
														<p style={{ paddingLeft: 5 }}>{`(${item?.questions?.length})`}</p>

														{open[index] ? <KeyboardArrowDownIcon style={{ position: 'absolute', right: 20 }} /> : <KeyboardArrowUpIcon style={{ position: 'absolute', right: 20 }} />}
													</div>
												</ListItem>
											</List>
											<Collapse in={open[index]} timeout="auto" unmountOnExit>  
												{item?.questions.map((question, index) => {
													const everySecond = index % 2 === 0;
													return (
														<ListItem key={question?._id} style={{ background: everySecond ? (branding.color ? branding.color : Colors.lightBlue) : '#e0e0e0'}} className={classes.root}>
															<div className={classes.dropdownItemContainer}>
																<div className={classes.questionContent}>
																	<p>
																		{question?.question?.scale?.type === 'RatingPoints'
																			? getQuestionsLanguage(question?.question?.answer)?.name?.replace(' - ', ' | ')
																			: makeUnderlineQuestion(
																				replaceQuestionString(
																					getQuestionsLanguage(question?.question)?.name,
																					caseState?.case?.candidate?.firstName,
																					caseState?.case?.candidate?.lastName,
																					item?.e
																				)
																			)
																		}
																	</p>
																	<DeleteContainer id={question?.question?._id} />
																</div>							
															</div>
														</ListItem>
													)
												})}
											</Collapse>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
	);
}
