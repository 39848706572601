import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Colors } from '../styles/globalStyles';

function DCCandidate({ candidate, errors, onChange, prefLanguage, languagesSupported, }) {

    const t = (str) => str;
    const classes = useStyles();

    return (
        <div>
            <div className={classes.marginTop} >
                <TextField 
                    autoFocus={true}
                    placeholder={t("First name")} 
                    error={errors?.firstName} 
                    value={candidate?.firstName ?? ''} 
                    onChange={(e) => onChange({ value: e.target.value, key: 'firstName' })} 
                    fullWidth
                    label={t("First name") + "*"} 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                />
            </div>    
            <div className={classes.marginTop} >
                <TextField 
                    placeholder={t("Last name")}
                    error={errors?.lastName} 
                    value={candidate?.lastName ?? ''} 
                    onChange={(e) => onChange({ value: e.target.value, key: 'lastName' })} 
                    fullWidth
                    label={t("Last name") + "*"} 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                />
            </div>    
            <div className={classes.marginTop} >
                <TextField 
                    placeholder={t("Email")} 
                    error={errors?.email} 
                    value={candidate?.email ?? ''} 
                    onChange={(e) => onChange({ value: e.target.value, key: 'email' })} 
                    fullWidth
                    label={t("Email") + "*"} 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                />
            </div>    
            <div className={classes.marginTop} >
                <TextField 
                    placeholder={t("Mobile")} 
                    value={candidate?.mobile ?? ''} 
                    onChange={(e) => onChange({ value: e.target.value, key: 'mobile' })} 
                    style={{ width: '100%' }} 
                    label={t("Mobile")} 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                />
            </div>    
            <div className={classes.marginTop} >
                <TextField 
                    placeholder={t("Phone")} 
                    value={candidate?.phone ?? ''} 
                    onChange={(e) => onChange({ value: e.target.value, key: 'phone' })} 
                    fullWidth
                    label={t("Phone")} 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                />
            </div>  
            <div className={classes.DropdownItem}>
                <div className={classes.LanguageSelectorContainer}>
                    <div className={classes.LanguageSelectorText}>
                        {t("Prefered language")}
                    </div>
                    <FormControl component="fieldset" className={classes.LanguageSelectorRadioContainer}>
                        <RadioGroup row aria-label="language" name="language" value={prefLanguage} onChange={(e) => onChange({value: e.target.value, key: 'preferedLanguage'})}>
                            {languagesSupported?.map((language) => {
                                let check = false;
                                let checkLanguage = prefLanguage === 'en' ? 'English' : 'Danish';
                                if(language === checkLanguage){
                                    check = true;
                                }
                                return (
                                    <FormControlLabel
                                        key={language}
                                        value={language === 'English' ? 'en' : 'da'}
                                        control={<Radio style={{ color: Colors.mainBlue }} checked={check} />}
                                        label={t(language)}
                                    />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
 
    )
}

const useStyles = makeStyles({
    DropdownItem: {
        marginTop: 20,
        borderBottom: '1px dashed #000',
        borderTop: '1px dashed #000',
        overflow: 'hidden',
        height: '50px',
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
    },
    LanguageSelectorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    LanguageSelectorText: {
        width: '50%'
    },
    LanguageSelectorRadioContainer: {
        width: '50%',
        '& .MuiFormGroup-root': {
            justifyContent: 'space-between',
        }
    },
    marginTop: {
        marginTop: 20,
    },
})


export default DCCandidate
