import { useMutation } from '@apollo/client';
import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DCLoader from '../../components/DCLoader';
import DCQuestionInput from '../../components/DCQuestionInput';
import LoadingBtn from '../../components/LoadingButton';
import QuestionAnswerMultipleChoice from '../../components/QuestionAnswerMultipleChoice';
import QuestionAnswerProsCons from '../../components/QuestionAnswerProsCons';
import QuestionsAnswerPrio from '../../components/QuestionsAnswerPrio';
import QuestionsAnswerSlider from '../../components/QuestionsAnswerSlider';
import { REFERENCE_ANSWER } from '../../graphql/mutations';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import { replaceQuestionString, replaceRatingPointString } from '../../services/caseService';
import { Colors } from '../../styles/globalStyles';
import { questionUnavailableTranslation } from '../../utils/constansts';
import { getLanguage, getLanguageLocal, getLanguageLocalQuestionsFilter } from '../../utils/getLanguage';

function ReferenceQuestions({ setCurrentStep, foundCase, history }) {
    const [displayProgress, setDisplayProgress] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [reference, setReference] = useState();

    const answerState = useSelector(state => state.answerQuestions);
    const selectedLanguage = useSelector(state => state.global.language);

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [referenceAnswer, { loading }] = useMutation(REFERENCE_ANSWER);

    useEffect(() => {
        setReference(foundCase?.references[0]);
    }, [foundCase]);

    useEffect(() => {
        setCurrentPage(reference?.pageNumber);
    }, [reference?.pageNumber]);

    useEffect(() => {
        setDisplayProgress(currentPage > 0 ? (currentPage / reference?.questions?.length) * 100 : 2);
    }, [currentPage]);

    const getComponent = (type, questionObj, index) => {
        if (!getLanguageLocalQuestionsFilter(questionObj?.question, { shortName: i18n.language })) {
            return (
                <div className={classes.unavailableContainer}>
                    <p className={classes.title}>
                        {getLanguageLocal(questionUnavailableTranslation, { shortName: i18n.language })?.name}
                    </p>
                </div>
            );
        } else if (type === 'RatingPoints') {
            return (
                <QuestionsAnswerSlider
                    showAnswers={true}
                    enabled={true}
                    duration={questionObj?.question?.duration}
                    questionIndex={index}
                    title={replaceRatingPointString(
                        getLanguage(questionObj?.question)?.name,
                        foundCase?.candidate?.firstName,
                        foundCase?.jobPosition,
                        getLanguage(questionObj?.question?.theme)?.name
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    answers={getLanguage(questionObj?.question?.answer)?.answers}
                    question={questionObj}
                    references={[foundCase?.references[0]]}
                    candidateName={foundCase?.candidate?.firstName}
                />
            );
        } else if (type === 'Open') {
            return (
                <DCQuestionInput
                    selectedLanguage={selectedLanguage}
                    submitAnswers={true}
                    showAnswers={true}
                    questionId={questionObj?.question?._id}
                    references={[reference]}
                    label={replaceQuestionString(
                        getLanguage(questionObj?.question)?.name,
                        foundCase?.candidate?.firstName,
                        foundCase?.jobPosition,
                        getLanguage(questionObj?.question.theme)?.name
                    )}
                />
            );
        } else if (type === 'Prio') {
            return (
                <QuestionsAnswerPrio
                    showAnswers={true}
                    reference={reference}
                    references={[reference]}
                    enabled={true}
                    duration={questionObj?.question?.duration}
                    questionIndex={index}
                    title={replaceQuestionString(
                        getLanguage(questionObj?.question)?.name,
                        foundCase?.candidate?.firstName,
                        foundCase?.jobPosition,
                        getLanguage(questionObj?.question.theme)?.name
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    max={questionObj?.question?.scale?.choices}
                    answers={getLanguage(questionObj?.question?.answer)?.answers}
                />
            );
        } else if (type === 'Multiple') {
            return (
                <QuestionAnswerMultipleChoice
                    showAnswers={true}
                    references={[reference]}
                    enabled={true}
                    duration={questionObj?.question?.duration}
                    questionIndex={index}
                    vertical={true}
                    title={replaceQuestionString(
                        getLanguage(questionObj?.question).name,
                        foundCase?.candidate?.firstName,
                        foundCase?.jobPosition,
                        getLanguage(questionObj?.question.theme)?.name
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    max={
                        questionObj?.question?.scale?.choices > 1
                            ? getLanguage(questionObj?.question?.answer)?.answers?.length - 1
                            : questionObj?.question?.scale?.choices
                    }
                    options={getLanguage(questionObj?.question?.answer)?.answers}
                    is5PointRating={questionObj?.question?.scale?.translations?.some(t => t.name === '5 point rating')}
                />
            );
        } else if (type === 'ProsCons') {
            return (
                <QuestionAnswerProsCons
                    references={[reference]}
                    showAnswers={true}
                    enabled={true}
                    duration={questionObj?.question?.duration}
                    questionIndex={index}
                    title={replaceQuestionString(
                        getLanguage(questionObj?.question).name,
                        foundCase?.candidate?.firstName,
                        foundCase?.jobPosition,
                        getLanguage(questionObj?.question.theme)?.name
                    )}
                    elaboration={questionObj?.question?.elaboration}
                    max={3}
                    answers={getLanguage(questionObj?.question?.answer)?.answers}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (window.innerWidth < 480) {
            if (document.getElementById('navigationHeader'))
                document.getElementById('navigationHeader').style.display = 'none';
            if (document.getElementById('paddingBreak'))
                document.getElementById('paddingBreak').style.paddingTop = '0px';
        }

        return () => {
            if (window.innerWidth > 480) {
                if (document.getElementById('navigationHeader'))
                    document.getElementById('navigationHeader').style.display = 'flex';
                if (document.getElementById('paddingBreak'))
                    document.getElementById('paddingBreak').style.paddingTop = '70px';
            }
        };
    }, []);

    const handleNext = () => {
        const isSubmit = isLastQuestion();
        referenceAnswer({
            variables: {
                caseId: answerState?.case?._id,
                referenceId: answerState?.case?.references[0]?._id,
                answerStatus: isSubmit ? true : false,
                questions: answerState?.case?.references[0]?.questions,
                pageNumber: currentPage + 1,
            },
        })
            .then(res => {
                if (res?.data?.referenceAnswer) {
                    if (isSubmit) {
                        history.push('/case/thanks', {
                            language: selectedLanguage?.shortName,
                        });
                    } else {
                        setCurrentPage(currentPage + 1);
                    }
                }
            })
            .catch(err => {
                dispatch(triggerFlashMessage({ type: 'error', title: t('Something went wrong, please try again') }));
            });
    };

    const handleBack = () => {
        if (currentPage !== 0) {
            referenceAnswer({
                variables: {
                    caseId: answerState?.case?._id,
                    referenceId: answerState?.case?.references[0]?._id,
                    pageNumber: currentPage - 1,
                    questions: answerState?.case?.references[0]?.questions,
                },
            })
                .then(res => {
                    setCurrentPage(currentPage - 1);
                })
                .catch(err => {
                    dispatch(
                        triggerFlashMessage({ type: 'error', title: t('Something went wrong, please try again') })
                    );
                });
        }
    };

    const isLastQuestion = () => {
        return reference?.questions?.length - 1 === currentPage;
    };

    if (loading) {
        return <DCLoader />;
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.topBarContainer}>
                    <Box className={classes.progressContainer}>
                        <LinearProgress
                            defaultValue={1}
                            className={classes.progressBar}
                            style={{ height: window.innerWidth < 480 ? 10 : 15 }}
                            barColorPrimary={Colors.mainBlue}
                            variant="determinate"
                            value={displayProgress}
                        />
                    </Box>
                </div>

                <div className={classes.questionContainer}>
                    {reference?.questions?.map((questionObj, index) => {
                        return (
                            <div>
                                {index === currentPage &&
                                    getComponent(questionObj?.question?.scale?.type, questionObj, index)}
                            </div>
                        );
                    })}
                </div>

                <div className={classes.bottomBarContainer}>
                    <LoadingBtn
                        style={currentPage === 0 ? { backgroundColor: 'grey' } : {}}
                        onClick={() => handleBack()}
                    >
                        {t('Back')}
                    </LoadingBtn>
                    <LoadingBtn onClick={handleNext}>{isLastQuestion() ? t('Submit') : t('Next')}</LoadingBtn>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        minHeight: 'calc(100vh - 106px)',
        width: '70%',
        borderLeft: '1px dashed black',
        borderRight: '1px dashed black',
        padding: '0px 70px',
        '@media (max-width: 480px)': {
            width: '100%',
            padding: '0px 15px',
        },
    },
    topBarContainer: {
        display: 'flex',
        alignItems: 'center',
        height: 100,
        '@media (max-width: 480px)': {
            height: 50,
        },
    },
    progressContainer: {
        width: '100%',
    },
    title: {
        textAlign: 'center',
        paddingBottom: '50px',
        fontWeight: '500',
        fontSize: '18px',
        '@media (max-width: 480px)': {
            textAlign: 'left',
            fontSize: 15,
            paddingBottom: '20px',
        },
    },
    bottomBarContainer: {
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        paddingTop: 30,
        paddingBottom: 30,
    },
    unavailableContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progressBar: {
        '& .MuiLinearProgress-root': {
            height: '100px',
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: `${Colors.mainBlue} !important`,
        },
    },
});

export default ReferenceQuestions;
