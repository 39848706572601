import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

export default function DCLoader() {
    return (
        <Backdrop style={{ backgroundColor: 'transparent'}} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}
