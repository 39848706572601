import { React, useEffect } from 'react';

export default function Support() {

    useEffect(() => {
        window.open('mailto:support@dooblecheck.com')
    }, [])

    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-start', alignItems: 'center', marginTop: 100, height: 'calc(100vh - 106px)', flexDirection: 'column' }}>
            <h1 style={{padding: 10, paddingBottom: 30}}>
                Support
            </h1>
            <p style={{padding: 10, textAlign: 'left'}} >
                If mail did not open click the email below:
            </p>
            <p style={{padding: 10, cursor: 'pointer', textAlign: 'left'}} onClick={() => window.open('mailto:support@dooblecheck.com')}>
                support@dooblecheck.com
            </p>
        </div>
    )
};
