import React, { useState, useEffect } from 'react'
import { Colors } from '../styles/globalStyles'
import { Modal, Box, TextField, Tooltip, IconButton, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import CustomMenuList from './CustomMenuList';
import { useMutation, useQuery } from '@apollo/client'
import { GET_SCALES, GET_ANSWERS, ADD_QUESTION, ADD_ANSWER } from '../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { validateNewAnswerTitle, validateNewQuestion, validateQuestionAnswerOther, validateQuestionAnswerTitle } from '../utils/validators';
import {getLanguage, getLanguageLocal } from '../utils/getLanguage';
import LoadingBtn from './LoadingButton';
import Close from '@material-ui/icons/Close';

function AddQuestion({ showModal = false, setShowModal, themes, refetch, selectedT, selectedC, selectedLanguage }) {
    const company = useSelector(state => state.auth.user.company)

    const [selectedTheme, setSelectedTheme] = useState()
    const [selectedScale, setSelectedScale] = useState()
    const [selectedAnswers, setSelectedAnswers] = useState();
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAnswerConfirmModal, setShowAnswerConfirmModal] = useState(false);
    const [languages, setLanguages] = useState();
    
    
    const [titles, setTitles] = useState()
    const [scales, setScales] = useState();
    const [answers, setAnswers] = useState();
    
    const [errors, setErrors] = useState({});
    const [errorsAnswers, setErrorsAnswers] = useState({});
    
    const [newAnswers, setNewAnswers] = useState({});
    const [newAnswersCopy, setNewAnswersCopy] = useState({});
    const [isCopyAnswers, setIsCopyAnswers] = useState(false);
    const [createAnswersOpen, setCreateAnswersOpen] = useState(false);
    const [isMultipleAnswers, setIsMultipleAnswers] = useState();
    const [scaleSubmit, setScaleSubmit] = useState();
    const [loadingAnswers, setLoadingAnswers] = useState(false);
    const [selectedAnswersText, setSelectedAnswersText] = useState('');

    const t = (str) => str; 

    const { data: scaleData } = useQuery(GET_SCALES, { variables: { companyId: company._id } })
    const { data: answersData, refetch: refetchAnswer } = useQuery(GET_ANSWERS, { variables: { companyId: company._id, type: getLanguageLocal(selectedScale, { shortName: 'en' })?.name }, fetchPolicy: 'cache-and-network' })

    const [addQuestion] = useMutation(ADD_QUESTION);
    const [addAnswer] = useMutation(ADD_ANSWER);

    const dispatch = useDispatch()

    const classes = useStyles();

    useEffect(() => {
        themes && selectedT?.title !== 'All' && setSelectedTheme(selectedT)
    }, [themes, selectedT])

    useEffect(() => {
        if(newAnswers?.type === 'Multiple choice'){
            const foundScale = scales?.find(scale => scale?.type === 'Multiple' && scale?.maxAnswers === 11 && ((isMultipleAnswers && scale?.choices) === 10 || (!isMultipleAnswers && scale?.choices ===  1)))
            if(foundScale){
                let tempScale = _.cloneDeep(foundScale)
                tempScale.title = 'Multiple choice';
                setScaleSubmit(tempScale)
            }
            else setScaleSubmit(selectedScale)
        } else {
            setScaleSubmit(selectedScale)
        }
    }, [scales, newAnswers?.type, isMultipleAnswers, selectedScale])

    useEffect(() => {
        setTitles(languages?.map(e => ({ language: e?.shortName, name: e.shortName === 'en' ? ' [Candidate] ' : ' [Kandidat] ' })))
    }, [showModal, languages])

    useEffect(() => {
        setScales(scaleData?.scales?.filter(s => s?.type !== 'RatingPoints'))
    }, [scaleData])

    useEffect(() => {
        setAnswers(answersData?.answers)
    }, [answersData, selectedScale])

    const getScaleNames = () => {
        const unique = [...new Set(scales?.map(scale => getLanguage(scale)?.name))];

        return unique?.map(scale => {
            return {
                ...scales?.find(x => getLanguage(x)?.name === scale),
                title: scale
            }
        });
    }

    const getAnswerNames = () => {
        return answers?.map((answer) => ({
            ...answer,
            title: getLanguageLocal(answer, selectedLanguage)?.name,
            answers: getLanguageLocal(answer, selectedLanguage)?.answers
        }))
    }
    const submit = () => {
        setLoading(true);
        const answersId = selectedAnswers?._id;
        const scaleId = selectedScale?._id;
        
        // validate
        const errors = validateNewQuestion(titles, scaleId, answersId, selectedScale?.type, selectedTheme);
        if(!_.isEmpty(errors)) {
            setLoading(false);
            setErrors(errors);

            const titles = [errors['0'], errors['1']];

            if(titles.some(item => item === false || item === undefined) && !errors.theme && !errors.scale && !errors.answers) {
                setShowConfirmModal(true)
            }
            return;
        }

        handleSubmit();
    }

    const getDuration = (scale) => {
        if(scale === 'Open Answer') return 20;
        if(scale === 'XofY priority') return 40;
        if(scale === '5 point rating') return 10;
        if(scale === 'Multiple choice') return 15;
        if(scale === 'Pros&Cons') return 40;
        if(scale === 'Yes/No') return 10;
        return 45;
    }

    const handleSubmit = () => {
        const answersId = selectedAnswers?._id;
        const scaleId = selectedScale?._id;
        const themeId = selectedTheme?._id;
        let submitTitles = _.cloneDeep(titles)
        submitTitles = submitTitles?.map(t => {
            if(selectedAnswers && !selectedAnswers?.translations?.find(a => a?.language === t?.language)) {
                return null;
            } else if(t?.name.replace('[Candidate]', '').replace('[Kandidat]', '').replace('[Theme]', '').replace('[Tema]', '').replace('(multiple answers possible)', '').replace('(flere svar muligt)', '').trim() === '' || t?.name === undefined) {
                return null;
            }
            return {...t}
        })
        const variables = {
            theme: themeId,
            company: company._id,
            scale: scaleId,
            duration: getDuration(selectedScale?.translations?.find(trans => trans?.shortName === 'en')?.name),
            answer: answersId,
            translations: submitTitles,
            type: selectedScale?.type
        }

        addQuestion({ variables: variables }).then(res => {
            setTitles([])
            setSelectedScale()
            setSelectedAnswers()
            setShowModal(false)
            setShowConfirmModal(false)
            setErrors('')
            setIsMultipleAnswers(undefined)
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully added question") }))
            refetch()
        }).catch(err => {
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        });
    }

    const handleSelectScale = (e) => {
        setSelectedScale(e)
        setIsMultipleAnswers(undefined)
        setSelectedAnswers(undefined)
    }

    const handleTitles = (e, index) => {
        const formatted = titles?.map((title, i) => {
            if(index === i) {
                return {
                    ...title,
                    name: e
                }
            } 
                
            return title;
        })
        setTitles(formatted)
    }

    const getMaxAnswers = () => {
        let maxAnswers;
        // MAKE LIKE THE BACKEND???? ?!?!?!?!?!???!?!?!!??!!?!?!?!??!?!?!?!?!???!?!?!!??!!?!?!?!??!?!?!?!?!???!?!?!!??!!?!?!?!??!?!?!?!?!???!?!?!!??!!?!?!?!?
        if(selectedScale?.title === 'Multiple choice'){
            maxAnswers = 11
        } else if (selectedScale?.title === '5 point rating') {
            maxAnswers = 6
        } else if (selectedScale?.title === 'Yes/No') {
            maxAnswers = 3
        } else if (selectedScale?.title === 'XofY priority') {
            maxAnswers = 11
        } else if (selectedScale?.title === 'Pros&Cons') {
            maxAnswers = 12
        } else {
            maxAnswers = 11
        }
        return maxAnswers
    }

    const getMinAnswers = () => {
        let minAnswers;
        if(selectedScale?.title === 'Multiple choice'){
            minAnswers = 4
        } else if (selectedScale?.title === '5 point rating') {
            minAnswers = 6
        } else if (selectedScale?.title === 'Yes/No') {
            minAnswers = 3
        } else if (selectedScale?.title === 'XofY priority') {
            minAnswers = 8
        } else if (selectedScale?.title === 'Pros&Cons') {
            minAnswers = 8
        } else {
            minAnswers = 4
        }
        return minAnswers
    }

    const getMinAnswersError = () => {
        let minAnswers;
        if(selectedScale?.title === 'Multiple choice'){
            minAnswers = 2
        } else if (selectedScale?.title === '5 point rating') {
            minAnswers = 5
        } else if (selectedScale?.title === 'Yes/No') {
            minAnswers = 3
        } else if (selectedScale?.title === 'XofY priority') {
            minAnswers = 6
        } else if (selectedScale?.title === 'Pros&Cons') {
            minAnswers = 6
        } else {
            minAnswers = 4
        }
        return minAnswers
    }

    const getAnswerTitle = () => {
        let title = '';
        if(isCopyAnswers) {
            if(selectedScale?.title === 'Multiple choice'){
                title = 'Modify statements/answers in order to fit the new question. Submit a minimum of 2 and a maximum of 9 statements/answers and select the number of answers possible.'
            } else if (selectedScale?.title === '5 point rating') {
                title = 'Modify answers in order to fit the new question.'
            } else if (selectedScale?.title === 'Yes/No') {
                title = 'Copy yes/no'
            } else if (selectedScale?.title === 'XofY priority') {
                title = 'Modify statements/answers in order to fit the new question. Submit a minimum of 6 and a maximum of 9 statements/answers.'
            } else if (selectedScale?.title === 'Pros&Cons') {
                title = 'Modify statements/answers in order to fit the new question. Submit a minimum of 6 and a maximum of 10 statements/answers.'
            } else {
                title = 'Copy '
            }
        } else {
            if(selectedScale?.title === 'Multiple choice'){
                title = 'Create the numbers of new statements/answers wanted (minimum of 2) and select the number of answers possible.'
            } else if (selectedScale?.title === '5 point rating') {
                title = 'Create new answers in order to fit the new question.'
            } else if (selectedScale?.title === 'Yes/No') {
                title = 'Create yes/no'
            } else if (selectedScale?.title === 'XofY priority') {
                title = 'Create a minimum of 6 and a maximum of 9 new statements/answers.'
            } else if (selectedScale?.title === 'Pros&Cons') {
                title = 'Create a minimum of 6 and a maximum of 10 new statements/answers.'
            } else {
                title = 'Create '
            }
        }
        return title;
    }

    const submitAnswers = () => {
        setLoadingAnswers(true)
        let tempNewAnswers = _.cloneDeep(newAnswers);
        let missingLanguage = false;
        let error = {
            translations: tempNewAnswers?.translations?.map(t => {
                let filledAnswers = t?.answers?.filter(a => !_.isNil(a?.title) && !_.isEmpty(a?.title?.trim()))?.length;
                let nonStandardAnswers = t?.answers?.filter(a => validateNewAnswerTitle(a?.title) && !_.isNil(a?.title) && !_.isEmpty(a?.title?.trim()));
                if(nonStandardAnswers?.length === 0) {
                    missingLanguage = true;
                }
                return {
                    lengthError: filledAnswers - getMinAnswers() < 0,
                    answers: t?.answers?.map(a => {
                        if((_.isNil(a?.title) || _.isEmpty(a?.title?.trim())) && (filledAnswers - getMinAnswers() < 0)) {
                            filledAnswers = filledAnswers + 1
                            return true
                        }
                        return false;
                    }),
                };
            }),
            missingSelected: selectedScale?.title === 'Multiple choice' && isMultipleAnswers === undefined,
        };
        setErrorsAnswers(error);
        if(error.missingSelected) {
            setLoadingAnswers(false)
            dispatch(triggerFlashMessage({ type: 'error', title: `Please select the number of answers possible` })) //!!!!!!!!!!
        } else if(missingLanguage) {
            setLoadingAnswers(false)
            setShowAnswerConfirmModal(true)
        } else if(!error?.translations?.find(t => t?.lengthError)) {
            handleSubmitNewAnswers()
        } else {
            setLoadingAnswers(false)
            dispatch(triggerFlashMessage({ type: 'error', title: `Put a minimum of ${getMinAnswersError()} answers or leave all inputs for the unwanted language empty` })) //!!!!!!!!!!
        }
    }

    const handleSubmitNewAnswers = () => {
        let tempNewAnswers = _.cloneDeep(newAnswers);
        if(isCopyAnswers && _.isEqual(tempNewAnswers, newAnswersCopy)){
            setLoadingAnswers(false)
            setSelectedScale(scaleSubmit)
            tempNewAnswers = {
                ...tempNewAnswers,
                translations: tempNewAnswers?.translations?.map(t => {
                    let filledAnswers = t?.answers?.filter(a => !_.isNil(a?.title) && !_.isEmpty(a?.title?.trim()));
                    if(filledAnswers?.length - getMinAnswers() < 0) {
                        return null;
                    }
                    return {
                        ...t,
                        answers: filledAnswers
                    };
                })
            }
            setSelectedAnswers(tempNewAnswers)
            setShowAnswerConfirmModal(false)
            setCreateAnswersOpen(false)
            setErrorsAnswers({});
            setSelectedAnswersText('Existing answers selected')
            return ;
        }
        let translations = tempNewAnswers?.translations?.map(t => {
            let filledAnswers = t?.answers?.filter(a => !_.isNil(a?.title) && !_.isEmpty(a?.title?.trim()));
            if(filledAnswers?.length - getMinAnswers() < 0) {
                return null;
            }
            return {
                ...t,
                answers: filledAnswers
            };
        })

        let variables = {
            company: company?._id,
            type: tempNewAnswers?.type,
            translations: translations,
        };
        addAnswer({ variables: variables }).then(res => {
            setLoadingAnswers(false);
            setSelectedScale(scaleSubmit)
            setSelectedAnswers(res?.data?.addAnswer)
            setErrorsAnswers({});
            setCreateAnswersOpen(false)
            setIsCopyAnswers(false)
            refetchAnswer()
            setShowAnswerConfirmModal(false)
            setSelectedAnswersText('New answers selected')
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully added answers") }))
        }).catch(err => {
            setLoadingAnswers(false);
            setShowAnswerConfirmModal(false)
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        });
    }

    const handleCloseNewAnswers = () => {
        setErrorsAnswers({});
        setSelectedAnswers(undefined);
        setCreateAnswersOpen(false);
        setIsMultipleAnswers(undefined)
        setSelectedAnswersText('')
    }

    const composeNewAnswers = () => {
        let maxAnswers = getMaxAnswers();
        let answersEnglish = [];
        let answersDanish = []
    
        if (selectedScale?.title !== 'Yes/No' && selectedScale?.title !== '5 point rating') {
            answersEnglish = new Array(maxAnswers - 2).fill({title: ''})?.concat({ title: "Don't know" })?.concat({ title: "Other" })
            answersDanish = new Array(maxAnswers - 2).fill({title: ''})?.concat({ title: "Ved ikke" })?.concat({ title: "Andet" })
        } else {
            answersEnglish = new Array(maxAnswers - 1).fill({title: ''})?.concat({ title: "Don't know" })
            answersDanish = new Array(maxAnswers - 1).fill({title: ''})?.concat({ title: "Ved ikke" })
        }
        let compAnswers = {
            answers: answersEnglish,
            translations: languages?.map(lan => ({
                answers: lan.shortName === 'en' ? answersEnglish : answersDanish,
                language: lan?.shortName,
                name: '',
            })),
            type: selectedScale?.title,
        }
        return compAnswers
    }

    const composeCopyAnswers = (copyAnswers) => {
        let translations = languages?.map(language => {
            let translation = {};
            let foundTrans = copyAnswers?.translations?.find(trans => trans?.language === language?.shortName)
            if(foundTrans) {
                let first = foundTrans?.answers?.filter(a => validateNewAnswerTitle(a.title));
                    let last = foundTrans?.answers?.filter(a => !validateNewAnswerTitle(a.title));
                    if(last.length < 2){
                        if(!last.find(a => !validateQuestionAnswerTitle(a.title))){
                            last.push({ title: foundTrans?.language === 'en' ? "Don't know" : "Ved ikke" })
                        }
                        if(!last.find(a => validateQuestionAnswerOther(a.title)) && copyAnswers?.type !== 'Yes/No' && copyAnswers?.type !== '5 point rating'){
                            last.push({ title: foundTrans?.language === 'en' ? "Other" : "Andet" })
                        }
                    }
                    let missingNumber = getMaxAnswers() - first?.length - last?.length;
                    let missingAnswers = new Array(missingNumber).fill({title: ''});
        
                    translation = {
                        answers: [].concat(first).concat(missingAnswers).concat(last),
                        language: foundTrans.language,
                        name: '',
                    };
            } else {
                let maxAnswers = getMaxAnswers();
                let answersEnglish = [];
                let answersDanish = []
    
                if (selectedScale?.title !== 'Yes/No' && selectedScale?.title !== '5 point rating') {
                    answersEnglish = new Array(maxAnswers - 2).fill({title: ''})?.concat({ title: "Don't know" })?.concat({ title: "Other" })
                    answersDanish = new Array(maxAnswers - 2).fill({title: ''})?.concat({ title: "Ved ikke" })?.concat({ title: "Andet" })
                } else {
                    answersEnglish = new Array(maxAnswers - 1).fill({title: ''})?.concat({ title: "Don't know" })
                    answersDanish = new Array(maxAnswers - 1).fill({title: ''})?.concat({ title: "Ved ikke" })
                }
                translation = {
                    answers: language?.shortName === 'en' ? answersEnglish : answersDanish,
                    language: language.shortName,
                    name: '',
                };
            }
            return translation;
        });
        
        const tempCopyAnswers = {
            ...copyAnswers,
            title: '',
            answers: translations.find(t => t.language === 'en' ).answers,
            translations: translations,
        }
        return tempCopyAnswers
    }

    const handleSelectAnswers = (selectAnswers, isCopy=false) => {
        if(selectedScale?.title === 'Yes/No') return setSelectedAnswers(selectAnswers)
        setIsMultipleAnswers(undefined)
        setCreateAnswersOpen(true);
        const tempAnswers = isCopy ? composeCopyAnswers(selectAnswers) : composeNewAnswers()
        setNewAnswers(tempAnswers);
        setNewAnswersCopy(tempAnswers);
        setIsCopyAnswers(isCopy);
    }

    useEffect(() => {
        let arr = company?.settings?.languages;        
        const first = arr?.filter(obj => obj?.shortName === selectedLanguage?.shortName);
        const last = arr?.filter(obj => obj?.shortName !== selectedLanguage?.shortName);
        setLanguages([].concat(first).concat(last))
    }, [selectedLanguage, company])
     
    const handleClose = () => {
        setErrors('')
        setShowModal(false)
        setSelectedAnswers(undefined)
        setSelectedScale(undefined)
    }

    const handleChangeAnswer = (lanIndex, value, index) => {
        let tempAnswers = _.cloneDeep(newAnswers)
        tempAnswers.translations[lanIndex].answers[index] = {title: value}
        setNewAnswers(tempAnswers)
    }

    useEffect(() => {
        let newTitles = [];
        if(!isMultipleAnswers) {
            newTitles = titles?.map(e => ({
                language: e?.language, name: e?.language === 'en' 
                    ? e?.name?.replace(' (multiple answers possible)', '')?.replace('(multiple answers possible)', '')
                    : e?.name?.replace(' (flere svar muligt)', '')?.replace('(flere svar muligt)', '')
            }))
        } else {
            newTitles = titles?.map(e => ({
                language: e?.language, name: e?.language === 'en' 
                ? `${e?.name?.replace(' (multiple answers possible)', '')?.replace('(multiple answers possible)', '')} (multiple answers possible)`
                : `${e?.name?.replace(' (flere svar muligt)', '')?.replace('(flere svar muligt)', '')} (flere svar muligt)`
            }))
        }
        setTitles(newTitles);
    }, [isMultipleAnswers, selectedScale]);

    useEffect(() => {
        let submitTitles = _.cloneDeep(titles)
        if(selectedAnswers !== undefined) {
            submitTitles = languages?.map(l => {
                const tempSelectedAnswers = selectedAnswers?.translations?.find(a => (a?.language === l?.shortName))
                const tempTitles = submitTitles?.find(t => t?.language === l?.shortName)
                if(!tempSelectedAnswers) {
                    return null;
                } else if(tempSelectedAnswers && !tempTitles) {
                    return {
                        language: l?.shortName,
                        name: l.shortName === 'en' ? ` [Candidate] ${isMultipleAnswers ? ' (multiple answers possible)' : ''}` : ` [Kandidat] ${isMultipleAnswers ? ' (flere svar muligt)' : ''}`
                    }
                }
                return tempTitles
            })
        } else {
            submitTitles = languages?.map(e => {
                const tempTitles = submitTitles?.find(t => t?.language === e?.shortName)
                if(!tempTitles){
                    return {
                        language: e?.shortName,
                        name: e?.shortName === 'en' ? ` [Candidate] ${isMultipleAnswers ? ' (multiple answers possible)' : ''}` : ` [Kandidat] ${isMultipleAnswers ? ' (flere svar muligt)' : ''}`
                    }
                } else {
                    return {...tempTitles}
                }
            })
        } 
        setTitles(submitTitles)
    }, [selectedAnswers, createAnswersOpen])

    return (
        <Modal
            open={showModal}
        >
            <Box className={createAnswersOpen ? classes.answersContainer : classes.container}>
                <Modal open={showConfirmModal}>
                    <Box style={{ height: '20%', width: '20%', padding: 30, display: 'flex', flexDirection: 'column' }} className={classes.container}>
                        <Close style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => setShowConfirmModal(false)} />
                        <div style={{ flex: 1}}>
                            <p>
                                In order for a Reference to submit their reference in their preferred language – please enter the New Question and Rating Points in all available languages. 
                            </p>
                            <p style={{ marginTop: 10 }}>If the preferred language is missing the Question or Rating Points will be left out.</p>
                        </div>
                    
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'flex-end', width: '100%'}}>
                            <LoadingBtn onClick={() => setShowConfirmModal(false)}>
                                <p>Back</p>
                            </LoadingBtn>
                            <LoadingBtn onClick={() => handleSubmit()}>
                                <p>Save <span style={{ display: 'block', fontSize: 8 }}>without more languages</span></p>
                            </LoadingBtn>
                        </div>
                    </Box>
                </Modal>
                <Modal open={showAnswerConfirmModal}>
                    <Box style={{ height: '20%', minWidth: '20%', padding: 30, display: 'flex', flexDirection: 'column' }} className={classes.container}>
                        <Close style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => setShowAnswerConfirmModal(false)} />
                        <div style={{ flex: 1}}>
                            <p>
                                In order for a Reference to submit their reference in their preferred language – please enter the Answers in all available languages. 
                            </p>
                            <p style={{ marginTop: 10 }}>If the preferred language is missing the Question will be left out.</p>
                        </div>
                    
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'flex-end', width: '100%'}}>
                            <LoadingBtn onClick={() => setShowAnswerConfirmModal(false)}>
                                <p>Back</p>
                            </LoadingBtn>
                            <LoadingBtn onClick={() => handleSubmitNewAnswers()}>
                                <p>{(!_.isEqual(newAnswers, newAnswersCopy) || !isCopyAnswers) ? 'Save' : 'Select'} <span style={{ display: 'block', fontSize: 8 }}>without more languages</span></p>
                            </LoadingBtn>
                        </div>
                    </Box>
                </Modal>
                {!createAnswersOpen &&
                    <div>
                        <div className={classes.headerContainer}>
                            <div className={classes.stepOneContainer}>
                                <p style={{ fontWeight: '500', fontSize: 14 }}>STEP</p>
                            </div>
                            <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 20 }}>
                                <Tooltip  title="Close">
                                    <IconButton onClick={handleClose}>
                                        <Close style={{ color: '#000' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 20 }}>1</p>
                                    </div>
                                    <div style={{ width: '90%' }}>
                                        <CustomMenuList 
                                            error={errors.theme} 
                                            withKey 
                                            onSelect={(e) => setSelectedTheme(e)} 
                                            title={t(selectedTheme?.title)} 
                                            label={t("Select Theme")} 
                                            elements={themes}
                                            selected={selectedTheme?.title}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, backgroundColor: Colors.mainBlue }}></div>

                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, marginTop: 20 }}>
                                    <div style={{ width: '10%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 20 }}>2</p>
                                    </div>
                                    <div style={{ width: '90%', marginRight: '20px' }}>

                                        <p style={{ marginBottom: 20, fontWeight: 500 }}>{t("Enter question title")}</p>
                                        {languages?.map((language, index) => {
                                            if(selectedAnswers && !selectedAnswers?.translations?.find(a => a?.language === language?.shortName)) {
                                                return null;
                                            }
                                            return (
                                                <TextField 
                                                    error={errors[`${index}`]} 
                                                    name={language?.fullName}
                                                    key={language?.shortName}
                                                    multiline
                                                    label={t(`New question in ${language?.fullName?.toLowerCase() === 'english' ? 'English' : 'Danish'}`)} 
                                                    variant="outlined"
                                                    onChange={(e) => handleTitles(e.target.value, index)} 
                                                    style={{ width: '100%', marginBottom: 20 }}
                                                    value={titles && titles[index]?.name ? titles[index]?.name : ''}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, backgroundColor: Colors.mainBlue }}></div>

                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 20 }}>3</p>
                                    </div>
                                    <div style={{ width: '90%' }}>
                                        <CustomMenuList 
                                            error={errors.scale} 
                                            withKey 
                                            onSelect={(e) => handleSelectScale(e)} 
                                            title={selectedScale?.title} 
                                            label={t("Select Answer Scale*")} 
                                            elements={getScaleNames()}
                                            selected={selectedScale?.title}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: 1, backgroundColor: Colors.mainBlue }}></div>

                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 20 }}>4</p>
                                    </div>
                                    <div style={{ width: '90%' }}>
                                        <CustomMenuList
                                            selectedScale={selectedScale}
                                            fullWidth={true}
                                            error={errors.answers}
                                            disabled={selectedScale === undefined}
                                            withKey
                                            subMenu
                                            onSelect={(e) => handleSelectAnswers(e, true)}
                                            title={selectedAnswers ? selectedAnswersText : ''}
                                            label={t("Select Answers*")}
                                            elements={getAnswerNames()}
                                            handleSelectAnswers={handleSelectAnswers}
                                            selectedTheme={selectedTheme}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 2, height: 1, backgroundColor: Colors.mainBlue }} />
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                            <LoadingBtn onClick={submit} loading={loading}>
                                {t("Save")}
                            </LoadingBtn>
                        </div>
                    </div>
                }

                {createAnswersOpen &&
                    <div style={{position: 'relative', padding: 20, overflowY: 'scroll'}}>
                        <div style={{position: 'absolute', right: 5, top: 5}}>
                            <Tooltip  title="Close">
                                <IconButton onClick={() => handleCloseNewAnswers(false)}>
                                    <Close style={{ color: '#000' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{position: 'absolute', top: 20, left: '25%', width: '50%'}}>
                            <div style={{flex: 1}}>
                                <p style={{textAlign: 'center', fontWeight: 600}}>
                                    {getAnswerTitle()}
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '40px', }}>
                            {languages?.map((language, index) => {
                                return (
                                    <div key={language?.shortName} style={{flex: 1, marginLeft: index === 0 && 30, paddingLeft: index === 1 && 30, marginRight: index === 1 && 30, }}>
                                        <div style={{display: 'flex', justifyContent: index === 0 ? 'flex-start' : 'flex-end', alignItems: 'center', padding: '0px 20px',  paddingBottom: 20,}}>
                                            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: Colors.mainBlue, }}>
                                                <p style={{color: 'white', margin: '5px 20px',}}>
                                                    {language?.fullName}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{borderRight: index === 0 && '3px solid grey', paddingRight: index === 0 && 40}}>

                                            {getLanguageLocal(newAnswers, language?.shortName)?.answers?.map((newAnswer, idx) => {
                                                return (
                                                    <div className={classes.answerContainer}>
                                                        <div style={{width: '50px'}}>
                                                            <p>
                                                                {`${idx + 1}.`}
                                                            </p>
                                                        </div>
                                                        <div style={{flex: 1}}>
                                                            <TextField 
                                                                fullWidth 
                                                                autoComplete="new-password" 
                                                                variant="outlined" 
                                                                size='small'
                                                                className={classes.newAnswerInput}
                                                                error={errorsAnswers?.translations && errorsAnswers?.translations[index]?.answers[idx]}
                                                                disabled={!validateNewAnswerTitle(newAnswer?.title)}
                                                                onChange={(e) => handleChangeAnswer(index, e.target.value, idx)}
                                                                InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                                                                value={newAnswer?.title ?? ''}
                                                                />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20}}>
                            {selectedScale?.title === 'Multiple choice' && 
                                <FormControl component="fieldset" className={classes.answerSelectorRadioContainer}>
                                    <RadioGroup row aria-label="language" name="language" value='onlyone' >
                                        <FormControlLabel value={true} onClick={(e) => setIsMultipleAnswers(false)} control={<Radio style={{ color: isMultipleAnswers === undefined && errorsAnswers?.missingSelected ? 'red' : Colors.mainBlue }} checked={isMultipleAnswers === false} />} label='Only one answer' />
                                        <FormControlLabel value={true} onClick={(e) => setIsMultipleAnswers(true)} control={<Radio style={{ color: isMultipleAnswers === undefined && errorsAnswers?.missingSelected ? 'red' : Colors.mainBlue }} checked={isMultipleAnswers} />} label='Multiple answers' />
                                    </RadioGroup>
                                </FormControl>
                            }
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                <LoadingBtn onClick={() => submitAnswers()} loading={loadingAnswers} disabled={!isCopyAnswers && _.isEqual(newAnswers, newAnswersCopy)} style={{backgroundColor: !isCopyAnswers && _.isEqual(newAnswers, newAnswersCopy) ? 'grey' : Colors.mainBlue}}>
                                    <p style={{color: 'white'}}>{(!_.isEqual(newAnswers, newAnswersCopy) || !isCopyAnswers) ? 'Submit' : 'Select'}</p>
                                </LoadingBtn>
                            </div>
                        </div>
                    </div>
                }
            </Box>
        </Modal>
    )
}

export default AddQuestion

const useStyles = makeStyles({
    container: {
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        minHeight: 200, 
        backgroundColor: '#fff', 
        transform: 'translate(-50%, -50%)',
        '@media (min-width: 1025px)': {
            width: '50%',
        },
        '@media (max-width: 1024px) and (min-width: 481px)': {
            width: '80%',
        },
        '@media (max-width: 480px)': {
            width: '100%',
        },
    },
    answersContainer: {
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        minHeight: 200,
        maxHeight: '90%',
        backgroundColor: '#fff', 
        transform: 'translate(-50%, -50%)',
        overflowY: 'scroll',
        '@media (min-width: 1025px)': {
            width: '80%',
        },
        '@media (max-width: 1024px) and (min-width: 481px)': {
            width: '80%',
        },
        '@media (max-width: 480px)': {
            width: '100%',
        },
    },
    headerContainer: {
        display: 'flex', 
        flexDirection: 'row',
        minHeight: 60,
        borderBottom: `1px solid ${Colors.mainBlue}`
    },
    stepOneContainer: {
        width: '10%', 
        backgroundColor: Colors.mainBlue, 
        display: 'flex', 
        flexDirection: 'row', 
        color: '#fff', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    answerContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 20px',
        '&:last-child': {
            paddingBottom: '0px'
        }
    },
    answerSelectorRadioContainer: {
        flex: 1,
        '& .MuiFormGroup-root': {
            justifyContent: 'space-evenly',
        }
    },
    newAnswerInput: {
        '& .MuiInputBase-input': {
            padding: '4px 14px',
        }
    },
});