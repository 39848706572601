import React, { useState, useEffect } from 'react'
import { Dialog, Divider, IconButton, Tooltip, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Backdrop, CircularProgress, } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Colors } from '../styles/globalStyles';
import { makeStyles } from '@material-ui/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { checkReferenceHasAnswer, checkAllReferencesHasAnswer } from '../services/caseService';
import axios from 'axios';
import QuestionsPreview from './QuestionsPreview';
import LanguageChange from './LanguageChange';
import { useSelector } from 'react-redux';

export default function DownloadDialog({ references, loading = false, caseInfo, selectedLanguage, setSelectedLanguage, isClosed = false }) {
    const [open, setOpen] = useState(false);
    const [combinedAvailable, setCombinedAvailable] = useState();
    const [showPreview, setShowPreview] = useState();
    const [previewRef, setPreviewRef] = useState();
    const [reportLoading, setReportLoading] = useState(false)
    const [isCombined, setIsCombined] = useState(false);

    const { user } = useSelector(state => state.auth)

    const classes = useStyles()
    const t = (str) => str;

    useEffect(() => {
        setCombinedAvailable(checkAllReferencesHasAnswer(references))
    }, [references])

    const handleReportPreview = (reference) => {
        setPreviewRef(reference)
        setIsCombined(false)
        setTimeout(() => {
            setShowPreview(true)
        }, 100)
    }

    const handleCombinedReportPreview = () => {
        setPreviewRef(references[0])
        setIsCombined(true)
        setTimeout(() => {
            setShowPreview(true)
        }, 100)
    }

    const handleReportDownload = async (refId) => {
        setReportLoading(true)
        const { data } = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1/report`, {
            method: 'POST',
            data: {
                caseId: caseInfo?._id,
                referenceId: refId,
                baseUrl: `${window.location.protocol}/${window.location.host}`,
                language: selectedLanguage?.shortName || "en"
            },
            responseType: 'blob' //Force to receive data in a Blob Format
        })

        // Create a Blob from the PDF Stream
        const file = new Blob([data], { type: 'application/pdf' });

        // //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        setReportLoading(false)
        window.open(fileURL)
    }

    return (
        <div>
            <div className={classes.tooltipContainer}>
                <QuestionsPreview
                    isReport={isCombined} 
                    showAnswers={true} 
                    reference={previewRef} 
                    caseInfo={caseInfo} 
                    open={showPreview} 
                    handleClose={setShowPreview}
                    combined={isCombined}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    isClosed={isClosed}
                />
                <Backdrop style={{ zIndex: 1000 }} open={reportLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <p className={classes.tooltipTitle}>{t("Reports")}</p>
                <Tooltip style={{ background: Colors.mainBlue, marginTop: 10 }} onClick={() => setOpen(true)} title={t("Download as PDF")}>
                    <IconButton>
                        <GetAppIcon fontSize="small" style={{ color: '#fff' }} />
                    </IconButton>
                </Tooltip>
            </div>
            <Dialog style={{ minWidth: 350, zIndex: 900 }} onClose={() => setOpen(false)} open={open}>
                <DialogTitle>
                    <p>{t("Reports")}</p>
                    <div style={{ paddingTop: 20 }}>
                        <LanguageChange
                            useLanguage={true}
                            handleLanguage={setSelectedLanguage}
                            language={selectedLanguage}
                            company={user?.company} 
                        />
                    </div>
                </DialogTitle>
                <CloseIcon style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => setOpen(false)} />

                <List style={{ paddingBottom: 0 }}>
                    <Divider />
                    {references?.map(ref => {
                        const answered = checkReferenceHasAnswer(ref);
                        const color = answered ? Colors.mainBlue : '#919191';
                        return (
                            <ListItem key={ref?._id} style={{ paddingLeft: 23, paddingRight: 23 }}>
                                <ListItemAvatar>
                                    {answered && <VisibilityIcon style={{ cursor: 'pointer', color: color }} onClick={() => handleReportPreview(ref)} />}
                                    {!answered && 
                                        <Tooltip title={"No report available"}>
                                            <VisibilityIcon style={{ cursor: 'pointer', color: color }} onClick={() => {}} />
                                        </Tooltip>
                                    }
                                </ListItemAvatar>
                                <ListItemAvatar>
                                    {answered && <GetAppIcon onClick={() => handleReportDownload(ref?._id)} style={{ color: color, cursor: 'pointer' }} />}
                                    {!answered && 
                                        <Tooltip title={"No report available"}>
                                            <GetAppIcon onClick={() => {}} style={{ color: color, cursor: 'pointer' }} />
                                        </Tooltip>
                                    }
                                </ListItemAvatar>
                                <ListItemText style={{ color: color }} primary={ref?.type} />
                            </ListItem>
                        )
                    })}
                    <Divider />
                    <ListItem style={{ paddingLeft: 23, paddingRight: 23 }}>
                        <ListItemAvatar key={'combined'}>
                            {combinedAvailable && <VisibilityIcon onClick={() => handleCombinedReportPreview()} style={{ color: Colors.mainBlue, cursor: 'pointer' }} />}
                            {!combinedAvailable && 
                                <Tooltip title={"No report available"}>
                                    <VisibilityIcon onClick={() => {}} style={{ color: "#919191", cursor: 'pointer' }} />
                                </Tooltip>
                            }
                        </ListItemAvatar>
                        <ListItemAvatar key={'combined'}>
                            {combinedAvailable && <GetAppIcon onClick={() => handleReportDownload()} style={{ color: Colors.mainBlue, cursor: 'pointer' }} />}
                            {!combinedAvailable && 
                                <Tooltip title={"No report available"}>
                                    <GetAppIcon onClick={() => {}} style={{ color: "#919191", cursor: 'pointer' }} />
                                </Tooltip>
                            }
                        </ListItemAvatar>
                        <ListItemText style={{ color: combinedAvailable ? Colors.mainBlue : "#919191" }} primary={'Combined Report'} />
                    </ListItem>
                    <Divider />
                </List>
                <div>
                    <Backdrop open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles({
    tooltipContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
})
