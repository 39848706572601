import React, { useEffect, useState } from 'react'
import DCNavigation from '../../components/DCNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStep, setJobPosition, setDeleteDate, setDeadlineListing, setDeadlineAnswer, setStakeholders, updateCandidate, setCase } from '../../redux/slices/createCaseSlice'
import Questions from '../../components/Questions'
import Preview from '../../components/Preview'
import { makeStyles } from '@material-ui/styles'
import { getDefaultCase, emptyCandidate } from '../../utils/constansts';
import { validateCaseInfo, validatePageChange, validateLiveCase } from '../../utils/validators'
import _ from 'lodash'
import { CasePrompt } from '../../components/CasePrompt'
import { formatCreateCase } from '../../services/caseService'
import { TEMPLATE_BY_ID, CASE_BY_ID, INVITE_CANDIDATE, ADD_CASE_LIVE, ADD_CASE_DRAFT } from '../../graphql/mutations'
import { useMutation, useLazyQuery } from '@apollo/client'
import { triggerFlashMessage } from '../../redux/slices/globalSlice'
import CaseDialog from '../../components/CaseDialog'
import CaseInfo from '../../components/CaseInfo'
import { useHistory } from 'react-router'
import moment from 'moment';

function NewCase({ location }) {
    const [caseErrors, setCaseErrors] = useState({});
    const [errorIndex, setErrorIndex] = useState();
    const [showPrompt, setShowPrompt] = useState(false);
    const [submitError, setSubmitError] = useState();

    const { activeStep, case: caseState, theme, category } = useSelector(state => state.createCase)
    const auth = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth)
    const [selectedLanguage, setSelectedLanguage] = useState(auth?.user?.company?.settings?.languages[auth?.user?.company?.settings?.defaultQALanguage]);
    const [defaultDeleteDate, setDefaultDeleteDate] = useState(moment().add(user?.company?.defaultDeleteDate || '90', 'days').valueOf());

    const dispatch = useDispatch()
    const classes = useStyles();
    const history = useHistory()

    const defaultCase = getDefaultCase();

    const [getTemplate, { data: templateData }] = useLazyQuery(TEMPLATE_BY_ID)
    const [getCase, { data: caseData }] = useLazyQuery(CASE_BY_ID);

    const [addCaseLive] = useMutation(ADD_CASE_LIVE);
    const [addCaseDraft] = useMutation(ADD_CASE_DRAFT);
    const [inviteCandidate] = useMutation(INVITE_CANDIDATE);

    const t = (str) => str;

    useEffect(() => {
        /** Check if copy template */
        if (location?.search?.includes('copy=template')) {
            const removeSearchChar = location?.search?.substring(1);
            const queryParams = removeSearchChar?.split('&')?.map(el => el?.split('='));
            const templateId = queryParams?.find(e => e[0] === 'templateId')[1]
            getTemplate({ variables: { id: templateId } });
        } else if (location?.search?.includes('copy=case')) {
            const removeSearchChar = location?.search?.substring(1);
            const queryParams = removeSearchChar?.split('&')?.map(el => el?.split('='));
            const caseId = queryParams?.find(e => e[0] === 'caseId')[1]
            getCase({ variables: { id: caseId } });
        } else {
            const newDefault = {
                ...defaultCase,
                deleteDate: defaultDeleteDate,
                candidate: {
                    ...defaultCase.candidate,
                    preferedLanguage: user?.company?.settings?.languages[user?.company?.settings?.defaultQALanguage]?.shortName,
                },
            }
            dispatch(setCase(newDefault))
        }
    }, [])

    useEffect(() => {
        if (templateData?.template) {
            const formattedTemplate = {
                ...templateData?.template,
                deleteDate: defaultDeleteDate,
                deadline: {
                    answer: `${templateData?.template?.deadline?.answer} hours`,
                    listing: `${templateData?.template?.deadline?.listing} hours`
                },
                candidate: {
                    ...emptyCandidate,
                    preferedLanguage: user?.company?.settings?.languages[user?.company?.settings?.defaultQALanguage]?.shortName,
                }
            }
            formattedTemplate && dispatch(setCase(formattedTemplate))
        }
    }, [templateData])

    useEffect(() => {
        if (caseData?.case) {
            const formattedCase = {
                ...caseData?.case,
                deleteDate: defaultDeleteDate,
                deadline: {
                    answer: `${caseData?.case?.deadline?.answer} hours`,
                    listing: `${caseData?.case?.deadline?.listing} hours`
                },
                candidate: {
                    ...emptyCandidate,
                    preferedLanguage: user?.company?.settings?.languages[user?.company?.settings?.defaultQALanguage]?.shortName,
                },
                references: caseData?.case?.references?.map((ref, index) => ({
                    _id: index,
                    questions: ref?.questions?.map(q => ({ question: q?.question })),
                    type: ref?.type
                }))
            }
            formattedCase && dispatch(setCase(formattedCase))
        }
    }, [caseData])

    /**
     * Validate on page changes
     */
    const handlePageChange = (item) => {
        const validatedPage = validatePageChange(activeStep, item, false)

        setCaseErrors(validatedPage?.errors)
        setErrorIndex(validatedPage?.errorIndex)
        dispatch(setActiveStep(validatedPage?.errorIndex))
        if (_.isEmpty(validatedPage?.errors)) {
            setErrorIndex()
            return dispatch(setActiveStep(item))
        }
    }

    const saveCase = async (status) => {
        let variables = formatCreateCase(caseState, user)
        variables.status = status;

        try {
            if (status === 'live') {
                const validated = validateLiveCase(caseState)
                if (!_.isEmpty(validated)) {
                    dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong, please follow live case guidelines") }))
                    setSubmitError(validated)
                    return Promise.reject()
                }
                await addCaseLive({ variables })
                dispatch(setCase({ ...defaultCase, deleteDate: defaultDeleteDate }))
                dispatch(triggerFlashMessage({ type: 'success', title: t(`Succesfully saved as ${status} case`) }))
                setShowPrompt(false)

                return history.push('/dashboard')
            }
            if(status === 'draft') {
                await addCaseDraft({ variables })
                dispatch(setCase({ ...defaultCase, deleteDate: defaultDeleteDate }))
                dispatch(triggerFlashMessage({ type: 'success', title: t(`Succesfully saved as ${status} case`) }))
                setShowPrompt(false)

                return history.push('/dashboard')
            }
        } catch(err) {
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        }
    }

    const sendCandidateInvite = async () => {
        let variables = formatCreateCase(caseState, user)
        variables.status = 'draft';
        variables.sendCandidateInvite = true;

        const errors = validateCaseInfo(false);
        if (!_.isEmpty(errors)) {
            setCaseErrors(errors)
            return setErrorIndex(0)
        }

        try {
            const caseRes = await inviteCandidate({ variables });
            dispatch(setCase({ ...defaultCase, deleteDate: defaultDeleteDate }))
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully invited Candidate") }))
            history.push('/update-case/' + caseRes?.data?.inviteCandidate?._id)
        } catch (err) {
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        }
    }

    useEffect(() => {
        !showPrompt && setSubmitError('')
    }, [showPrompt])

    return (
        <div style={{ maxHeight: 'calc(100vh - 106px)', overflow: 'hidden' }}>
            <CasePrompt
                when={!_.isEqual(caseState, { ...defaultCase, deleteDate: defaultDeleteDate })}
                onOk={() => console.log('ok')}
                onCancel={() => true}
                onDraftClick={() => saveCase('draft')}
                onLiveClick={() => saveCase('live')}
                error={submitError}
                setError={setSubmitError}
            />

            {showPrompt &&
                <CaseDialog
                    error={submitError}
                    continueButton={false}
                    handleCancel={() => {
                        setSubmitError({})
                        setShowPrompt(false)
                    }}
                    handleSaveAsDraft={() => saveCase('draft')}
                    handleSaveAsLive={() => saveCase('live')}
                    title="You are about to save this Case."
                />
            }

            <div className={classes.contentContainer}>
                <DCNavigation
                    setShowPrompt={setShowPrompt}
                    setActiveStep={(index) => handlePageChange(index)}
                    activeStep={activeStep}
                    errorIndex={errorIndex}
                    type="Case"
                    create={true}
                />
            </div>

            <div className={classes.contentContainer}>
                {user.role === 'TemplateOnly' 
                    ? (
                        <div>
                            {activeStep === 0 &&
                                <CaseInfo
                                    errors={caseErrors}
                                    caseState={caseState}
                                    setJobPosition={(e) => dispatch(setJobPosition(e))}
                                    setDeleteDate={(e) => dispatch(setDeleteDate(e))}
                                    setDeadlineListing={(e) => dispatch(setDeadlineListing(e))}
                                    setDeadlineAnswer={(e) => dispatch(setDeadlineAnswer(e))}
                                    setStakeholders={(e) => dispatch(setStakeholders(e))}
                                    updateCandidate={(e) => dispatch(updateCandidate(e))}
                                    sendCandidateInvite={(e) => sendCandidateInvite()}
                                />
                            }
                            {activeStep === 1 && <Preview caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
                        </div>
                    )
                    : (
                        <div>
                            {activeStep === 0 &&
                                <CaseInfo
                                    errors={caseErrors}
                                    caseState={caseState}
                                    setJobPosition={(e) => dispatch(setJobPosition(e))}
                                    setDeleteDate={(e) => dispatch(setDeleteDate(e))}
                                    setDeadlineListing={(e) => dispatch(setDeadlineListing(e))}
                                    setDeadlineAnswer={(e) => dispatch(setDeadlineAnswer(e))}
                                    setStakeholders={(e) => dispatch(setStakeholders(e))}
                                    updateCandidate={(e) => dispatch(updateCandidate(e))}
                                    sendCandidateInvite={(e) => sendCandidateInvite()}
                                />
                            }
                            {activeStep === 1 && <Questions theme={theme} category={category} caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
                            {activeStep === 2 && <Preview caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles({

})

export default NewCase
