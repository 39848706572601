/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from "react";
import '../sass/components/textEditor.scss'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


function insertCustomTags (args) {
  
    let value = ""
    switch(String(args)) {
		case "1":
			value = "${company}"
			break
		case "2":
			value = "${user}"
			break
		case "3":
			value = "${candidateName}"
			break
		case "4":
			value = "${referenceName}"
			break
		case "5":
			value = "${caseOwnerName}"
			break
		case "6":
			value = "${stakeholderName}"
			break
		case "7":
			value = "${role}"
			break
		case "8":
			value = "${link}"
			break
		case "9":
			value = "${position}"
			break
		case "10":
			value = "${email}"
			break
		case "11":
			value = "${hoursLeft}"
			break
		case "12":
			value = "${referenceList}"
			break
		case "13":
			value = "${referencePhone}"
			break
		case "14":
			value = "${referenceMobile}"
			break
		case "15":
			value = "${candidatePhone}"
			break
		case "16":
			value = "${candidateMobile}"
			break
		case "17":
			value = "${mailSubject}"
			break
		case "18":
			value = "${IPAddress}"
			break
		case "19":
			value = "${referenceType}"
			break
		case "20":
			value = "${referenceStatus}"
			break		
		case "21":
			value = "${companyAdmin}"
			break
		default: 
			break
	}    
    const cursorPosition = this.quill.getSelection().index
    this.quill.insertText(cursorPosition, value)
    this.quill.setSelection(cursorPosition + value.length)
}

export const formats = [
	"header",
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"align",
	"strike",
	"script",
	"blockquote",
	"background",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
	"color",
	"code-block"
];

const CustomToolbar = () => (
	<div id="toolbar">
		<span className="ql-formats">
			<select className="ql-font" defaultValue="arial">
				<option value="arial">Arial</option>
				<option value="comic-sans">Comic Sans</option>
				<option value="courier-new">Courier New</option>
				<option value="georgia">Georgia</option>
				<option value="helvetica">Helvetica</option>
				<option value="lucida">Lucida</option>
			</select>
			<select className="ql-size" defaultValue="medium">
				<option value="extra-small">Size 1</option>
				<option value="small">Size 2</option>
				<option value="medium">Size 3</option>
				<option value="large">Size 4</option>
			</select>
			<select className="ql-header" defaultValue="3">
				<option value="1">Heading</option>
				<option value="2">Subheading</option>
				<option value="3">Normal</option>
			</select>
		</span>
		<span className="ql-formats">
			<button className="ql-bold" />
			<button className="ql-italic" />
			<button className="ql-underline" />
			<button className="ql-strike" />
		</span>
		{<span className="ql-formats">
			<button className="ql-list" value="ordered" />
			<button className="ql-list" value="bullet" />
		</span>}
		<span className="ql-formats">
			<select className="ql-align" />
			<select className="ql-color" />
			<select className="ql-background" />
		</span>
		<span className="ql-formats">
			<button className="ql-link" />
			<button className="ql-image" />
		</span>
		<span className="ql-formats">
			<select className="ql-insertCustomTags">
				<option value="0"></option>
				<option value="1"></option>
				<option value="2"></option>
				<option value="3"></option>
				<option value="4"></option>
				<option value="5"></option>
				<option value="6"></option>
				<option value="7"></option>
				<option value="8"></option>
				<option value="9"></option>
				<option value="10"></option>
				<option value="11"></option>
				<option value="12"></option>
				<option value="13"></option>
				<option value="14"></option>
				<option value="15"></option>
				<option value="16"></option>
				<option value="17"></option>
				<option value="18"></option>
				<option value="19"></option>
				<option value="20"></option>
				<option value="21"></option>
			</select>
		</span>
	</div>
);

export default function TextEditor( {deltaArg, onChangeP } ) {
	const [editorHtml, setEditorHtml] = useState("")
	const [saveState, setSaveState] = useState(false)
	const modules = {
        toolbar: {
            container: "#toolbar",
            handlers: {
        	    insertCustomTags: insertCustomTags,
            }
        }
    }

	useEffect(()=> {
		if(deltaArg == null) return
		setEditorHtml(deltaArg)
	}, [deltaArg])

	useEffect(()=> {
		if(editorHtml === "") return
		onChangeP(editorHtml)
	}, [editorHtml])

	useEffect(()=> {
		saveState && editorHtml && (onChangeP(editorHtml))
		setSaveState(false)
	}, [saveState])
	
	return (
		<div style={{marginTop: '20px'}} className="text-editor">
            <CustomToolbar>
			</CustomToolbar>
            <ReactQuill
                value={editorHtml}
                onChange={setEditorHtml}
                modules={modules}
            />
        </div>
	);	
}

