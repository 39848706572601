import store from '../redux/store';

export const getLanguage = (element) => {
    const language = store.getState().global.language
    const found = element?.translations?.find(t => t?.language === language || t?.language === language?.shortName)

    if(found) {
        return found;
    }
    return element?.translations?.find(t => t?.language === 'en')
}

export const getQuestionsLanguage = (element) => {
    const language = store.getState().global.questionsLanguage
    const found = element?.translations?.find(t => t?.language === language || t?.language === language?.shortName)

    if(found) {
        return found;
    }
    return element?.translations?.find(t => t?.language === 'da')
}

export const getLanguageLocal = (element, language) => {
    const found = element?.translations?.find(t => t?.language === language || t?.language === language?.shortName)

    if(found) {
        return found;
    }
    return element?.translations?.find(t => t?.language === 'en')
}

export const getLanguageLocalQuestionsFilter = (element, language) => {
    const found = element?.translations?.find(t => t?.language === language || t?.language === language?.shortName)
    return found;
}

export const findLanguage = (languages, language) => {
    return languages?.find(l => l?.shortName === language);
}
