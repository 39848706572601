import React, { useEffect, useState } from 'react';

import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import CustomAlertDialog from '../../components/CustomAlertDialog';
import QuestionsPreview from '../../components/QuestionsPreview';
import { Colors } from '../../styles/globalStyles';
import { formatDate } from '../../utils/constansts';
import { validateUser } from '../../utils/validators';

const Reference = ({
    reference,
    index,
    handleReference,
    caseInfo,
    handleResendListing,
    handleResendListingHalftime,
    handleResendListingDeadline,
    isClosed = false,
}) => {
    const [previewState, setPreviewState] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [refIsFilled, setRefIsFilled] = useState(true);
    const [showResendModal, setShowResendModal] = useState(false);
    const [resendAction, setResendAction] = useState('');
    const [errors, setErrors] = useState();

    const classes = useStyles();

    const openInNewTab = url => {
        url = url.match(/^https?:/) ? url : '//' + url;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const formatListingDate = array => {
        return array
            ?.filter(date => date !== null)
            ?.map(date => moment(parseInt(date)).format(`${formatDate()} HH:mm`))
            .join(', \n');
    };

    const handleShowModal = action => {
        setResendAction(action);
        setShowResendModal(true);
    };

    const handleLock = () => {
        const user = {
            firstName: reference?.firstName,
            lastName: reference?.lastName,
            email: reference?.email,
        };
        let errObj = validateUser(user);

        if (!_.isEmpty(errObj)) {
            setErrors(errObj);
        } else {
            setErrors();
            setEditActive(false);
        }
    };

    useEffect(() => {
        setRefIsFilled(reference?.email !== null && reference?.firstName !== null && reference?.lastName !== null);
    }, [caseInfo, reference]);

    const ResendAdornment = ({ className, resend }) => {
        if (isClosed) return null;
        return (
            <InputAdornment position="end">
                {className && !reference?.answerStatus && <span className={className} />}
                <Tooltip title="Resend mail">
                    <IconButton aria-label="email" onClick={() => resend()}>
                        <MailIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        );
    };

    return (
        <div className={classes.outerContainerReferences}>
            {showResendModal && (
                <CustomAlertDialog
                    open={showResendModal}
                    handleClose={() => setShowResendModal(false)}
                    handleCancel={() => setShowResendModal(false)}
                    id={reference?._id}
                    handleAccept={() =>
                        resendAction === 'deadline'
                            ? handleResendListingDeadline(reference?._id)
                            : resendAction === 'halftime'
                            ? handleResendListingHalftime(reference?._id)
                            : handleResendListing(reference?._id)
                    }
                    alertTitle={'Resend mail'}
                    alertText={'Are you sure you want to resend mail to Reference?'}
                    okText="Resend"
                />
            )}

            {!refIsFilled && (
                <div className={classes.notListedContainer}>
                    <p>Not listed</p>
                </div>
            )}
            <div className={classes.headerContainer}>
                {refIsFilled && (
                    <div className={classes.statusSpan}>
                        {reference?.halftimeDates?.send && !reference?.answerDate && (
                            <span className={classes.statusYellow} />
                        )}
                        {reference?.deadlineDates?.send && !reference?.answerDate && (
                            <span className={classes.statusRed} />
                        )}
                    </div>
                )}
                <p className={classes.headerTitle}>{reference?.type}</p>
                {!isClosed && reference?.listingMailSend && (
                    <div
                        style={{ backgroundColor: editActive ? '#1f5f1f' : '#ab1d1d' }}
                        className={classes.lockContainer}
                    >
                        {editActive ? (
                            <LockOpenIcon style={{ fontSize: 20 }} className={classes.lock} onClick={handleLock} />
                        ) : (
                            <LockIcon className={classes.lock} onClick={() => setEditActive(true)} />
                        )}
                    </div>
                )}
            </div>

            {refIsFilled && (
                <div className={classes.contentContainer}>
                    <div className={classes.namesContainer}>
                        <div className={classes.inputContainer}>
                            <TextField
                                name="firstName"
                                error={errors?.firstName}
                                label="First name"
                                size="small"
                                disabled={!editActive}
                                fullWidth
                                value={isClosed ? '-' : reference?.firstName ?? ''}
                                onChange={e => handleReference(reference?._id, e.target.name, e.target.value)}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <TextField
                                name="lastName"
                                error={errors?.lastName}
                                label="Last name"
                                size="small"
                                aria-disabled={false}
                                disabled={!editActive}
                                fullWidth
                                value={isClosed ? '-' : reference?.lastName ?? ''}
                                onChange={e => handleReference(reference?._id, e.target.name, e.target.value)}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>

                    <div className={classes.phoneContainer}>
                        <div className={classes.inputContainer}>
                            <TextField
                                name="email"
                                error={errors?.email}
                                label="Email"
                                size="small"
                                disabled={!editActive}
                                fullWidth
                                value={isClosed ? '-' : reference?.email ?? ''}
                                onChange={e => handleReference(reference?._id, e.target.name, e.target.value)}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div
                            onClick={e => {
                                if (editActive || !reference?.linkedIn) return;
                                e.stopPropagation();
                                openInNewTab(reference?.linkedIn);
                            }}
                            style={{
                                pointerEvents: !editActive && reference?.linkedIn ? 'auto' : 'none',
                                cursor: !editActive && reference?.linkedIn ? 'pointer' : 'default',
                            }}
                            className={classes.inputContainer}
                        >
                            <TextField
                                name="linkedIn"
                                onChange={e => handleReference(reference?._id, e.target.name, e.target.value)}
                                label="URL LinkedIn profile"
                                size="small"
                                style={{ pointerEvents: !editActive ? 'none' : 'auto' }}
                                fullWidth
                                value={isClosed ? '-' : reference?.linkedIn ?? ''}
                                className={reference?.linkedIn ? classes.linkedInInput : classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>

                    <div className={classes.phoneContainer}>
                        <div className={classes.inputContainer}>
                            <TextField
                                name="phone"
                                disabled={!editActive}
                                label="Work phone"
                                size="small"
                                fullWidth
                                value={isClosed ? '-' : reference?.phone ?? ''}
                                onChange={e => handleReference(reference?._id, e.target.name, e.target.value)}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <TextField
                                name="mobile"
                                disabled={!editActive}
                                label="Mobile phone"
                                size="small"
                                fullWidth
                                value={isClosed ? '-' : reference?.mobile ?? ''}
                                onChange={e => handleReference(reference?._id, e.target.name, e.target.value)}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>
                    <div className={classes.phoneContainer}>
                        <div className={classes.inputContainer}>
                            <TextField
                                disabled
                                label="Deadline: Answering reference-survey"
                                size="small"
                                fullWidth
                                value={caseInfo?.deadline?.answer ? `${caseInfo?.deadline?.answer}` : ''}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <TextField
                                disabled
                                label="Date: Listing of Reference"
                                size="small"
                                fullWidth
                                value={
                                    reference?.listedDate
                                        ? `${moment(new Date(parseInt(reference?.listedDate))).format(
                                              `${formatDate()} HH:mm`
                                          )}`
                                        : ''
                                }
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    </div>
                    <div className={classes.textContainer}>
                        <TextField
                            size="small"
                            label="Date: Invitation mail to Reference"
                            disabled={true}
                            fullWidth
                            multiline
                            InputProps={{
                                endAdornment: <ResendAdornment resend={() => handleShowModal('listing')} />,
                            }}
                            value={
                                !reference?.listingMailSend
                                    ? 'Not yet send'
                                    : formatListingDate(reference?.listingMailDate)
                            }
                            className={classes.input}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={classes.textContainer}>
                        <TextField
                            disabled
                            multiline
                            label="Date: Answering reference-survey"
                            size="small"
                            fullWidth
                            value={
                                reference?.answerDate
                                    ? `${moment(parseInt(reference?.answerDate)).format(`${formatDate()} HH:mm`)} ${
                                          !reference?.answerStatus ? '(Not completed)' : '(Completed)'
                                      }`
                                    : 'Not yet answered'
                            }
                            className={classes.input}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    {reference?.halftimeDates?.send && (
                        <div className={classes.textContainer}>
                            <TextField
                                size="small"
                                label="Half-time reminder date"
                                disabled={true}
                                fullWidth
                                multiline
                                InputProps={{
                                    endAdornment: (
                                        <ResendAdornment
                                            className={classes.deadlineStatusYellow}
                                            resend={() => handleShowModal('halftime')}
                                        />
                                    ),
                                }}
                                value={formatListingDate(reference?.halftimeDates?.dates) ?? ''}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    )}
                    {reference?.deadlineDates?.send && (
                        <div className={classes.textContainer}>
                            <TextField
                                size="small"
                                label="Deadline exceeded date"
                                disabled={true}
                                fullWidth
                                multiline
                                InputProps={{
                                    endAdornment: (
                                        <ResendAdornment
                                            className={classes.deadlineStatusRed}
                                            resend={() => handleShowModal('deadline')}
                                        />
                                    ),
                                }}
                                value={formatListingDate(reference?.deadlineDates?.dates) ?? ''}
                                className={classes.input}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                    )}
                </div>
            )}
            {previewState && (
                <QuestionsPreview
                    answerPreview={true}
                    caseId={caseInfo?._id}
                    referenceId={reference?._id}
                    candidateName={`${caseInfo?.candidate?.firstName} ${caseInfo?.candidate?.lastName}`}
                    reference={reference}
                    handleClose={() => setPreviewState(false)}
                    open={previewState}
                    jobPosition={caseInfo?.jobPosition}
                />
            )}
        </div>
    );
};

const useStyles = makeStyles({
    input: {
        '& .MuiOutlinedInput-input.Mui-disabled': {
            '-webkit-text-fill-color': 'rgba(0,0,0, 0.65) !important',
        },
    },
    linkedInInput: {
        '& .MuiOutlinedInput-input.Mui-disabled': {
            '-webkit-text-fill-color': '#0000EE !important',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    outerContainerReferences: {
        width: '100%',
        paddingBottom: 50,
        maxWidth: '100%',
        position: 'relative',
        marginBottom: 20,
    },
    notListedContainer: {
        position: 'absolute',
        top: 45,
        left: 0,
        minHeight: 40,
        width: '100%',
        background: '#000',
        zIndex: 1,
        opacity: '0.6',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        fontSize: 16,
        justifyContent: 'center',
    },
    headerContainer: {
        position: 'relative',
        height: 45,
        backgroundColor: Colors.mainBlue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 668px)': {
            height: 40,
            alignItems: 'center',
        },
    },
    statusYellow: {
        marginRight: 5,
        height: 20,
        width: 20,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: Colors.yellow,
    },
    statusRed: {
        height: 20,
        width: 20,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: Colors.red,
    },
    statusSpan: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginLeft: 'auto',
        top: 5,
        '@media (min-width: 668px)': {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 10,
            top: 0,
            bottom: 0,
        },
    },
    headerTitle: {
        color: '#fff',
    },
    lockContainer: {
        borderRadius: '0%',
        height: '100%',
        width: 40,
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        right: 0,
    },
    contentContainer: {
        paddingTop: 20,
    },
    namesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputContainer: {
        width: '48%',
        height: '100%',
        paddingBottom: 10,
    },
    textContainer: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    phoneContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    deadlineStatusYellow: {
        height: 20,
        width: 20,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: Colors.yellow,
        marginRight: 10,
    },
    deadlineStatusRed: {
        height: 20,
        width: 20,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: Colors.red,
        marginRight: 10,
    },
    lock: {
        color: 'white',
    },
});

export default Reference;
