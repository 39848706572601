import React from "react";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete, TextField } from "@material-ui/core";
import axios from "axios";

import CustomDropdownMenu from "../../components/CustomDropdownMenu";
import { Colors } from "../../styles/globalStyles";
import TextEditor from "../../components/TextEditor";
import LoadingBtn from "../../components/LoadingButton";
import { GET_TEXTEDITORS } from "../../graphql/queries";
import { UPDATE_COMPANY, UPDATE_TEXTEDITOR } from "../../graphql/mutations";

export default function Branding({ allCompanies, refetchAllCompanies }) {
  const [showColor, setShowColor] = useState(false);
  const [color, setColor] = useState("#fff");
  const [language, setLanguage] = useState();
  const [delta, setDelta] = useState();
  const [textLanguage, setTextLanguage] = useState();
  const [textEditorsTypes, setTextEditorsTypes] = useState();
  const [mediaType, setMediaType] = useState();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [file, setFile] = useState();
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [brandingChosen, setbrandingChosen] = useState();
  const [brandingArr, setBrandingArr] = useState();

  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [updateTextEditor] = useMutation(UPDATE_TEXTEDITOR);

  const auth = useSelector((state) => state.auth);
  const hiddenFileInput = React.useRef(null);

  const { user } = auth;
  // const allCompanies = companies?.companies;
  const { company } = user;
  const { data: textEditors, refetch: refetchTextEditor } = useQuery(
    GET_TEXTEDITORS,
    { variables: { companyId: value?._id } },
    { fetchPolicy: "no-cache" }
  );
  const { languages } = company?.settings;
  const { t } = useTranslation();
  const classes = useStyles();
  const TextEditorsSet = new Set();

  useEffect(() => {
    if (inputValue && allCompanies) {
      if (
        inputValue &&
        brandingArr?.find((company) => company.name === inputValue)?.useDefault
      ) {
        setbrandingChosen(
          allCompanies
            .map((company) => {
              return {
                name: company.name,
                useDefault: company.branding.useDefault,
              };
            })
            .find((company) => company.name === "Dooblecheck ApS")
        );
      } else {
        setbrandingChosen(
          allCompanies
            .map((company) => {
              return {
                name: company.name,
                useDefault: company.branding.useDefault,
              };
            })
            .find((company) => company.name === inputValue)
        );
      }
    }
  }, [brandingArr]);

  useEffect(() => {
    if (inputValue && inputValue !== "Dooblecheck ApS") {
      allCompanies &&
        setBrandingArr(
          allCompanies
            .filter((company) => {
              if (
                company.name === "Dooblecheck ApS" ||
                company.name === inputValue
              ) {
                return true;
              }
              return false;
            })
            .map((company) => {
              return {
                name: company.name,
                useDefault: company.branding.useDefault,
              };
            })
        );
    } else {
      allCompanies &&
        setBrandingArr(
          allCompanies
            .filter((company) => company.name === "Dooblecheck ApS")
            .map((company) => {
              return {
                name: company.name,
                useDefault: company.branding.useDefault,
              };
            })
        );
    }
  }, [allCompanies, inputValue]);

  useMemo(() => {
    textEditors &&
      textEditors.textEditors.forEach((te) => {
        TextEditorsSet.add(te.type);
      });
    setTextEditorsTypes(Array.from(TextEditorsSet));
  }, [textEditors]);

  useEffect(() => {
    textEditorsTypes &&
      mediaType === undefined &&
      setMediaType(textEditorsTypes[0]);
    mediaType !== undefined &&
      setCategories(
        textEditors?.textEditors.filter((e) => mediaType === e.type)
      );
  }, [textEditorsTypes]);

  useEffect(() => {
    setCategories(textEditors?.textEditors.filter((e) => mediaType === e.type));
  }, [mediaType]);

  useEffect(() => {
    categories && category === undefined && setCategory(categories[0]);
    categories &&
      category &&
      setCategory(categories.find((cat) => category.name === cat.name));
  }, [categories]);

  const updateText = (input) => {
    let updateObj = [...category.translations];
    updateObj = updateObj.map((e) => {
      let newText = { ...e };
      if (e === textLanguage) {
        newText.text = delta;
        return newText;
      } else {
        return e;
      }
    });
    updateTextEditor({
      variables: { input: { ...category, translations: [...updateObj] } },
    })
      .then(() => {
        // TODO: add confirmation
        setIsSaved(true);
      })
      .catch((e) => {
        // TODO: add error handling
        console.log("error", e);
      });
  };

  useEffect(() => {
    isSaved && refetchTextEditor();
    setIsSaved(false);
  }, [isSaved]);

  useEffect(() => {
    category && setTextLanguage(category?.translations[0]);
    textLanguage &&
      setTextLanguage(
        category?.translations.find((e) => e.language === textLanguage.language)
      );
  }, [category]);

  useEffect(() => {
    const del = textLanguage?.text;
    setDelta(del);
  }, [textLanguage]);

  useEffect(() => {
    const val = allCompanies?.find((com) => com?._id === company?._id);
    setValue(val);

    let language = "";
    if (val && val.settings.defaultLanguage === 0)
      language = val.settings.languages[0].fullName;
    if (val && val.settings.defaultLanguage === 1)
      language = val.settings.languages[1].fullName;
    setLanguage(language);
  }, [allCompanies]);

  const onChangeDefaultLanguage = (e) => {
    let language = e;
    if (language) {
      let num = 0;
      if (language === "English") num = 0;
      if (language === "Dansk") num = 1;
      updateCompany({
        variables: {
          input: {
            _id: value?._id,
            settings: { ...value?.settings, defaultLanguage: num },
          },
        },
      })
        .then(() => {
          console.log("succes change language");
          refetchAllCompanies();
        })
        .catch((err) => {
          // TODO: add error handling
          console.log("error", err);
        });
    }
  };

  // SET COLOR FOR COMPANY BRANDING - REMOVED TILL IMPLEMENTED TODO
  // useEffect(() => {
  //     if(color) {
  //         updateCompany({ variables: { input: {_id: value?._id, branding: { ...value?.branding, colors : color }}}}).then(() => {
  //             console.log('succes')
  //         }).catch(err => {
  //             // TODO: add error handling
  //             console.log('error', err)
  //         })
  //     }
  // }, [color])

  useEffect(() => {
    if (brandingArr && brandingArr.length === 1) {
      return;
    }
    if (value && brandingChosen && brandingChosen.name !== "Dooblecheck ApS") {
      updateCompany({
        variables: {
          input: {
            _id: value?._id,
            branding: { ...value.branding, useDefault: false },
          },
        },
      })
        .then(() => {
          // TODO: add confirmation
          console.log("success Own branding");
        })
        .catch((err) => {
          // TODO: add error handling
          console.log("error", err);
        });
    }
    if (value && brandingChosen && brandingChosen.name === "Dooblecheck ApS") {
      updateCompany({
        variables: {
          input: {
            _id: value?._id,
            branding: { ...value.branding, useDefault: true },
          },
        },
      })
        .then(() => {
          // TODO: add confirmation
          console.log("success DC Default");
        })
        .catch((err) => {
          // TODO: add error handling
          console.log("error", err);
        });
    }
  }, [brandingChosen]);

  useEffect(() => {
    if (
      value?.branding?.logo &&
      (value?.branding?.logo?.name !== "" ||
        value?.branding?.logo?.base64 !== "")
    ) {
      setFile(value?.branding?.logo);
    }
    setColor(value?.branding?.colors);
    // refetchAllCompanies()
  }, [value]);

  const handleImageSelected = async (inputFile) => {
    const dataFile = inputFile.target.files[0];
    const formData = new FormData();
    formData.append("file", dataFile);
    try {
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/image/upload`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      await updateCompany({
        variables: {
          input: {
            _id: value?._id,
            branding: { ...value.branding, logo: data },
          },
        },
      });
      refetchAllCompanies();
      // TODO: add confirmation
    } catch (error) {
      // TODO handle error
    }
  };

  const handleUploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const lanConverter = (lan) => {
    switch (lan) {
      case "da":
        return "Dansk";
      case "Dansk":
        return "da";
      case "en":
        return "English";
      case "English":
        return "en";
      // not sure if this is right, but switches needs a default.
      default:
        return "en";
    }
  };

  return (
    <div className={classes.caseContainer}>
      <div className={classes.caseContent}>
        {value !== undefined && (
          <div className={classes.caseSelectorCompSearch}>
            <Autocomplete
              value={value}
              onChange={(e, newVal) => {
                setValue(newVal);
              }}
              inputValue={inputValue}
              onInputChange={(e, newInputVal) => {
                setInputValue(newInputVal);
              }}
              options={allCompanies}
              getOptionLabel={(option) => option?.name}
              style={{
                width: "100%",
                marginLeft: "20px",
                marginTop: "5px",
                textColor: "red",
              }}
              freeSolo
              disabled={user.role === "SuperAdmin" ? false : true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ paddingLeft: "10px" }}
                  label="Company, search or choose from drop-down"
                  variant="standard"
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  className={classes.textFieldBorder}
                />
              )}
            />
          </div>
        )}
        <div className={classes.caseSelectors}>
          <div className={classes.caseSelector}>
            <CustomDropdownMenu
              selected={language}
              onSelect={(e) => {
                setLanguage(e);
                onChangeDefaultLanguage(e);
              }}
              label={t("Select default Language (Q&A, mails, Website)")}
              title={language}
              elements={languages.map((e) => e.fullName)}
            />
          </div>
          {brandingArr && (
            <div className={classes.caseSelector}>
              <CustomDropdownMenu
                selected={brandingChosen?.name}
                onSelect={(e) =>
                  setbrandingChosen(brandingArr.find((e1) => e1.name === e))
                }
                label={t("Branding (Mails and websites)")}
                title={brandingChosen?.name}
                elements={brandingArr.map((e) => e.name)}
              />
            </div>
          )}
        </div>
        <div className={classes.caseSelectors}>
          <div className={classes.caseSelector}>
            <CustomDropdownMenu
              noMenu
              label={t("Upload default logo (JPEG, TIFF)")}
              title={file?.name}
            >
              <div
                style={{
                  maxWidth: 300,
                  backgroundColor: "#fff",
                  alignItems: "center",
                }}
              >
                <LoadingBtn
                  style={{ fontSize: 10, fontWeight: "400 !important" }}
                  onClick={() => handleUploadClick()}
                >
                  UPLOAD IMAGE
                </LoadingBtn>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => {
                    handleImageSelected(e);
                  }}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />
                <img
                  style={{ width: "100%", paddingTop: "20px" }}
                  alt="logo"
                  src={file?.base64}
                />
              </div>
            </CustomDropdownMenu>
          </div>
          <div className={classes.caseSelector}>
            <CustomDropdownMenu
              noMenu
              onSelect={(e) => setShowColor(!showColor)}
              title={color}
              label={t("Color (lines, grids and radio buttons)")}
            >
              <SketchPicker
                color={color}
                onChangeComplete={handleColorChange}
              />
            </CustomDropdownMenu>
          </div>
        </div>
        <div className={classes.caseSelectors}>
          {textLanguage && (
            <div className={classes.caseSelector}>
              <CustomDropdownMenu
                selected={lanConverter(textLanguage?.language)}
                onSelect={(e) => {
                  setTextLanguage(
                    category?.translations?.find(
                      (ver) => ver?.language === lanConverter(e)
                    )
                  );
                }}
                label={t("Select language version")}
                title={lanConverter(textLanguage?.language)}
                elements={category?.translations.map((e) =>
                  lanConverter(e.language)
                )}
              />
            </div>
          )}
          {mediaType && (
            <div className={classes.caseSelector}>
              <CustomDropdownMenu
                selected={mediaType}
                onSelect={(e) => {
                  setMediaType(e);
                  setCategory(
                    textEditors?.textEditors.filter((e1) => e === e1.type)[0]
                  );
                }}
                label={t("Select media")}
                title={mediaType}
                elements={textEditorsTypes}
              />
            </div>
          )}
          {category && (
            <div className={classes.caseSelector}>
              <CustomDropdownMenu
                selected={category?.name}
                onSelect={(e) =>
                  setCategory(categories.find((res) => res.name === e))
                }
                label={t("Select text")}
                title={category?.name}
                elements={categories?.map((e) => e.name)}
              />
            </div>
          )}
        </div>
        <div></div>
        {mediaType && (
          <TextEditor
            deltaArg={delta}
            onChangeP={(del) => {
              setDelta(del);
            }}
          />
        )}
        <LoadingBtn
          onClick={() => updateText()}
          style={{ marginTop: 5, fontSize: 10, fontWeight: "400 !important" }}
        >
          SAVE
        </LoadingBtn>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  caseContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  caseContent: {
    width: "70%",
    paddingBottom: "10%",
  },
  caseSelectors: {
    display: "flex",
    flexDirection: "row",
    borderTop: `1px dashed ${Colors.mainBlue}`,
    borderBottom: `1px dashed ${Colors.mainBlue}`,
    position: "relative",
    "&:last-child": {
      borderLeft: "none",
    },
    marginTop: 20,
  },
  caseSelector: {
    width: "100%",
    borderLeft: `1px dashed ${Colors.mainBlue}`,
    position: "relative",
    minHeight: "55px",
    display: "flex",
    alignItems: "flex-end",
    "& .MuiOutlinedInput-input": {
      border: "none",
    },
    "&:first-child": {
      borderLeft: `1px dashed ${Colors.mainBlue}`,
    },
    "&:last-child": {
      borderRight: `1px dashed ${Colors.mainBlue}`,
    },
    "& .MuiFormControl-root": {
      height: "100% !important",
      width: "100%",
    },
    "&.picker": {
      display: "flex",
      alignItems: "flex-end",
    },
  },
  caseSelectorCompSearch: {
    width: "99,9%",
    border: `1px dashed ${Colors.mainBlue}`,
    minHeight: "55px",
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    padding: "6px 8px",
    justifyContent: "center",
  },
  textFieldBorder: {
    "& input:valid + fieldset": {
      borderStyle: "dashed",
      borderColor: "black",
      borderRadius: 0,
    },
    "& input:valid:focus + fieldset": {
      borderWidth: 1,
      borderColor: "black",
    },
  },
});
