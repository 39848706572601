import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Modal, Box, Tooltip, IconButton, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Colors } from '../../../styles/globalStyles';
import GuideMenu from './GuideMenu';
import RoleContainer from '../../../helpers/RoleContainer';
import { useSelector } from 'react-redux';
import rolesImage from "../../../assets/images/RolesGuideImage.png"

function Guide({ open = false, setOpen, company }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const sectionId = {
        dashboard: 'dashboard-section',
        quickGuide: 'quick-guide-section',
        detailedUserGuide: 'detailed-user-guide-section',
        caseInfo: 'case-info-section',
        questionsRatingPoints: 'questions-rating-points-section',
        previewSave: 'preview-save-section',
        caseStatus: 'case-status-section',
        account: 'account-section',
        profile: 'profile-section',
        qaManager: 'qa-manager-section',
    }
    
    const {user} = useSelector(state => state.auth);

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNav = (id) => {
        document?.getElementById(id)?.scrollIntoView();
        setAnchorEl(null);
    };

    const renderDashboard = () => {
        return (
            <div>
                <div id={sectionId.dashboard} className={classes.newSection} />
                <h1 >Dashboard</h1>
                <div>
                    <p className={classes.underline}>Template and Case tables</p>
                    <p className={classes.marginTop}>The search field and calendar allows searching for specific Cases by all basic information and dates. Otherwise, all available Templates and Cases related to the specific user are presented with basic information on the job-position and Candidate and can be sorted by the column header. Status of a Case will either be:</p>
                    <ul>
                        <p className={classes.marginTop} >1. Draft (Case under development)</p>
                        <p className={classes.marginTop} >2. Live (Case completed and saved)</p>
                        <p className={classes.marginTop} >3. Closed (Case closed or exceeded the delete date, by which all Candidate and Reference data is deleted)</p>
                    </ul>
                    <p className={classes.marginTop}>The trashcan icon will delete the Template or Case entirely – and not only information on the Candidate and References.</p>
                    <p className={classes.marginTop}>A yellow dot (halftime) and a red dot (deadline exceeded) will appear as part of the Case information when a Candidate and/or Reference receives a reminder mail.</p>
                </div>
                <div>
                    <p className={classes.underline}>New Case</p>
                    <p className={classes.marginTop}>A new case is created by:</p>
                    <ul>
                        <p className={classes.marginTop} >1. Click the copy icon on a Template</p>
                        <p className={classes.marginTop} >2. Click the copy icon on an existing Case</p>
                        <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign']}>
                            <p className={classes.marginTop} >3. Click the New Case action button</p>
                        </RoleContainer>
                    </ul>
                </div>
                <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                    <div>
                        <p className={classes.underline}>New Template</p>
                        <p className={classes.marginTop}>Templates can be created by the appointed DIGITAL DOOBLECHECK Account-Administrators at the company.</p>
                    </div>
                </RoleContainer>
            </div>
        );
    }

    const renderQuickGuide = () => {
        return (
            <div>
                <div id={sectionId.quickGuide} className={classes.newSection} />
                <h1>Quick Guide</h1>
                <div>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK is a unique Online Reference Solution that ensures thorough, structured and unbiased reference retrieval of the selected job candidates. The solution has a number of built-in automations which, in addition to minimizing the resource consumption, ensures uniqueness and documentation of each individual reference.</p>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK offers online reference retrieval through a few simple steps. A new Reference Case can be started by either clicking the New Case action button or using the copy icon on an existing case or pre-designed company Template if such has been created.</p>
                    <p className={classes.marginTop}>The 4 steps in creating and handling a Case is:</p>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>1: Case Info</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Basic Case Info about job-position, deadlines and candidate info - name and email - as well as the choice of types of reference</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>2: Questions | Rating Points</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>{'Select the Questions & Answers and Rating Points that are relevant to the position and/or the individual candidate. There is an open choice among more than 150 predefined questions with associated answers - and if the crucial question is missing, it can be easily created directly in the solution and stored in the Company Question database for future use.'}</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>3: Preview</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Save the Case - and the rest is managed by the Candidate himself, the selected references, and of course the automation built into THE DIGITAL DOOBLECHECK.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>Candidate input:</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>When the Case is created, an auto-mail is sent to the Candidate with a link to online registration of the References.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>Answering Reference-survey:</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Right after the Candidate’s listing of References, each of them will receive an automated invitation mail with a link to the online reference survey.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>{'4: Case Status & Reports:'}</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>After a reference survey has been completed an auto-mail with a link to the final report is sent to the team responsible for the specific recruitment for them to review online or print as part of the Case Status and Management.</p>
                            <p className={classes.marginTop}>{'For more information:  Detailed User Guide >Detailed User Guide<'}</p> {/* MAKE THIS A LINK! */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderDetailedUserGuide = () => {
        return (
            <div>
                <div id={sectionId.detailedUserGuide} className={classes.newSection} />
                <h1>Detailed User Guide</h1>
                <div>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK is a unique Online Reference Solution that ensures thorough, structured and unbiased reference retrieval of the selected job candidates. The solution has a number of built-in automations which, in addition to minimizing the resource consumption, ensures uniqueness and documentation of each individual reference.</p>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK offers online reference retrieval through a few simple steps. A new Reference Case can be started by either clicking the New Case action button or using the copy icon on an existing case or pre-designed company Template if such has been created.</p>
                    <p className={classes.marginTop}>The 4 steps in creating and handling a Case is:</p>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>1: Case Info</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Basic Case Info about job-position, deadlines and candidate info - name and email - as well as the choice of types of reference</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>2: Questions | Rating Points</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>{'Select the Questions & Answers and Rating Points that are relevant to the position and/or the individual candidate. There is an open choice among more than 150 predefined questions with associated answers - and if the crucial question is missing, it can be easily created directly in the solution and stored in the Company Question database for future use.'}</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>3: Preview</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Save the Case - and the rest is managed by the Candidate himself, the selected references, and of course the automation built into THE DIGITAL DOOBLECHECK.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>Candidate input:</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>When the Case is created, an auto-mail is sent to the Candidate with a link to online registration of the References.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>Answering Reference-survey:</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Right after the Candidate’s listing of References, each of them will receive an automated invitation mail with a link to the online reference survey.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>{'4: Case Status & Reports:'}</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>After a reference survey has been completed an auto-mail with a link to the final report is sent to the team responsible for the specific recruitment for them to review online or print as part of the Case Status and Management.</p>
                            <p className={classes.marginTop}>{'For more information:  Detailed User Guide >Detailed User Guide<'}</p> {/* MAKE THIS A LINK! */}
                        </div>
                    </div>
                    <p className={classes.marginTop}>Cases can be designed all at once or split over several times. Completed and saved cases will be listed in the Dashboard with the status Live while non-completed cases are listed as Draft.</p>
                </div>
            </div>
        );
    }

    const renderDetailedUserGuideTemplateOnly = () => {
        return (
            <div>
                <div id={sectionId.detailedUserGuide} className={classes.newSection} />
                <h1>Detailed User Guide</h1>
                <div>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK is a unique Online Reference Solution that ensures thorough, structured and unbiased reference retrieval of the selected job candidates. The solution has a number of built-in automations which, in addition to minimizing the resource consumption, ensures uniqueness and documentation of each individual reference.</p>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK offers online reference retrieval through a few simple steps. A new Reference Case can be started by either clicking the New Case action button or using the copy icon on an existing case or pre-designed company Template if such has been created.</p>
                    <p className={classes.marginTop}>The 3 steps in creating and handling a Case is:</p>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>1: Case Info</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Basic Case Info about job-position, deadlines and candidate info - name and email - as well as the choice of types of reference</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>2: Preview</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Save the Case - and the rest is managed by the Candidate himself, the selected references, and of course the automation built into THE DIGITAL DOOBLECHECK.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>Candidate input:</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>When the Case is created, an auto-mail is sent to the Candidate with a link to online registration of the References.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>Answering Reference-survey:</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>Right after the Candidate’s listing of References, each of them will receive an automated invitation mail with a link to the online reference survey.</p>
                        </div>
                    </div>
                    <div className={classes.subSection}>
                        <div className={classes.title}>
                            <p className={classes.marginTop}>{'3: Case Status & Reports:'}</p>
                        </div>
                        <div className={classes.text}>
                            <p className={classes.marginTop}>After a reference survey has been completed an auto-mail with a link to the final report is sent to the team responsible for the specific recruitment for them to review online or print as part of the Case Status and Management.</p>
                            <p className={classes.marginTop}>{'For more information:  Detailed User Guide >Detailed User Guide<'}</p> {/* MAKE THIS A LINK! */}
                        </div>
                    </div>
                    <p className={classes.marginTop}>Cases can be designed all at once or split over several times. Completed and saved cases will be listed in the Dashboard with the status Live while non-completed cases are listed as Draft.</p>
                </div>
            </div>
        );
    }

    const renderCaseInfo = () => {
        return (
            <div>
                <div id={sectionId.caseInfo} className={classes.newSection} />
                <h1>Case Info</h1>
                <div>
                    <p className={classes.marginTop}>First step when creating a Case is entering basic information and selecting your preferred settings for this specific Case.</p>
                    <p className={classes.underline}>Delete date</p>
                    <p className={classes.marginTop}>The Delete date is defaulted to 90 days or the number of days selected by the Account-Administrators at your company. However, you can change the delete date to your preference. Just keep in mind any guidelines of your company and the GDPR regulations.</p>
                    <p className={classes.marginTop}>By the delete date all Candidate and Reference related information is deleted – while the composition of the reference survey is kept. That way it is possible to make a copy of the case in the future if a similar job-position later is to be filled.</p>
                    <p className={classes.underline}>Job-position</p>
                    <p className={classes.marginTop}>Simply enter the Job-position in which the case is related. Note: Job-position data field is mandatory.</p>
                    <p className={classes.underline}>Deadline: Listing of Reference</p>
                    <p className={classes.marginTop}>This deadline defines the number of hours in which the Candidate is expected to list the various types of References decided. (See pts. 1.8.) The deadline is defaulted to 48 hours – counting from the point of time an invitation-mail is auto-sent to the Candidate. The deadline can be changed in intervals from 12 – 96 hours by use of a dropdown selector.</p>
                    <p className={classes.marginTop}>References can be listed all at once or separately, and listings can be corrected if information has been entered wrongly or the Candidate needs to replace a References with another. If however, the mail of a Reference is to be changed, it is necessary to delete the Reference entirely and list the Reference correctly once again.</p>
                    <p className={classes.marginTop}>Reminder mails will automatically be sent to the Candidate halfway and at the time of exceeding the deadline. Note that even exceeding the deadline, the link in the invitation mail will still be active and the Candidate will be able to list References until the Case is closed or deleted.</p>
                    <p className={classes.underline}>Deadline: Answering Online Reference</p>
                    <p className={classes.marginTop}>This deadline defines the number of hours in which the References is expected to answer the reference-survey. The deadline is defaulted to 48 hours – counting from the point of time an invitation-mail is auto-sent to the Reference. The deadline can be changed in intervals from 12 – 96 hours by use of a dropdown selector.</p>
                    <p className={classes.marginTop}>A reference can be completed in one go or a few visits if the References prefer. Answers will be saved as they are entered.</p>
                    <p className={classes.marginTop}>Reminder mails are automatically sent to the Reference halfway and at the time of exceeding the deadline. Note that even exceeding the deadline, the link in the invitation mail will still be active and a Reference will be able to answer questions until the survey is completed, the Case is closed or deleted.</p>
                    <p className={classes.underline}>Stakeholders</p>
                    <p className={classes.marginTop}>Invite colleagues who have a stake in the recruitment by entering their mail-address. Stakeholders must be registered users of THE DIGITAL DOOBLECHECK. When listed as a Stakeholder an invitation-mail is automatically sent including a link to the specific case. The Stakeholder in this particular Case will have full Case-Design-Access, even if the Stakeholder in general has a lower Template-Only-Access. Numbers of Stakeholders per case are unlimited.</p>
                    <p className={classes.underline}>Candidate</p>
                    <p className={classes.marginTop}>The Candidate name and mail is mandatory, while phone numbers are optional.</p>
                    <p className={classes.underline}>Select type of References</p>
                    <p className={classes.marginTop}>For each Case it is possible to select 3 different types of References – and for each selected Type of Reference it is possible to add unlimited numbers of References. When creating a new Case, the choice of “Manager”, “Employee” and “Team-colleague” is defaulted – but can easily be changed to more relevant types of References.</p>
                    <p className={classes.marginTop}>The selected types of References are included in an invitation-mail, auto-sent to the Candidate at completion of step 1. Only exception is type of Reference called “Self-selected”.</p>
                    <p className={classes.underline}>Self-selected References</p>
                    <p className={classes.marginTop}>When selecting a “Self-selected” type of Reference it is, as the title indicates, a Reference found and registered by the Case-owner and not by the Candidate. Therefore, a box with data field will appear for the Case-owner to enter information on each of the “Self-selected” References.</p>
                </div>
            </div>
        );
    }

    const renderQuestionsRatingPoints = () => {
        return (
            <div>
                <div id={sectionId.questionsRatingPoints} className={classes.newSection} />
                <h1>Questions | Rating Points</h1>
                <div>
                    <p className={classes.marginTop}>In Step 2 the actual reference-survey is designed according to the topics that needs to be clarified regarding a Candidate and/or job-position.</p>
                    <p className={classes.underline}>Select Theme</p>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK consists of more than 150 predefined Questions & Answers and Rating Points, all related to one of more than 10 themes. Using the dropdown selector, themes can be selected one by one or all together according to preferences.</p>
                    <p className={classes.underline}>Select Method</p>
                    <p className={classes.marginTop}>By the Select Method dropdown it is possible to choose to display either Questions or Rating Points related to a selected Theme. Of course, it is also possible to have both Questions and Rating Points displayed at the same time by selecting ALL.</p>
                    <p className={classes.underline}>Select languages</p>
                    <p className={classes.marginTop}>Choose the preferred language for the Questions & Answers and Rating Points. As all reference surveys are designed with dynamic links to the Questions & Answers and Rating Points databases, language can at any time be changed by all users, the Candidate or References.</p>
                    <p className={classes.underline}>Questions | Rating Points</p>
                    <p className={classes.marginTop}>A Question or Rating Point is included in the reference survey by clicking either the Select radio button or the radio button in one the columns of the selected Types of References.</p>
                    <p className={classes.underline}>View answers</p>
                    <p className={classes.marginTop}>To view the predefined answer scale and answers related to a question - simply expand each of the questions. When expanding a Rating Point a more detailed description of the two main points will appear.</p>
                    <p className={classes.underline}>Elaboration field</p>
                    <p className={classes.marginTop}>It is possible to add an Elaboration field for each of the selected Questions – except for Questions with an Open Answer scale.</p>
                    <p className={classes.underline}>Reference columns</p>
                    <p className={classes.marginTop}>For each selected type of Reference there is at column of radio buttons – which will automatically be highlighted when selecting a Question. If a Question is not relevant to one or more of the References, it is deactivated and excluded from the survey by clicking at the related radio button in the Reference column.</p>
                    <p className={classes.marginTop}>If a Question is only relevant for one of three References, the easiest way of selection is to click the related radio button in the Reference column.</p>
                    <p className={classes.underline}>Duration</p>
                    <p className={classes.marginTop}>Above each Reference column a Duration field indicates an estimated time for completing the survey based on the selected Questions and Rating Points for each of the type of References.</p>
                    <p className={classes.underline}>Create New Questions and New Rating Points</p>
                    <p className={classes.marginTop}>When necessary, a new question and related answers can easily be created and included in the reference survey. The procedure for creating a new question is:</p>
                    <ol>
                        <li>
                            <p  className={classes.marginTop}>Accepts or changes the Theme to which the Questions is related.</p>
                        </li>
                        <li>
                            <p  className={classes.marginTop}>Formulate the new question in both the local languages and English. (Note: if a language is shipped, the Question will be excluded if a Reference later choose to answer the survey in that specific language).</p>
                        </li>
                        <li>
                            <p  className={classes.marginTop}>Select the most suitable and preferred answer scales. Note: it is only possible to use existing answer scales.</p>
                        </li>
                        <li>
                            <p  className={classes.marginTop}>elect the most suitable set of predefined answers related to the selected answer scale - or define new answers, in both the local languages and English. (Note: if a language is shipped, the Question will be excluded if a Reference later choose to answer the survey in that specific language).</p>
                        </li>
                    </ol>
                    <p  className={classes.marginTop}>A new set of Rating Points can also be created by formulating both the Headline and the description of both Ration Points. These should also be created in both the local language and English (Note: if a language is shipped, the Question will be excluded if a Reference later choose to answer the survey in that specific language).</p>
                    <p  className={classes.marginTop}>New Questions, Answers and Rating Points are automatically added to the Case as well as a Company Question Database for future use.</p>
                </div>
            </div>
        );
    }

    const renderPreviewSave = () => {
        return (
            <div>
                <div id={sectionId.previewSave} className={classes.newSection} />
                <h1>Preview</h1>
                <div>
                    <p className={classes.marginTop}>Before saving a Case, it is possible to have a detailed preview of the final survey for each type of References. It is also possible to change the order of the selected Themes by using the Drag-N-Drop icon. (Note: Intro & Qualifying and Outro cannot be moved).</p>
                    <p className={classes.marginTop}>When expanding a selected Theme all selected Questions and Rating Points can be viewed - and deleted. When deleting Questions and Rating Points the Duration is updated for each of the References.</p>
                    <p className={classes.marginTop}>When everything is finalized – the Case is saved – and will appear as a live case in the Dashboard tables.</p>
                </div>
            </div>
        );
    }

    const renderCaseStatus = () => {
        return (
            <div>
                <div id={sectionId.caseStatus} className={classes.newSection} />
                <h1>Case Status</h1>
                <div>
                    <p className={classes.marginTop}>When Cases are saved, information on the Candidate is already listed. The data fields are locked but can be un-locked with one click on the padlock for updating of information. As a Case progresses information on each Reference will be listed as they are registered online by the Candidate – and can also be updated, if necessary, by un-locking the data fields.</p>
                    <p className={classes.underline}>Reference invitation</p>
                    <p className={classes.marginTop}>Immediately after the Candidates listing of References, each of them will receive an automated invitation mail with a link to the online reference-survey.</p>
                    <p className={classes.underline}>Reminder mails</p>
                    <p className={classes.marginTop}>If reminder mails are auto sent to either Candidates or References, a yellow dot (halfway) and a red dot (deadline exceeded) will be placed below the related person as well as part of the Case info on the Dashboard. If a Candidate or Reference has not responded accordingly to the sent reminder mails – they can be re-sent manually by clicking the action button.</p>
                    <p className={classes.underline}>Reference Reports</p>
                    <p className={classes.marginTop}>Reports can be viewed online or printed per Reference or as a combined report including the answers of all the references.</p>
                </div>
            </div>
        );
    }

    const renderAccount = () => {
        return (
            <div>
                <div id={sectionId.account} className={classes.newSection} />
                <h1>Account</h1>
                <div>
                </div>
            </div>
        );
    }

    const renderProfile = () => {
        return (
            <div>
                <div id={sectionId.profile} className={classes.newSection} />
                <h1>Profile</h1>
                <div>
                    <p className={classes.marginTop}>Account-Administrators can invite new users as well as manage existing users. Company info and default DIGITAL DOOBLECHECK settings are also managed here.</p>
                    <p className={classes.underline}>Invite New User</p>
                    <p className={classes.marginTop}>New users can be invited by using the envelope icon and entering their mail-address. A standard mail is automatically sent including a link for the new user to create their profile and login. Users are invited with one of the following access levels:</p>
                    <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-start'}}>
                        <img alt='' style={{ width: '100%' }} className="img" src={rolesImage} />
                    </div>
                </div>
            </div>
        );
    }

    const renderQAManager = () => {
        return (
            <div>
                <div id={sectionId.qaManager} className={classes.newSection} />
                <h1>Q&A Manager</h1>
                <div>
                    <p className={classes.marginTop}>The Q&A Manager allows the Account-Administrators to manage both THE DIGITAL DOOBLECHECK and company Questions and Rating Points. It is possible to hide Questions and Rating Points with no relevance for the Company and to default include an elaboration field to selected Questions. Questions and Rating Points are navigated by:</p>
                    <p className={classes.underline}>Select Theme</p>
                    <p className={classes.marginTop}>THE DIGITAL DOOBLECHECK consists of more than 150 predefined Questions & Answers and Rating Points, all related to more than 10 themes. Using the dropdown list, themes can be selected one by one or all together according to preferences.</p>
                    <p className={classes.underline}>Select Method</p>
                    <p className={classes.marginTop}>By the Select Method dropdown it possible to choose to display either Questions or Rating Points related to a selected Theme. Of course, it is also possible to have both Questions and Rating Points displayed at the same time by selecting ALL</p>
                    <p className={classes.underline}>Select Languages</p>
                    <p className={classes.marginTop}>Choose the preferred language for the Questions & Answers and Rating Points. As all reference-surveys are design with dynamic links to the Questions & Answers and Rating Points databases language can at any time be changes by all users, the Candidate or References.</p>
                    <p className={classes.underline}>Select Questions and Rating Points</p>
                    <p className={classes.marginTop}>A Question or Rating Point is included in the reference-survey by clicking either the Select radio button or the radio button in one the columns of the selected Types of References.</p>
                    <p className={classes.underline}>View answers</p>
                    <p className={classes.marginTop}>To view the predefined answer scale and answers related to a Question - simply expand each of the Question. When expanding a Rating Point a more detailed description of the two main points will appear.</p>
                    <p className={classes.underline}>Elaboration field</p>
                    <p className={classes.marginTop}>Adding an Elaboration field to a Question or Rating Point allows the Reference to include a more in-depth answer in their own words.</p>
                </div>
            </div>
        );
    }

    return (
        <Modal style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}} open={open}>
            <Box className={classes.container}>
                <div>
                    <div style={{ position: 'sticky', top: -1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: Colors.mainBlue, paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10 }}>
                        <div>
                            <GuideMenu open={openMenu} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose}>
                                <MenuItem onClick={() => handleNav(sectionId.dashboard)}>Dashboard</MenuItem>
                                <MenuItem onClick={() => handleNav(sectionId.quickGuide)}>Quick Guide</MenuItem>
                                <MenuItem onClick={() => handleNav(sectionId.detailedUserGuide)}>Detailed User Guide</MenuItem>
                                <MenuItem onClick={() => handleNav(sectionId.caseInfo)}>Case Info</MenuItem>
                                <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign']}>
                                    <MenuItem onClick={() => handleNav(sectionId.questionsRatingPoints)}>Questions | Rating Points</MenuItem>
                                </RoleContainer>
                                <MenuItem onClick={() => handleNav(sectionId.previewSave)}>Preview</MenuItem>
                                <MenuItem onClick={() => handleNav(sectionId.caseStatus)}>Case Status</MenuItem>
                                <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                                    <MenuItem onClick={() => handleNav(sectionId.account)}>Account</MenuItem>
                                </RoleContainer>
                                <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                                    <MenuItem onClick={() => handleNav(sectionId.profile)}>Profile</MenuItem>
                                </RoleContainer>
                                <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                                    <MenuItem onClick={() => handleNav(sectionId.qaManager)}>Q&A Manager</MenuItem>
                                </RoleContainer>
                            </GuideMenu>
                        </div>
                        <p style={{color: 'white', fontSize: 30, fontWeight: 'bold'}}>
                            Detailed User Guide
                        </p>
                        <Tooltip  title="Close">
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseIcon style={{ color: '#fff' }} />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <div className='scroll-container' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '5%'}}>
                        {renderDashboard()}
                        {renderQuickGuide()}
                        <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign']}>
                            {renderDetailedUserGuide()}
                        </RoleContainer>
                        <RoleContainer isGuide user={user} roles={['TemplateOnly']}>
                            {renderDetailedUserGuideTemplateOnly()}
                        </RoleContainer>
                        {renderCaseInfo()}
                        <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign']}>
                            {renderQuestionsRatingPoints()}
                        </RoleContainer>
                        {renderPreviewSave()}
                        {renderCaseStatus()}
                        <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                            {renderAccount()}
                        </RoleContainer>
                        <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                            {renderProfile()}
                        </RoleContainer>
                        <RoleContainer isGuide user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                            {renderQAManager()}
                        </RoleContainer>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

const useStyles = makeStyles({
    container: {
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        maxHeight: '80%', 
        overflowY: 'auto',
        backgroundColor: '#fff', 
        transform: 'translate(-50%, -50%)',
        border: '1px solid black',
        '@media (min-width: 1025px)': {
            width: '80%',
        },
        '@media (max-width: 1024px) and (min-width: 481px)': {
            width: '90%',
        },
        '@media (max-width: 480px)': {
            width: '100%',
        },
    },
    newSection: {
        paddingBottom: 70
    },
    subSection: {
        display: 'flex',
        flex: 1,
    },
    text: {
        flex: 5,
    },
    title: {
        flex: 2,
        marginRight: 50,
    },
    marginTop: {
        marginTop: 20,
        lineHeight: 1.5,
    },
    underline: {
        marginTop: 20,
        textDecoration: 'underline'
    },
})

export default Guide
