import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles';
import CustomMenuList from './CustomMenuList';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { referenceTypes, } from '../utils/constansts';
import { Colors } from '../styles/globalStyles';
import { setReferences } from '../redux/slices/createCaseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import SelfSelectedRefModal from './SelfSelectedRefModal';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash'
import CustomAlertDialog from './CustomAlertDialog';

function DCReference({ title, subTitle, references, errors, isTemplate }) {
    const [show, setShow] = useState();
    const [showSelfSelected, setShowSelfSelected] = useState();
    const [selfSelectedIndex, setSelfSelectedIndex] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState();

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;
    const classes = useStyles({ color: branding?.colors + '!important' });
    const { t } = useTranslation();

    const handleDeleteReference = (reference, index) => {
        setShowDelete(true)
        setDeleteIndex(index)
    }

    const deleteReference = (delIndex) => {
        if (delIndex) {
            dispatch(setReferences(references.filter((reference, index) => index !== delIndex)))
        } else {
            dispatch(setReferences(references.filter((reference, index) => index !== deleteIndex)))
        }
    }

    const addReference = (type) => {
        const referenceTypes = new Set([...references.map((ref) => ref.type)])

        if (Array.from(referenceTypes).length >= 3 && !referenceTypes.has(type)) {
            return dispatch(triggerFlashMessage({ type: 'error', title: t("You can only add 3 types of Reference."), description: t("However, feel free to add more than one of each selected type of Reference.") }))
        }

        if (references.length >= 8) {
            return dispatch(triggerFlashMessage({ type: 'error', title: t("You can only add a maximum of 8 References.") }))
        }

        if (checkIfSelfSelected(type)) {
            setSelfSelectedIndex(references.length + 1)
            setShowSelfSelected(true);
        }

        const foundReference = references?.find(ref => ref?.type === type)

        if (foundReference) {
            dispatch(setReferences([...references, {
                _id: references.length + 1, type, questions: foundReference?.questions?.map(q => ({
                    ...q,
                    answer: null
                }))
            }]))
        } else {
            var allSelectedQuestions = []
            references?.forEach(ref => {
                const clonedRef = _.cloneDeep(ref)
                const questions = clonedRef?.questions?.map(q => ({
                    ...q,
                    answer: null,
                }))
                if (questions !== null && questions !== undefined) {
                    allSelectedQuestions = allSelectedQuestions?.concat(questions)
                }
            })
            if (allSelectedQuestions?.length > 0) {
                const uniqueReferences = [
                    ...new Set(allSelectedQuestions?.map((o) => JSON.stringify(o))),
                ]?.map((string) => JSON.parse(string))
                dispatch(setReferences([...references, { _id: references.length + 1, type, questions: Array.from(uniqueReferences) }]))
            } else {
                dispatch(setReferences([...references, { _id: references.length + 1, type, questions: [] }]))
            }
        }
    }

    const checkIfSelfSelected = (type) => {
        if (type === 'Self-selected' && !isTemplate) {
            return true;
        }
        return false;
    }

    const handleEditReference = (index) => {
        setSelfSelectedIndex(index + 1)
        setShowSelfSelected(true)
    }

    return (
        <div className={classes.container}>
            {!isTemplate && <SelfSelectedRefModal
                index={selfSelectedIndex}
                open={showSelfSelected}
                handleClose={() => setShowSelfSelected(false)}
            />}

            <CustomAlertDialog
                alertTitle="You are about the delete this Reference."
                alertText="All information regarding the reference will be lost."
                okText="Delete"
                open={showDelete}
                handleClose={() => setShowDelete(false)}
                handleCancel={() => setShowDelete(false)}
                handleAccept={() => deleteReference()}
            />

            <p className={classes.referenceText}>{t(title)}</p>
            <p className={classes.subTitle}>{t(subTitle)}</p>
            {references?.map((reference, index) => {
                return (
                    <div key={reference?._id} className={classes.referenceContainer}>
                        <p>{t(reference?.type)}</p>
                        <div className={classes.icons}>
                            <DeleteIcon onClick={() => handleDeleteReference(reference, index)} fontSize="small" />
                        </div>
                        {checkIfSelfSelected(reference?.type) && !isTemplate && <EditIcon onClick={() => handleEditReference(index)} fontSize="small" style={{ position: 'absolute', right: 20, cursor: 'pointer' }} />}
                    </div>
                )
            })}

            <div onClick={() => setShow(!show)} className={classes.addButtonContainer}>
                <CustomMenuList iconButton={true} onSelect={(e) => addReference(e)} elements={referenceTypes} />
                <p className={classes.errorText}>{errors?.references && t('Please choose atleast 1 reference')}</p>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        marginTop: 50
    },
    referenceText: {
        marginBottom: 2,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500
    },
    subTitle: {
        marginBottom: '10px',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 500
    },
    icons: {
        position: 'absolute',
        left: 20,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: 50
    },
    referenceContainer: {
        height: 50,
        backgroundColor: props => props.color ? props.color : `${Colors.mainBlue} !important`,
        color: '#fff',
        marginTop: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    addButtonContainer: {
        marginTop: 10
    },
    errorText: {
        textAlign: 'center',
        color: 'red',
        fontSize: 14,
        marginTop: 10
    },
})

export default DCReference
