import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, roles, auth, ...rest }) => {
	const isAuthenticated = auth.authenticated ? true : false
	return (
		<Route
			{...rest}
			render={props => {
				return isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ 
							pathname: '/', 
							state: { 
								prevPath: props?.location?.pathname + props?.location?.search, 
								roles: roles ? roles : ['SuperAdmin', 'AccountAdmin', 'CaseDesign', 'TemplateOnly']
							} 
						}}
					/>
				)
			}}
		/>
	)
}

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
