import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authenticated: false,
        user: {},
        loading: false,
        errors: [],
    },
    reducers: {
        loginUser: (state, action) => {
            state.authenticated = true;
            state.user = action.payload;
        },
        logoutUser: state => {
            state.authenticated = false;
            state.user = {};
        },
        setLanguage: (state, action) => {
            state.user.company.settings.defaultLanguage = action.payload;
        },
        setQALanguage: (state, action) => {
            state.user.company.settings.defaultQALanguage = action.payload;
        },
        setDefaultDeleteDate: (state, action) => {
            state.user.company.defaultDeleteDate = action.payload;
        },
        updateOwnCompanyInfo: (state, action) => {
            state.user.company = action.payload;
        },
        visitorUser: (state, action) => {
            state.user = action.payload;
        },
    },
});

export const {
    loginUser,
    logoutUser,
    setLanguage,
    visitorUser,
    setQALanguage,
    setDefaultDeleteDate,
    updateOwnCompanyInfo,
} = authSlice.actions;

export default authSlice.reducer;
