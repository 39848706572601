import React from 'react';
import { makeStyles } from "@material-ui/styles";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Colors } from '../styles/globalStyles';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../redux/slices/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Icons
import {ReactComponent as DashboardIcon} from "../assets/images/DashboardIcon.svg";
import {ReactComponent as AccountIcon} from "../assets/images/AccountIcon.svg";
import {ReactComponent as SupportIcon} from "../assets/images/SupportIcon.svg";
import {ReactComponent as GuideIcon} from "../assets/images/GuideIcon.svg";
import {ReactComponent as ContactIcon} from "../assets/images/ContactIcon.svg";
import {ReactComponent as LogoutIcon} from "../assets/images/LogoutIcon.svg";

export default function Sidebar({ handleOpenGuide }) {
    const [open, setOpen] = useState();

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(open);
    };

    const handlePageChange = (isLogout) => {
        if(isLogout) {
            localStorage.removeItem('dooblecheck_token');
            dispatch(logoutUser())
        }
        setOpen(false)
    }

    return (
        <div>
             <Button onClick={toggleDrawer(true)}>
                 <MenuIcon />
             </Button>
            <Drawer className={classes.drawer}  anchor={'left'} open={open} onClose={toggleDrawer(false)}> 
                <div onClick={() => toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <div style={{ height: 50, background: branding?.colors ? branding?.colors : Colors.mainBlue, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 20 }}>
                        {/* <CloseIcon style={{ color: '#fff'}} /> */}
                    </div>
                    <List style={{ padding: 0, paddingTop: 20}}>
                        <Divider />
                        <Link to="/dashboard" onClick={() => handlePageChange(false)}>
                            <ListItem button key={'dashboard'}>
                                <ListItemIcon>
                                    <DashboardIcon className={classes.itemLogo} fill={branding?.colors ? branding?.colors : Colors.mainBlue} stroke='white' />
                                </ListItemIcon>
                                <ListItemText primary={t('Dashboard')} />
                            </ListItem>
                        </Link> 
                        <Divider />
                        <Link to="/account" onClick={() => handlePageChange(false)}>
                            <ListItem button key={'account'}>
                                <ListItemIcon>
                                    <AccountIcon className={classes.itemLogo} fill={branding?.colors ? branding?.colors : Colors.mainBlue} stroke='white' />
                                </ListItemIcon>
                                <ListItemText primary={t('Account')} />
                            </ListItem>
                        </Link>
                        <Divider />

                        <Divider style={{ marginTop: 50}} />
                        
                        <Link to="/support" onClick={() => handlePageChange(false)}>
                            <ListItem button key={'support'}>
                                <ListItemIcon>
                                    <SupportIcon className={classes.itemLogo} fill={branding?.colors ? branding?.colors : Colors.mainBlue} stroke='white' />
                                </ListItemIcon>
                                <ListItemText primary={t('Support')} />
                            </ListItem>
                        </Link>
                        <Divider />
                        <div onClick={handleOpenGuide}>
                            <ListItem button key={'guide'}>
                                <ListItemIcon>
                                    <GuideIcon className={classes.itemLogo} fill={branding?.colors ? branding?.colors : Colors.mainBlue} stroke='white' />
                                </ListItemIcon>
                                <ListItemText primary={t('Guide')} />
                            </ListItem>
                        </div>
                        <Divider />
                        <Link to="/contact" onClick={() => handlePageChange(false)}>
                            <ListItem button key={'contact'}>
                                <ListItemIcon>
                                    <ContactIcon className={classes.itemLogo} fill={branding?.colors ? branding?.colors : Colors.mainBlue} stroke='white' />
                                </ListItemIcon>
                                <ListItemText primary={t('Contact')} />
                            </ListItem>
                        </Link> 
                    </List>
                        <Link to="" style={{ position: 'absolute', bottom: 20, width: '100%'}} onClick={() => handlePageChange(true)}>
                            <Divider />
                            <ListItem  button key={'logout'}>
                                <ListItemIcon>
                                    <LogoutIcon className={classes.itemLogo} fill={branding?.colors ? branding?.colors : Colors.mainBlue} stroke='white' />
                                </ListItemIcon>
                                <ListItemText primary={t('Logout')} />
                            </ListItem>
                            <Divider />
                        </Link>
                    <Divider />
                </div>
            </Drawer>
        </div>
    );
}

const useStyles = makeStyles({
    drawer: {
        '& .MuiPaper-root': {
            width: '30%',
            '@media (max-width: 767px)': {
                width: '70%'
            }
        },
    },
    itemLogo: {
        height: 40,
    },
});
