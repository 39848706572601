import React, { useState } from 'react'
import { ListItem, Collapse, List, Tooltip } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Colors } from '../styles/globalStyles';

export default function DCList({ style, className, leftIcon, title, children, expandIcon, parentShow, setParentShow, useParentShow, customHeader, customBadge, tooltipTitle, hasUnsavedChanges, }) {
    const [show, setShow] = useState(false)
    const classes = useStyles();
    
    return (
        <React.Fragment>
            <Tooltip style={{ fontSize: 15 }} title={tooltipTitle && hasUnsavedChanges ?  <p style={{ fontSize: 14 }}>{tooltipTitle}</p> : ''}>
                {customHeader ? customHeader(title, show, setShow) :
                    <ListItem style={style} className={className ? className : classes.button} onClick={() => useParentShow ? setParentShow(!parentShow) : setShow(!show)}>
                        <div className={classes.leftIcon}>
                            {leftIcon ? leftIcon() : null}
                        </div>
                        <p>{title}</p>
                        {useParentShow 
                            ? expandIcon ? parentShow ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} /> : null
                            : expandIcon ? show ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} /> : null
                        }
                        {customBadge ? customBadge() : null}
                    </ListItem>
                }
            </Tooltip>
            <Collapse style={{ background: '#fff' }} in={useParentShow ? parentShow : show} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </React.Fragment>
       
    )
}

const useStyles = makeStyles({
    button: {
        cursor: 'pointer',
        minHeight: 45,
        '&:hover': {
            zIndex: 2,
            outline: `2px solid ${Colors.mainBlue}`,
        }
    },
    icon: {
        position: 'absolute',
        right: 20
    },
    leftIcon: {
        position: 'absolute',
        left: 20,
        fontSize: 12,
        transform: 'translateY(-50%)',
        top: '50%'
    }
})