import React from 'react'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../styles/globalStyles';
import { useSelector } from 'react-redux'

const SubNavigationHeader = ({ items, onClick, activePage }) => {
    const { t } = useTranslation();
    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;
    const classes = useStyles({color: '1px solid' + branding?.colors + '!important'});

    return (
        <div className={classes.container}>
            <ul className={classes.list}>
                {items?.map(item => {
                    return (
                        <li className={classes.item} key={item} onClick={() => onClick(item)}>
                            <p className={item === activePage ? classes.selected : classes.default}>{t(item)}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default SubNavigationHeader;

const useStyles = makeStyles({
    container: {
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
    },
    list: {
        listStyle: 'none',
    },
    item: {
        display: 'inline',
        marginRight: 20,
        cursor: 'pointer',
        minHeight: '50px',  
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 20,
        borderRight: props => props.color ? props.color : `1px solid ${Colors.mainBlue}`,

        '&:last-child': {
            borderRight: 'none !important',
        },
    },
    selected: {
        fontWeight: 'bold',
        display: 'inline',
    },
    default: {
        display: 'inline',
    }
})
