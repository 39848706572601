import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LanguageChange from '../../components/LanguageChange'
import moment from 'moment';
import Countdown from 'react-countdown';
import _ from 'lodash'
import Reference from './Reference';

import { makeStyles } from '@material-ui/styles';

import { CASE_BY_ID, UPDATE_DRAFT_CASE, UPDATE_LIVE_CASE } from '../../graphql/mutations';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/globalStyles';
import LoadingBtn from '../../components/LoadingButton';
import PrivacyPolicy from '../general/PrivacyPolicy';
import CustomMenuList from '../../components/CustomMenuList';
import { setLanguage } from '../../redux/slices/globalSlice';
import CustomAlertDialog from '../../components/CustomAlertDialog';
import { visitorUser } from '../../redux/slices/authSlice';
import { CircularProgress } from '@material-ui/core';
import DCLoader from '../../components/DCLoader';

function CandidateInvite({ match }) {
    // States
    const [next, setNext] = useState(false)
    const [references, setReferences] = useState([]);
    const [currentCaseCopy, setCurrentCaseCopy] = useState([]);
    const [currentCase, setCurrentCase] = useState();
    const [isAccepted, setIsAccepted] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteReference, setDeleteReference] = useState();
    const [showReferences, setShowReferences] = useState();
    const [firstLoad, setFirstLoad] = useState(true);
    

    // Mutations
    const [updateLiveCase] = useMutation(UPDATE_LIVE_CASE);
    const [updateDraftCase] = useMutation(UPDATE_DRAFT_CASE);

    // Queries
    const { data, refetch, loading } = useQuery(CASE_BY_ID, { variables: { id: match?.params?.id } })

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const classes = useStyles();

    const getReferenceTypes = () => {
        const uniqueTypes = [...new Set(currentCase?.references?.map(ref => ref.type))];
        const filtered = uniqueTypes.filter(type => type !== 'Self-selected')
        return filtered;
    }

    const handleAddReference = (e) => {
        const currentRefType = currentCase?.references?.find(ref => ref?.type === e);
        const currentRefQuestions = currentRefType?.questions?.map(q => ({ question: q?.question }))
        const newCase = {
            ...currentCase,
            references: currentCase?.references?.concat({ type: e, questions: currentRefQuestions })
        }
        
        updateLiveCase({ variables: { input: {  _id: newCase?._id, references: newCase?.references} } }).then(res => {
            refetch();
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        })
    }

    useEffect(() => {
        const comp = _.cloneDeep(data?.case?.company)
        dispatch(visitorUser({
            _id: '',
            firstName: '',
            lastName: '',
            email: '',
            company: comp,
        }))
    }, [data])

    useEffect(() => {
        let clonedCase = _.cloneDeep(data?.case)
        clonedCase = {
            ...clonedCase,
            references: clonedCase?.references?.map(ref => ({
                ...ref,
                language: 
                    (!ref?.listedDate && !ref?.email)
                    ? clonedCase?.candidate?.preferedLanguage
                    : ref?.language
            }))
        }
        setCurrentCase(clonedCase)
        setCurrentCaseCopy(clonedCase)  
        setReferences(currentCase?.references)
        setShowReferences(new Array(data?.case?.references?.length).fill(false))
        data?.case?.candidate?.consentGiven ? setIsAccepted(data?.case?.candidate?.consentGiven) : setIsAccepted(false);
        if(data?.case && firstLoad){
            const language = data?.case?.candidate?.preferedLanguage ? data?.case?.candidate?.preferedLanguage === 'en' ? {shortName: 'en', fullName: 'English'} : {shortName: 'da', fullName: 'Danish'} : data?.case?.company?.settings?.languages[data?.case?.company?.settings?.defaultQALanguage];
            dispatch(setLanguage(language))
            i18n.changeLanguage(language?.shortName)
            const comp = _.cloneDeep(data?.case?.company)
            dispatch(visitorUser({
                _id: '',
                firstName: '',
                lastName: '',
                email: '',
                company: comp,
            }))
            setFirstLoad(false)
        }
    }, [data]);

    const handleNext = async () => {
        if (isAccepted) {
            try {
                const caseInput = {
                    _id: currentCase._id,
                    candidate: { ...currentCase?.candidate, consentGiven: true },
                }
                if(currentCase.status === 'draft') {
                    await updateDraftCase({ variables: { input: caseInput } })
                } else if(currentCase.status === 'live') {
                    await updateLiveCase({ variables: { input: caseInput } })
                }
                setNext(!next)
                setIsAccepted(!isAccepted)
                refetch();
            } catch (err) {
                dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
            }
        }
    };

    const Completionist = () => <span>Expired</span>;

    const handleDeleteReference = (reference) => {
        setShowDelete(true)
        setDeleteReference(reference)
    }

    const handleDeleteReference2 = () => {
        const newRef = _.pick(deleteReference, ['questions', 'type'])
        newRef.questions = newRef?.questions?.map(question => ({ question: question?.question }))

        const foundIndex = currentCase?.references.findIndex(ref => ref?._id === deleteReference?._id);
        const newReferences = [...currentCase?.references];
        newReferences[foundIndex] = newRef;

        updateLiveCase({ variables: { input: {  _id: currentCase?._id, references: newReferences } } }).then(res => {
            refetch();
            handleClearShow()
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: t("Something went wrong, please try again") }))
        })
    }

    const handleReferences = (e, refId) => {
        const newReferences = currentCase?.references?.map(x =>  x._id === refId ? {...x, [e.target.name]: e.target.value } : x)
        setCurrentCase({
            ...currentCase,
            references: newReferences
        })
    }

    const handleShowReference = (index) => {
        let newArr = [];
        const hasOpenIndex = showReferences.indexOf(true)
        if(_.isEqual(currentCase, currentCaseCopy)){
            if(hasOpenIndex === -1) {
                newArr = showReferences?.map((val, i) => {
                    if(i === index) {
                        return !val
                    }
                    return val
                })
            } else if(hasOpenIndex !== -1) {
                newArr = showReferences?.map((val, i) => {
                    if(i === index) {
                        return !val
                    }
                    return false
                })
            }
        } else {
            const currentRef = _.cloneDeep(currentCase?.references[index]);
            const currentRefCopy = _.cloneDeep(currentCaseCopy?.references[index]);
            
            newArr = showReferences?.map((val, i) => {
                if(i === index && !_.isEqual(currentRef, currentRefCopy)) {
                    return !val
                }
                return val
            })
        }
        setShowReferences(newArr)
    }

    const handleClearShow = () => {
        setShowReferences(references?.map(val => false))
    }

    useEffect(() => {
        const listingDate = currentCase?.candidate?.listingMailDate?.find(ld => ld !== null)
        const deadlineAddTime = currentCase?.deadline?.listing
        const deadlineTime = moment(parseInt(listingDate)).add(deadlineAddTime, 'hours');
        if(0 > deadlineTime.diff(moment(), 'minutes')){
            if(document.getElementById('CandidateDeadlineContainer')) document.getElementById('CandidateDeadlineContainer')?.remove();
        } else if(!deadlineTime?.isValid()) {
            if(document.getElementById('CandidateDeadlineContainer')) document.getElementById('CandidateDeadlineContainer').style.display = 'none'
        } else {
            if(document.getElementById('CandidateDeadlineContainer')) document.getElementById('CandidateDeadlineContainer').style.display = 'flex'
        }
    }, [currentCase])

    if(loading) {
        return <DCLoader />
    }

    return (
        <div className={classes.Container}>
            <PrivacyPolicy open={showPrivacyPolicy} setOpen={(e) => setShowPrivacyPolicy(e)} />
            <CustomAlertDialog handleAccept={() => handleDeleteReference2()} handleCancel={() => setShowDelete(false)} handleClose={() => setShowDelete(false)} alertTitle="You are about the delete this Reference." alertText="All information regarding the reference will be lost." open={showDelete} okText="Delete" />
            {loading && !currentCase && !currentCase?.references &&
                <div className={classes.spinnerContainer}>
                    <CircularProgress style={{width: 100, height: 100, }} className={classes.loadingSpinner} />
                </div>
            }
            {currentCase && currentCase?.references && currentCase?.status !== "closed" && !loading &&
                <div className={classes.CandidateInviteContainer}>
                    <div className={classes.topContainer}>
                        <div className={classes.languageContainer}>
                            <LanguageChange company={currentCase?.company} />   
                        </div>
                        <div id='CandidateDeadlineContainer' className={classes.DeadlineContainer}>
                            <div>
                                <LoadingBtn
                                    disabled
                                    style={{minWidth: 150}}
                                >
                                    <p className={classes.DeadlineTitle}>{t("DEADLINE")}</p>
                                </LoadingBtn>
                            </div>
                            <div className={classes.DeadlineTimerContainer}>
                                <Countdown
                                    daysInHours={true}
                                    date={moment(parseInt(data?.case?.candidate?.listingMailDate?.find(d => d !== null))).add(data?.case?.deadline?.listing, 'hours')}
                                >
                                    <Completionist />
                                </Countdown>
                            </div>
                        </div>
                    </div>
                    <div className={classes.ContentContainer}>
                        {!next ?
                            <div className={classes.TitleContainer}>
                                <div className={classes.Title}>
                                    {t("Registration of References")}
                                </div>
                                <div className={classes.TextContainer}>
                                    <div className={classes.Text}>
                                        {t('Dear {{candidateName}}', { candidateName: `${currentCase?.candidate?.firstName} ${currentCase?.candidate?.lastName}` })}
                                    </div>
                                    <div className={classes.Text}>
                                        {t("We are pleased to proceed with your candidature for the position as {{jobPosition}} and would like you to register your references by the deadline indicated. If you have any problems meeting the deadline, please let us know by telephone.", { jobPosition: currentCase?.jobPosition })}
                                    </div>
                                    <div className={classes.Text}>
                                        {t("All information is stored and processed in accordance with applicable legislation and GDPR regulation. For more information read here ")} <p onClick={() => setShowPrivacyPolicy(true)} style={{ color: '#1700ff', display: 'inline', cursor: 'pointer'}}>{t("Privacy Policy")}</p>
                                    </div>
                                    <div className={classes.AcceptContainer}>
                                        <FormControl component="fieldset" className={classes.LanguageSelectorRadio} >
                                            <FormControlLabel onClick={() => setIsAccepted(!isAccepted)} value="accept" className={classes.radioButtonText} control={<Radio style={{ color: Colors.mainBlue }} checked={isAccepted} />} label={t("I accept the above terms and conditions.")} />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <LoadingBtn style={{ backgroundColor: Colors.mainBlue, padding: '5px 50px'}} variant="contained" onClick={handleNext}>{t("Next")}</LoadingBtn> 
                                </div>
                            </div>
                            :
                            <div className={classes.TitleContainer}>
                                <div className={classes.Title}>
                                    {t("Registration of References")}
                                </div>
                                <div className={classes.TextContainer}>
                                    <div className={classes.Text}>
                                        {t("Register your references according to the types of references listed below. You can register them together or separately. An invitation email for each reference will be sent by our partner DOOBLECHECK immediately after registration.")}
                                    </div>
                                    <div className={classes.Text}>
                                        {t("Please make sure to obtain your references' acceptance to participate as well as their consent to register their information. Lastly indicate each reference's preferred language.")}
                                    </div>
                                </div>
                                <div className={classes.ReferencesContainer}>
                                    {currentCase?.references?.filter(ref => ref?.type !== 'Self-selected')?.map((reference, index) => {
                                        return (
                                            <Reference
                                                clearShow={handleClearShow}
                                                show={showReferences && showReferences[index]}
                                                setShow={() => handleShowReference(index)}
                                                key={reference?._id}
                                                handleDelete={(e) => handleDeleteReference(e)}
                                                refetchCase={refetch}
                                                onReferenceDelete={(e) => handleDeleteReference(e)}
                                                reference={reference}
                                                references={currentCase?.references}
                                                setReferences={handleReferences}
                                                caseId={currentCase._id}
                                                company={currentCase?.company}
                                                hasUnsavedChanges={!_.isEqual(currentCase, currentCaseCopy)}
                                            />
                                        )
                                    })}
                                </div>
                                <div style={{ alignSelf: 'center', paddingTop: 20}} onClick={() => console.log('clicked')}>
                                    <CustomMenuList iconButton={true} onSelect={(e) => handleAddReference(e)} elements={getReferenceTypes()} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {(!currentCase || !currentCase?.references  || currentCase?.status === "closed") && !loading && 
                <h3 style={{paddingTop: 100}}>Sorry for any inconvenience – but the case has been closed and your registration is no longer needed</h3>
            }
        </div>
    )
}

const useStyles = makeStyles({
    Container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        '& .MuiCircularProgress-root': {
            color: Colors.mainBlue,
        },
        '@media (max-width: 1025px)': {
           zoom: 0.5,
        },
    },
    CandidateInviteContainer: {
        width: '70%',
        position: 'relative',
        '@media (max-width: 1025px)': {
            width: '96%',
        },
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: 15,
        alignItems: 'flex-start',
        '@media (max-width: 480px)': {
            paddingTop: 0,
            zoom: 0.7,
        },
    },
    languageContainer: {
        marginBottom: '45px',
        '@media (max-width: 480px)': {
            marginBottom: 20,
        },
    },
    DeadlineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '10px',
        justifyContent: 'center',
    },
    DeadlineTitle: {
        color: 'white',
        fontWeight: 'bold',
    },
    DeadlineTimerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        width: '10%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ContentContainer: {
        borderLeft: '1px dashed black',
        borderRight: '1px dashed black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '50px 70px',
        position: 'relative',
        '@media (max-width: 1025px)': {
            border: 'none',
            padding: '0px',
            paddingBottom: '30px',
        },
    },
    TitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    Title: {
        fontWeight: 'bold',
        fontSize: '28px',
        '@media (max-width: 1025px)': {
            fontSize: '18px',
        },
    },
    TextContainer: {
        marginLeft: '50px',
        marginTop: '30px',
        lineHeight: 1.6,
        '@media (max-width: 1025px)': {
            fontSize: '15px',
        },
        '@media (max-width: 480px)': {
            marginLeft: '0px',
        },
    },
    Text: {
        paddingBottom: '30px',
    },
    ButtonContainer: {
        marginTop: '100px',
        display: 'flex',
        alignSelf: 'center',
        '@media (max-width: 1025px)': {
            marginTop: '30px',
        },
    },
    ReferencesContainer: {
        alignSelf: 'center',
        width: '70%',
        '@media (max-width: 1025px)': {
            width: '100%',
        },
    },
    LanguageSelectorRadio: {
        display: 'flex',
        justifyContent: 'space-between !important',
    },
    radioButtonText: {
        '@media (max-width: 1025px)': {
            '& .MuiTypography-root': {
                fontSize: 15,
            },
        },
    },
});

export default CandidateInvite
