import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

export default function CustomAlertDialog({ open = false, handleClose, handleAccept, alertText, alertTitle, id, okText, cancelText, handleCancel }) {
	const classes = useStyles();
	return (
		<div>
			<Dialog open={open} onClose={handleCancel}>
				<div className={classes.titleContainer}>
					<DialogTitle id="alert-dialog-title">
						{alertTitle}
					</DialogTitle>
					<div className={classes.closeIconContainer}>
						<CloseIcon  onClick={handleCancel}/>
					</div>
				</div>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{alertText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { handleAccept(id); handleClose(); }} autoFocus>
						{okText ? okText : "Agree"}
					</Button>
					<Button onClick={handleClose}>{cancelText ? cancelText : "Cancel"}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const useStyles = makeStyles(() => {
	return {
		titleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		closeIconContainer: {
			padding: 15,
			cursor: 'pointer',
		},
	}
});
