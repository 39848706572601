import { gql } from '@apollo/client'


export const GET_COMPANIES = gql`
    query companies {
    companies{
        _id
        name
        address
        address2
        zipCode
        city
        country
        phone
        defaultLanguage
        defaultDatebase
        defaultDeleteDate
        EAN
        branding {
            useDefault
            logo {
                name
                base64
            } 
            colors
        } 
        settings {
            languages {
                shortName
                fullName
                englishName
            }
            defaultLanguage
            defaultQALanguage
        }
    }
}
`

export const GET_TEXTEDITORS = gql`
    query textEditors($companyId: String!) {
        textEditors(companyId: $companyId) {
            _id,
            name,
            type,
            translations {
                _id,
                language,
                text
            }
        }
    }
`


export const GET_USERS_BY_COMPANY = gql`
    query usersByCompany($companyId: String) {
        usersByCompany(companyId: $companyId){
            _id,
            firstName,
            lastName,
            email
        }
    }
`