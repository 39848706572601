import * as React from 'react';
import Menu from '@mui/material/Menu';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

export default function GuideMenu({open = false, anchorEl, handleClick, handleClose, children}) {
    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon style={{color: 'white'}} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {children}
            </Menu>
        </div>
    );
}
