import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import LoadingBtn from './LoadingButton';
import { Colors } from '../styles/globalStyles';
import { useTranslation } from 'react-i18next';
import { validateReference } from '../utils/validators';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { setReferences } from '../redux/slices/createCaseSlice';

const useStyles = makeStyles({
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        padding: '40px 60px',
        boxShadow: 24,
        p: 4,
    },
    languageContainer: {
        display: 'flex',
        paddingBottom: 30,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

const box = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    padding: '40px 60px',
    boxShadow: 24,
    p: 4,
}

export default function SelfSelectedRefModal({ open = false, handleClose, index }) {
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState()
    const [isSubmitted, setIsSubmitted] = useState()
    const [selfSelectedRef, setSelfSelectedRef] = useState();

    const languagesSupported = [{ shortName: 'da', fullName: 'Danish'}, { shortName: 'en', fullName: 'English'}];
    const classes = useStyles();

    const caseState = useSelector(state => state.createCase);
    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    
    const {t} = useTranslation()
    const dispatch = useDispatch()
    
    const handleChange = (e) => {
        setSelfSelectedRef({
            ...selfSelectedRef,
            [e.target.name]: e.target.value
        })
    }

    const handleLanguage = (e) => {
        setSelfSelectedRef({
            ...selfSelectedRef,
            language: e.target.name
        })
    }

    const handleRegister = async () => {
        setLoading(true)
        let submitErrors = validateReference(selfSelectedRef.firstName, selfSelectedRef.lastName, selfSelectedRef.email);
        if(!_.isEmpty(submitErrors)) {
            setErrors(submitErrors);
            setIsSubmitted(true);
            dispatch(triggerFlashMessage({ type: "error", title: t("Please make sure all the info is filled out correctly") }))
            setLoading(false)
            return;
        }

        const refIndex = caseState?.case?.references.findIndex(((obj, i) => i === index - 1));

        const references = [...caseState?.case?.references];
        references[refIndex] = {
            ...references[refIndex],
            firstName: selfSelectedRef.firstName,
            lastName: selfSelectedRef.lastName,
            email: selfSelectedRef.email,
            phone: selfSelectedRef.phone,
            mobile: selfSelectedRef.mobile,
            linkedIn: selfSelectedRef.linkedIn,
            language: selfSelectedRef?.language
        }

        dispatch(setReferences(references))
        setLoading(false)
        handleClose()
        setSelfSelectedRef({})
    };

    const isChecked = (language) => {
        if(selfSelectedRef?.language) {
            return selfSelectedRef?.language === language.shortName;
        } else {
            return language?.shortName === company?.settings?.languages[company?.settings?.defaultQALanguage]?.shortName
        }
    }

    const isEmpty = () => {
        if(index) {
            const subset = _.pick(caseState?.case?.references[index - 1], ['firstName', 'lastName', 'email', 'linkedIn', 'mobile', 'phone']);
            return _.isEmpty(subset)
        }
    }

    useEffect(() => {
        let ref = caseState?.case?.references?.find((obj, i) => i === index - 1);
        if(ref && !ref?.language) {
            setSelfSelectedRef({
                ...ref,
                language: company?.settings?.languages[company?.settings?.defaultQALanguage]?.shortName
            })
        } else {
            setSelfSelectedRef(ref)
        }
    }, [open, index])

    useEffect(() => {
        isSubmitted && setErrors(validateReference(selfSelectedRef.firstName, selfSelectedRef.lastName, selfSelectedRef.email));
    }, [selfSelectedRef])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={box}>
                        <div>
                            <p style={{paddingBottom: 20}}>
                                Self-selected
                            </p>
                            <TextField
                                style={{paddingBottom: 20}}
                                name="firstName"
                                label="First name"
                                fullWidth
                                value={selfSelectedRef?.firstName ?? ''}
                                onChange= {(e) => handleChange(e)}
                                className={classes.input}
                                error={errors?.firstName}
                            />
                            <TextField
                                style={{paddingBottom: 20}}
                                name="lastName"
                                label="Last name"
                                fullWidth
                                value={selfSelectedRef?.lastName ?? ''}
                                onChange= {(e) => handleChange(e)}
                                className={classes.input}
                                error={errors?.lastName}
                            />
                            <TextField
                                style={{paddingBottom: 20}}
                                name="email"
                                label="Email"
                                fullWidth
                                value={selfSelectedRef?.email ?? ''}
                                onChange= {(e) => handleChange(e)}
                                className={classes.input}
                                error={errors?.email}
                            />
                            <TextField
                                style={{paddingBottom: 20}}
                                name="mobile"
                                label="Mobile phone"
                                fullWidth
                                value={selfSelectedRef?.mobile ?? ''}
                                onChange= {(e) => handleChange(e)}
                                className={classes.input}
                            />
                            <TextField
                                style={{paddingBottom: 20}}
                                name="phone"
                                label="Work phone"
                                fullWidth
                                value={selfSelectedRef?.phone ?? ''}
                                onChange= {(e) => handleChange(e)}
                                className={classes.input}
                            />
                            <TextField
                                style={{paddingBottom: 20}}
                                name="linkedIn"
                                label="URL LinkedIn profile"
                                fullWidth
                                value={selfSelectedRef?.linkedIn ?? ''}
                                onChange= {(e) => handleChange(e)}
                                className={classes.input}
                            />
                            <div className={classes.languageContainer}>
                                <p>Prefered language</p>
                                <FormControl component="fieldset" className={classes.LanguageSelectorRadioContainer}>
                                    <RadioGroup row aria-label="language" name="preferedLanguage" value={selfSelectedRef?.preferedLanguage} onChange={handleLanguage}>
                                        {languagesSupported.map((language) => {
                                            return <FormControlLabel key={language.shortName} value={language.shortName} control={<Radio name={language.shortName} style={{ color: Colors.mainBlue }} checked={isChecked(language)} />} label={t(language.fullName)} />
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.buttonContainer}>
                            {isEmpty() && <LoadingBtn onClick={handleRegister} loading={loading} >Save</LoadingBtn>}
                            {isEmpty() && <LoadingBtn onClick={handleClose}>Register later</LoadingBtn>}
                            
                            {!isEmpty() && <LoadingBtn onClick={handleRegister} loading={loading}>Save</LoadingBtn>}
                            {!isEmpty() && <LoadingBtn style={{ backgroundColor: 'white', color: '#000' }} onClick={handleClose}>Cancel</LoadingBtn>}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
