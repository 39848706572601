import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import Autocomplete from '@material-ui/core/Autocomplete';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-GB';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { makeStyles } from '@material-ui/styles';

import { Colors } from '../styles/globalStyles';
import { deadlineOptionsTranslate, formatDate } from '../utils/constansts'; 
import { inputValidator } from '../utils/validators';
import CustomDropdownMenu from './CustomDropdownMenu';
import { useQuery } from '@apollo/client';
import { GET_USERS_BY_COMPANY } from '../graphql/queries';
import DCCandidate from './DCCandidate';
import DCReference from './DCReference';
import LoadingBtn from './LoadingButton';
import DCList from './DCList';
import DCLoader from './DCLoader';

const dateLanguage = {
    en: enLocale,
    da: daLocale
}

function CaseInfo({ caseState, errors, setDeleteDate, setJobPosition, setDeadlineListing, setDeadlineAnswer, setStakeholders, sendCandidateInvite, updateCandidate, isTemplate, setTemplateName }) {
    const languagesSupported = ['Danish', 'English'];
    
    const [allUsers, setAllUsers] = useState();
    const [showCandidate, setShowCandidate] = useState(false);

    const user = useSelector(state => state.auth.user);
    const {company} = user;
    const globalState = useSelector(state => state.global)

    const { data: usersData, loading } = useQuery(GET_USERS_BY_COMPANY, { variables: { companyId: user?.company?._id }, fetchPolicy: 'no-cache'})

    const dispatch = useDispatch();
    const t = (str) => str;
    const classes = useStyles();

    const deadlineOptions = deadlineOptionsTranslate(t);

    useEffect(() => {
        const formatted = usersData?.usersByCompany?.map(user => ({ ...user, label: user?.email }));
        setAllUsers(formatted)
    }, [usersData])

    useEffect(() => {
        /** Handle errors */
        if(inputValidator(errors)) dispatch(triggerFlashMessage({ type: 'error', title: t("Please make sure all the info is filled out correctly") }))
        // if(errors?.stakeholders) setShowStakeholders(true)
        if(errors?.candidate) setShowCandidate(true)
    }, [errors])

    if(loading) {
        return <DCLoader />
    }

    return (
        <div className={classes.caseContainer}>
            <div className={classes.caseContent}>
                <div className={classes.caseSelectors1}>
                    {isTemplate &&
                        <div style={{ width: 'calc(50% + 1px)'}} className={classes.caseSelector1}>
                            <TextField
                                autoFocus={isTemplate}
                                label="Template name" 
                                variant="outlined" 
                                onChange={(e) => setTemplateName(e.target.value)} 
                                error={errors?.templateName}
                                value={caseState?.templateName ?? ''}
                                fullWidth
                                className={classes.textFieldBorder}
                                InputProps={{ classes: {notchedOutline: classes.notchedOutlineFirst} }}
                                InputLabelProps={{ shrink: true }}

                            />
                        </div>
                    }
                    {!isTemplate &&
                        <div className={classes.caseSelectorDate} /* picker */>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateLanguage[globalState?.language?.shortName]}>
                                <DesktopDatePicker
                                    error={true}
                                    label={t("Delete date")}
                                    value={caseState?.deleteDate && moment(caseState?.deleteDate) ? moment(caseState?.deleteDate) : ''}
                                    minDate={new Date()}
                                    showDaysOutsideCurrentMonth={true}
                                    inputFormat={formatDate(true)}  
                                    onChange={(newValue) => {
                                        setDeleteDate(moment(newValue).valueOf())
                                    }}
                                    inputProps={{ readOnly: true }}
                                    renderInput={(params) => {
                                        return <TextField className={classes.root} {...params} variant="filled" InputProps={{...params.InputProps, disableUnderline: true,}} />
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    }
                    <div className={classes.caseSelector1}>
                        <TextField
                            autoFocus={!isTemplate}
                            label="Job-position" 
                            variant="outlined" 
                            error={errors?.jobPosition}
                            value={caseState?.jobPosition ?? ''}
                            onChange={(e) => setJobPosition(e.target.value)}
                            fullWidth
                            className={classes.textFieldBorder}
                            InputProps={{classes: {notchedOutline: classes.notchedOutlineSecond}}}
                        />
                    </div>
                </div>

                <div className={classes.caseSelectors}>
                    <div className={classes.caseSelector}>
                        <CustomDropdownMenu 
                            selected={caseState?.deadline?.listing}
                            onSelect={(e) => setDeadlineListing(e)} 
                            title={caseState?.deadline?.listing} 
                            label={t("Deadline: Listing of references")} 
                            elements={deadlineOptions}
                            style={{borderRadius: 1}}
                        />
                    </div>
                    <div className={classes.caseSelector}>
                        <CustomDropdownMenu 
                            selected={caseState?.deadline?.answer}
                            onSelect={(e) => setDeadlineAnswer(e)} 
                            title={caseState?.deadline?.answer} 
                            label={t("Deadline: Answering reference-survey")} 
                            elements={deadlineOptions}
                        />
                    </div>
                </div>

                <div className={classes.marginTop}>
                    <DCList expandIcon title={t("Stakeholders")} className={classes.listContainer}>
                        <div className={classes.stakeholderInputContainer}>
                            <Autocomplete
                                autoFocus={true}
                                multiple
                                style={{ paddingBottom: 20}}
                                options={allUsers && caseState?.stakeholders ? allUsers?.filter(user => !caseState?.stakeholders?.map(s => s?.user?._id || s?._id)?.includes(user._id)) :  []}
                                value={caseState?.stakeholders}
                                getOptionLabel={(option) => option?.label ? option?.label : option?.user?.email}
                                renderInput={(params) => (
                                    <TextField
                                        {...params} 
                                        autoFocus={true}
                                        label={t('Stakeholders')} 
                                        autoComplete="off" // disable auto fill
                                    />
                                )}
                                onChange={(e, value, reason) => setStakeholders(value)}
                            />
                        </div>
                    </DCList>

                    {!isTemplate &&
                        <DCList useParentShow={true} parentShow={showCandidate} setParentShow={setShowCandidate} expandIcon title="Candidate" className={classes.listContainer}>
                            <DCCandidate 
                                visible={showCandidate}
                                candidate={caseState?.candidate} 
                                onChange={(e) => dispatch(updateCandidate({ value: e.value, key: e.key }))} 
                                errors={errors}
                                languagesSupported={languagesSupported}
                                prefLanguage={caseState?.candidate?.preferedLanguage ? caseState?.candidate?.preferedLanguage : company?.settings?.languages[company?.settings?.defaultQALanguage]?.shortName}
                            />
                        </DCList>
                    }
               
                    <DCReference 
                        title="Select type of Reference" 
                        subTitle="(Max 3 different types)"
                        errors={errors}
                        references={caseState?.references}
                        isTemplate={isTemplate}
                    />
                </div>
                {!isTemplate && !caseState?.candidate?.listingMailSend &&
                    <div className={classes.sendCandidateInviteContainer}>
                        <LoadingBtn style={{ padding: '10px 20px' }} onClick={() => sendCandidateInvite()} >
                            Invite candidate
                        </LoadingBtn>
                    </div>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => {
    return {
        caseContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: -20,
            maxHeight: 'calc(100vh - 182px)',
            overflowY: 'overlay'
        },
        caseContent: {
            width: '70%',
            paddingBottom: '10%',
            '@media (max-width: 1024px)': {
                width: '100%',
                paddingLeft: '10px',
                paddingRight: '10px',
            },
        },
        caseSelectors1: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: 40,
            '@media (max-width: 1024px)': {
                flexDirection: 'column',
            },
        },
        caseSelector1: {
            width: '50%',
            position: 'relative',
            minHeight: '55px',
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 10,
            '@media (max-width: 1024px)': {
                width: '100%'
            },
        },
        caseSelectors: {
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            '@media (min-width: 1024px)': {
                borderBottom: `1px dashed ${Colors.mainBlue}`,
                borderTop: `1px dashed ${Colors.mainBlue}`,
                '&:last-child': {
                    borderLeft: 'none'
                },
            },
            '@media (max-width: 1024px)': {
                width: '100%',
                flexDirection: 'column'
            },
        },
        caseSelector: {
            width: '50%',
            borderLeft: `1px dashed ${Colors.mainBlue}`,
            position: 'relative',
            minHeight: '55px',
            display: 'flex',
            alignItems: 'flex-end',
            '& .MuiOutlinedInput-input': {
                border: 'none'
            },
            '@media (min-width: 1024px)': {
                '&:first-child': {
                    borderLeft: `1px dashed ${Colors.mainBlue}`,
                },
                '&:last-child': {
                    borderRight: `1px dashed ${Colors.mainBlue}`
                },
            },
            '& .MuiFormControl-root': {
                height: '100% !important',
                marginTop: '0px !important',
                width: '100%'
            },
            '&.picker': {
                display: 'flex',
                alignItems: 'flex-end',
            },
            '@media (max-width: 1024px)': {
                width: '100%',
                border: `1px dashed ${Colors.mainBlue}`,
                marginTop: 10
            },
        },
        caseSelectorDate: {
            marginBottom: '10px',
            width: '50%',
            borderLeft: `1px dashed ${Colors.mainBlue}`,
            borderBottom: `1px dashed ${Colors.mainBlue}`,
            borderRight: `1px dashed ${Colors.mainBlue}`,
            borderTop: `1px dashed ${Colors.mainBlue}`,
            '& .MuiFormControl-root': {
                height: '100% !important',
                marginTop: '0px !important',
                width: '100%'
            },
            '@media (max-width: 1024px)': {
                width: '100%'
            },
        },
        root: {
            '& .MuiInputLabel-root': {
                fontSize: 16,
                fontFamily: 'Montserrat',
                color: '#1976d2',
                fontWeight: '500',
                paddingLeft: '19px',
                paddingTop: '2px',
                height: '100%',
            },
            '& .MuiFilledInput-root': {
                background: '#fff',
                paddingLeft: 20,
                fontFamily: 'Montserrat',
                fontSize: 13.5,
                fontWeight: '500',
                paddingRight: 20,
                height: '100%',
                '&:hover': {
                    background: '#fff'
                }
            }
        },
        sendCandidateInviteContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '50px',
            flexDirection: 'column',
            paddingBottom: 50,
        },
        marginTop: {
            marginTop: 20,
            position: 'relative'
        },
        // inputLabel: {
        //     position: 'absolute',
        //     fontSize: '10px',
        //     top: '10px',
        //     left: '20px',
        //     color: '#1976d2',
        //     textTransform: 'uppercase',
        // },
        // inputLabelError: {
        //     position: 'absolute',
        //     fontSize: '10px',
        //     top: '10px',
        //     left: '20px',
        //     color: 'red',
        //     textTransform: 'uppercase',
        // },
        // input: {
        //     height: '100%',
        //     width: '100%',
        //     border: 'none',
        //     outline: 'none',
        //     paddingLeft: '30px',
        //     boxSizing: 'border-box',
        //     paddingTop: '20px',
        //     color: '#000',
        //     fontSize: '13.5px',
        //     fontFamily: 'Montserrat',
        //     fontWeight: '500',
        // },
        // stakeholderDelete: {
        //     height: '50px',
        //     justifyContent: 'center',
        //     display: 'flex',
        //     paddingTop: '5px',
        //     paddingBottom: '5px',
        //     position: 'absolute',
        //     top: '8px',
        //     right: '50px'
        // },
        // deleteToolTip: {
        //     height: 30, 
        //     width: 30, 
        //     display: 'flex', 
        //     justifyContent: 'center', 
        //     alignItems: 'center', 
        //     color: '#fff', 
        //     borderRadius: 5, 
        //     cursor: 'pointer', 
        //     zIndex: 10
        // },
        // addButtonContainer: {
        //     backgroundColor: '#fff', 
        //     height: 30, 
        //     justifyContent: 'center', 
        //     display: 'flex', 
        //     paddingTop: 5, 
        //     paddingBottom: 5
        // },
        // addButton: {
        //     backgroundColor: Colors.mainBlue, 
        //     height: 30, 
        //     width: 30, 
        //     display: 'flex', 
        //     justifyContent: 'center', 
        //     alignItems: 'center', 
        //     color: '#fff', 
        //     borderRadius: 5, 
        //     cursor: 'pointer'
        // },
        // referenceText: {
        //     marginBottom: '10px', 
        //     textAlign: 'center', 
        //     fontSize: 16, 
        //     fontWeight: 500 
        // },
        // referenceContainer: {
        //     marginTop: '1px',
        //     marginBottom: '1px',
        //     height: 50,
        //     backgroundColor: Colors.mainBlue,
        //     color: '#fff',
        //     display: 'flex', 
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     position: 'relative'
        // },
        // refDeleteIcon: {
        //     position: 'absolute',
        //     right: '15px',
        //     height: '100%',
        //     display: 'flex',
        //     alignItems: 'center',
        //     cursor: 'pointer'
        // },
        // errorText: {
        //     textAlign: 'center', 
        //     color: 'red', 
        //     fontSize: 14 , 
        //     marginTop: 10
        // },
        notchedOutlineSecond: {
            '@media (min-width: 1024px)': {
                borderLeft: '0px !important',
            },
        },
        notchedOutlineFirst: {},
        textFieldBorder: {
            '& input:valid + fieldset': {
                borderStyle: 'dashed',
                borderColor: 'black',
                borderRadius: 0,
            },
            '& input:valid:focus + fieldset': {
                borderWidth: 1,
                borderColor: 'black',
            },
        },
        listContainer: {
            backgroundColor: Colors.mainBlue, 
            color: '#fff',
            display: 'flex !important', 
            justifyContent: 'center !important', 
            marginTop: 5,
            minHeight: 50,
            cursor: 'pointer'
        },
        stakeholderInputContainer: {
            paddingTop: 20,
        },
    }
})

export default CaseInfo
