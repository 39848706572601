import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import MuiAlert from '@material-ui/core/Alert';


export default function CustomFlashMessage() {
	const show = useSelector(state => state.global.flashMessage.show);
	const title = useSelector(state => state.global.flashMessage.title);
	const type = useSelector(state => state.global.flashMessage.type);
	const description = useSelector(state => state.global.flashMessage.description);

	const dispatch = useDispatch();

	const onClose = () => {
		dispatch(triggerFlashMessage())
	}

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={onClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);
	
	return (
		<div>
			{show && title !== '' && 
				<Snackbar
					open={show}
					autoHideDuration={4500}
					onClose={onClose}
					action={action}
					severity="success"
				>
					<Alert style={{ border: '2px solid #000', background: type === 'error' ? '#b31d1d' : '#26873e'}} onClose={onClose} severity={type} sx={{ width: '100%' }}>
						<p>{title}</p>
						<p>{description}</p>
					</Alert>
				</Snackbar>
			}
		</div>
	)
}
