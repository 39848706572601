import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CaseDialog from "./CaseDialog";

export function CasePrompt({ when, onLiveClick, onDraftClick, onCancel, status, error, setError }) {
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    const history = useHistory();

    useEffect(() => {
        window.addEventListener('beforeunload', onWindowOrTabClose);

        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const onWindowOrTabClose = (event) => {
        if (!when) {
            return;
        }

        if (typeof event === 'undefined') {
            event = window.event;
        }

        if (event) {
            event.returnValue = 'HELLO';
        }

        return 'HELLO';
    };

    const handleSaveAsLive = useCallback(async () => {        
        if (onLiveClick) {
            try {
                const canRoute = await Promise.resolve(onLiveClick());
                if (canRoute) {
                    history.block(() => {});
                    history.push(currentPath);
                }
                handleContinue()
            } catch(err) {
                // TODO add error handling
            }
        }
    }, [onLiveClick]);

    const handleSaveAsDraft = useCallback(async () => {        
        if (onDraftClick) {
            const canRoute = await Promise.resolve(onDraftClick());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        handleContinue()
    }, [onDraftClick]);

    
    const handleContinue = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setError({})
        setShowPrompt(false);
    }, [onCancel]);
    
    const handleCancel = () => {
        setError({})
        setShowPrompt(false);
    };

    return (
        showPrompt && 
            <CaseDialog 
                error={error}
                status={status}
                continueButton={true}
                handleCancel={handleCancel} 
                handleSaveAsDraft={handleSaveAsDraft} 
                handleContinue={handleContinue} 
                handleSaveAsLive={handleSaveAsLive}
                title="You are about to close this Case."
            />
    )
}
