import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import SubNavigationHeader from '../../components/SubNavigationHeader';
import Branding from '../branding/Branding';
import { setActivePage } from '../../redux/slices/customerSlice';
import Company from './Company';
import { GET_COMPANIES } from '../../graphql/queries';
import Invoice from '../invoice/Invoice';

export default function Customer() {
    const [allCompanies, setAllCompanies] = useState([]);
    const classes = useStyles();

    const { activePage } = useSelector(state => state.customer)
    const dispatch = useDispatch();

    const { data: companies, refetch: refetchAllCompanies } = useQuery(GET_COMPANIES, {fetchPolicy: 'no-cache'})

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        setAllCompanies(companies?.companies?.sort((a, b) => (a?.name > b?.name) ? 1 : (b?.name > a?.name) ? -1 : 0));
    }, [companies]);

    return (
        <div className={classes.container}>
            <SubNavigationHeader
                activePage={activePage}
                items={['Company', 'Branding', 'Invoice']}
                onClick={(item) => dispatch(setActivePage(item))}
            />
            <div className={classes.content}>
                {activePage === 'Company' && <Company allCompanies={allCompanies} refetchAllCompanies={refetchAllCompanies} />}
            </div>
            <div className={classes.content}>
                {activePage === 'Branding' && <Branding allCompanies={allCompanies} refetchAllCompanies={refetchAllCompanies} />}
            </div>
            {auth?.user?.role === 'SuperAdmin' && <div className={classes.content}>
                {activePage === 'Invoice' && <Invoice allCompanies={allCompanies} refetchAllCompanies={refetchAllCompanies} />}
            </div>}
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    content: {
        width: '100%',
        justifyContent: 'center',
        maxHeight: 'calc(100vh - 216px)',
        overflowY: 'overlay'
    },
    QAManger: {
        width: '70%',
        justifyContent: 'center'
    }
});
