import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@material-ui/core';
import { Colors } from '../../styles/globalStyles';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../utils/constansts';
import daLocale from 'date-fns/locale/da';
import enLocale from 'date-fns/locale/en-GB';
import CustomButton from '../../components/Button';
import moment from 'moment';
import axios from 'axios';
import { useMutation, useQuery } from '@apollo/client';
import { ALL_INVOICE_CASES } from '../../graphql/mutations';

const Invoice = ({ allCompanies, refetchAllCompanies }) => {
    const [value, setValue] = useState({name: ''});
    const [inputValue, setInputValue] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [allCases, setAllCases] = useState([]);

    const globalState = useSelector(state => state.global)

    const classes = useStyles({ color: Colors.mainBlue });
    const t = (str) => str;
    const dateLanguage = {
        en: enLocale,
        da: daLocale
    };

    const { data: casesData, refetch: caseRefetch } = useQuery(ALL_INVOICE_CASES, {
        notifyOnNetworkStatusChange: true,
        variables: {
            companyId: value._id,
        }
    });

    useEffect(()=> {
        casesData && setAllCases(casesData.cases.filter(caseElement => caseElement.status !== 'draft' && moment(Number(caseElement.createdAt)).isBetween(moment(startDate), moment(endDate)) ).map(caseElement => {
            const createDateFormat = moment(Number(caseElement.createdAt)).format('YYYY-MM-DD');
            const deleteDateFormat = moment(Number(caseElement.deleteDate)).format('YYYY-MM-DD');
            const caseFormatted = {
                caseID: caseElement._id,
                dateOfCreation: createDateFormat,
                status: caseElement.status,
                deleteDate: deleteDateFormat,
                company: caseElement.company.name,
                caseOwner: caseElement.creator !== null ? `${caseElement.creator.firstName} ${caseElement.creator.lastName}` : 'Creator not found',
                jobPosition: caseElement.jobPosition,
                candidateInitials: `${caseElement.candidate.firstName.charAt(0)}.${caseElement.candidate.lastName.charAt(0)}`,
                typeOfReferences1: '-',
                answerDateOfRef1: '-',
                typeOfReferences2: '-',
                answerDateOfRef2: '-',
                typeOfReferences3: '-',
                answerDateOfRef3: '-',
                typeOfReferences4: '-',
                answerDateOfRef4: '-',
                typeOfReferences5: '-',
                answerDateOfRef5: '-',
                typeOfReferences6: '-',
                answerDateOfRef6: '-',
                typeOfReferences7: '-',
                answerDateOfRef7: '-',
                typeOfReferences8: '-',
                answerDateOfRef8: '-',
                typeOfReferences9: '-',
                answerDateOfRef9: '-',
                typeOfReferences10: '-',
                answerDateOfRef10: '-',
                noOfAnsweredReferences: 0,
            }

            const noAnsweredReference = {noOfAnsweredReferences: 0};

            if(caseElement.references.length !== 0) {
                for (let i = 0; i < caseElement.references.length; i++) {
                    // Check for null values
                    if(caseElement && caseElement.references && caseElement.references[i] && caseElement.references[i].type) {
                        // Add to Number of references if answerDate not null
                        if(caseElement.references[i].answerDate) {
                            // console.log('answerDate: ', caseElement.references[i].answerDate)
                            noAnsweredReference.noOfAnsweredReferences += 1;
                            const keyAnswerDate =  'answerDateOfRef' + `${(i+1)}`
                            caseFormatted[keyAnswerDate] = moment(Number(caseElement.references[i].answerDate)).format('YYYY-MM-DD');  
                        }
                        const element = caseElement.references[i].type;
                        const key = "typeOfReferences" + `${(i+1)}`;
                        caseFormatted[key] = element; 
                    }
                }
            }
            caseFormatted.noOfAnsweredReferences = noAnsweredReference.noOfAnsweredReferences;
            return caseFormatted;
        }))
    }, [casesData, value, startDate, endDate]);

    // function createData(
    //     name,
    //     calories,
    //     fat,
    //     carbs,
    //     protein,
    //   ) {
    //     return { name, calories, fat, carbs, protein };
    //   }
    // const rows = [
    //     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    //     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    //     createData('Eclair', 262, 16.0, 24, 6.0),
    //     createData('Cupcake', 305, 3.7, 67, 4.3),
    //     createData('Gingerbread', 356, 16.0, 49, 3.9),
    //   ];


    const handleDownloadInvoice = async () => {
        const startD = moment(startDate).format('YYYY-MM-DD');
        const endD = moment(endDate).format('YYYY-MM-DD');
        const axiosConfig = { method: 'GET', responseType: 'blob'} 

        const {data} = await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}/export/billing/${startD}&${endD}&${value.name}`, axiosConfig);

        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL; 
        link.setAttribute('download', `${value.name.replaceAll(' ', '-')}-${startD}-${endD}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }

    return (
    <div className={classes.container}>
        <div className={classes.contentContainer}>
            <div className={classes.caseSelectorCompSearch}>
                    <Autocomplete
                        value={value}
                        onChange={(e, newVal) => {setValue(newVal)}}
                        inputValue={inputValue}
                        onInputChange={(e, newInputVal) => {setInputValue(newInputVal)}}
                        options={allCompanies}
                        getOptionLabel={(option) => option?.name}
                        className={classes.searchAutoComplete}
                        freeSolo
                        renderInput={(params) => 
                            <TextField
                                {...params}
                                label="Company, search or choose from drop-down"
                                variant="standard"
                                InputProps={{ 
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                className={classes.textFieldBorder}
                            />
                        }
                    />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', paddingBottom: '20px'}}>
            <div style={{display:'flex', flexDirection:'row'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateLanguage[globalState?.language?.shortName]}>
                    <div className='Desktop-Date-Picker-From'>
                        <DesktopDatePicker
                            label={t("From")}
                            inputFormat={formatDate(true)} 
                            value={startDate}
                            showDaysOutsideCurrentMonth={true}
                            ignoreInvalidInputs={true}
                            onChange={(newValue) => {
                                setStartDate(newValue);
                            }}
                            renderInput={(params) => <TextField size='small' {...params} helperText={null} />}
                        />
                    </div>
                    <div>
                        <DesktopDatePicker
                            label={t("To")}
                            inputFormat={formatDate(true)} 
                            value={endDate}
                            showDaysOutsideCurrentMonth={true}
                            ignoreInvalidInputs={true}
                            onChange={(newValue) => {
                                setEndDate(newValue);
                            }}
                            renderInput={(params) => <TextField size='small' {...params} helperText={null} />}
                        />
                    </div>
                </LocalizationProvider>
            <div style={{paddingLeft: '20px'}}>
                <CustomButton title='Download Invoice' onClick={handleDownloadInvoice}/> 
            </div>
            </div>

            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow className={classes.root}>
                        <TableCell>Case ID</TableCell>
                        <TableCell align="right">Date of Creation</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Delete Date</TableCell>
                        <TableCell align="right">Company</TableCell>
                        <TableCell align="right">Case Owner</TableCell>
                        <TableCell align="right">Jobposition</TableCell>
                        <TableCell align="right">Candidate Initials</TableCell>
                        <TableCell align="right">Type of References 1</TableCell>
                        <TableCell align="right">Date Answer of References 1</TableCell>
                        <TableCell align="right">Type of References 2</TableCell>
                        <TableCell align="right">Date Answer of References 2</TableCell>
                        <TableCell align="right">Type of References 3</TableCell>
                        <TableCell align="right">Date Answer of References 3</TableCell>
                        <TableCell align="right">Type of References 4</TableCell>
                        <TableCell align="right">Date Answer of References 4</TableCell>
                        <TableCell align="right">Type of References 5</TableCell>
                        <TableCell align="right">Date Answer of References 5</TableCell>
                        <TableCell align="right">Type of References 6</TableCell>
                        <TableCell align="right">Date Answer of References 6</TableCell>
                        <TableCell align="right">Type of References 7</TableCell>
                        <TableCell align="right">Date Answer of References 7</TableCell>
                        <TableCell align="right">Type of References 8</TableCell>
                        <TableCell align="right">Date Answer of References 8</TableCell>
                        <TableCell align="right">Type of References 9</TableCell>
                        <TableCell align="right">Date Answer of References 9</TableCell>
                        <TableCell align="right">Type of References 10</TableCell>
                        <TableCell align="right">Date Answer of References 10</TableCell>
                        <TableCell align="right">No of Answered References</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {allCases.length > 0 && allCases.map((row) => (
                        <TableRow
                        key={row.caseID}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.caseID}
                        </TableCell>
                        <TableCell align="right">{row.dateOfCreation}</TableCell>
                        <TableCell align="right">{row.status}</TableCell>
                        <TableCell align="right">{row.deleteDate}</TableCell>
                        <TableCell align="right">{row.company}</TableCell>
                        <TableCell align="right">{row.caseOwner}</TableCell>
                        <TableCell align="right">{row.jobPosition}</TableCell>
                        <TableCell align="right">{row.candidateInitials}</TableCell>
                        <TableCell align="right">{row.typeOfReferences1}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef1}</TableCell>
                        <TableCell align="right">{row.typeOfReferences2}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef2}</TableCell>
                        <TableCell align="right">{row.typeOfReferences3}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef3}</TableCell>
                        <TableCell align="right">{row.typeOfReferences4}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef4}</TableCell>
                        <TableCell align="right">{row.typeOfReferences5}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef5}</TableCell>
                        <TableCell align="right">{row.typeOfReferences6}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef6}</TableCell>
                        <TableCell align="right">{row.typeOfReferences7}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef7}</TableCell>
                        <TableCell align="right">{row.typeOfReferences8}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef8}</TableCell>
                        <TableCell align="right">{row.typeOfReferences9}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef9}</TableCell>
                        <TableCell align="right">{row.typeOfReferences10}</TableCell>
                        <TableCell align="right">{row.answerDateOfRef10}</TableCell>
                        <TableCell align="right">{row.noOfAnsweredReferences}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>);
}

export default Invoice;


const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    root: {
        "& .MuiTableCell-head": {
            color: "white",
        },
    },
    contentContainer: {
        width: '70%',
        paddingBottom: '10%',
    },
    caseSelectorCompSearch : {
        width: '98.9%',
        border: `1px dashed ${Colors.mainBlue}`,
        minHeight: '55px',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        padding: '6px 8px',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    searchAutoComplete: {
        width: '100%',
        marginLeft: '20px',
        marginTop: '5px',
        textColor: 'red',
    },
    textFieldBorder: {
        paddingLeft: '10px',
        '& input:valid + fieldset': {
            borderStyle: 'dashed',
            borderColor: 'black',
            borderRadius: 0,
        },
        '& input:valid:focus + fieldset': {
            borderWidth: 1,
            borderColor: 'black',
        },
    },
});