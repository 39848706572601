import React from 'react'
import { Box } from '@material-ui/system';
import { Step, Stepper } from '@material-ui/core';
import { StepButton } from '@mui/material';
import LoadingBtn from './LoadingButton';
import { makeStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning'
import { useSelector } from 'react-redux';
import { createCaseNavigation, createCaseNavigationClosedStatus, createCaseNavigationFull, createCaseNavigationNoStatus, createCaseNavigationNoStatusTemplateOnly, createTemplateNavigation } from '../utils/constansts';

function DCNavigation({ activeStep, setActiveStep, errorIndex, type, setShowPrompt, create, isClosed = false }) {
    const { user } = useSelector(state => state.auth);
    const classes = useStyles();

    const handlePageChange = (index) => {
        setActiveStep(index)
    }

    const getMenuItems = () => {
        if(type === 'Template') {
            return createTemplateNavigation
        } else if(user.role === 'TemplateOnly' && type === 'Template') {
            return createCaseNavigation
        }

        if(create && user.role === 'TemplateOnly') {
            return createCaseNavigationNoStatusTemplateOnly;
        } else if(!create && user.role === 'TemplateOnly') { 
            if(isClosed) {
                return createCaseNavigationClosedStatus;
            }
            return createCaseNavigation;
        } else if(create) {
            return createCaseNavigationNoStatus
        } else if(isClosed) {
            return createCaseNavigationClosedStatus;
        } else {
            return createCaseNavigationFull
        }
    }

    return (
        <div className={classes.container}>             
            <Box className={classes.innerContainer}>
                <Box sx={{ width: !isClosed ? "90%" : '100%'}}>
                    <Stepper activeStep={activeStep}>
                        {getMenuItems()?.map((label, index) => {
                            const isActive = index === activeStep;
                            const hasError = index === errorIndex && !isClosed;

                            return (
                                <Step className={classes.stepContainer} key={label}>
                                    <StepButton className={isActive ? classes.stepButtonActive : classes.stepButton} onClick={() => handlePageChange(index)} >
                                        <div className={isActive ? hasError ? classes.focusError : classes.focus : {}}>
                                            <p className={classes.label}>{index + 1}. {label}</p>
                                            {errorIndex === index && <WarningIcon className={classes.errorLabel} />}
                                        </div>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                {!isClosed && <LoadingBtn onClick={() => setShowPrompt(true)}>
                    Save
                </LoadingBtn>}
            </Box>
        </div>
    )
}

const useStyles = makeStyles(() => {
    return {
        container: {
            flex: 1, 
            justifyContent: 'center', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            paddingBottom: 20    
        },
        innerContainer: {
            width: '70%', 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            paddingTop: '20px', 
            paddingBottom: 5 
        },
        stepContainer: {
            marginLeft: 20, 
            marginRight: 20
        },
        stepButton: {
            paddingLeft: '20px !important', 
            paddingRight: '20px !important', 
            paddingTop: '10px !important', 
            paddingBottom: '10px !important', 
            '&:hover': {
                backgroundColor: '#eee9 !important'
            }
        },
        stepButtonActive: {
            paddingLeft: '20px !important', 
            paddingRight: '20px !important', 
            paddingTop: '10px !important', 
            paddingBottom: '10px !important', 
            backgroundColor: '#eee !important'
        },
        focus: {
            fontWeight: 'bold',
        },
        focusError: {
            fontWeight: 'bold',
            color: 'red',
            display: 'flex'
        },
        label: {
            fontSize: 16
        },
        errorLabel: {
            color: 'red', 
            paddingLeft: 10
        }
    }
})

export default DCNavigation

