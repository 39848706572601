import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";

import LoadingBtn from "../../components/LoadingButton";
import { CREATE_USER, VALIDATE_INVITE } from "../../graphql/mutations";
import { triggerFlashMessage } from "../../redux/slices/globalSlice";
import {
    comparePasswords,
    inputValidator,
    isValidPassword,
    validateEmail,
    validateMobileNumber,
} from "../../utils/validators";

const useStyles = makeStyles({
    page: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        width: "20%",
        paddingBottom: "50px",
    },
    userInfo: {
        paddingTop: "40px",
    },
    loginInfo: {
        paddingTop: "50px",
        paddingBottom: "50px",
    },
});

function NewUser({ match, history }) {
    const userState = {
        firstName: "",
        lastName: "",
        title: "",
        phone: "",
        mobile: "",
        email: "",
        password: "",
        company: "",
        role: "",
    };
    const defaultUserError = {
        firstName: false,
        lastName: false,
        title: false,
        email: false,
        password: false,
        errorMsg: "",
        mobile: false,
    };
    const [validated, setValidated] = useState(false); // FALSE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const [user, setUser] = useState(userState);
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [userError, setUserError] = useState(defaultUserError);
    const [loading, setIsLoading] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const token = match?.params?.token;

    const [validateInvite] = useMutation(VALIDATE_INVITE);
    const [register] = useMutation(CREATE_USER);

    useEffect(() => {
        validateInvite({ variables: { token } })
            .then((res) => {
                console.log(
                    "🚀 ~ file: NewUser.js:72 ~ validateInvite ~ res:",
                    res
                );
                setValidated(true);
                setUser({
                    ...user,
                    email: res?.data?.validateInvite?.email,
                    role: res?.data?.validateInvite?.role,
                    company: res?.data?.validateInvite?.company?._id,
                });
            })
            .catch((err) => {
                setValidated(false);
            });
    }, []);

    useEffect(() => {
        if (userError.firstName && inputValidator(user.firstName))
            setUserError({ ...userError, firstName: false });
        if (userError.lastName && inputValidator(user.lastName))
            setUserError({ ...userError, lastName: false });
        if (userError.title && inputValidator(user.title))
            setUserError({ ...userError, title: false });
        if (userError.email && validateEmail(user.email))
            setUserError({ ...userError, email: false });
    }, [user.firstName, user.lastName, user.title, user.email]);

    useEffect(() => {
        if (user.password !== "" && repeatedPassword !== "")
            if (!comparePasswords(user.password, repeatedPassword)) {
                setUserError({ ...userError, password: true });
            } else {
                setUserError({ ...userError, password: false });
            }
    }, [user.password, repeatedPassword]);

    const handleCreateUser = () => {
        setIsLoading(true);

        if (!inputValidator(user.firstName)) userError.firstName = true;
        else userError.firstName = false;

        if (!inputValidator(user.lastName)) userError.lastName = true;
        else userError.lastName = false;

        if (!inputValidator(user.title)) userError.title = true;
        else userError.title = false;

        if (user.password === "" || repeatedPassword === "")
            userError.password = true;
        else if (!isValidPassword(user.password)) {
            userError.password = true;
            userError.errorMsg =
                "* Password must be at least 8 characters long";
        } else {
            userError.password = false;
            userError.errorMsg = "";
        }

        if (!validateMobileNumber(user.mobile)) userError.mobile = true;
        if (user.mobile.trim() !== "") {
            if (!validateMobileNumber(user.mobile)) userError.mobile = true;
            else userError.mobile = false;
        }

        if (!validateEmail(user.email))
            setUserError({ ...userError, email: true });
        else setUserError({ ...userError, email: false });

        if (
            userError.firstName ||
            userError.lastName ||
            userError.title ||
            userError.password ||
            userError.email ||
            userError.phone
        ) {
            setIsLoading(false);
            dispatch(
                triggerFlashMessage({
                    type: "error",
                    title: t(
                        "Please make sure all the info is filled out correctly"
                    ),
                })
            );
        } else {
            user.token = token;
            register({ variables: { input: user } })
                .then((res) => {
                    localStorage.setItem(
                        "dooblecheck_token",
                        res.data.register.token
                    );
                    setIsLoading(false);
                    history.push("/");
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err?.graphQLErrors[0]?.code === 11000) {
                        // mongo duplicate
                        return dispatch(
                            triggerFlashMessage({
                                type: "error",
                                title: `${user?.email} is already used by another user`,
                            })
                        );
                    }
                    dispatch(
                        triggerFlashMessage({
                            type: "error",
                            title: err.toString(),
                        })
                    );
                });
        }
    };

    return (
        <div className={classes.page}>
            {validated ? (
                <div className={classes.container}>
                    <div className={classes.userInfo}>
                        <h1>User info</h1>
                        <TextField
                            autoComplete="new-password"
                            error={userError.firstName}
                            style={{ marginTop: 40, width: "100%" }}
                            value={user?.firstName ?? ""}
                            onChange={(e) => {
                                setUser({ ...user, firstName: e.target.value });
                            }}
                            size="small"
                            label={t("First name")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            error={userError.lastName}
                            style={{ marginTop: 40, width: "100%" }}
                            value={user?.lastName ?? ""}
                            onChange={(e) => {
                                setUser({ ...user, lastName: e.target.value });
                            }}
                            size="small"
                            label={t("Last name")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            error={userError.title}
                            style={{ marginTop: 40, width: "100%" }}
                            value={user.title ?? ""}
                            onChange={(e) => {
                                setUser({ ...user, title: e.target.value });
                            }}
                            size="small"
                            label={t("Job-title")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            error={userError.mobile}
                            style={{ marginTop: 40, width: "100%" }}
                            value={user.mobile ?? ""}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    mobile: e.target.value.toString(),
                                });
                            }}
                            size="small"
                            label={t("Mobile")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            style={{ marginTop: 40, width: "100%" }}
                            value={user.phone ?? ""}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    phone: e.target.value.toString(),
                                });
                            }}
                            size="small"
                            label={t("Phone")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                    </div>
                    <div className={classes.loginInfo}>
                        <h1>Create Login</h1>
                        {userError.password && (
                            <div style={{ color: "red", paddingTop: "20px" }}>
                                {userError.errorMsg}
                            </div>
                        )}
                        <TextField
                            autoComplete="new-password"
                            disabled
                            error={userError.email}
                            style={{ marginTop: 40, width: "100%" }}
                            value={user.email ?? ""}
                            onChange={(e) =>
                                setUser({ ...user, email: e.target.value })
                            }
                            size="small"
                            label={t("Email")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            type="password"
                            error={userError.password}
                            style={{ marginTop: 40, width: "100%" }}
                            value={user.password ?? ""}
                            onChange={(e) => {
                                setUser({ ...user, password: e.target.value });
                            }}
                            size="small"
                            label={t("Password")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            type="password"
                            error={userError.password}
                            style={{ marginTop: 40, width: "100%" }}
                            value={repeatedPassword ?? ""}
                            onChange={(e) => {
                                setRepeatedPassword(e.target.value);
                            }}
                            size="small"
                            label={t("Repeat password")}
                            variant="outlined"
                            InputProps={{
                                autoComplete: "new-password",
                                form: { autoComplete: "off" },
                            }}
                        />
                    </div>
                    <LoadingBtn
                        onClick={() => handleCreateUser()}
                        loading={loading}
                        className="create-user-button"
                        href="#contained-buttons"
                    >
                        {t("Create user")}
                    </LoadingBtn>
                </div>
            ) : (
                <div style={{ marginTop: 20 }}>
                    Link is no longer valid. Please contact a DIGITAL
                    DOOBLECHECK Administrator at your company
                </div>
            )}
        </div>
    );
}

export default NewUser;
