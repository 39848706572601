import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../../redux/slices/authSlice';
import { LOGIN_NOW, USER_BY_ID } from '../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/client'
import { makeStyles } from '@material-ui/styles';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { Colors } from '../../styles/globalStyles';
import TextField from '@material-ui/core/TextField';

import Logo from '../../assets/images/Isbjerg_pp_tekst_Final_Cut-min.jpg';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { setLanguage, setQuestionsLanguage } from '../../redux/slices/globalSlice';
import LoadingBtn from '../../components/LoadingButton';

export default function Login({ history, location }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false);
    const [userId, setUserId] = useState();

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loginNow, {loading}] = useMutation(LOGIN_NOW, { variables: { email, password } })
    const { data } = useQuery(USER_BY_ID, { variables: { id: userId } })

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const submit = async (e) => {
        e.preventDefault();

        loginNow().then(user => {
            dispatch(loginUser(user.data.login))
            localStorage.setItem('dooblecheck_token', JSON.stringify(user.data.login.token));
            history.push('/dashboard');
        }).catch(err => {
            setError(err.message)
        })
    }

    const forgotPassword = () => {
        history.push('/forgot-password')
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    // Listens for change on USER_BY_ID used for fetch auto login
    useEffect(() => {
        if (data) {
            dispatch(loginUser(data?.user))
        }
    }, [data])

    // Checks if there is a auth token, and validates if expired
    useEffect(() => {
        const token = localStorage.getItem('dooblecheck_token');
        if (token) {
            // Decode token
            const decoded = jwtDecode(token);
            const exp = moment(decoded.exp * 1000)

            if (moment().isBefore(exp)) {
                setUserId(decoded.id)
            }
        }
    }, []);

    // Listens on redux auth state, and pushes to dashboard if user changes to authenticated
    useEffect(() => {
        if (auth.authenticated) {
            const settings = auth?.user?.company?.settings;
            i18n.changeLanguage(settings.languages[settings.defaultLanguage].shortName);
            dispatch(setLanguage(settings.languages[settings.defaultLanguage]));
            dispatch(setQuestionsLanguage(settings.languages[settings.defaultQALanguage]));

            if(location?.state?.prevPath && location?.state?.roles?.includes(auth.user.role)) {
                return history.push(`${location.state.prevPath}`)
            }
            history.push('/dashboard')
        }
    }, [auth.authenticated]);

    return (
        <div className={classes.container}>
            <div className={classes.columnLeft}>
                <form onSubmit={submit}>
                    <h1>{t("Login")}</h1>
                    <TextField 
                        autoFocus={true} 
                        error={error} 
                        style={{ marginTop: 40}} 
                        fullWidth 
                        value={email} 
                        onChange={handleEmail} 
                        size="small" 
                        id="loginEmail"
                        label={t('Email')} 
                        variant="outlined"
                        autoComplete='username'
                    />
                    <TextField 
                        error={error} 
                        style={{ marginTop: 40 }} 
                        fullWidth value={password} 
                        onChange={handlePassword} 
                        size="small" 
                        id="loginPassword" 
                        type="password" 
                        label={t("Password")} 
                        variant="outlined"
                        autoComplete='current-password'
                    />
                    <div style={{ marginTop: 20 }}>
                        <LoadingBtn type="submit" loading={loading} onClick={(e) => submit(e)} fullWidth>{t("login")}</LoadingBtn>
                    </div>
                    <div className={classes.bottomContainer}>
                        <p className={classes.bottomText} onClick={forgotPassword} >{t("Forgot password?")}</p>
                        {error && <p className={classes.error}>{error}</p>}
                    </div>
                </form>
            </div>
            <div className={classes.columnRight} style={{backgroundImage: `url(${Logo})`, backgroundRepeat: 'no-repeat, repeat', backgroundSize: 'contain', backgroundPosition: 'right'}} />
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        height: 'calc(100vh)',
    },
    columnRight: {
        display: 'flex',
        width: '50%',
        justifyContent: 'flex-end'
    },
    columnLeft: {
        width: '50%',
        justifyContent:'center',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '5%'
    },
    bottomContainer: {
        position: 'relative', 
        display: 'flex', 
        justifyContent: 'center'
    },
    bottomText: {
        marginTop: 20, 
        fontSize: 12, 
        textAlign: 'center', 
        cursor: 'pointer', 
        position: 'relative'
    },
    error: {
        marginTop: '20px',
        color: 'red',
        position: 'absolute',
        textAlign: 'center',
        bottom: '-40px',
        fontSize: '12px',
    },
})
