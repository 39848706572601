import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Switch } from '@mui/material';


export default function DCSwitch({ checked, onChange, type, value }) {
	const classes = useStyles()

	return (
		<Switch
			value={value}
			checked={checked}
			color={type}
			disableRipple
			className={classes.switch}
			onChange={onChange}
		/>
	)	
}

const useStyles = makeStyles({
	switch: {
		'& .MuiSwitch-root': {
			fontSize: 0,
			position: 'relative',
			display: 'inline-block',
			width: '50px !important',
			height: '50px !important',
			margin: 10,
			background: '#b9b9b9',
			borderRadius: 10,
			cursor: 'pointer',

			'& .Mui-checked': {
				backgroundColor: '#000 !important',
			},
		},
		'& .MuiSwitch-switchBase': {
			transition: 'none !important',

			'&:hover': {
				backgroundColor: 'transparent !important',
			},
		},
		'& .MuiSwitch-thumb': {
			display: 'block',
			width: 10,
			height: 10,
			top: 5,
			left: 5,
			borderRadius: 16,
			position: 'relative',
			transition: 'all 200ms ease',
			boxShadow: 'none !important',
			pointerEvents: 'none',

			'&:hover': {
				backgroundColor: 'none !important',
			},
		},
		'& .MuiSwitch-input': {
			cursor: 'inherit',
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			opacity: 0,
			zIndex: 1,
			margin: 0,
		},
	}
})
