import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice';
import createCaseSlice from './slices/createCaseSlice';
import accountSlice from './slices/accountSlice';
import globalSlice from './slices/globalSlice';
import answerQuestionsSlice from './slices/answerQuestions';
import customerSlice from './slices/customerSlice';

export default configureStore({
    reducer: {
        auth: authReducer,
        createCase: createCaseSlice,
        account: accountSlice,
        customer: customerSlice,
        global: globalSlice,
        answerQuestions: answerQuestionsSlice
    },
})
