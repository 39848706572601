import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from "@apollo/client";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_URL });

const authLink = new ApolloLink((operation, forward) => {
    const AUTHTOKEN = localStorage.getItem('dooblecheck_token');

    operation.setContext(({ headers }) => ({
        headers: {
            authorization: AUTHTOKEN, // however you get your token
            ...headers
        }
    }));
    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    link: ApolloLink.from([authLink, httpLink]),
});

export default client;
