import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { RESET_PASSWORD } from '../../graphql/mutations';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';

import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import { validateEmail } from '../../utils/validators';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../styles/globalStyles';
import LoadingBtn from '../../components/LoadingButton';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const [forgotPassword] = useMutation(RESET_PASSWORD, { variables: { email } })

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const classes = useStyles();

    const submit = (e) => {
        e.preventDefault();

        if(validateEmail(email)){
            return forgotPassword().then(res => {
                setEmail('')
                dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully send email with reset link") }))
                setError()
            }).catch(err => {
                setError(err.message)
                dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong, please try again") }))
            })
        } 
        setError(t('* Invalid email'));
    }

    return (
        <div className={classes.container}>
            <form style={{ position: 'relative'}} onSubmit={submit}>
                <h1>{t("Forgot password")}</h1>
                <TextField 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    autoFocus 
                    fullWidth
                    error={error} 
                    style={{ marginTop: 30 }}
                    className={classes.textField} 
                    size="small" 
                    label={t("Email")} 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                />
                <div className={classes.buttonContainer}>
                    <LoadingBtn type="submit" fullWidth onClick={(e) => submit(e)}>{t("Send email")}</LoadingBtn>
                </div>
                <Link to="/">
                    <p className={classes.underlineText}>{t("Already have account? Sign in here")}</p>
                </Link>
                {error &&
                    <p className={classes.error}>
                        {error}
                        <p>
                            Please call DOOBLECHECK at <a href="tel:+4561638019">+45 6163 8019</a> or send an email to <a style={{ color: 'blue' }} href="mailto:fh@dooblecheck.com">fh@dooblecheck.com</a> for help
                        </p>
                    </p>
                }
            </form>
        </div>
    )
}

export default ForgotPassword

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(90vh - 110px)',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20,
        width: '100%'
    },
    underlineText: {
        fontSize: 12, 
        textAlign: 'center', 
        marginTop: 20
    },
    error: {
        marginTop: 20,
        color: 'red',
        position: 'absolute',
        textAlign: 'center',
        bottom: -100,
        fontSize: 12
    },
})
