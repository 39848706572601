import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import _ from 'lodash'
import '../sass/components/dropdown-secondary.scss';
import { useDispatch } from 'react-redux';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import LoadingBtn from './LoadingButton';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../utils/constansts';
import { validateUser } from '../utils/validators';

export default function DCUser({ user, users, setCompanyUsers, onSave , refetch}) {
    const [userCopy, setUserCopy] = useState();
    const [loading, setLoading] = useState();
    const [errors, setErrors] = useState();
    const t = (str) => str;

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        setUserCopy(user)
    }, []);

    const onChange = (e) => {
        setCompanyUsers(users?.map(u => {
            if(u?._id === user?._id) {
                return {
                    ...u,
                    [e.target.name]: e.target.value
                }
            } else {
                return u;
            }
        }))
    }

    const handleSave = (e) => {
        setLoading(true);

        const errors = validateUser(user)

        if(!_.isEmpty(errors)) {
            dispatch(triggerFlashMessage({ type: 'error', title: t('Please make sure all the info is filled out correctly')}))
            return setErrors(errors)
        }

        onSave({ variables: { input: user } }).then(res => {
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'success', title: t('Succesfully updated user')}))
            refetch();
            setUserCopy(user)
        }).catch(err => {
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'error', title: t('Failed to update user')}))
        })
    }

    return (
        <div className={classes.container}>
            <div className={classes.inputSectionContainer}>
                <div className={classes.leftInputContainer}>
                    <TextField 
                        fullWidth
                        label={t('First name')} 
                        autoComplete="new-password" 
                        name="firstName" 
                        variant="outlined" 
                        onChange={onChange} 
                        value={user?.firstName ? user?.firstName : ''} 
                        error={errors?.firstName} 
                        InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                    />
                </div>
                <div className={classes.rightInputContainer}>
                    <TextField 
                        fullWidth
                        label={t('Last name')} 
                        autoComplete="new-password" 
                        name="lastName" 
                        variant="outlined" 
                        onChange={onChange} 
                        value={user?.lastName ? user?.lastName : ''} 
                        error={errors?.lastName} 
                        InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                    />
                </div>
            </div>
            <div className={classes.inputSectionContainer}>
                <div className={classes.leftInputContainer}>
                    <TextField 
                        fullWidth
                        label={t('Phone')} 
                        autoComplete="new-password" 
                        name="phone" 
                        variant="outlined" 
                        onChange={onChange} 
                        value={user?.phone ? user?.phone : ''} 
                        InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                    />
                </div>
                <div className={classes.rightInputContainer}>
                    <TextField 
                        fullWidth
                        label={t('Mobile')} 
                        autoComplete="new-password" 
                        name="mobile" 
                        variant="outlined" 
                        onChange={onChange} 
                        value={user?.mobile ? user?.mobile : ''} 
                        InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                    />
                </div>
            </div>
            <div className={classes.inputSectionContainer}>
                <div className={classes.leftInputContainer}>
                    <TextField 
                        fullWidth
                        label={t('Email')} 
                        autoComplete="new-password" 
                        name="email" 
                        variant="outlined" 
                        onChange={onChange} 
                        value={user?.email ? user?.email : ''}
                        error={errors?.email}  
                        InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                    />
                </div>
                <div className={classes.rightInputContainer}>
                    <TextField 
                        fullWidth
                        label={t('Date of creation')} 
                        autoComplete="new-password" 
                        name="createdAt" 
                        variant="outlined"
                        disabled 
                        onChange={onChange} 
                        value={moment(parseInt(user?.createdAt)).format(formatDate()) ? moment(parseInt(user?.createdAt)).format(formatDate()) : ''} 
                        className={classes.dateInput}
                        InputProps={{autoComplete: 'new-password', form: { autoComplete: 'off',} }}
                    />
                </div>
            </div>
            {!_.isEqual(userCopy, user) &&
                <div className={classes.buttonContainer} >
                    <LoadingBtn loading={loading} onClick={(e) => handleSave(e)} >{t('Save')}</LoadingBtn>
                </div>
            }
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        paddingTop: 0,
        paddingBottom: 30,
    },
    dateInput: {
        "& input.Mui-disabled": {
          '-webkit-text-fill-color': "rgba(0, 0, 0, 0.70)"
        }
    },
    inputSectionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftInputContainer: {
        flex: 1,
        paddingRight: 10,
        marginTop: 20,
    },
    rightInputContainer: {
        flex: 1,
        paddingLeft: 10,
        marginTop: 20,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 0,
    },
})
