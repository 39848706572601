import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Colors } from '../styles/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setReferenceQuestions } from '../redux/slices/answerQuestions';
import { getLanguageLocal } from '../utils/getLanguage';
import { elaborationBodyTranslations } from '../utils/constansts';
import { formatReferenceAnswer2 } from '../services/caseService';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';

export default function DCQuestionInput({ label, references, selectedLanguage, questionId, showAnswers, submitAnswers, isClosed = false }) {
    const [combined, setCombined] = useState();
    const [value, setValue] = useState();

    const auth = useSelector(state => state.auth)

    const valueRef = useRef()
    valueRef.current = value;
    
    const classes = useStyles({color: auth?.user?.company?.branding?.colors + '!important'});
    
    const dispatch = useDispatch();

    const handleChange = (newValue, refIndex) => {
        const newValues = value?.map((val, index) => {
            if(index === refIndex) {
                return newValue
            }

            return val;
        })

        setValue(newValues)
    }

    useEffect(() => {
        setCombined(references?.length > 1);
        setValue(references?.map(ref => {
            const foundQuestion = ref?.questions?.find(q => q?.question?._id === questionId);
            return foundQuestion?.answer?.title;
        }))
    }, [references])

    useEffect(() => {
        return () => {
            if(submitAnswers) {
                const reference = references[0];
                const formattedReference = formatReferenceAnswer2(reference, questionId, null, valueRef?.current[0]);
                dispatch(setReferenceQuestions([formattedReference]))
            }
        }
    }, [])

    return (
        <div className={classes.container}>
            <p className={classes.label}>{makeUnderlineQuestion(label)}</p>
            {references?.map((reference, index) => {
                return (
                    <div style={{ marginTop: 10 }}>
                        {combined && 
                            <p className={classes.referenceName}>
                                {!isClosed
                                    ? `${reference?.firstName} ${reference?.lastName}. (${reference?.type})`
                                    : reference?.type
                                }
                            </p>
                        }
                        <TextField
                            className={classes.input}   
                            label={value && value[index] && showAnswers
                                ? ''
                                : getLanguageLocal(elaborationBodyTranslations, selectedLanguage)?.name
                            }
                            onChange={(e) => handleChange(e.target.value, index)}
                            value={value && showAnswers && value[index] ? value[index] : ''}
                            multiline
                            minRows={4}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel,
                                    filled: classes.inputLabel,
                                },
                            }}
                        />
                    </div>
                )
            })}            
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        width: '100%',
        '@media print': {
            breakInside: 'avoid'
        }
    },
    label: {
        marginBottom: '20px', 
        fontSize: '20px', 
        fontWeight: '500', 
        fontStyle: 'italic',
        '@media (max-width: 480px)': {
            fontSize: 15,
            marginBottom: '0px',
        },
    },
    input:{
        width: '100%',
        '& .MuiTextField-root': {
            borderColor: props => props.color ? props.color :`${Colors.mainBlue} !important`,
        },
    },
    inputLabel: {
        color: props => props.color ? props.color :`${Colors.mainBlue} !important`,
    },
    notchedOutline: {
        borderColor:`${Colors.secondaryBlue} !important`
    },
    referenceName: {
        fontWeight: '500', 
        paddingBottom: 5
    }
});