import React, { useEffect, useState } from 'react'
import DCNavigation from '../../components/DCNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStep, setJobPosition, setDeleteDate, setDeadlineListing, setDeadlineAnswer, setStakeholders, updateCandidate, setCase } from '../../redux/slices/createCaseSlice'
import Questions from '../../components/Questions'
import Preview from '../../components/Preview'
import { makeStyles } from '@material-ui/styles'
import { validatePageChange, validateLiveCase, validateCaseInfo } from '../../utils/validators'
import _ from 'lodash'
import { CasePrompt } from '../../components/CasePrompt'
import { formatCreateCase } from '../../services/caseService'
import { CASE_BY_ID, INVITE_CANDIDATE, UPDATE_DRAFT_CASE, UPDATE_LIVE_CASE } from '../../graphql/mutations'
import { useMutation, useQuery } from '@apollo/client'
import { triggerFlashMessage } from '../../redux/slices/globalSlice'
import CaseDialog from '../../components/CaseDialog'
import CaseInfo from '../../components/CaseInfo'
import DCLoader from '../../components/DCLoader'
import { useHistory } from 'react-router'
import moment from 'moment';
import Status from './Status'

function UpdateCase({ match }) {
    const [caseErrors, setCaseErrors] = useState({});
    const [errorIndex, setErrorIndex] = useState();
    const [showPrompt, setShowPrompt] = useState(false);
    const [submitError, setSubmitError] = useState();
    const [caseId, setCaseId] = useState();
    const [caseCopy, setCaseCopy] = useState();

    const { activeStep, case: caseState, theme, category } = useSelector(state => state.createCase)
    const auth = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth)
    const [selectedLanguage, setSelectedLanguage] = useState(auth?.user?.company?.settings?.languages[auth?.user?.company?.settings?.defaultQALanguage]);
    
    const dispatch = useDispatch()
    const classes = useStyles();
    const history = useHistory()

    const [updateLiveCase] = useMutation(UPDATE_LIVE_CASE);
    const [updateDraftCase] = useMutation(UPDATE_DRAFT_CASE);
    const [inviteCandidate] = useMutation(INVITE_CANDIDATE);

    const { data, loading, refetch } = useQuery(CASE_BY_ID, { variables: { id: caseId }, fetchPolicy: 'cache-and-network' });

    const t = (str) => str;

    useEffect(() => {
        setCaseId(match?.params?.id)
    }, [match, data])

    useEffect(() => {
        if(data?.case?.status === 'live') {
            dispatch(setActiveStep(user.role === 'TemplateOnly' ? 2 : 3))
        }

        const formattedCase = {
            ...data?.case,
            deadline: {
                answer: `${data?.case?.deadline?.answer} hours`,
                listing: `${data?.case?.deadline?.listing} hours`
            },
            deleteDate: moment(parseInt(data?.case?.deleteDate)).valueOf(),
            // candidate: {
            //     ...data?.case?.candidate,
            //     preferedLanguage: data?.case?.candidate?.preferedLanguage ? data?.case?.candidate?.preferedLanguage : user?.company?.settings?.languages[user?.company?.settings?.defaultLanguage]?.shortName,
            // },
        }

        setCaseCopy(formattedCase)
        dispatch(setCase(formattedCase))
    }, [data])

    /**
     * Validate on page changes
     */
    const handlePageChange = (item) => {
        const validatedPage = validatePageChange(activeStep, item, false)

        setCaseErrors(validatedPage?.errors)
        setErrorIndex(validatedPage?.errorIndex)
        dispatch(setActiveStep(validatedPage?.errorIndex))
        if(_.isEmpty(validatedPage?.errors)) {
            setErrorIndex()
            return dispatch(setActiveStep(item))
        }
    }

    const sendCandidateInvite = async () => {
        let variables = formatCreateCase(caseState, user, true)
        variables.status = 'draft';
        variables.sendCandidateInvite = true;

        const errors = validateCaseInfo(false);
        if (!_.isEmpty(errors)) {
            setCaseErrors(errors)
            return setErrorIndex(0)
        }
        
        try {
            delete variables?.createdAt
            const caseRes = await inviteCandidate({ variables });

            setCaseCopy(caseRes?.data?.updateCase)
            dispatch(setCase(caseRes?.data?.updateCase))
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully invited Candidate") }))
            history.push('/update-case/' + caseRes?.data?.updateCase?._id)
        } catch(err) {
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        }
    }

    const saveCase = async (status) => {
        let variables = formatCreateCase(caseState, user, true)
        variables.status = status;

        delete variables.creator;
        delete variables?.createdAt;
        
        try {
            if(status === 'live') {
                const validated = validateLiveCase(caseState)
                if(!_.isEmpty(validated)) {
                    dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong, please follow live case guidelines") }))
                    setSubmitError(validated)
                    return Promise.reject();
                }
                await updateLiveCase({ variables: { input: variables }})
                dispatch(setCase(caseCopy))
                dispatch(triggerFlashMessage({ type: 'success', title: t(`Succesfully saved as ${status} case`) })) 
                setShowPrompt(false)
    
                return history.push('/dashboard')
            }
    
            if(status === 'draft') {
                await updateDraftCase({ variables: { input: variables } })
                dispatch(setCase(caseCopy))
                dispatch(triggerFlashMessage({ type: 'success', title: t(`Succesfully saved as ${status} case`) })) 
                setShowPrompt(false)
                history.push('/dashboard')
            }
        } catch(err) {
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        }
    }

    useEffect(() => {
        !showPrompt && setSubmitError('')
    }, [showPrompt])

    if(loading) {
        return <DCLoader />
    }

    return (
        <div style={{ maxHeight: 'calc(100vh - 106px)', overflow: 'hidden'}}>
            <CasePrompt
                when={!_.isEqual(caseState, caseCopy)}
                onOk={() => console.log('ok') }
                onCancel={() => true}
                status={caseState?.status}
                onDraftClick={() => saveCase('draft')}
                onLiveClick={() => saveCase('live')}
                error={submitError}
                setError={setSubmitError}
            />

            {showPrompt &&
                <CaseDialog
                    error={submitError}
                    continueButton={false}
                    status={caseState?.status}
                    handleCancel={() => {
                        setSubmitError({})
                        setShowPrompt(false)
                    }} 
                    handleSaveAsDraft={() => saveCase('draft')} 
                    handleSaveAsLive={() => saveCase('live')}
                    title="You are about to save this Case."
                />
            }
    
            <div className={classes.contentContainer}>
                <DCNavigation 
                    setShowPrompt={setShowPrompt}
                    setActiveStep={(index) => handlePageChange(index)}
                    activeStep={activeStep} 
                    errorIndex={errorIndex} 
                    type="Case"
                />
            </div>

            <div className={classes.contentContainer}>
                {user.role === 'TemplateOnly'
                    ? (
                        <div>
                            {activeStep === 0 && 
                                <CaseInfo 
                                    errors={caseErrors}
                                    caseState={caseState} 
                                    setJobPosition={(e) => dispatch(setJobPosition(e))}
                                    setDeleteDate={(e) => dispatch(setDeleteDate(e))}
                                    setDeadlineListing={(e) => dispatch(setDeadlineListing(e))}
                                    setDeadlineAnswer={(e) => dispatch(setDeadlineAnswer(e))}
                                    setStakeholders={(e) => dispatch(setStakeholders(e))}
                                    updateCandidate={(e) => dispatch(updateCandidate(e))}
                                    sendCandidateInvite={(e) => sendCandidateInvite()}
                                /> 
                            }
                            {activeStep === 1 && <Preview caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}  /> }
                            {activeStep === 2 && <Status refetchCase={refetch} caseInfo={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> }
                        </div>
                    )
                    : (
                        <div>
                            {activeStep === 0 && 
                                <CaseInfo 
                                    errors={caseErrors}
                                    caseState={caseState} 
                                    setJobPosition={(e) => dispatch(setJobPosition(e))}
                                    setDeleteDate={(e) => dispatch(setDeleteDate(e))}
                                    setDeadlineListing={(e) => dispatch(setDeadlineListing(e))}
                                    setDeadlineAnswer={(e) => dispatch(setDeadlineAnswer(e))}
                                    setStakeholders={(e) => dispatch(setStakeholders(e))}
                                    updateCandidate={(e) => dispatch(updateCandidate(e))}
                                    sendCandidateInvite={(e) => sendCandidateInvite()}
                                /> 
                            }
                            {activeStep === 1 && <Questions theme={theme} category={category} caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> }
                            {activeStep === 2 && <Preview caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> }
                            {activeStep === 3 && <Status refetchCase={refetch} caseInfo={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles({

})

export default UpdateCase
