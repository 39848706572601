import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import { setReferenceQuestions} from '../redux/slices/answerQuestions';
import { useDispatch } from 'react-redux';
import QuestionAnswerElaboration from './QuestionAnswerElaboration';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import { Colors } from '../styles/globalStyles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';
import { validateQuestionAnswerOther, validateQuestionAnswerTitle } from '../utils/validators';
import { getLanguageLocal } from '../utils/getLanguage';
import { dontKnowAnswerTranslations } from '../utils/constansts';
import QuestionAnswerOther from './QuestionAnswerOther';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';
import DCSwitch from './DCSwitch'

const useStyles = makeStyles({
    title: {
        marginBottom: '30px',
        fontSize: '20px',
        fontWeight: '500',
        textAlign: 'left',
        fontStyle: 'italic',
        '@media (max-width: 480px)': {
            fontSize: 15,
            marginBottom: 5,
        },
    },
    middleInfoContainer: {
        flex: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        '@media (max-width: 480px)': {
            flex: 3,
        },
    },
    infoContainerLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 40px',
        color: 'white',
        flexDirection: 'column',
        borderRight: '1px solid white',
        flex: 1,
        '@media (max-width: 480px)': {
            padding: 5,
        },
    },
    infoContainerRight: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 40px',
        color: 'white',
        flexDirection: 'column',
        borderLeft: '1px solid white',
        flex: 1,
        '@media (max-width: 480px)': {
            padding: 5,
        },
    },
    icon: {
        fontSize: '30px !important',
        '@media (max-width: 480px)': {
            fontSize: '20px !important',
        },
    },
    topInfo: {
        color: 'white',
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontWeight: 'bold',
        '@media (max-width: 480px)': {
            fontSize: '12px !important',
        },
    },
    answersContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px dashed black',
    },
    switchContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 40px',
        color: 'white',
        flexDirection: 'column',
        '@media (max-width: 480px)': {
            padding: '5px 0px',
        },
    },
    answerTitle: {
        flex: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 40px',
        flexDirection: 'column',
        fontWeight: 500,
        fontSize: 18,
        textAlign: 'center',
        '@media (max-width: 480px)': {
            flex: 3,
            padding: '5px 0px',
            fontSize: 13,
        },
    },
    dontKnow: {
        textAlign: 'center',
        color: 'black',
        padding: '5px 10px',
        fontWeight: 500,
        fontSize: 18,
        '@media (max-width: 480px)': {
            fontSize: 15,
        },
    },
    optionsContainerVertical: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-around',

    },
    breakAvoid: {
        '@media (max-width: 480px)': {
            paddingBottom: 0,
        },
        '@media print': {
            breakInside: 'avoid'
        }
    },
    containerReport: {
        paddingBottom: 50,
        marginTop: 40,
        backgroundImage: `linear-gradient(to right, ${Colors.mainBlue} 40%, rgba(255,255,255,0) 0%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '10px 1px',
        backgroundRepeat: 'repeat-x',
        '@media print': {
            breakInside: 'avoid'
        }
    },
    containerReportLast: {
        paddingBottom: 0,
        marginTop: 40,
        '@media print': {
            breakInside: 'avoid'
        },
        '@media (max-width: 480px)': {
            marginTop: 0,
        },
    },
});

export default function QuestionAnswerProsCons({ title, references, answers, questionIndex, enabled, elaboration, max, selectedLanguage, showAnswers, isReport, questionId, isClosed = false }) {
    const [activeReferences, setActiveReferences] = useState();
    const [otherAnswerIndex, setOtherAnswerIndex] = useState();

    const { t } = useTranslation();
    
    const classes = useStyles();
    const dispatch = useDispatch();

    const formatNewReference = (ref, newAnswers) => {
        return {
            ...ref,
            questions: ref?.questions?.map((question, index) => {
                if(index === questionIndex) {
                    return {
                        ...question,
                        answer: {
                            ...question?.answer,
                            answers: newAnswers
                        }
                    }
                }
                return question
            })
        }
    }

    const handleProsConsAnswer = (checked, value, answers, ref) => {
        let array = [];
        if(answers && answers.length > 0 && parseInt(answers[0]) !== 0){
            array = [].concat(answers)
        }
        if(checked){
            const prosArray = array.filter(a => a > 0)
            const consArray = array.filter(a => a < 0)
            if(value > 0 && prosArray.length === 3){
                return dispatch(triggerFlashMessage({ type: 'error', title: t(`You can not choose more than {{max}} pros`, { max }) }))
            } else if (value < 0 && consArray.length === 3){
                return dispatch(triggerFlashMessage({ type: 'error', title: t(`You can not choose more than {{max}} cons`, { max }) }))
            } else {
                array = array.filter(a => Math.abs(parseInt(a)) !== Math.abs(parseInt(value)))
                array.push(parseInt(value))
            }
        } else {
            array = array.filter(a => parseInt(a) !== parseInt(value))
        }
        const newReference = formatNewReference(ref, array)
        dispatch(setReferenceQuestions([newReference]))
    }

    useEffect(() => {
        if(isReport) {
            setActiveReferences(references?.filter(ref => {
                return ref?.listingMailSend && ref?.questions?.some(e => e?.question?._id === questionId)
            })) 
        } else {
            setActiveReferences(references)
        }
    }, [references])

    useEffect(() => {
        answers?.forEach((answer, index) => {
            if(validateQuestionAnswerOther(answer?.title)){
                setOtherAnswerIndex(index + 1)
            }
        })
    }, [answers])

    useEffect(() => {}, [])

    const checkSelected = (refAnswers, value, ref) => {
        if(isReport) {
            const foundQuestion = ref?.questions?.find(e => e?.question?._id === questionId);
            const foundAnswers = foundQuestion?.answer?.answers;
            return foundAnswers?.filter(a => parseInt(a) === value).length === 1;
        } else {
            return refAnswers?.filter(a => parseInt(a) === value).length === 1;
        }
    }

    const handleDontKnow = (checked, value, ref) => {
        if(checked) {
            const newReference = formatNewReference(ref, [parseInt(value)])
            dispatch(setReferenceQuestions([newReference]))
        } else {
            const newReference = formatNewReference(ref, [])
            dispatch(setReferenceQuestions([newReference]))
        }
    }
    
    return (
        <div className={classes.breakAvoid}>
            {activeReferences?.map((reference, index) => {
                return (
                    <div className={isReport ? index !== activeReferences.length - 1 ? classes.containerReport : classes.containerReportLast : {}}>
                        <p className={classes.title}>{makeUnderlineQuestion(title)}</p>

                        {isReport && 
                            <p style={{ fontWeight: '500', marginBottom: 20, fontSize: 18 }}>
                                {!isClosed ? `${reference?.firstName} ${reference?.lastName} (${reference?.type})` : reference?.type}
                            </p>
                        }
                        <div style={{backgroundColor: Colors.mainBlue, display: 'flex', justifyContent: 'space-between', flex: 1}}>
                            <div className={classes.infoContainerLeft}>
                                <AddIcon className={classes.icon}/>
                                <p className={classes.topInfo}>
                                    {t('choose max {{max}}', { max })}
                                </p>
                            </div>
                            <div className={classes.middleInfoContainer}>
                             
                            </div>
                            <div className={classes.infoContainerRight}>
                                <RemoveIcon className={classes.icon}/>
                                <p className={classes.topInfo}>
                                    {t('choose max {{max}}', { max })}
                                </p>
                            </div>
                        </div>
                        <div className={classes.optionsContainerVertical}>
                            <FormControl component="fieldset" >
                                {answers?.map((answer, index) => {
                                    if(validateQuestionAnswerTitle(answer?.title)) {
                                        return (
                                            <div className={classes.answersContainer}>
                                                <div className={classes.switchContainer}>
                                                    <DCSwitch 
                                                        type="success" 
                                                        value={index+1} 
                                                        onChange={(e) => enabled && handleProsConsAnswer(e.target.checked, e.target.value, reference?.questions[questionIndex]?.answer?.answers, reference)} 
                                                        checked={showAnswers && checkSelected(reference?.questions[questionIndex]?.answer?.answers, index+1, reference)}
                                                    />
                                                </div>
                                                <p className={classes.answerTitle}>
                                                    {answer?.title}
                                                </p>
                                                <div className={classes.switchContainer}>
                                                    <DCSwitch 
                                                        type="error" 
                                                        value={-index-1} 
                                                        onChange={(e) => enabled && handleProsConsAnswer(e.target.checked, e.target.value, reference?.questions[questionIndex]?.answer?.answers, reference)} 
                                                        checked={showAnswers && checkSelected(reference?.questions[questionIndex]?.answer?.answers, -index-1, reference)}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    } else return null;
                                })}
                                <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px 20px', color: 'white', flexDirection: 'column',}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <p className={classes.dontKnow}>
                                            {selectedLanguage ? getLanguageLocal(dontKnowAnswerTranslations, selectedLanguage)?.name : t("Don't know")}
                                        </p>
                                        <DCSwitch 
                                            type="primary"
                                            value={0} 
                                            checked={showAnswers && checkSelected(reference?.questions[questionIndex]?.answer?.answers, 0)} 
                                            onChange={(e) => enabled && handleDontKnow(e.target.checked, e.target.value, reference)} 
                                        />
                                    </div>
                                </div>
                            </FormControl>
                            {otherAnswerIndex && reference?.questions[questionIndex]?.answer?.answers?.some(a => Math.abs(a) === otherAnswerIndex) && <QuestionAnswerOther questionId={questionId} showAnswers={showAnswers} reference={reference} selectedLanguage={selectedLanguage} enabled={enabled} questionIndex={questionIndex} isReport={isReport} removePadding={true} isClosed={isClosed} />}
                            {!reference?.questions[questionIndex]?.answer?.answers?.some(a => Math.abs(a) === otherAnswerIndex) && elaboration && <QuestionAnswerElaboration questionId={questionId} showAnswers={showAnswers} reference={reference} selectedLanguage={selectedLanguage} enabled={enabled} questionIndex={questionIndex} isReport={isReport} removePadding={true} isClosed={isClosed}/>}
                        </div>
                    </div>
                )
            })}
        </div>
    )
};
