import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    NavLink,
    useLocation,
} from "react-router-dom";
import { logoutUser } from '../redux/slices/authSlice';

//NEW ICONS
import {ReactComponent as DashboardIcon} from "../assets/images/DashboardIcon.svg";
import {ReactComponent as AccountIcon} from "../assets/images/AccountIcon.svg";
import {ReactComponent as SupportIcon} from "../assets/images/SupportIcon.svg";
import {ReactComponent as GuideIcon} from "../assets/images/GuideIcon.svg";
import {ReactComponent as ContactIcon} from "../assets/images/ContactIcon.svg";
import {ReactComponent as LogoutIcon} from "../assets/images/LogoutIcon.svg";

import { useTranslation } from 'react-i18next';
import { Colors } from "../styles/globalStyles";
import { makeStyles } from "@material-ui/styles";
import Sidebar from "./Sidebar";
import RoleContainer from "../helpers/RoleContainer";
import Guide from "../screens/general/guide/Guide";

export default function NavigationHeader(props) {
    const location = useLocation();
    const [showGuide, setShowGuide] = useState(false);
    const [hasCompanyLogo, setHasCompanyLogo] = useState();
    const [logoLoaded, setLogoLoaded] = useState(false)
    
    const auth = useSelector(state => state.auth);
    const { user } = auth;
    const companyLogo = user?.company?.branding?.logo
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles({color: user?.company?.branding ? '1px solid' + user?.company?.branding?.colors + '!important' : ('1px solid' + Colors.mainBlue + '!important')});

    const activeStyle = { fontWeight: 'bold' };

    const logout = () => {
        localStorage.removeItem('dooblecheck_token');
        dispatch(logoutUser())
    }

    const DooblecheckLogo = ({className}) => {
      return (
        <div className={classes.logo}>
            <img alt='logo' src={require("../assets/images/TheDigitalDooblecheckLogo.svg").default} />
        </div>
      );
    };

    const DooblecheckLogoWithSub = ({className}) => {
      return (
        <div className={className}>
            <img alt='logo' src={require("../assets/images/TheDigitalDooblecheckLogoWithSub.svg").default} />
        </div>
      );
    };

    useEffect(() => {
        if(user?.company) {
            if(companyLogo?.base64 && companyLogo?.name && !user?.company?.branding?.useDefault) {
                setHasCompanyLogo(true)
            } else {
                setHasCompanyLogo(false)
            }
            setLogoLoaded(true)
        }
    }, [companyLogo])

    return (
        <div id='navigationHeader' className={classes.container}> {/* IKKE SLET ID */}  
            <Guide open={showGuide} setOpen={(e) => setShowGuide(e)} />
            <div className={classes.headerWidth}>
                <div className={classes.content}>
                    <div className={classes.logoContainer}>
                        <div className={auth.authenticated ? classes.pointerEvent : classes.pointerEventNone}>
                            {auth.authenticated || location.pathname === '/' ? <NavLink to="/" >
                                <DooblecheckLogoWithSub className={classes.logo} />
                            </NavLink>
                            : logoLoaded ? hasCompanyLogo ? <img alt={companyLogo?.name} className={classes.companylogo} src={companyLogo?.base64}/> : <DooblecheckLogo /> : null} 
                        </div>

                        {/** quick fix skal fjernes */}
                        {location.pathname === '/case/thanks' && user && !user.company &&
                            <div className={auth.authenticated ? classes.pointerEvent : classes.pointerEventNone}>
                                <DooblecheckLogo />
                            </div>
                        }

                        {/** quick fix skal fjernes */}
                        {location.pathname === '/case/closed' &&
                            <div className={auth.authenticated ? classes.pointerEvent : classes.pointerEventNone}>
                                <DooblecheckLogo />
                            </div>
                        }

                    </div>
                    {auth?.authenticated &&
                        <div className={classes.primaryNav}>
                            <NavLink style={{ paddingLeft: 0 }} className={classes.itemContainer} to="/dashboard" activeStyle={activeStyle}>
                                <DashboardIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                <p className={classes.itemText}>{t("Dashboard")}</p>
                            </NavLink>
                    
                            <RoleContainer user={user} roles={['SuperAdmin', 'AccountAdmin']}>
                                <NavLink className={classes.itemContainer} to="/account" activeStyle={activeStyle}>
                                    <AccountIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                    <p className={classes.itemText}>{t("Account")}</p>
                                </NavLink>
                            </RoleContainer>
                    
                            <RoleContainer user={user} roles={['SuperAdmin']}>
                                <NavLink className={classes.itemContainer} to="/customer" activeStyle={activeStyle}>
                                    <AccountIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                    <p className={classes.itemText}>{t("Customer")}</p>
                                </NavLink>
                            </RoleContainer>
                        </div>
                    }
                    {auth?.authenticated &&
                        <div className={classes.secondaryNav} >
                            <NavLink to="/support" className={classes.itemContainer} activeStyle={activeStyle}>
                                <SupportIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                <p className={classes.itemText}>{t("Support")}</p>
                            </NavLink>
                            <div onClick={() => setShowGuide(true)} className={classes.itemContainer}>
                                <GuideIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                <p className={classes.itemText}>{t("Guide")}</p>
                            </div>
                            <NavLink to="/contact" className={classes.itemContainer} activeStyle={activeStyle}>
                                <ContactIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                <p className={classes.itemText}>{t("Contact")}</p>
                            </NavLink>
                            <NavLink onClick={logout} to="/" className={classes.itemContainer}>
                                <LogoutIcon className={classes.itemLogo} fill={user?.company?.branding?.colors ? user?.company?.branding?.colors : Colors.mainBlue} stroke='white' />
                                <p className={classes.itemText}>{t("Logout")}</p>
                            </NavLink>
                        </div>
                    }

                    {auth?.authenticated && 
                        <div className={classes.sidebarWrapper}>
                            <Sidebar handleOpenGuide={() => setShowGuide(true)} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        borderBottom: props => props.color,
        position: 'fixed',
        width: '100%',
        background: '#fff',
        zIndex: 100,
        '@media (max-width: 1024px)': {
            justifyContent: 'space-between', 
        }
    },
    content: {
        display: 'flex', 
        justifyContent: 'space-between', 
        height: 100, 
        paddingBottom: 5,
        '@media (max-width: 1024px)': {
            justifyContent: 'center', 
            height: 60,
            paddingBottom: 0,
        },
    },
    logoContainer: {
        width: '40%', 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            width: 'unset',
        },
    },
    itemContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        paddingLeft: 40,
        cursor: 'pointer'
    },
    itemLogo: {
        height: 40
    },
    itemText: {
        fontSize: 12,
        paddingTop: 5
    },
    secondaryNav: {
        width: '40%', 
        display: 'flex', 
        paddingTop: 20,
        justifyContent: 'flex-end',
        '@media (max-width: 1024px)': {
            display: 'none'
        }
    },
    primaryNav: {
        display: 'flex', 
        width: '40%',
        paddingTop: 20,
        '@media (max-width: 1024px)': {
            display: 'none'
        }
    },
    sidebarWrapper: {
        position: 'absolute',
        top: '50%',
        right: '10%',
        transform: 'translateY(-50%)',
        '@media (min-width: 1025px)': {
            display: 'none'
        }
    },
    headerWidth: {
        position: 'relative',
        width: '70%',
        '@media (max-width: 1024px)': {
            width: '100%',
        },
    },
    logo: {
        width: '260px',
        marginLeft: -10,
        maxHeight: '100px',
        maxWidth: '300px',
        '@media (max-width: 1025px)': {
            maxWidth: '160px',
            maxHeight: '50px',
        },
    },
    companylogo: {
        maxHeight: 100,
        marginLeft: -12,
        maxWidth: 300,
        '@media (max-width: 1025px)': {
            maxWidth: '150px',
            maxHeight: '50px',
        },
    },
    pointerEvent: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    pointerEventNone: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        pointerEvent: 'none'
    },
})