import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';

import { setActivePage } from '../../redux/slices/accountSlice';

import SubNavigationHeader from '../../components/SubNavigationHeader';
import Profile from './Profile';
import QAManager from './QAManager';
import Branding from '../branding/Branding';

import { COMPANY_BY_ID } from '../../graphql/mutations';

export default function Account() {
    const [company, setCompany] = useState();

    const { activePage } = useSelector(state => state.account)
    const { user } = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const classes = useStyles();
    
    const { data, refetch } = useQuery(COMPANY_BY_ID, { variables: { id: user?.company?._id } })

    useEffect(() => {
        setCompany(data?.company)
    }, [data])

    return (
        <div className={classes.container}>
            <SubNavigationHeader
                activePage={activePage}
                // items={user?.role === 'SuperAdmin' ? ['Profile', 'Q&A Manager', 'Branding'] : ['Profile', 'Q&A Manager']}
                items={['Profile', 'Q&A Manager']}
                onClick={(item) => dispatch(setActivePage(item))}
            />
            <div className={classes.content}>
                {activePage === 'Profile' && <Profile company={company} companyRefetch={refetch} />}
            </div>
            <div className={classes.QAManger}>
                {activePage === 'Q&A Manager' && <QAManager />}
            </div>
            {/* <div className={classes.content}>
                {activePage === 'Branding' && <Branding />}
            </div> */}
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    content: {
        width: '100%',
        justifyContent: 'center',
        maxHeight: 'calc(100vh - 216px)',
        overflowY: 'overlay'
    },
    QAManger: {
        width: '70%',
        justifyContent: 'center'
    }
});
