import { createSlice } from '@reduxjs/toolkit';

export const customerSlice = createSlice({
	name: 'customer',
	initialState: {
		activePage: 'Company',
	},
	reducers: {
        setActivePage: (state, action) => {
            state.activePage = action.payload
        },
    }
});

export const {
    setActivePage
} = customerSlice.actions

export default customerSlice.reducer;