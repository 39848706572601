import React from 'react';

import LoadingButton from '@material-ui/lab/LoadingButton';

function CustomLoadingButton({ loading, title, onClick, variant="outlined" }) {

    return (
        <LoadingButton onClick={onClick} style={{ minWidth: 130, fontSize: 10 }} loading={loading} variant={variant}>
            {title ? title : 'Submit'}
        </LoadingButton>
    )
}

export default CustomLoadingButton
