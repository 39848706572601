import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
	name: 'global',
	initialState: {
		showOverlay: false,
		flashMessage: {
			show: false,
			title: "Something went wrong",
			description: ''
		},
		language: {
			shortName: 'en',
			fullName: 'english'
		},
		questionsLanguage: {
			shortName: 'en',
			fullName: 'english'
		},
		guidePath: 'dashboard',
	},
	reducers: {
	    showOverlay: (state, action) => {
            state.showOverlay = true
        },
		hideOverlay: (state) => {
			state.showOverlay = false;
		},
		triggerFlashMessage: (state, action) => {
			state.flashMessage.show = !state.flashMessage.show;
			state.flashMessage.title = action?.payload?.title || '';
			state.flashMessage.type = action?.payload?.type || state.flashMessage.type;
			state.flashMessage.description = action?.payload?.description || ''
		},
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setQuestionsLanguage: (state, action) => {
			state.questionsLanguage = action.payload;
		},
		setGuidePath: (state, action) => {
			state.guidePath = action.payload;
		},
	}
});

export const {
	hideOverlay,
	showOverlay,
	triggerFlashMessage,
	setLanguage,
	setQuestionsLanguage,
	setGuidePath,
} = globalSlice.actions

export default globalSlice.reducer;
