import React, { useEffect, useState } from 'react'
import DCNavigation from '../../components/DCNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveStep, setJobPosition, setDeadlineListing, setDeadlineAnswer, setStakeholders, setCase, setTemplateName } from '../../redux/slices/createCaseSlice'
import CaseInfo from '../../components/CaseInfo'
import Questions from '../../components/Questions'
import Preview from '../../components/Preview'
import { makeStyles } from '@material-ui/styles'
import { validatePageChange, validateCompleteTemplate } from '../../utils/validators'
import _ from 'lodash'
import { formatTemplate} from '../../services/caseService'
import { TEMPLATE_BY_ID, UPDATE_TEMPLATE } from '../../graphql/mutations'
import { useMutation, useQuery } from '@apollo/client'
import { triggerFlashMessage } from '../../redux/slices/globalSlice'
import { useHistory } from 'react-router'
import TemplateDialog from '../../components/TemplateDialog'
import { TemplatePrompt } from '../../components/TempatePrompt'
import DCLoader from '../../components/DCLoader'

function UpdateTemplate({ match }) {
    const [caseErrors, setCaseErrors] = useState({});
    const [errorIndex, setErrorIndex] = useState();
    const [showPrompt, setShowPrompt] = useState(false);
    const [templateCopy, setTemplateCopy] = useState();
    const [templateId, setTemplateId] = useState();
    const [submitError, setSubmitError] = useState();

    const { activeStep, case: caseState, theme, category } = useSelector(state => state.createCase)
    
    const auth = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth)
    const [selectedLanguage, setSelectedLanguage] = useState(auth?.user?.company?.settings?.languages[auth?.user?.company?.settings?.defaultQALanguage]);
    
    const dispatch = useDispatch()
    const classes = useStyles();
    const history = useHistory()

    const { data, loading } = useQuery(TEMPLATE_BY_ID, { variables: { id: templateId }, fetchPolicy: 'cache-and-network' });

    const [updateTemplate] = useMutation(UPDATE_TEMPLATE);

    const t = (str) => str;

    useEffect(() => {
        const formattedTemplate = {
            ...data?.template,
            deadline: {
                answer: `${data?.template?.deadline?.answer} hours`,
                listing: `${data?.template?.deadline?.listing} hours`
            },
        }

        setTemplateCopy(formattedTemplate)
        dispatch(setCase(formattedTemplate))
    }, [data])

    useEffect(() => {
        setTemplateId(match?.params?.id)
    }, [data])

    /**
     * Validate on page changes
     */
    const handlePageChange = (item) => {
        const validatedPage = validatePageChange(activeStep, item, true)
        setCaseErrors(validatedPage?.errors)
        setErrorIndex(validatedPage?.errorIndex)
        dispatch(setActiveStep(validatedPage?.errorIndex))
        if(_.isEmpty(validatedPage?.errors)) {
            setErrorIndex()
            return dispatch(setActiveStep(item))
        }
    }

    const saveTemplate = async (status) => {
        let variables = formatTemplate(caseState, user, true)
        variables.status = status;
        delete variables.creator;

        if (status === 'complete') {
            const validated = validateCompleteTemplate(caseState)
            if (!_.isEmpty(validated)) {
                dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong, please follow complete template guidelines") }))
                setSubmitError(validated)
                return Promise.reject()
            }
        }

        try {
            await updateTemplate({ variables: { input: variables } })
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully updated template") }))
            dispatch(setCase(templateCopy))
            setTimeout(() => {
                setShowPrompt(false)
                history.push('/dashboard')
            }, 50)
        } catch(err) {
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        }
    }

    if(loading) {
        return <DCLoader />
    }

    return (
        <div style={{ maxHeight: 'calc(100vh - 106px)', overflow: 'hidden'}}>
            <TemplatePrompt
                when={!_.isEqual(caseState, templateCopy)}
                onCancel={() => true}
                onSaveClick={saveTemplate}
                error={submitError}
                setError={setSubmitError}
            />

            {showPrompt &&
                <TemplateDialog 
                    title="You are about to save this Template."
                    handleSave={(status) => saveTemplate(status)} 
                    handleCancel={() => setShowPrompt(false)} 
                    status={caseState?.status}
                    error={submitError}
                />          
            }
    
            <div className={classes.contentContainer}>
                <DCNavigation 
                    setShowPrompt={setShowPrompt}
                    setActiveStep={(index) => handlePageChange(index)}
                    activeStep={activeStep} 
                    errorIndex={errorIndex} 
                    type="Template"
                />
            </div>

            <div className={classes.contentContainer}>
                {activeStep === 0 && 
                    <CaseInfo
                        isTemplate 
                        errors={caseErrors}
                        caseState={caseState} 
                        setTemplateName={(e) => dispatch(setTemplateName(e))}
                        setJobPosition={(e) => dispatch(setJobPosition(e))}
                        setDeadlineListing={(e) => dispatch(setDeadlineListing(e))}
                        setDeadlineAnswer={(e) => dispatch(setDeadlineAnswer(e))}
                        setStakeholders={(e) => dispatch(setStakeholders(e))}
                    /> 
                }  
                {activeStep === 1 && <Questions theme={theme} category={category} caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> }
                {activeStep === 2 && <Preview caseState={caseState} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} /> }
            </div>
        </div>
    )
}

const useStyles = makeStyles({
})

export default UpdateTemplate
