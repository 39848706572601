import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Step4_1Icon from '../assets/images/5pointrating/Step4-1.svg';
import Step4_2Icon from '../assets/images/5pointrating/Step4-2.svg';
import Step4_3Icon from '../assets/images/5pointrating/Step4-3.svg';
import Step4_4Icon from '../assets/images/5pointrating/Step4-4.svg';
import Step4_5Icon from '../assets/images/5pointrating/Step4-5.svg';
import { setReferenceQuestions } from '../redux/slices/answerQuestions';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import { formatReferenceAnswer } from '../services/caseService';
import { Colors } from '../styles/globalStyles';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';
import { referenceAnswerOrderTranslation } from '../utils/constansts';
import { validateQuestionAnswerOther, validateQuestionAnswerTitle } from '../utils/validators';
import QuestionAnswerElaboration from './QuestionAnswerElaboration';
import QuestionAnswerOther from './QuestionAnswerOther';

const data = [
    {
        icon: Step4_1Icon,
        color: '#e05d5d',
    },
    {
        icon: Step4_2Icon,
        color: '#e5906c',
    },
    {
        icon: Step4_3Icon,
        color: '#ffd955',
    },
    {
        icon: Step4_4Icon,
        color: '#a7e880',
    },
    {
        icon: Step4_5Icon,
        color: '#0ece16',
    },
];

function QuestionAnswerMultipleChoice({
    title,
    options,
    questionIndex,
    enabled,
    elaboration,
    selectedLanguage,
    references,
    showAnswers,
    isReport,
    max,
    questionId,
    isClosed = false,
    is5PointRating = false,
}) {
    const [activeReferences, setActiveReferences] = useState();
    const [otherAnswerIndex, setOtherAnswerIndex] = useState();
    const [dontKnowAnswerIndex, setDontKnowAnswerIndex] = useState();

    const auth = useSelector(state => state.auth);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSelectedOption = (checked, index, ref, isDontKnow) => {
        console.log('🚀 ~ file: QuestionAnswerMultipleChoice.js:75 ~ handleSelectedOption ~ ref:', checked, ref);
        const currentAnswers = ref?.questions[questionIndex]?.answer?.answers
            ? ref?.questions[questionIndex]?.answer?.answers
            : [];
        if (isDontKnow && currentAnswers?.some(answer => answer === index)) {
            const newReference = formatReferenceAnswer(ref, questionIndex, []);
            console.log(
                '🚀 ~ file: QuestionAnswerMultipleChoice.js:81 ~ handleSelectedOption ~ newReference:',
                newReference
            );
            return dispatch(setReferenceQuestions([newReference]));
        }
        if (isDontKnow) {
            const newReference = formatReferenceAnswer(ref, questionIndex, [index]);
            console.log(
                '🚀 ~ file: QuestionAnswerMultipleChoice.js:86 ~ handleSelectedOption ~ newReference:',
                newReference
            );
            return dispatch(setReferenceQuestions([newReference]));
        }
        if (checked && max === 1) {
            const newReference = formatReferenceAnswer(ref, questionIndex, [index]);
            console.log(
                '🚀 ~ file: QuestionAnswerMultipleChoice.js:91 ~ handleSelectedOption ~ newReference:',
                newReference
            );
            return dispatch(setReferenceQuestions([newReference]));
        }
        if (!checked && max > 1 && currentAnswers?.length === max) {
            console.log('🚀 ~ file: QuestionAnswerMultipleChoice.js:95 ~ handleSelectedOption ~ max:', max);
            return dispatch(
                triggerFlashMessage({
                    type: 'error',
                    title: t(`You can not choose more than {{max}} answers`, { max }),
                })
            );
        }
        if (
            checked &&
            currentAnswers?.some(answer => {
                return answer === index;
            })
        ) {
            const newAnswers = currentAnswers?.filter(a => a !== index);
            console.log(
                '🚀 ~ file: QuestionAnswerMultipleChoice.js:110 ~ handleSelectedOption ~ newAnswers:',
                newAnswers
            );
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers);
            return dispatch(setReferenceQuestions([newReference]));
        }
        if (checked) {
            const newAnswers = currentAnswers?.concat(index)?.filter(answer => answer !== dontKnowAnswerIndex);
            console.log(
                '🚀 ~ file: QuestionAnswerMultipleChoice.js:116 ~ handleSelectedOption ~ newAnswers:',
                newAnswers
            );
            const newReference = formatReferenceAnswer(ref, questionIndex, newAnswers);
            dispatch(setReferenceQuestions([newReference]));
        }
        console.log('lol');
    };

    const getQuestionChecked = (answers, index, ref) => {
        if (isReport) {
            const question = ref?.questions?.find(e => e?.question?._id === questionId);
            const foundAnswers = question?.answer?.answers;
            return foundAnswers ? foundAnswers?.some(a => a === index) : false;
        } else {
            return answers ? answers?.some(a => a === index) : false;
        }
    };

    useEffect(() => {
        if (isReport) {
            setActiveReferences(
                references?.filter(ref => {
                    return ref?.listingMailSend && ref?.questions?.some(e => e?.question?._id === questionId);
                })
            );
        } else {
            setActiveReferences(references);
        }
    }, [references]);

    useEffect(() => {
        options?.forEach((answer, index) => {
            if (validateQuestionAnswerOther(answer?.title)) {
                setOtherAnswerIndex(index);
            }
            if (!validateQuestionAnswerTitle(answer?.title)) {
                setDontKnowAnswerIndex(index);
            }
        });
    }, [options]);

    const getReferenceAnswerText = () => {
        return referenceAnswerOrderTranslation?.translations?.find(e => e?.language === selectedLanguage?.shortName)
            ?.name;
    };

    return (
        <div className={classes.breakAvoid}>
            <p className={classes.title}>{makeUnderlineQuestion(title)}</p>
            {isReport && (
                <div>
                    <p className={classes.referenceAnswerOrderText}>{getReferenceAnswerText()}</p>
                    <div className={classes.referenceAnswerContainer}>
                        {activeReferences?.map((ref, index) => {
                            return (
                                <p className={classes.referenceAnswer}>
                                    <span style={{ fontWeight: '500', paddingRight: index === 0 ? 12 : 9 }}>
                                        {index + 1}.
                                    </span>
                                    {!isClosed ? (
                                        <>
                                            {`${ref?.firstName} ${ref?.lastName} `}
                                            <span>{`(${ref?.type})`}</span>
                                        </>
                                    ) : (
                                        <span>{`${ref?.type}`}</span>
                                    )}
                                </p>
                            );
                        })}
                    </div>
                </div>
            )}

            <div className={classes.optionsContainerVertical}>
                <FormControl component="fieldset">
                    {options?.map((option, index) => {
                        return (
                            <div key={index} className={classes.optionLight}>
                                {activeReferences?.map((ref, refIndex) => {
                                    const isChecked = getQuestionChecked(
                                        ref?.questions[questionIndex]?.answer?.answers,
                                        index,
                                        ref
                                    );
                                    const isDontKnow = !validateQuestionAnswerTitle(option?.title);
                                    return (
                                        <div className={classes.checkboxContainer}>
                                            {isReport && (
                                                <p style={{ fontWeight: '600' }}>{index === 0 && refIndex + 1}</p>
                                            )}
                                            {is5PointRating && !isDontKnow && (
                                                <Radio
                                                    style={{
                                                        color: auth?.user?.company?.branding?.colors
                                                            ? auth?.user?.company?.branding?.colors
                                                            : Colors.mainBlue,
                                                    }}
                                                    icon={
                                                        <img
                                                            src={data[index]?.icon}
                                                            alt="5 point rating"
                                                            style={{
                                                                borderRadius: 200,
                                                                width: 25,
                                                                padding: 6,
                                                                height: 25,
                                                                opacity: 0.5,
                                                                backgroundColor: 'transparent',
                                                            }}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <img
                                                            src={data[index]?.icon}
                                                            alt="5 point rating"
                                                            style={{
                                                                width: 25,
                                                                height: 25,
                                                                backgroundColor: 'transparent',
                                                                border: `4px solid ${data[index]?.color}`,
                                                                borderRadius: 200,
                                                                padding: 2,
                                                                opacity: 1,
                                                            }}
                                                        />
                                                    }
                                                    checked={showAnswers && isChecked}
                                                    onClick={e =>
                                                        enabled &&
                                                        handleSelectedOption(e.target.checked, index, ref, isDontKnow)
                                                    }
                                                />
                                            )}
                                            {(isDontKnow || !is5PointRating) && (
                                                <Radio
                                                    style={{
                                                        color: auth?.user?.company?.branding?.colors
                                                            ? auth?.user?.company?.branding?.colors
                                                            : Colors.mainBlue,
                                                        padding: 15,
                                                        paddingLeft: 16,
                                                    }}
                                                    checked={showAnswers && isChecked}
                                                    onClick={e =>
                                                        enabled &&
                                                        handleSelectedOption(e.target.checked, index, ref, isDontKnow)
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                                <div className={index === 0 ? classes.answersContainerTop : classes.answersContainer}>
                                    <p className={classes.answerTitle}>{option?.title}</p>
                                </div>
                            </div>
                        );
                    })}
                </FormControl>
                {activeReferences
                    ?.filter(ref => ref?.listingMailSend)
                    ?.map(ref => {
                        if (
                            otherAnswerIndex &&
                            ref?.questions[questionIndex]?.answer?.answers?.some(a => a === otherAnswerIndex)
                        ) {
                            return (
                                <QuestionAnswerOther
                                    showAnswers={showAnswers}
                                    reference={ref}
                                    selectedLanguage={selectedLanguage}
                                    enabled={enabled}
                                    questionIndex={questionIndex}
                                    isReport={isReport}
                                    isClosed={isClosed}
                                />
                            );
                        } else if (elaboration) {
                            return (
                                <div style={{ marginTop: 20 }}>
                                    <QuestionAnswerElaboration
                                        questionId={questionId}
                                        isReport={isReport}
                                        showAnswers={showAnswers}
                                        reference={ref}
                                        selectedLanguage={selectedLanguage}
                                        enabled={enabled}
                                        questionIndex={questionIndex}
                                        isClosed={isClosed}
                                    />
                                </div>
                            );
                        }
                        return null;
                    })}
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    title: {
        marginBottom: '10px',
        fontSize: '20px',
        fontWeight: '500',
        fontStyle: 'italic',
        '@media (max-width: 480px)': {
            fontSize: 15,
        },
    },
    optionsContainerVertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingTop: '10px',
        '@media (max-width: 480px)': {
            zoom: 0.8,
        },
    },
    optionLight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#F2F7Fb',
        paddingLeft: 10,
        borderBottom: '1px solid #000',
        minHeight: 40,
        '&:first-child': {
            borderTop: '1px solid #000',
        },
    },
    breakAvoid: {
        '@media print': {
            breakInside: 'avoid',
        },
    },
    referenceAnswerOrderText: {
        fontWeight: '500',
        paddingLeft: 10,
    },
    referenceAnswerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxHeight: 100,
        flexWrap: 'wrap',
    },
    referenceAnswer: {
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
    },
    checkboxContainer: {
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
    },
    answerTitle: {
        paddingLeft: 5,
        fontWeight: 500,
        fontSize: 18,
        '@media (max-width: 480px)': {
            fontSize: 15,
        },
    },
});

export default QuestionAnswerMultipleChoice;
