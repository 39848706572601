
import store from "../redux/store";
import { getLanguageLocal } from "./getLanguage";

export const referenceTypes = [
    'Manager',
    'Employee',
    'Team-colleague',
    'Non Team-colleague',
    'Customer',
    'Supplier',
    'External partner',
    'Teacher',
    'Fellow-student',
    'Other',
    'Self-selected'
]

export const userRoles = [
    'AccountAdmin',
    'CaseDesign',
    'TemplateOnly',
]

export const userRolesSuper = [
    'SuperAdmin',
    'AccountAdmin',
    'CaseDesign',
    'TemplateOnly',
]

export const prettyUserRoles = [
    'Account-Admin-Access',
    'Case-Design-Access',
    'Template-Only-Access',
]

export const prettyUserRolesSuper = [
    'Super-Admin-Access',
    'Account-Admin-Access',
    'Case-Design-Access',
    'Template-Only-Access',
]

export const deadlineOptionsTranslate = (t) => {
    return [
        `1 ${t('hour')}`,
        `12 ${t('hours')}`,
        `24 ${t('hours')}`,
        `36 ${t('hours')}`,
        `48 ${t('hours')}`,
        `72 ${t('hours')}`,
        `96 ${t('hours')}`,
    ]
}

export const defaultDeleteDateOptions = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150];

export const getDefaultCase = () => {
    return {
        jobPosition: '',
        stakeholders: [],
        references: defaultReferences,
        deadline: {
            listing: '48 hours',
            answer: '48 hours'
        },
        candidate: emptyCandidate,
    }
}

export const getDefaultTemplate = () => {
    return {
        jobPosition: '',
        stakeholders: [],
        references: defaultReferences,
        deadline: {
            listing: '48 hours',
            answer: '48 hours'
        },
    }
}

export const getMarks = (t) => {
    return [
        {
            value: -1000,
            label: t('Very high degree'),
        },
        {
            value: 0,
            label: t('Neither/nor'),
        },
        {
            value: 1000,
            label: t('Very high degree'),
        },
    ];
}

export const getMarks2 = (selectedLanguage) => {
    return [
        {
            value: -1000,
            label: getLanguageLocal(marksHighLowTranslations, selectedLanguage)?.name,
        },
        {
            value: 0,
            label: getLanguageLocal(marksMidTranslations, selectedLanguage)?.name,
        },
        {
            value: 1000,
            label: getLanguageLocal(marksHighLowTranslations, selectedLanguage)?.name,
        },
    ];
}

export const createCaseNavigation = [
    'Case info',  
    'Preview',
    'Status'
]

export const createCaseNavigationClosedStatus = [
    "Preview",
    "Status"
]

export const createCaseNavigationFull = [
    'Case info', 
    'Questions | Rating Points', 
    'Preview',
    'Status'
]

export const createCaseNavigationNoStatus = [
    'Case info', 
    'Questions | Rating Points', 
    'Preview',
]

export const createCaseNavigationNoStatusTemplateOnly = [
    'Case info', 
    'Preview',
]

export const createTemplateNavigation = [
    'Case info',
    'Questions | Rating Points', 
    'Preview',
]

export const defaultReferences = [
    {
        _id: 1,
        type: 'Manager',
    },
    {
        _id: 2,
        type: 'Employee'
    },
    {
        _id: 3,
        type: 'Team-colleague'
    }
]

export const emptyCandidate = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    mobile: '',
    preferedLanguage: '',
}

export const formatDate = (isCal=false) => {
    let format = 'YYYY-MM-DD';
    const languageSetting = store.getState().global.language;

    if(languageSetting === 'da') {
        format = 'DD-MM-YYYY';
    }

    if(isCal) {
        format = 'yyyy-MM-dd';
        if(languageSetting === 'da'){
            format = 'dd-MM-yyyy';
        }
    }

    return format;
}

export const referenceMailFilledText = () => {
    return {
        translations:   [
            {
                "language": "da",
                "name": "Hvis du ønsker at ændre Referencens mail, bedes du slette Referencen ved et klik på skraldespandsikonet – og oprette Referencen på ny."
            },
            {
                "language": "en",
                "name": "If the mail address of the Reference has to be updated – please delete the Reference on the garbage can and list the Reference once again."
            }
        ]
    }
}

export const referenceOpenText = () => {
    return {
        translations:   [
            {
                "language": "da",
                "name": "Gem registreringen af en reference, før en ny registrering kan foretages."
            },
            {
                "language": "en",
                "name": "Save your registration of one reference before beginning registration of the next."
            }
        ]
    }
}

export const openAnswerTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Åbent svar"
        },
        {
            "language": "en",
            "name": "Open answer"
        }
    ]
}

export const dontKnowAnswerTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Ved ikke"
        },
        {
            "language": "en",
            "name": "Don't know"
        }
    ]
}

export const elaborationHeaderTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Venligst uddyb dit svar"
        },
        {
            "language": "en",
            "name": "Please elaborate on your answer"
        }
    ]
}

export const elaborationBodyTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Skriv her"
        },
        {
            "language": "en",
            "name": "Write here"
        }
    ]
}

export const otherHeaderTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Andet"
        },
        {
            "language": "en",
            "name": "Other"
        }
    ]
}

export const otherBodyTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Skriv her"
        },
        {
            "language": "en",
            "name": "Write here"
        }
    ]
}

export const inputAnswerTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Dit svar"
        },
        {
            "language": "en",
            "name": "Your answer"
        }
    ]
}

export const marksHighLowTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "I meget\nhøj grad"
        },
        {
            "language": "en",
            "name": "To a very\nhigh degree"
        }
    ]
}

export const marksMidTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Både/og"
        },
        {
            "language": "en",
            "name": "Neither/nor"
        }
    ]
}

export const dontKnowTranslations =  {
    translations:   [
        {
            "language": "da",
            "name": "Ved ikke"
        },
        {
            "language": "en",
            "name": "Don't know"
        }
    ]
}

export const candidateTranslations = {
    translations:   [
        {
            "language": "da",
            "name": "Kandidat"
        },
        {
            "language": "en",
            "name": "Candidate"
        }
    ]
}

export const referenceNameTranslations = {
    translations:   [
        {
            "language": "da",
            "name": "[Reference navn]"
        },
        {
            "language": "en",
            "name": "[Reference name]"
        }
    ]
}

export const candidateNameTranslations = {
    translations:   [
        {
            "language": "da",
            "name": "[Kandidat navn]"
        },
        {
            "language": "en",
            "name": "[Candidate name]"
        }
    ]
}

export const questionUnavailableTranslation = {
    translations:   [
        {
            "language": "da",
            "name": "Spørgsmålet er ikke tilgængeligt i det valgte sprog"
        },
        {
            "language": "en",
            "name": "The question is not available in the selected language"
        }
    ]
}

export const notAnsweredTranslation = {
    translations:   [
        {
            "language": "da",
            "name": "Ikke svaret"
        },
        {
            "language": "en",
            "name": "Not answered"
        }
    ]
}

export const referenceAnswerOrderTranslation = {
    translations:   [
        {
            "language": "da",
            "name": "Reference svar rækkefølge:"
        },
        {
            "language": "en",
            "name": "Reference answer order:"
        }
    ]
}

export const candidateInviteConsent = {
    translations:   [
        {
            "language": "da",
            "name": "Jeg bekræfter hermed referencens accept og tilladelse ifm. deltagelse som reference, registrering af kontaktoplysninger og fremsendelse af et digital reference-spørgeskema."
        },
        {
            "language": "en",
            "name": "I hereby confirm the reference's acceptance and permission for participation as a reference, registration of contact information and submission of a digital reference questionnaire."
        }
    ]
}

export const types = [
    'RatingPoints',
    'Open',
    'Prio',
    'Multiple'
]
