import React, {useState, useEffect} from 'react'

import moment from 'moment'
import { Colors } from '../../styles/globalStyles';
import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../../utils/constansts';

import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import MailIcon from '@material-ui/icons/Mail'
import _ from 'lodash'
import CustomAlertDialog from '../../components/CustomAlertDialog';
import { validateUser } from '../../utils/validators';

const Candidate = ({ caseInfo, handleCandidate, handleCancelEdit, refetchCase, handleResendCandidate, handleResendCandidateHalftime, handleResendCandidateDeadline, isClosed = false}) => {
    const [editActive, setEditActive] = useState(false)
    const [hasFilledRef, setHasFilledRef] = useState(true);
    const [showResendModal, setShowResendModal] = useState (false);
    const [resendAction, setResendAction] = useState('');
    const [errors, setErrors] = useState('');
    
    const classes = useStyles()
    
    const t = (str) => str;

    const formatListingDate = (array) => {
        return array?.map((date) => moment(parseInt(date)).format(`${formatDate()} HH:mm`)).join(', \n')
    };

    useEffect(() => {
        setHasFilledRef(caseInfo?.references?.some(r => !r?.email ));
    }, [caseInfo])

    const handleShowModal = (action) => {
        setResendAction(action)
        setShowResendModal(true)
    }
    
    const ResendAdornment = ({ className, resend }) => {
        if(isClosed) return null;
        return (
            <InputAdornment position='end'>
                {className && hasFilledRef && <span className={className}/> }
                 <Tooltip title="Resend mail">
                    <IconButton aria-label="email" onClick={() => resend()}>
                        <MailIcon />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
        ) 
    }

    const handleLock = () => {
        const user = {
            firstName: caseInfo?.candidate?.firstName,
            lastName: caseInfo?.candidate?.lastName,
            email: caseInfo?.candidate?.email,
        }
        let errObj = validateUser(user);
        
        if(!_.isEmpty(errObj)) {
            setErrors(errObj);
        } else {
            setErrors()
            setEditActive(false);
        }
    };

    return ( 
         <div className={classes.outerContainer}>
            {showResendModal && 
                <CustomAlertDialog 
                    open={showResendModal} 
                    handleClose={() => setShowResendModal(false)}
                    handleCancel={() => setShowResendModal(false)}
                    handleAccept={() => resendAction === 'deadline' ? handleResendCandidateDeadline() : resendAction === 'halftime' ? handleResendCandidateHalftime() : handleResendCandidate()} 
                    alertTitle={"Resend mail"} 
                    alertText={"Are you sure you want to resend mail to Candidate?"} 
                    okText="Resend"
                />
            }
            <div className={classes.headerContainer}>
                {hasFilledRef && 
                    <div className={classes.statusSpan}>
                        {caseInfo?.candidate?.halftimeDates?.send && <span className={classes.statusYellow}/>}
                        {caseInfo?.candidate?.deadlineDates?.send && <span className={classes.statusRed}/>}
                    </div>
                }
                <p className={classes.headerTitle}>{t("Candidate Information")}</p>
                {!isClosed && <div style={{borderRadius: '0%', height: '100%', width: 40, display:'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: editActive ? '#1f5f1f' : '#ab1d1d', position: 'absolute', right: 0}} >
                    {editActive
                        ? <LockOpenIcon style={{ fontSize: 20 }} className={classes.lock} onClick={handleLock} /> 
                        : <LockIcon className={classes.lock} onClick={()=> setEditActive(true)}/>
                    }
                </div>}
            </div>
            <div className={classes.inputsContainer}>
                <div className={classes.namesContainer}>
                    <div className={classes.nameContainer}>
                        <TextField 
                            name="firstName" 
                            error={errors?.firstName}
                            size='small' 
                            label={t("First name")} 
                            disabled={!editActive} 
                            fullWidth 
                            value={isClosed ? "-" : caseInfo?.candidate?.firstName ?? ''} 
                            onChange= {(e) => handleCandidate(e.target.name, e.target.value)} 
                            className={classes.input} 
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={classes.nameContainer}>
                        <TextField 
                            name="lastName" 
                            error={errors?.lastName}
                            size='small' 
                            label={t("Last name")} 
                            disabled={!editActive} 
                            fullWidth 
                            value={isClosed ? "-" : caseInfo?.candidate?.lastName ?? ''} 
                            onChange= {(e) => handleCandidate(e.target.name, e.target.value)} 
                            className={classes.input} 
                            InputLabelProps={{ shrink: true }} 
                        />
                    </div>
                </div>
                <div className={classes.inputFieldContainer}>
                    <TextField 
                        name="email" 
                        error={errors?.email}
                        size='small' 
                        label={t("Email")} 
                        disabled={!editActive} 
                        fullWidth 
                        value={isClosed ? "-" : caseInfo?.candidate?.email ?? ''} 
                        onChange= {(e) => handleCandidate(e.target.name, e.target.value)} 
                        className={classes.input} 
                        InputLabelProps={{ shrink: true }} 
                    />
                </div>
                <div className={classes.phoneContainer}>
                    <div className={classes.nameContainer}>
                        <TextField 
                            name="phone" 
                            size='small' 
                            label={t("Work phone")} 
                            disabled={!editActive} 
                            fullWidth 
                            value={isClosed ? "-" : caseInfo?.candidate?.phone ?? ''} 
                            onChange= {(e) => handleCandidate(e.target.name, e.target.value)} 
                            className={classes.input} 
                            InputLabelProps={{ shrink: true }}  
                        />
                    </div>
                    <div className={classes.nameContainer}>
                        <TextField 
                            name="mobile" 
                            size='small' 
                            label={t("Mobile phone")} 
                            disabled={!editActive} 
                            fullWidth 
                            value={isClosed ? "-" : caseInfo?.candidate?.mobile ?? ''} 
                            onChange= {(e) => handleCandidate(e.target.name, e.target.value)} 
                            className={classes.input} 
                            InputLabelProps={{ shrink: true }} 
                        />
                    </div>
                </div>
                <div className={classes.inputFieldContainer}>
                    <TextField 
                        name="deadline.listing"
                        size='small' 
                        label={t("Deadline: Listing of references")}
                        disabled={true} 
                        fullWidth 
                        value={caseInfo?.deadline?.listing ? `${caseInfo?.deadline?.listing}` : ''} 
                        className={classes.input} 
                        InputLabelProps={{ shrink: true }} 
                    />
                </div>
                <div className={classes.inputFieldContainer}>
                    <TextField
                        size='small'
                        label="Date: Invitation mail to Candidate"
                        disabled={true}
                        fullWidth
                        multiline
                        InputProps={{ endAdornment: <ResendAdornment resend={() => handleShowModal('listing')} /> }}
                        value={!caseInfo?.candidate?.listingMailSend ? 'Not yet send' : formatListingDate(caseInfo?.candidate?.listingMailDate)}
                        className={classes.input}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                {caseInfo?.candidate?.halftimeDates?.send && 
                    <div className={classes.inputFieldContainer}>
                        <TextField
                            size='small'
                            label="Half-time reminder date"
                            disabled={true}
                            fullWidth 
                            multiline
                            InputProps={{
                                endAdornment: <ResendAdornment className={classes.statusDeadlinesYellow} resend={() => handleShowModal('halftime')} /> 
                            }}
                            value={formatListingDate(caseInfo?.candidate?.halftimeDates.dates) ?? ''}
                            className={classes.input}
                            InputLabelProps={{ shrink: true }} 
                        />
                    </div>
                }
                {caseInfo?.candidate?.deadlineDates?.send && 
                    <div className={classes.inputFieldContainer}>
                        <TextField
                            size='small'
                            label="Deadline exceeded date"
                            disabled={true}
                            fullWidth
                            multiline
                            InputProps={{
                                endAdornment: <ResendAdornment className={classes.statusDeadlinesRed} resend={() => handleShowModal('deadline')} />
                            }}
                            value={formatListingDate(caseInfo?.candidate?.deadlineDates.dates) ?? ''}
                            className={classes.input}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    input: {
        "& .MuiOutlinedInput-input.Mui-disabled": {
            "-webkit-text-fill-color": "rgba(0,0,0, 0.65) !important"
        },
    },
    outerContainer: {
        width: 'calc(50% - 10px)', 
        paddingRight: 10
    },
    headerContainer: {
        position: 'relative',
        height: 45, 
        backgroundColor: Colors.mainBlue,  
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 668px)': {
            height: 40, 
            display: 'flex', 
            alignItems: 'center',
        }
    },
    statusYellow: {
        marginRight: 5,
        height:20, 
        width: 20, 
        borderRadius: '50%', 
        display:'inline-block', 
        backgroundColor: Colors.yellow,
    },
    statusRed: {
        height:20, 
        width: 20, 
        borderRadius: '50%', 
        display:'inline-block', 
        backgroundColor: Colors.red,
    },
    headerTitle: {
        color: '#fff',
    },
    inputsContainer: {
        paddingTop: 20,
    },
    namesContainer: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
    },
    nameContainer: {
        width: '48%', 
        marginBottom: 20,
    },
    inputFieldContainer: {
        marginBottom: 20,
    },
    phoneContainer: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginTop: 10,
    },
    statusDeadlinesYellow: {
        height:20, 
        width: 20, 
        borderRadius: '50%', 
        display:'inline-block', 
        backgroundColor: Colors.yellow, 
        marginRight: 10
    },
    statusDeadlinesRed: {
        height:20, 
        width: 20, 
        borderRadius: '50%', 
        display:'inline-block', 
        backgroundColor: Colors.red, 
        marginRight: 10
    },
    statusSpan: {
        position: 'absolute',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        alignSelf: 'center',
        marginLeft: 'auto',
        top: 5,
        '@media (min-width: 668px)': {
            position: 'absolute',
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 10, 
            top: 0,
            bottom: 0
        },
    },
    lock: {
        color: 'white', 
    },
})

export default Candidate;
