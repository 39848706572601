import * as React from 'react';
import { Colors } from '../styles/globalStyles';
import { CircularProgress } from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useSelector } from 'react-redux';

export default function LoadingBtn( {onClick, children, loading, fullWidth, style, disabled, className, type } ) {
  const auth = useSelector(state => state.auth)
  
  return (
        <LoadingButton
            type={type}
            className={{...className}}
            onClick={!loading ? onClick : undefined}
            loading={loading}
            variant="contained"
            fullWidth={fullWidth}
            disabled={disabled}
            loadingIndicator={
              <CircularProgress
                size={25}
                style={{color: 'white'}}
              />
            }
            style={{ fontSize: 12,textTransform: 'uppercase', fontWeight: '400', letterSpacing: '1px', backgroundColor: auth?.user?.company?.branding?.colors ? auth?.user?.company?.branding?.colors : Colors.mainBlue, fontFamily: 'Montserrat', '&.MuiCircularProgressColorPrimary': {color: 'white'}, ...style }}
            >
            {children}
        </LoadingButton>
  );
}