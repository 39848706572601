import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { GET_FULL_CASE_BY_REFERENCE_ID } from '../../graphql/mutations';
import { useQuery } from '@apollo/client';
import ReferenceConsent from './ReferenceConsent';
import ReferenceIntro from './ReferenceIntro';
import ReferenceQuestions from './ReferenceQuestions';
import { setCase } from '../../redux/slices/answerQuestions';
import { setLanguage } from '../../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import { findLanguage } from '../../utils/getLanguage';
import DCLoader from '../../components/DCLoader';
import { visitorUser } from '../../redux/slices/authSlice';

function ReferenceAnswer({ match, history }) {  
    const foundCase = useSelector(state => state.answerQuestions.case);

    const [currentStep, setCurrentStep] = useState(0); 

    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const { data, loading } = useQuery(GET_FULL_CASE_BY_REFERENCE_ID, { variables: { caseId: match?.params?.id, referenceId: match?.params?.refId }});

    useEffect(() => {
        if(data?.caseByReference === null) {
            return history.push('/case/closed')
        }

        dispatch(setCase(data?.caseByReference));
        const language = findLanguage(data?.caseByReference?.company?.settings?.languages, data?.caseByReference?.references[0]?.language)
        dispatch(setLanguage(language))
        i18n.changeLanguage(data?.caseByReference?.references[0]?.language)
        const comp = _.cloneDeep(data?.caseByReference?.company)
        dispatch(visitorUser({
            _id: '',
            firstName: '',
            lastName: '',
            email: '',
            company: comp,
        }))
    }, [data])

    useEffect(() => {
        if (foundCase?.references[0]?.answerStatus) {
            return history.push('/case/thanks', {
                reVisit: true,
                language: foundCase?.references[0].language
            })
        } 
    }, [foundCase])


    const getComponent = () => {
        switch(currentStep) {
            case 0:
                return <ReferenceConsent foundCase={foundCase} setCurrentStep={setCurrentStep} />
            case 1:
                return <ReferenceIntro foundCase={foundCase} match={match} setCurrentStep={setCurrentStep} />
            case 2:
                return <ReferenceQuestions foundCase={foundCase} history={history} match={match} answerPage={true}/>
            // not sure if this is right, but switches needs a default.
            default:
                return <ReferenceConsent foundCase={foundCase} setCurrentStep={setCurrentStep} />
        }
    }

    if(loading) {
        return <DCLoader />
    }

    if(!loading && foundCase?.status === "closed") {
        return <div style={{ display: "flex", justifyContent: "center" }}><h3 style={{paddingTop: 100}}>Sorry for any inconvenience – but the case has been closed and your reference is no longer needed</h3></div>
    }

    return ( 
        getComponent()
    )
}

export default ReferenceAnswer
