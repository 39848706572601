import React, { useState, useEffect, } from 'react';
import { makeStyles } from '@material-ui/styles';

import { useDispatch } from 'react-redux';
import Slider, { SliderThumb } from '@material-ui/core/Slider';
import { getMarks, dontKnowTranslations, getMarks2 } from '../utils/constansts'
import { useTranslation } from 'react-i18next';
import QuestionAnswerElaboration from './QuestionAnswerElaboration';
import { Colors } from '../styles/globalStyles';
import { getLanguageLocal } from '../utils/getLanguage';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { FormControlLabel, Radio } from '@material-ui/core';
import { setReferenceQuestions } from '../redux/slices/answerQuestions';
import { formatReferenceAnswer } from '../services/caseService';
import _ from 'lodash';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';

export default function QuestionsAnswerSlider({ title, answers, questionIndex, enabled, elaboration, selectedLanguage, showAnswers, isReport, references, questionId, isClosed = false }) {
    const [showArrows, setShowArrows] = useState(false);
    const [activeReferences, setActiveReferences] = useState();

    const classes = useStyles({color: Colors.mainBlue + '!important'});

    const dispatch = useDispatch();
    
    const { t } = useTranslation();
    const marks = selectedLanguage ? getMarks2(selectedLanguage) : getMarks(t)

    const handleChange = (newValue, ref) => {
        const formattedReference = formatReferenceAnswer(ref, questionIndex, newValue, null)
        dispatch(setReferenceQuestions([formattedReference]))
    };

    useEffect(() => {
        if(isReport) {
            setActiveReferences(references?.filter(ref => {
                return ref?.listingMailSend && ref?.questions?.some(e => e?.question?._id === questionId)
            })) 
        } else {
            setActiveReferences(references)
        }
    }, [references])

    const CustomThumbComponent = (props) => {
        const { children, ...other } = props;
        return (
            <SliderThumb {...other} className={`${classes.slider} ${isReport ? classes.sliderThumbNoShadow : classes.sliderThumb}`}>
                {showArrows && 
                    <div style={{paddingRight: 20, display: 'flex', color: Colors.mainBlue}}>
                        <ArrowBackIcon style={{fontSize: 40}}/>
                    </div>
                }
                {children}
                {showArrows && 
                    <div style={{paddingLeft: 20, display: 'flex', color: Colors.mainBlue}}>
                        <ArrowForwardIcon style={{fontSize: 40}}/>
                    </div>
                }
            </SliderThumb>
        );
    }

    const getQuestionState = (question, ref) => {
        if(isReport) {
            const foundQuestion = ref?.questions?.find(e => e?.question?._id === questionId);
            const dontKnow = _.isEqual(foundQuestion?.answer?.answers, [0]);
            const value = foundQuestion?.answer?.answers ? foundQuestion?.answer?.answers : [-1];
            return { dontKnow, value }
        } else {
            const dontKnow = _.isEqual(question?.answer?.answers, [0]);
            const value = question?.answer?.answers ? question?.answer?.answers : [0];
            return { dontKnow, value }
        }
    }

    return (
        <div className={classes.breakAvoid}>
            <p className={classes.title}>{makeUnderlineQuestion(title)}</p>
            <div className={classes.questionsWrapper}>
                <div className={classes.questionContainer}>
                    <div className={classes.questionTitleContainerStart}>
                        <p className={classes.questionTitle}>{answers ? answers[0]?.title : 'title'}</p>
                    </div>
                    <div className={classes.descriptionContainer}>
                        <p className={classes.description} >{answers ? answers[0]?.description : 'Answers'}</p>
                    </div>
                </div>
                <div className={classes.questionContainerEnd}>
                    <div className={classes.questionTitleContainerEnd}>
                        <p className={classes.questionTitle}>{answers ? answers[1]?.title : 'Answers' }</p>
                    </div>
                    <div className={classes.descriptionContainer}>
                        <p align='right' className={classes.description} >{answers ? answers[1]?.description : 'Answers'}</p>
                    </div>
                </div>
            </div>
            <div className={classes.sliderContainer}>
                {activeReferences?.map((ref, index) => {
                    const questionState = getQuestionState(ref?.questions[questionIndex], ref)

                    return (
                        <div className={isReport ? classes.sliderInnerContainer : {}} style={{ marginTop: index !== 0 ? 50 : 0 }}>
                            {isReport && <p style={{ fontWeight: '500', marginBottom: 30, fontSize: 18  }}>{!isClosed ? `${ref?.firstName} ${ref?.lastName} (${ref?.type})` : ref?.type}</p>}
                            <Slider
                                value={showAnswers ? questionState?.value : [0]}
                                onMouseLeave={() => setShowArrows(false)}
                                onMouseDown={() => setShowArrows(false)}
                                onMouseEnter={() => setShowArrows(true)}
                                className={classes.slider}
                                components={{ Thumb: CustomThumbComponent }}
                                onChange={enabled ? (e, val) => handleChange(val, ref) : null}
                                valueLabelDisplay="off"
                                marks={marks}
                                min={-1000}
                                max={1000}
                            />
                             <div className={classes.dontKnowContainer}>
                                <FormControlLabel
                                    label={selectedLanguage ? getLanguageLocal(dontKnowTranslations, selectedLanguage)?.name : t('Don\'t know') } 
                                    className={classes.label} 
                                    labelPlacement="end" 
                                    onClick={() => enabled ? handleChange([0], ref) : null} 
                                    control={
                                        <Radio 
                                            style={{ color: Colors.mainBlue }} 
                                            checked={showAnswers ? questionState?.dontKnow : null} 
                                        />
                                    }  
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            {elaboration && activeReferences?.map(ref => {
                return <QuestionAnswerElaboration questionId={questionId} isReport={isReport} showAnswers={showAnswers} reference={ref} selectedLanguage={selectedLanguage} enabled={enabled} questionIndex={questionIndex} isClosed={isClosed} />
            })}
        </div>
    )
}

const useStyles = makeStyles({
    title: {
        marginBottom: '30px',
        fontSize: '20px',
        fontWeight: '500',
        marginLeft: '10%',
        marginRight: '10%',
        textAlign: 'center',
        fontStyle: 'italic',
        whiteSpace: 'break-spaces',
        '@media (max-width: 480px)': {
            fontSize: 15,
            textAlign: 'left',
            margin: '10px 5px',
        },
    },
    label: {
        '@media (max-width: 480px)': {
            '& .MuiTypography-root': {
                fontSize: 13,
            },
        },
    },
    sliderThumb: {
        pointerEvents: 'none',
    },
    sliderThumbNoShadow: {
        pointerEvents: 'none',
        boxShadow: 'none !important',
        '&:before': {
            boxShadow: 'none !important',
        },
    },
    slider: {
        '&.MuiSlider-root': {
            color: props => props.color ? props.color : `${Colors.mainBlue} !important`,
        },
        '& .MuiSlider-markLabel[data-index="0"]': {
            transform: "translateX(0%)",
            whiteSpace: "pre",
            textAlign: 'start',
            color: 'black',
            fontSize: '1rem',
            fontStyle: 'italic',
            '@media (max-width: 480px)': {
                fontSize: '0.7rem',
            },
        },
        '& .MuiSlider-markLabel[data-index="1"]': {
            top: -30,
            color: 'black',
            fontSize: '1rem',
            '@media (max-width: 480px)': {
                fontSize: '0.7rem',
                top: -10,
            },
        },
        '& .MuiSlider-markLabel[data-index="2"]': {
            transform: "translateX(-100%)",
            whiteSpace: "pre",
            textAlign: 'end',
            color: 'black',
            fontSize: '1rem',
            fontStyle: 'italic',
            '@media (max-width: 480px)': {
                fontSize: '0.7rem',
            },
        },
    },
    sliderContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        position: 'relative',
        marginLeft: 20,
        marginRight: 20,
    },
    sliderInnerContainer: {
        paddingBottom: 30, 
        backgroundImage: `linear-gradient(to right, ${Colors.mainBlue} 40%, rgba(255,255,255,0) 0%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '10px 1px',
        backgroundRepeat: 'repeat-x',
        marginBottom: 20    
    },
    breakAvoid: {
        '@media print': {
            breakInside: 'avoid',
        }
    },
    questionsWrapper: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        marginBottom: '50px',
        paddingTop: 10,
    },
    questionContainer: {
        maxWidth: '350px', 
        backgroundColor: '#F2F7Fb', 
        minHeight: '100px', 
        marginRight: 60,
        '@media (max-width: 480px)': {
            width: '50%',
        },
    },
    questionContainerEnd: {
        maxWidth: '350px', 
        backgroundColor: '#F2F7Fb', 
        minHeight: '100px',
        '@media (max-width: 480px)': {
            width: '50%',
        },
    },
    questionTitleContainerStart: {
        minWidth: '100px',
        minHeight: '50px',
        backgroundColor: '#003E55', 
        display: 'flex', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        color: '#fff',
        wordBreak: 'break-word',
    },
    questionTitleContainerEnd: {
        minWidth: '100px',
        minHeight: '50px',
        backgroundColor: '#003E55', 
        display: 'flex', 
        justifyContent: 'flex-end', 
        alignItems: 'center', 
        color: '#fff',
        textAlign: 'right',
        wordBreak: 'break-word',
    },
    questionTitle: {
        padding: '5px 15px', 
        fontWeight: 'bold',
        '@media (max-width: 480px)': {
            fontSize: 15,
            padding: '5px 5px',
        },
    },
    descriptionContainer: {
        padding: '15px',
        wordBreak: 'break-word',
        '@media (max-width: 480px)': {
            padding: 5,
        },
    },
    description: {
        '@media (max-width: 480px)': {
            fontSize: 13,
        },
    },
    dontKnowContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        paddingTop: 20,
    }
});
