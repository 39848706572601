import React, { useEffect, useState } from 'react'
import { ListItem, Collapse, List } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Colors } from '../styles/globalStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeUnderlineQuestion } from '../utils/QuestionStringReplacer';

export default function QuestionDropdown({ style, className, leftIcon, title, children, expandIcon, parentShow, setParentShow, useParentShow, customHeader, customBadge, shownId, setShownId, currentIndex }) {
    const [show, setShow] = useState(false)
    const classes = useStyles();

    const handler = () => {
        setShow(!show)
        setShownId(currentIndex)
    }

    useEffect(() => {
        if(shownId !== currentIndex) {
            setShow(false)
        }
    }, [shownId])

    return (
        <React.Fragment>
            {customHeader ? customHeader(title, show, handler) :
                <ListItem style={style} className={className ? className : classes.button} onClick={() => useParentShow ? setParentShow(!parentShow) : handler(!show)}>
                    <div className={classes.leftIcon}>
                        {leftIcon ? leftIcon() : null}
                    </div>
                    <p>{makeUnderlineQuestion(title)}</p>
                    
                    {useParentShow 
                        ? expandIcon ? parentShow ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} /> : null
                        : expandIcon ? show ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} /> : null
                    }
                    {customBadge ? customBadge() : null}
                </ListItem>
            }
            <Collapse style={{ background: '#fff', width: '100%' }} in={useParentShow ? parentShow : show} timeout="auto" unmountOnExit>
                <ClickAwayListener onClickAway={() => setShow(false)}>
                    <List component="div" disablePadding>
                        {children}
                    </List>
                </ClickAwayListener>
            </Collapse>
        </React.Fragment>
    )
}

const useStyles = makeStyles({
    button: {
        cursor: 'pointer',
        minHeight: 45,
        '&:hover': {
            zIndex: 2,
            outline: `2px solid ${Colors.mainBlue}`,
        }
    },
    icon: {
        position: 'absolute',
        right: 20
    },
    leftIcon: {
        color: Colors.mainBlue,
        paddingRight: 10,
    }
})
