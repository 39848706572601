import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { useDispatch } from 'react-redux';
import { triggerFlashMessage } from '../../redux/slices/globalSlice';
import { validateReference } from '../../utils/validators';
import _ from 'lodash';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation } from '@apollo/client'

import { LIST_REFERENCE } from '../../graphql/mutations';
import { Colors } from '../../styles/globalStyles';
import LoadingBtn from '../../components/LoadingButton';
import DCList from '../../components/DCList';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { candidateInviteConsent, referenceMailFilledText, referenceOpenText } from '../../utils/constansts';
import { getLanguage } from '../../utils/getLanguage';

const useStyles = makeStyles({
    container: {
        position: 'relative',
    },
    DropdownItem: {
        borderBottom: '1px dashed #000',
        borderTop: '1px dashed #000',
        overflow: 'hidden',
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        marginTop: 15,
    },
    normalInput: {
        marginTop: 15
    },
    LanguageSelectorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    LanguageSelectorText: {},
    LanguageSelectorRadioContainer: {
        width: '50%',
        '& .MuiFormGroup-root': {
            justifyContent: 'space-between',
        }
    },
    ButtonSubmitContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        marginBottom: '30px',
        '@media (min-width: 1026px)': {
            display: 'none',
        },
    },
    ButtonSubmitContainer2: {
        position: 'absolute',
        top: 0,
        right: '-100px',
        '@media (max-width: 1025px)': {
            display: 'none',
        },
    },
    ConsentContainer: {
        display: 'flex', 
        paddingTop: 10, 
        paddingBottom: 10, 
        borderBottom: '1px dashed #000',
        '& .MuiFormControlLabel-root': {
            margin: 0
        }
    },
    ConsentCheckbox: {
        width: '10%', 
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ConsentText: {
        width: '90%',
        paddingLeft: 10,
    },
    listContainer: {
        backgroundColor: Colors.mainBlue,
        textAlign: 'center',
        color: '#fff',
        display: 'flex !important', 
        justifyContent: 'center !important', 
        minHeight: 50,
        marginTop: 2,
        cursor: 'pointer'
    },
    contentContainer: {
        '@media (min-width: 1026px)': {
            paddingBottom: 20,
        },
        '@media (max-width: 479px)': {
            zoom: 0.8
        },
    },
});

function Reference({reference, setReferences, caseId, refetchCase, handleDelete, key, show, setShow, clearShow, hasUnsavedChanges, }) {
    const [loading, setLoading] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [prefLanguage, setPrefLanguage] = useState()
	const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [referenceCopy, setReferenceCopy] = useState(false);
    const [refetchCalled, setRefetchCalled] = useState(true);

    const [listReference] = useMutation(LIST_REFERENCE)
    const t = (txt) => (txt) 

    const languagesSupported = ['Danish', 'English'];

    const dispatch = useDispatch()

    useEffect(() => {
        setPrefLanguage(reference?.language)
    }, [])

    useEffect(() => {
        setIsAccepted(reference?.listedDate !== null)
    }, [])

	useEffect(() => {
		isSubmitted && setErrors(validateReference(reference.firstName, reference.lastName, reference.email));
	}, [reference])

    useEffect(() => {
        if(refetchCalled && reference) {
            setReferenceCopy(reference)
            setRefetchCalled(false)
        }
    }, [reference])

    const classes = useStyles();

    const handleChange = () => {
        setIsAccepted(!isAccepted)
    };

    const onChange = (e) => {
        if(e.target.name === 'language'){
            setPrefLanguage(e.target.value);
        }
        setReferences(e, reference?._id)
    }

	const onSubmit = async () => {
        setLoading(true)
        listReference({ variables: { caseId: caseId, referenceId: reference._id, firstName: reference.firstName, lastName: reference.lastName, email: reference.email, phone: reference.phone, mobile: reference.mobile, language: reference.language, linkedIn: reference.linkedIn } }).then(res => {
            refetchCase()
            dispatch(triggerFlashMessage({ type: "success", title: t("Succesfully updated reference") }))
            setLoading(false)
            clearShow()
            setRefetchCalled(true)
        }).catch(err => {
            dispatch(triggerFlashMessage({ type: "error", title: "Failed to update references" }))
            setLoading(false)
        })
	}

    const handleSubmit = async () => {
      if(isAccepted) {
        // validate
        let submitErrors = validateReference(reference.firstName, reference.lastName, reference.email);
        if(!_.isEmpty(submitErrors)) {
            setErrors(submitErrors);
            setIsSubmitted(true);
            dispatch(triggerFlashMessage({ type: "error", title: t("Please make sure all the info is filled out correctly") }))
            return;
        }
		onSubmit();
      } else {
        dispatch(triggerFlashMessage({ type: "error", title: t("Please confirm that you have received permission to record the following information") }))
      }
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation(); 
        handleDelete(reference)
    }

    return (
        <div className={classes.container} key={key}>
            <DCList
                setParentShow={setShow}
                useParentShow={true}
                parentShow={show}
                leftIcon={() => <DeleteIcon onClick={(e) => handleDeleteClick(e)}
                style={{ fontSize: 20 }} />}
                className={classes.listContainer}
                expandIcon
                title={t(reference.type)}
                tooltipTitle={getLanguage(referenceOpenText())?.name}
                hasUnsavedChanges={hasUnsavedChanges}
            >
                {reference &&
                    <div className={classes.contentContainer}>
                        <div className={classes.ConsentContainer}>
                            <FormControl className={classes.ConsentCheckbox} component="fieldset">
                                <FormControlLabel onClick={handleChange} value="accept" control={<Radio style={{ color: Colors.mainBlue }} checked={isAccepted} />} label="" />
                            </FormControl>
                            <p className={classes.ConsentText}>{getLanguage(candidateInviteConsent)?.name}</p>
                        </div>
                        
                        <div className={classes.normalInput}>
                            <TextField 
                                error={errors.firstName}
                                label={t("First name")}
                                fullWidth 
                                variant="outlined" 
                                name="firstName" 
                                onChange={onChange}
                                value={reference.firstName ?? ''}
                            />
                        </div>
                        <div className={classes.normalInput}>
                            <TextField
                                error={errors.lastName}
                                label={t("Last name")}
                                fullWidth
                                variant="outlined"
                                name="lastName"
                                onChange={onChange}
                                value={reference.lastName ?? ''}
                            />
                        </div>
                        <div className={classes.normalInput}>
                            <Tooltip style={{ fontSize: 15 }} title={reference?.listingMailSend ? <p style={{ fontSize: 14 }}>{getLanguage(referenceMailFilledText())?.name}</p> : ''}>
                                <TextField 
                                    disabled={reference?.listingMailSend} 
                                    error={errors.email} 
                                    label={t("E-mail")}
                                    fullWidth 
                                    variant="outlined" 
                                    name="email" 
                                    onChange={onChange} 
                                    value={reference.email ?? ''}
                                />
                            </Tooltip>
                        </div>
                        <div className={classes.normalInput}>
                            <TextField
                                label={t("Mobile phone")}
                                fullWidth
                                variant="outlined"
                                name="mobile"
                                onChange={onChange}
                                value={reference.mobile ?? ''}
                            />
                        </div>
                        <div className={classes.normalInput}>
                            <TextField
                                label={t("Work phone")}
                                fullWidth
                                variant="outlined"
                                name="phone"
                                onChange={onChange}
                                value={reference.phone ?? ''}
                            />
                        </div>
                        <div style={{ marginTop: 15 }} className={classes.normalInput}>
                            <TextField
                                label={t("URL LinkedIn profile")}
                                fullWidth
                                variant="outlined"
                                name="linkedIn"
                                onChange={onChange}
                                value={reference.linkedIn ?? ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LinkedInIcon style={{ fontSize: 35, color: Colors.mainBlue }} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <div className={classes.DropdownItem}>
                            <div className={classes.LanguageSelectorContainer}>
                                <div className={classes.LanguageSelectorText}>
                                    {t("Prefered language")}
                                </div>
                                <FormControl component="fieldset" className={classes.LanguageSelectorRadioContainer}>
                                    <RadioGroup row aria-label="language" name="language" value={prefLanguage} onChange={onChange}>
                                        {languagesSupported.map((language) => {
                                            let check = false;
                                            let checkLanguage = prefLanguage === 'en' ? 'English' : 'Danish';
                                            if(language === checkLanguage){
                                                check = true;
                                            }
                                            return <FormControlLabel value={language === 'English' ? 'en': 'da'}  control={<Radio style={{ color: Colors.mainBlue }} checked={check} />} label={t(language)} />
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        {!_.isEqual(reference, referenceCopy) && <div className={classes.ButtonSubmitContainer}>
                            <LoadingBtn onClick={handleSubmit} loading={loading}>
                                {reference?.editted ? t('Save') : t("Submit")}
                            </LoadingBtn>
                        </div>}
                    </div>
                }
            </DCList>
            {!_.isEqual(reference, referenceCopy) && <div className={classes.ButtonSubmitContainer2}>
                <LoadingBtn onClick={handleSubmit} loading={loading}>
                    Save
                </LoadingBtn>
            </div>}
        </div>
    )
}

export default Reference;
