import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { FormControl, ListItem, Radio } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AddQuestion from "../../components/AddQuestion";
import AddRatingPoint from "../../components/AddRatingPoint";
import CustomAlertDialog from "../../components/CustomAlertDialog";
import CustomBadge from "../../components/CustomBadge";
import CustomDropdownMenu from "../../components/CustomDropdownMenu";
import DCLoader from "../../components/DCLoader";
import LanguageChange from "../../components/LanguageChange";
import LoadingBtn from "../../components/LoadingButton";
import QuestionDropdown from "../../components/QuestionDropdown";
import {
    COMPANY_BY_ID,
    DELETE_QUESTION,
    GET_CATEGORIES,
    GET_QUESTIONS,
    GET_THEMES,
    HIDE_QUESTION,
    SET_DEFAULT_ELABORATION,
    UPDATE_HIDE_QUESTION_Filter,
} from "../../graphql/mutations";
import { triggerFlashMessage } from "../../redux/slices/globalSlice";
import { replaceQuestionString, sortThemes } from "../../services/caseService";
import { Colors } from "../../styles/globalStyles";
import { openAnswerTranslations } from "../../utils/constansts";
import {
    getLanguageLocal,
    getLanguageLocalQuestionsFilter,
} from "../../utils/getLanguage";
import { validateRatingPointTheme } from "../../utils/validators";

export default function QAManager() {
    const [themes, setThemes] = useState();
    const [categories, setCategories] = useState();
    const [questions, setQuestions] = useState();
    var skipQuestionIndex = 0;

    const [selectedTheme, setSelectedTheme] = useState();
    const [selectedCategory, setSelectedCategory] = useState({
        _id: null,
        title: "All",
    });

    const [showQuestionModal, setShowQuestionModal] = useState();
    const [showRatingModal, setShowRatingModal] = useState();
    const [shownId, setShownId] = useState();

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const { company } = useSelector((state) => state.auth.user);
    const [selectedLanguage, setSelectedLanguage] = useState(
        company?.settings?.languages[company?.settings?.defaultQALanguage]
    );

    const dispatch = useDispatch();

    const [hideQuestion] = useMutation(HIDE_QUESTION);
    const [hideQuestionFilter] = useMutation(UPDATE_HIDE_QUESTION_Filter);
    const [setElaboration] = useMutation(SET_DEFAULT_ELABORATION);
    const [deleteQuestion] = useMutation(DELETE_QUESTION);

    const { data: themeData, loading: themesLoading } = useQuery(GET_THEMES, {
        variables: { companyId: company?._id },
    });
    const { data: categoryData, loading: categoriesLoading } = useQuery(
        GET_CATEGORIES,
        { variables: { companyId: company?._id } }
    );
    const { data: questionsData, refetch: refetchQuestions } = useQuery(
        GET_QUESTIONS,
        {
            variables: {
                companyId: company?._id,
                themeId: selectedTheme?._id,
                categoryId: selectedCategory?._id,
            },
            fetchPolicy: "network-only",
        }
    );

    const { data: companyData, refetch: refetchCompany } = useQuery(
        COMPANY_BY_ID,
        { variables: { id: company?._id }, fetchPolicy: "network-only" }
    );

    const classes = useStyles();
    const t = (str) => str;

    const handleHideQuestionDisable = (question) => {
        return false;
    };

    const handleHideQuestion = async (question) => {
        // Add or delete from company questionHideFilter array
        await hideQuestionFilter({
            variables: { id: company?._id, questionID: question?._id },
        });
        // If question belongs to dc and company trying to hide is not dc do not set question hidden variable
        if (question.company._id !== company._id) {
            refetchCompany();
            refetchQuestions();
        } else {
            // if question belongs to the company editing set question hidden variable and refetch
            await hideQuestion({
                variables: { id: question?._id, hidden: !question?.hidden },
            });
            refetchCompany();
            refetchQuestions();
        }
    };

    const handleQuestionElaboration = async (question) => {
        if (handleElaborationDisable(question)) return;
        await setElaboration({
            variables: {
                id: question?._id,
                elaboration: !question?.elaboration,
            },
        });
        refetchQuestions();
    };

    const handleElaborationDisable = (question) => {
        return question?.hidden
            ? true
            : question?.scale?.type === "Open"
            ? true
            : false;
    };

    useEffect(() => {
        setCategories(
            categoryData?.categories?.map((e) => ({
                ...e,
                title: getLanguageLocal(e, { shortName: "en" })?.name,
            }))
        );
    }, [categoryData]);

    useEffect(() => {
        setQuestions(questionsData?.questions);
    }, [questionsData]);

    useEffect(() => {
        const selectedLanguage = themeData?.themes?.map((e) => ({
            ...e,
            title: getLanguageLocal(e, { shortName: "en" })?.name,
        }));
        const sorted = sortThemes(selectedLanguage);
        setThemes(sorted);
        sorted && setSelectedTheme(sorted[0]);
    }, [themeData]);

    const RenderOpenQuestion = () => {
        return (
            <div style={{ padding: 20, borderBottom: "1px dashed #000" }}>
                <p style={{ fontSize: "14px", fontWeight: "500" }}>
                    {
                        getLanguageLocal(
                            openAnswerTranslations,
                            selectedLanguage
                        )?.name
                    }
                </p>
            </div>
        );
    };

    const RenderListQuestion = ({ question }) => {
        return (
            <div style={{ padding: 0, paddingBottom: 20 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {getLanguageLocal(
                        question?.answer,
                        selectedLanguage
                    )?.answers?.map((answer) => {
                        return (
                            <ListItem
                                key={answer?._id}
                                style={{
                                    background: "#fff",
                                    borderBottom: "1px dashed #000",
                                    minHeight: 40,
                                }}
                                button
                            >
                                <div style={{ width: "100%" }}>
                                    <p
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        {answer.title}
                                    </p>
                                </div>
                            </ListItem>
                        );
                    })}
                </div>
            </div>
        );
    };

    const RatingPointsListHeader = ({ question, isNew, isCompany }) => {
        return (
            <ListItem
                style={{ padding: 0, margin: 0 }}
                className={classes.button}
                onClick={() => null}
            >
                <div style={{ width: "100%" }}>
                    {canDeleteQuestion(question) && (
                        <div className={classes.rpDeleteIcon}>
                            <DeleteIcon
                                style={{ color: "#fff" }}
                                onClick={(e) =>
                                    handleOnDelete(e, question?._id)
                                }
                                fontSize="small"
                            />
                        </div>
                    )}
                    <div className={classes.questionHeaderContainer}>
                        {(isNew || isCompany) && (
                            <div className={classes.newRatingPoint}>
                                <p style={{ color: "white", fontSize: 12 }}>
                                    {isNew && isCompany
                                        ? "New + C"
                                        : isNew
                                        ? "New"
                                        : "C"}
                                </p>
                            </div>
                        )}
                        {getLanguageLocal(
                            question.answer,
                            selectedLanguage
                        )?.answers?.map((answer, index) => {
                            return (
                                <div
                                    key={answer?.title}
                                    className={classes.questionHeaderElement}
                                >
                                    <p
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "500",
                                        }}
                                    >
                                        {answer?.title}
                                    </p>
                                    <p style={{ fontSize: 14, marginTop: 5 }}>
                                        {answer?.description}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ListItem>
        );
    };

    const RenderBadge = (isNew, isCompany) => {
        return (
            <CustomBadge
                title={isNew && isCompany ? "New + C" : isNew ? "New" : "C"}
                className={classes.newBadge}
            />
        );
    };

    const handleOnDelete = (e, id) => {
        e.stopPropagation();
        setIsDeleting(true);
        setDeleteId(id);
    };

    const canDeleteQuestion = (question) => {
        if (company?._id === question?.company?._id) {
            return true;
        }

        return false;
    };

    const handleDeleteQuestion = (id) => {
        deleteQuestion({ variables: { id: id } })
            .then((res) => {
                refetchQuestions();
                dispatch(
                    triggerFlashMessage({
                        type: "success",
                        title: t("Succesfully deleted question"),
                    })
                );
            })
            .catch((err) => {
                dispatch(
                    triggerFlashMessage({
                        type: "error",
                        title: t("Failed to delete question"),
                    })
                );
            });
    };

    useEffect(() => {
        skipQuestionIndex = 0;
    }, [questionsData]);

    if ([themesLoading, categoriesLoading]?.some((e) => e === true)) {
        return <DCLoader />;
    }

    return (
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <div style={{ display: "flex" }}>
                    <div className={classes.selectors}>
                        <div className={classes.selector}>
                            <CustomDropdownMenu
                                selected={t(selectedTheme?.title)}
                                withKey
                                elementKey="title"
                                onSelect={(e) => setSelectedTheme(e)}
                                title={t(selectedTheme?.title)}
                                label={t("Select Theme")}
                                elements={
                                    themes && [
                                        { _id: null, title: t("All") },
                                        ...themes,
                                    ]
                                }
                            />
                        </div>
                        <div className={classes.selector}>
                            <CustomDropdownMenu
                                selected={t(selectedCategory?.title)}
                                withKey
                                elementKey="title"
                                onSelect={(e) => setSelectedCategory(e)}
                                title={t(selectedCategory?.title)}
                                label={t("Select Method")}
                                elements={
                                    categories && [
                                        { _id: null, title: "All" },
                                        ...categories,
                                    ]
                                }
                            />
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                    <div className={classes.questions}>
                        <div className={classes.questionsHeader}>
                            <p>
                                {selectedCategory?.title === "All"
                                    ? "Questions | Rating Points"
                                    : selectedCategory?.title}
                            </p>
                            <LanguageChange
                                useLanguage={true}
                                handleLanguage={setSelectedLanguage}
                                language={selectedLanguage}
                                company={company}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            flex: 1,
                            justifyContent: "space-between",
                            display: "flex",
                        }}
                    >
                        {/* <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <p>Hide</p>
                        </div> */}
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <p>Default elaboration</p>
                        </div>
                    </div>
                </div>

                <CustomAlertDialog
                    id={deleteId}
                    open={isDeleting}
                    handleCancel={() => setIsDeleting(false)}
                    handleClose={() => setIsDeleting(false)}
                    handleAccept={(id) => handleDeleteQuestion(id)}
                    alertTitle={t("Delete")}
                    alertText={t("Are you sure you want to delete?")}
                    okText={t("Delete")}
                />
                <div className={classes.scrollContainer}>
                    {questions?.map((question, index) => {
                        const diff = moment().diff(
                            moment(parseInt(question.createdAt)),
                            "minutes"
                        );
                        const days = 60;
                        const everySecond =
                            (index + skipQuestionIndex) % 2 === 0;
                        const isCompany =
                            question?.company?._id === company?._id;
                        const isNew = diff < days;

                        if (
                            !getLanguageLocalQuestionsFilter(
                                question,
                                selectedLanguage
                            )?.name
                        ) {
                            skipQuestionIndex += 1;
                            return null;
                        }

                        return (
                            <div
                                key={question?._id}
                                style={{ display: "flex" }}
                            >
                                <div style={{ flex: 4, position: "relative" }}>
                                    <QuestionDropdown
                                        leftIcon={
                                            canDeleteQuestion(question)
                                                ? () => (
                                                      <DeleteIcon
                                                          className={
                                                              classes.deleteIcon
                                                          }
                                                          style={{
                                                              paddingTop: 3,
                                                              paddingRight: 3,
                                                          }}
                                                          onClick={(e) =>
                                                              handleOnDelete(
                                                                  e,
                                                                  question?._id
                                                              )
                                                          }
                                                      />
                                                  )
                                                : null
                                        }
                                        shownId={shownId}
                                        setShownId={setShownId}
                                        customBadge={
                                            isNew || isCompany
                                                ? () =>
                                                      RenderBadge(
                                                          isNew,
                                                          isCompany
                                                      )
                                                : null
                                        }
                                        customHeader={
                                            question?.scale?.type ===
                                            "RatingPoints"
                                                ? () => (
                                                      <RatingPointsListHeader
                                                          question={question}
                                                          isNew={isNew}
                                                          isCompany={isCompany}
                                                      />
                                                  )
                                                : null
                                        }
                                        expandIcon={
                                            question?.scale?.type ===
                                            "RatingPoints"
                                                ? false
                                                : true
                                        }
                                        style={
                                            everySecond
                                                ? {
                                                      backgroundColor:
                                                          Colors.table1,
                                                      paddingRight: 115,
                                                  }
                                                : {
                                                      backgroundColor:
                                                          Colors.table2,
                                                      paddingRight: 115,
                                                  }
                                        }
                                        title={
                                            question?.scale?.type ===
                                            "RatingPoints"
                                                ? getLanguageLocal(
                                                      question.answer,
                                                      selectedLanguage
                                                  )?.name
                                                : replaceQuestionString(
                                                      getLanguageLocal(
                                                          question,
                                                          selectedLanguage
                                                      )?.name,
                                                      null,
                                                      null,
                                                      getLanguageLocal(
                                                          question?.theme,
                                                          selectedLanguage
                                                      )?.name
                                                  )
                                        }
                                    >
                                        {question?.scale?.type === "Open" && (
                                            <RenderOpenQuestion
                                                question={question}
                                            />
                                        )}
                                        {question?.scale?.type ===
                                            "Multiple" && (
                                            <RenderListQuestion
                                                question={question}
                                            />
                                        )}
                                        {question?.scale?.type ===
                                            "ProsCons" && (
                                            <RenderListQuestion
                                                question={question}
                                            />
                                        )}
                                        {question?.scale?.type === "Prio" && (
                                            <RenderListQuestion
                                                question={question}
                                            />
                                        )}
                                    </QuestionDropdown>
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        justifyContent: "space-between",
                                        display: "flex",
                                        alignItems:
                                            question?.scale?.type ===
                                            "RatingPoints"
                                                ? "center"
                                                : "flex-start",
                                    }}
                                >
                                    {/* <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <FormControl
                                            disabled={handleHideQuestionDisable(
                                                question
                                            )}
                                            onClick={() =>
                                                handleHideQuestion(question)
                                            }
                                        >
                                            <Radio
                                                disabled={handleHideQuestionDisable(
                                                    question
                                                )}
                                                style={{
                                                    color: handleHideQuestionDisable(
                                                        question
                                                    )
                                                        ? Colors.grey
                                                        : Colors.red,
                                                }}
                                                checked={
                                                    companyData?.company?.questionHideFilterList?.includes(
                                                        question?._id
                                                    ) || question?.hidden
                                                }
                                            />
                                        </FormControl>
                                    </div> */}
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <FormControl
                                            disabled={handleElaborationDisable(
                                                question
                                            )}
                                            onClick={() =>
                                                handleQuestionElaboration(
                                                    question
                                                )
                                            }
                                        >
                                            <Radio
                                                disabled={handleElaborationDisable(
                                                    question
                                                )}
                                                style={{
                                                    color: !handleElaborationDisable(
                                                        question
                                                    )
                                                        ? Colors.mainBlue
                                                        : Colors.grey,
                                                }}
                                                checked={question?.elaboration}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {questions?.length === 0 && (
                        <p className={classes.noItems}>
                            No{" "}
                            {selectedCategory?.title === "All"
                                ? "Questions | Rating Points"
                                : selectedCategory?.title}{" "}
                            found
                        </p>
                    )}
                </div>

                <AddQuestion
                    selectedLanguage={selectedLanguage}
                    selectedC={selectedCategory}
                    selectedT={selectedTheme}
                    refetch={refetchQuestions}
                    themes={themes}
                    setShowModal={setShowQuestionModal}
                    showModal={showQuestionModal}
                />
                <AddRatingPoint
                    selectedLanguage={selectedLanguage}
                    selectedC={selectedCategory}
                    selectedT={selectedTheme}
                    refetch={refetchQuestions}
                    themes={themes}
                    setShowModal={setShowRatingModal}
                    showModal={showRatingModal}
                />

                <div className={classes.addButton}>
                    <div className={classes.addButton}>
                        <div
                            style={{
                                flex: 4,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {(selectedCategory?.title === "All" ||
                                selectedCategory?.title !==
                                    "Rating Points") && (
                                <LoadingBtn
                                    onClick={() => setShowQuestionModal(true)}
                                >
                                    New Question
                                </LoadingBtn>
                            )}

                            {(selectedCategory?.title === "All" ||
                                selectedCategory?.title === "Rating Points") &&
                                validateRatingPointTheme(
                                    selectedTheme?.title
                                ) && (
                                    <LoadingBtn
                                        onClick={() => setShowRatingModal(true)}
                                    >
                                        New Rating Point
                                    </LoadingBtn>
                                )}
                        </div>
                        <div style={{ flex: 2 }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    container: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    innerContainer: {
        width: "100%",
        position: "relative",
        "@media (max-width: 1024px)": {
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
        },
    },
    selectors: {
        flex: 4,
        display: "flex",
        flexDirection: "row",
        borderTop: `1px dashed ${Colors.mainBlue}`,
        borderBottom: `1px dashed ${Colors.mainBlue}`,
        position: "relative",
        "&:last-child": {
            borderLeft: "none",
        },
    },
    questions: {
        flex: 4,
    },
    questionsHeader: {
        height: 60,
        width: "100%",
        backgroundColor: Colors.mainBlue,
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "& p": {
            paddingLeft: 20,
            color: "#fff",
            fontWeight: "bold",
        },
    },
    selector: {
        flex: 1,
        borderLeft: `1px dashed ${Colors.mainBlue}`,
        "&:first-child": {
            borderLeft: `1px dashed ${Colors.mainBlue}`,
        },
        "&:last-child": {
            borderRight: `1px dashed ${Colors.mainBlue}`,
        },
    },
    addButton: {
        display: "flex",
        justifyContent: "space-between",
        flex: 4,
        marginTop: 10,
    },
    button: {
        cursor: "pointer",
        minHeight: 45,
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
    },
    newBadge: {
        position: "absolute",
        right: 50,
    },
    newRatingPoint: {
        position: "absolute",
        right: "10px",
        top: "5px",
        borderRadius: "5px",
        border: "1px solid #fff",
        padding: "5px",
        backgroundColor: Colors.mainBlue,
        zIndex: 3,
    },
    questionHeaderContainer: {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        borderBottom: "1px dashed #000",
    },
    questionHeaderElement: {
        flex: 1,
        textAlign: "center",
        backgroundColor: Colors.mainBlue,
        minHeight: 80,
        margin: "15px 1px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        color: "#fff",
    },
    scrollContainer: {
        maxHeight: "calc(100vh - 450px)",
        overflowY: "overlay",
        position: "relative",
    },
    noItems: {
        textAlign: "center",
        paddingTop: 20,
        paddingBottom: 20,
    },
    rpDeleteIcon: {
        top: "5px",
        left: "10px",
        border: "1px solid #fff",
        paddingTop: "3px",
        zIndex: 3,
        position: "absolute",
        borderRadius: "5px",
        backgroundColor: Colors.mainBlue,
    },
    deleteIcon: {
        fontSize: 20,
        color: Colors.mainBlue,
    },
});
