import React, { useState, useEffect } from 'react'
import ListItem from '@material-ui/core/ListItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import '../sass/screens/case-preview.scss';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import DragAndDrop from './DragAndDrop';
import { setReferences } from '../redux/slices/createCaseSlice';

import { useTranslation } from 'react-i18next';
import QuestionsPreview from './QuestionsPreview';
import { Colors } from '../styles/globalStyles';
import CustomDropdownMenu from './CustomDropdownMenu';
import moment from 'moment';
import { formatCaseWithTheme, formatCaseOrder } from '../services/caseService';

export default function Preview({ caseState, selectedLanguage, setSelectedLanguage, isClosed = false }) {
    const [showPreview, setShowPreview] = useState(false);
    const [questions, setQuestions] = useState();
    const [duration, setDuration] = useState(0);
    
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const classes = useStyles()

    const getNames = () => {
        return [...new Set(caseState?.references?.map((e) => e.type))]
    }

    const getDragAndDropElements = (arr) => {
        return formatCaseWithTheme(arr)
    }

    const filterQuestions = (arr) => {
        return formatCaseOrder(arr)
    }

    const formatQuestions = (ref) => {
        const withTheme = formatCaseWithTheme(ref?.questions);
        const ordered = formatCaseOrder(withTheme);

        let newQuestions = [];
        ordered?.forEach(element => {
            newQuestions?.push(...element?.questions?.map(q => ({ question: q?.question, answer: q?.answer })))
        });

        const newRef = {
            ...ref,
            questions: newQuestions
        }
       
        return newRef
    }

    const [selectedReference, setSelectedReference] = useState();

    const handleDragAndDrop = (e) => {
        const currentReference = caseState?.references?.find(ref => ref._id === selectedReference?._id);
        const allQuestions = [];

        e?.map(section => {
            const formatted = section?.questions?.map(q => ({ question: q?.question }));

            allQuestions.push(...formatted)
            return section
        })

        dispatch(setReferences(caseState?.references?.map(ref => {
            if(ref?._id === currentReference?._id) {
                return {
                    ...ref,
                    questions: allQuestions
                }
            } else {
                return ref;
            }
        })))
        setQuestions(e)
    }

    useEffect(() => {
        const currentReference = caseState?.references?.find(ref => ref._id === selectedReference?._id);
        const filteredQuestions = filterQuestions(getDragAndDropElements(currentReference?.questions))  
        setQuestions(filteredQuestions)
        handleDuration(currentReference)
    }, [selectedReference, caseState?.references])

    useEffect(() => {
        setSelectedReference(formatQuestions(caseState?.references?.[0]));
    }, [caseState?.references])

    const handleDuration = (selectedReference) => {
        let seconds = 0;
        selectedReference?.questions?.forEach(e => {
            seconds += e?.question?.duration
        })

        const formatted = moment.utc(seconds*1000).format('mm:ss');
        setDuration(formatted)
    }

    const onPreviewClick = () => {
        setShowPreview(true)
    }

    const handleSelect = (selectedRefType) => {
        const selectedRef = caseState?.references?.find(e => e.type === selectedRefType);
        setSelectedReference(formatQuestions(selectedRef))
    };

    return (
        <div className={classes.container}>
            <div className="case-preview-container">
                <div style={{ marginTop: 20 }} className="case-preview-header">
                    <div style={{ width: '33%' }} className="case-preview-header-item">
                        <CustomDropdownMenu
                            label={t("Reference")}
                            elements={getNames()}
                            onSelect={(e) => handleSelect(e)}
                            title={t(selectedReference?.type)}
                            selected={t(selectedReference?.type)}
                            open={true}
                        />
                    </div>
                    <div className="case-preview-header-item">
                        <p>{t("Duration")}<span className="duration-margin">{duration}</span></p>
                    </div>
                    <div className="case-preview-header-item">
                        <ListItem onClick={() => onPreviewClick()} style={{ padding: 0, height: 60, paddingLeft: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 20 }} button color="primary" href="#contained-buttons">
                            <p style={{ color: '#1976d2'}}>{t("Preview")}</p>
                            <VisibilityIcon htmlColor={Colors.mainBlue} />
                        </ListItem>
                    </div>
                </div>
                <div>
                    <div className={classes.questionsWrapper}>
                        <DragAndDrop
                            selectedRef={selectedReference}
                            items={questions}
                            setItems={(e) => handleDragAndDrop(e)}
                            isClosed={isClosed}
                        />
                    </div>
                </div>
                <QuestionsPreview 
                    reference={selectedReference} 
                    handleClose={setShowPreview} 
                    open={showPreview} 
                    caseInfo={caseState}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    isClosed={isClosed}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    questionsWrapper: {
        overflowY: 'overlay',
        marginTop: 40,
        maxHeight: 'calc(100vh - 350px)',
    }
})
