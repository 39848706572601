import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import '../sass/components/table.scss'
import '../sass/screens/dashboard.scss'
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import CustomAlertDialog from './CustomAlertDialog';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils/constansts';
import { Colors } from '../styles/globalStyles';
import RoleContainer from '../helpers/RoleContainer';

const useStyles = makeStyles({
    tableDataRowContainerEven: {
        backgroundColor: Colors.table1,
        '&.MuiTableRow-root:hover': {
            backgroundColor: `${Colors.table1} !important`,
            outline: props => props.color ? '2px solid' + props.color : `2px solid ${Colors.mainBlue} !important`,
        },
    },
    tableDataRowContainerOdd: {
        backgroundColor: Colors.table2,
        '&.MuiTableRow-root:hover': {
            backgroundColor: `${Colors.table2} !important`,
            outline: props => props.color ? '2px solid' + props.color : `2px solid ${Colors.mainBlue} !important`,
        },
    },
    statusYellow: {
        marginLeft: 5,
        height: 15,
        width: 15,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: Colors.yellow,
    },
    statusRed: {
        marginLeft: 5,
        margin: '0px 5px',
        height: 15,
        width: 15,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: Colors.red,
    },
});

function descendingComparator(a, b, orderBy) {
    let aComparator = a[orderBy];
    let bComparator = b[orderBy];
    if (orderBy === 'candidate') {
        aComparator = a.candidateName.toLowerCase();
        bComparator = b.candidateName.toLowerCase();
    }
    if (orderBy === 'creator') {
        aComparator = a.creatorName.toLowerCase();
        bComparator = b.creatorName.toLowerCase();
    }
    if (orderBy === 'jobPosition') {
        aComparator = aComparator.toLowerCase();
        bComparator = bComparator.toLowerCase();
    }
    if (bComparator < aComparator) {
        return -1;
    }
    if (bComparator > aComparator) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, showDeleteButton, showCopyButton } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;

    return (
        <TableHead style={{ backgroundColor: branding?.colors, height: '50px' }}>
            <TableRow >
                {showDeleteButton ? <TableCell key={`DeleteButton`} ></TableCell> : null}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell._id}
                        align='left'
                        sortDirection={orderBy === headCell._id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell._id}
                            direction={orderBy === headCell._id ? order : 'asc'}
                            onClick={createSortHandler(headCell._id)}
                        >
                            <div className='tableHeaderText' >
                                {headCell.label}
                            </div>
                            {orderBy === headCell._id ? (
                                <span className='visuallyHidden' >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {showCopyButton ? <TableCell key={`CopyButton`} ></TableCell> : null}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired,
    showDeleteButton: PropTypes.bool.isRequired,
    showCopyButton: PropTypes.bool.isRequired,
};


const EnhancedTable = (props) => {
    const { showDeleteButton, showCopyButton, tableHeaders, tableData, handleDelete, handleCopy, isTemplate, handleClick, caseData, canEdit, canDelete } = props;
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [headCells, setHeadCells] = useState(tableHeaders);
    const [rows, setRows] = useState(tableData);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const { t } = useTranslation();
    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const { company } = user
    const { branding } = company;
    const classes = useStyles({ color: branding?.colors + '!important' });

    const caseMarks = (rowData) => {
        const foundCase = caseData?.find(c => c?._id === rowData?._id);
        let markComponent;
        // candidate yellow & red mark
        let candidateYellow = false;
        let candidateRed = false;
        // references yellow & red mark
        let referencesYellow = false;
        let referencesRed = false;

        // candidate mark
        if (foundCase?.candidate?.deadlineDates?.send && foundCase?.references?.filter(r => !r?.email)?.length !== 0) {
            candidateRed = true;
        } else if (foundCase?.candidate?.halftimeDates?.send && foundCase?.references?.filter(r => !r?.email)?.length !== 0) {
            candidateYellow = true;
        }

        // references mark
        foundCase?.references?.forEach(ref => {
            if (ref?.deadlineDates?.send && !ref?.answerStatus) {
                referencesRed = true;
            } else if (ref?.halftimeDates?.send && !ref?.answerStatus) {
                referencesYellow = true;
            }
        })

        // combinations of marks
        if (candidateRed && referencesYellow) {
            markComponent = <div><div className={classes.statusYellow} /> <div className={classes.statusRed} /></div>
        } else if (candidateYellow && referencesRed) {
            markComponent = <div><div className={classes.statusYellow} /> <div className={classes.statusRed} /></div>
        } else if (candidateRed || referencesRed) {
            markComponent = <div className={classes.statusRed} />
        } else if (candidateYellow || referencesYellow) {
            markComponent = <div className={classes.statusYellow} />
        }

        return markComponent;
    };

    const handleClickOpen = (id) => {
        setOpen(true);
        setDeleteId(id);
    };

    const handleClose = () => {
        setOpen(false);
        setDeleteId('');
    };

    useEffect(() => {
        setHeadCells(tableHeaders);
    }, [])

    useEffect(() => {
        setRows(tableData);
        setPage(0);
    }, [tableData])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (row) => {
        return canEdit ? handleClick(row) : () => {}
    }

    const renderData = (row, data) => {
        if (data === '_id') return null;
        else if (data === 'creatorName') return null;
        else if (data === 'candidateName') return null;
        else if (data === 'createdAt') return <TableCell style={canEdit ? {cursor: 'pointer'} : {cursor: 'auto'}} key={`CreatedAt${row._id}`} onClick={() => handleRowClick(row)} align="left">{moment(parseInt(row[data])).format(formatDate())}</TableCell>
        else if (data === 'creator') return <TableCell style={canEdit ? {cursor: 'pointer'} : {cursor: 'auto'}} key={`Creator${row._id}`} onClick={() => handleRowClick(row)} align="left">{row[data]?.firstName && row[data].lastName ? `${row[data].firstName} ${row[data].lastName}` : '-'}</TableCell>
        else if (data === 'candidate') return <TableCell style={canEdit ? {cursor: 'pointer'} : {cursor: 'auto'}} key={`Candidate${row._id}`} onClick={() => handleRowClick(row)} align="left">{row[data]?.firstName && row[data].lastName && row.status !== "closed" ? `${row[data].firstName} ${row[data].lastName}` : '-'}</TableCell>
        else if (data === 'status' && row[data] === 'live') return <TableCell style={canEdit ? {cursor: 'pointer'} : {cursor: 'auto'}} key={`StatusLive${row._id}`} onClick={() => handleRowClick(row)} align="left"><div className='status-cell-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>{row[data]} {caseMarks(row)}</div></TableCell>;
        else if (data === 'status' && row[data] === 'closed') return <TableCell style={canEdit ? {cursor: 'pointer'} : {cursor: 'auto'}} key={`StatusClosed${row._id}`} onClick={() => handleRowClick(row)} align="left"><div className='status-cell-container'>{row[data]} {/* <FiberManualRecordIcon className='status-circle-closed' fontSize='small' /> */}</div></TableCell>;
        else return <TableCell style={canEdit ? {cursor: 'pointer'} : {cursor: 'auto'}} key={`${data}${row._id}`} onClick={() => handleRowClick(row)} align="left">{row[data] ? row[data] : '-'}</TableCell>
    };

    return (
        <div className='tableContaier' >
            <CustomAlertDialog open={open} handleClose={handleClose} handleCancel={handleClose} id={deleteId} handleAccept={handleDelete} alertTitle={"Delete"} alertText={"Are you sure you want to delete?"} okText="Delete" />
            <Paper className='paper'>
                <TableContainer sx={{ overflowX: 'clip' }}>
                    <Table
                        className='table-Container'
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows?.length}
                            headCells={headCells}
                            showDeleteButton={showDeleteButton}
                            showCopyButton={showCopyButton}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            style={{ cursor: 'pointer', }}
                                            className={index % 2 === 0 ? classes.tableDataRowContainerEven : classes.tableDataRowContainerOdd}
                                            hover
                                            role="data"
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            {showDeleteButton
                                                ? <TableCell key={`Delete${row._id}`} style={{ padding: '0px 10px' }} align="left">
                                                    <RoleContainer strict={(isTemplate || user?.role !== 'SuperAdmin' || user?.role !== 'AccountAdmin') ? false : true} user={user} id={row?.creator?._id} roles={canDelete}>
                                                        <Tooltip title={t("Delete")}>
                                                            <IconButton aria-label="delete" onClick={() => handleClickOpen(row._id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </RoleContainer>
                                                </TableCell> : null}
                                            {Object.keys(row).map((data) => (renderData(row, data)))}
                                            {showCopyButton ?
                                                <TableCell key={`Copy${row._id}`} style={{ padding: '0px 10px' }} align="right">
                                                    <RoleContainer strict={(isTemplate || user?.role !== 'SuperAdmin' || user?.role !== 'AccountAdmin') ? false : true} user={user} id={row?.creator?._id} roles={['SuperAdmin', 'AccountAdmin', 'CaseDesign', 'TemplateOnly']}>
                                                        <Tooltip title={t("Copy")}>
                                                            <IconButton aria-label="Copy" onClick={() => handleCopy(row)}>
                                                                <FileCopyIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </RoleContainer>
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    style={{ height: '40px !important', minHeight: '0px !important', padding: 0, '&.MuiToolbarRoot': { margin: '0px !important' } }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows?.length ? rows?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelDisplayedRows={({ from, to, count }) => (`${from}-${to} ${t('of')} ${count !== -1 ? count : to}`)}
                    labelRowsPerPage={t("Rows per page")}
                    nextIconButtonProps={{ title: t('Go to next page') }}
                    backIconButtonProps={{ title: t('Go to previous page') }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default withRouter(EnhancedTable);

EnhancedTable.prototype = {
    showDeleteButton: PropTypes.bool.isRequired,
    showCopyButton: PropTypes.bool.isRequired,
    tableData: PropTypes.array.isRequired,
    tableHeaders: PropTypes.array.isRequired,
}
