import './App.scss';
import { React, useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation,
} from "react-router-dom";
import store from './redux/store'
import { Provider } from 'react-redux'
import { makeStyles } from "@material-ui/styles";

import Dashboard from './screens/dashboard/Dashboard';
import CandidateInvite from './screens/candidateInvite/CandidateInvite';
import Account from './screens/account/Account';
import Login from './screens/auth/Login';
import PrivateRoute from './helpers/PrivateRoute';
import { history } from './helpers/history';
import apolloClient from './utils/apolloClient';
import NavigationHeader from './components/NavigationHeader';
import Branding from './screens/branding/Branding'

import { ApolloProvider } from "@apollo/client";
import ResetPassword from './screens/auth/ResetPassword';
import ForgotPassword from './screens/auth/ForgotPassword';
import CustomFlashMessage from './components/CustomFlashMessage';
import ReferenceAnswer from './screens/referenceAnswer/ReferenceAnswer';
import NewUser from './screens/account/NewUser';
import ThanksPage from './screens/referenceAnswer/ThanksPage';
import PrivacyPolicy from './screens/general/PrivacyPolicy';
import NewCase from './screens/case/NewCase';
import NewTemplate from './screens/template/NewTemplate';
import UpdateTemplate from './screens/template/UpdateTemplate';
import UpdateCase from './screens/case/UpdateCase';
import Contact from './screens/contact/Contact';
import Support from './screens/support/Support';
import ReferenceReport2 from './screens/referenceAnswer/ReferenceReport2';
import ClosedPage from './screens/referenceAnswer/ClosedPage';
import Customer from './screens/customer/Customer';
import Invoice from './screens/invoice/Invoice';
import ViewClosedCase from './screens/case/ViewClosedCase';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = ({ children, className }) => (
    <div id="paddingBreak" className={className}>
        {children}
    </div>
  );

function App() {
	const classes = useStyles();
	return (
		<Provider store={store}>
			<ApolloProvider client={apolloClient}>
				<Router getUserConfirmation={() => {}} history={history} >
					<div>
						<CustomFlashMessage />
					</div>
					<Switch>
						<Route exact path="/case/generate/single/:id/:refId/:language" component={ReferenceReport2} />
						<Route exact path="/case/generate/combined/:id/:language" component={ReferenceReport2} />
					</Switch>
					<ScrollToTop />
					<NavigationHeader  />
					<Switch>
						<Route exact path="/" component={Login} />
						<Layout className={classes.content}>
							<PrivateRoute path="/dashboard" component={Dashboard} />
							<PrivateRoute roles={['SuperAdmin']}  path="/branding" component={Branding} />
							<PrivateRoute path="/report" component={CandidateInvite} />
							<PrivateRoute roles={['SuperAdmin', 'AccountAdmin']} path="/account" component={Account} />
							<PrivateRoute roles={['SuperAdmin']} path="/customer" component={Customer} />
							<PrivateRoute roles={['SuperAdmin']} path="/invoice" component={Invoice} />
							<PrivateRoute path="/contact" component={Contact} />
							<PrivateRoute path="/support" component={Support} />
							<Route exact path="/forgot-password" component={ForgotPassword} />
							<Route exact path="/new-password/:token" component={ResetPassword} />
							<PrivateRoute path="/create-case" component={NewCase} />
							<PrivateRoute exact path="/create-template" component={NewTemplate} />
							<PrivateRoute exact path="/update-template/:id" component={UpdateTemplate} />
							<PrivateRoute exact path="/update-case/:id" component={UpdateCase} />
							<PrivateRoute exact path="/view-closed/:id" component={ViewClosedCase} />
							<Route exact path="/case/reference/:id" component={CandidateInvite} />
							<Route exact path="/case/answer/:id" component={ReferenceAnswer} />
							<Route exact path="/case/answer/:id/:refId" component={ReferenceAnswer} />
							<Route exact path="/auth/new-user/:token" component={NewUser} />
							<Route exact path="/case/thanks" component={ThanksPage} />
							<Route exact path="/case/closed" component={ClosedPage} />
							<Route exact path="/privacy-policy" component={PrivacyPolicy} />
						</Layout>
					</Switch>
				</Router>
			</ApolloProvider>
		</Provider>
	);
}

const useStyles = makeStyles({
    content: {
        paddingTop: 106,
        '@media (max-width: 1024px)': {
            paddingTop: 70,
        }
    },
})

export default App;
