import React from 'react'
import moment from 'moment'
import { Colors } from '../../styles/globalStyles';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../../utils/constansts';

const Stakeholders = ({ caseInfo, handleChangeCaseInfo }) => {
    const t = (str) => str;
    const classes = useStyles()

    return (
        <div className={classes.outerContainer}>
            <div className={classes.header}>
                <p>{t("Stakeholders")}</p>
            </div>
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                {caseInfo?.stakeholders?.length !== 0 && caseInfo?.stakeholders?.map((stakeholder, index) => {
                    return (
                        <div style={{ marginTop: index !== 0 ? 30 : 0 }}>
                            <TextField 
                                name="email" 
                                label="Email" 
                                size='small' 
                                disabled={true} 
                                value={stakeholder?.user?.email ?? ''}
                                fullWidth
                                className={classes.input} 
                                InputLabelProps={{ shrink: true }} 
                            />
                            <div style={{ paddingTop: 20 }}>
                                <TextField
                                    size='small'
                                    label="Date: Listing of Stakeholder"
                                    disabled={true}
                                    fullWidth
                                    value={stakeholder.inviteMailDate && moment(new Date(parseInt(stakeholder.inviteMailDate))).format(`${formatDate()} HH:mm`) ? moment(new Date(parseInt(stakeholder.inviteMailDate))).format(`${formatDate()} HH:mm`) : ''}
                                    className={classes.input}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                        </div>
                    );
                })} 
                {caseInfo?.stakeholders?.length === 0 && <div className={classes.noStakeholderContainer}>{t("No Stakeholders listed")}</div>}
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    input: {
        marginBottom: 20,
        "& .MuiOutlinedInput-input.Mui-disabled": {
            "-webkit-text-fill-color": "rgba(0,0,0, 0.65) !important"
        },  
    },
    outerContainer: {
        width: 'calc(50% - 10px)',    
        '@media (min-width: 1025px)': {
            width: 'calc(50% - 10px)',  
            paddingLeft: 10  
        }
    },
    header: {
        height: 40, 
        backgroundColor: Colors.mainBlue, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        position: 'relative',
        color: '#fff',
        textAlign: 'center'
    },
    noStakeholderContainer: {
        textAlign: 'center'
    },
})

export default Stakeholders;
