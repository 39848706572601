import React, { useState, useEffect } from 'react'
import { Colors } from '../styles/globalStyles'
import { Modal, Box, TextField, Tooltip, IconButton } from '@material-ui/core'
import CustomMenuList from './CustomMenuList';
import { useMutation, useQuery } from '@apollo/client'
import { GET_SCALES, ADD_QUESTION, ADD_ANSWER } from '../graphql/mutations';
import { useDispatch, useSelector } from 'react-redux';
import { triggerFlashMessage } from '../redux/slices/globalSlice';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { validateNewRatingPoint, validateRatingPointTheme } from '../utils/validators';
import {getLanguage} from '../utils/getLanguage';
import LoadingBtn from './LoadingButton';
import Close from '@material-ui/icons/Close';

function AddRatingPoint({ showModal = false, setShowModal, themes, refetch, selectedT, selectedC, selectedLanguage }) {
    const company = useSelector(state => state.auth.user.company)

    const [selectedTheme, setSelectedTheme] = useState()
    const [selectedScale, setSelectedScale] = useState()
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [languages, setLanguages] = useState();

    const [loading, setLoading] = useState(false);
    const [rp, setRP] = useState([]);

    const [titles, setTitles] = useState()
    
    const [errors, setErrors] = useState({});

    const t = (str) => str;

    const { data: scaleData } = useQuery(GET_SCALES, { variables: { companyId: company._id } })

    const [addAnswer] = useMutation(ADD_ANSWER);
    const [addQuestionDB] = useMutation(ADD_QUESTION);

    const dispatch = useDispatch()

    const classes = useStyles();

    useEffect(() => {
        if(themes && selectedT?.title !== 'All' && validateRatingPointTheme(selectedT?.title)){
            setSelectedTheme(selectedT)
        } else {
            setSelectedTheme()
        }
    }, [themes, selectedT])

    useEffect(() => {
        const foundScale = scaleData?.scales?.find(x => x.type === 'RatingPoints');
        setSelectedScale({ ...foundScale, title: getLanguage(foundScale)?.name })
    }, [selectedC, scaleData])

    useEffect(() => {
        setTitles(languages?.map(e => (e?.shortName === 'en' ? { language: e?.shortName, name: 'Regarding [Theme], which statement best describes [Candidate] and to what degree is that expressed?' } : { language: e?.shortName, name: 'I forhold til [Tema], hvilket udsagn beskriver bedst [Kandidat] og i hvor høj grad kommer det til udtryk?' })))
        let array = [];
        languages?.forEach((l) => {
            array.push({
                language: l?.shortName,
                name: '',
                answers: [
                    {
                        title: '',
                        description: '',
                    },
                    {
                        title: '',
                        description: '',
                    },
                ],
            });
        })
        setRP({answer: {translations: array, type: 'RatingPoints'}});
    }, [languages])

    const submit = async () => {
        const themeId = selectedTheme?._id;
        if(!themeId) return dispatch(triggerFlashMessage({ type: 'error', title: t("Please select a theme") }))
        
        let ratingpoint = rp;

        // validate
        const errors = validateNewRatingPoint(selectedTheme, ratingpoint);
        if(!_.isEmpty(errors)) {
            setLoading(false);
            setErrors(errors);

            if(errors.isValid) {
                setShowConfirmModal(true)
            }
            return;
        }
        
       handleSubmit()
    }

    const handleSubmit = async () => {
        setShowConfirmModal(false)
        const scaleId = selectedScale?._id;
        const themeId = selectedTheme?._id;

        let ratingpoint = rp;

        ratingpoint.answer.translations.forEach((translation) => {
            translation.name = `${translation.answers[0].title} - ${translation.answers[1].title}`;
        })

        const answerVariables = {
            translations: ratingpoint.answer.translations,
            type: 'Rating Points',
            company: company?._id,
        }

        const answer = await addAnswer({variables: answerVariables}).then(res => res).catch(err => {
            setLoading(false);
            return dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        })

        const answerId = answer?.data?.addAnswer?._id;
        
        let filteredTitles = [...titles];
        answerVariables?.translations?.forEach(av => {
            if(av?.name === ' - ') {
                filteredTitles = filteredTitles?.filter(ft => ft?.language !== av?.language)
            }
        })

        const variables = {
            theme: themeId,
            company: company._id,
            scale: scaleId,
            duration: 20,
            answer: answerId,
            translations: filteredTitles,
            type: 'RatingPoints',
        };

        addQuestionDB({variables: variables}).then(res => {
            setLoading(false);
            setShowModal(false);
            dispatch(triggerFlashMessage({ type: 'success', title: t("Succesfully added Rating Point") }));
            refetch();
            setErrors('')
        }).catch(err => {
            setLoading(false);
            dispatch(triggerFlashMessage({ type: 'error', title: t("Something went wrong") }))
        });
    }
    const handleClose = () => {
        setErrors('')
        setShowModal(false)
    }

    const handleRatingPoint = (e, i) => {
        const {name, value, id} = e.target;
        let tempRP = rp;
        tempRP.answer.translations[i].answers[parseInt(id)][name] = value
        setRP(tempRP)
    }

    useEffect(() => {
        let arr = company?.settings?.languages;        
        const first = arr?.filter(obj => obj?.shortName === selectedLanguage?.shortName);
        const last = arr?.filter(obj => obj?.shortName !== selectedLanguage?.shortName);
        setLanguages([].concat(first).concat(last))
    }, [selectedLanguage, company?.settings?.languages])
     
    return (
        <Modal
            open={showModal}
            onClose={() => console.log('close')}
        >
            <Box className={classes.container}>
                <div className={classes.headerContainer}>
                    <div className={classes.stepOneContainer}>
                        <p onClick={() => setShowModal} style={{ fontWeight: '500', fontSize: 14 }}>STEP</p>
                    </div>
                    <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 20 }}>
                        <Tooltip  title="Close">
                            <IconButton onClick={handleClose}>
                                <Close style={{ color: '#000' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Modal open={showConfirmModal}>
                    <Box style={{ height: '20%', width: '20%', padding: 30, display: 'flex', flexDirection: 'column' }} className={classes.container}>
                        <Close style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => setShowConfirmModal(false)} />
                        <div style={{ flex: 1}}>
                            <p>
                                In order for a Reference to submit their reference in their preferred language – please enter the New Question and Rating Points in all available languages. 
                            </p>
                            <p style={{ marginTop: 10 }}>If the preferred language is missing the Question or Rating Points will be left out.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'flex-end', width: '100%'}}>
                            <LoadingBtn onClick={() => setShowConfirmModal(false)}>
                                <p>Back</p>
                            </LoadingBtn>
                            <LoadingBtn onClick={() => handleSubmit()}>
                                <p>Save <span style={{ display: 'block', fontSize: 8 }}>without more languages</span></p>
                            </LoadingBtn>
                        </div>
                    </Box>
                </Modal>
                <div>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontWeight: 'bold', fontSize: 20 }}>1</p>
                            </div>
                            <div style={{ width: '90%' }}>
                                <CustomMenuList 
                                    error={errors.theme} 
                                    withKey 
                                    onSelect={(e) => setSelectedTheme(e)} 
                                    title={t(selectedTheme?.title)} 
                                    label={t("Select Theme")} 
                                    elements={themes?.filter(t => validateRatingPointTheme(t?.title) )} 
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 1, backgroundColor: Colors.mainBlue }}></div>
                    <div>
                        {languages?.map((language, index) => {
                            return (
                                <div key={language?.shortName}>
                                    <div style={{paddingTop: 20, display: 'flex'}}>
                                        <div style={{ width: '10%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                            <p style={{ fontWeight: 'bold', fontSize: 20 }}>{2+index}</p>
                                        </div>
                                        <div style={{ width: '100%'}}>
                                            <div style={{display: 'flex', width: '90%'}}>
                                                <TextField 
                                                    error={errors.ratingPoint && errors.ratingPoint[index].title1} 
                                                    name='title'
                                                    multiline
                                                    id='0'
                                                    label={t(`Rating Point I in ${language?.fullName?.toLowerCase() === 'english' ? 'English' : 'Danish'}`)} 
                                                    variant="outlined"
                                                    onChange={(e) => handleRatingPoint(e, index)} 
                                                    style={{ width: '100%', marginBottom: 20 }}
                                                    value={rp[index] && rp[index].title1}  
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField 
                                                    error={errors.ratingPoint && errors.ratingPoint[index].title2} 
                                                    name='title'
                                                    multiline
                                                    id='1'
                                                    label={t(`Rating Point II in ${language?.fullName?.toLowerCase() === 'english' ? 'English' : 'Danish'}`)} 
                                                    variant="outlined"
                                                    onChange={(e) => handleRatingPoint(e, index)} 
                                                    style={{ width: '100%', marginBottom: 20, marginLeft: 10 }}
                                                    value={rp[index] && rp[index].title2}  
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>
                                            <div style={{display: 'flex', width: '90%'}}>
                                                <TextField 
                                                    error={errors.ratingPoint && errors.ratingPoint[index].des1} 
                                                    name='description'
                                                    multiline
                                                    id='0'
                                                    label={t(`Description I in ${language?.fullName?.toLowerCase() === 'english' ? 'English' : 'Danish'}`)} 
                                                    variant="outlined"
                                                    onChange={(e) => handleRatingPoint(e, index)} 
                                                    style={{ width: '100%', marginBottom: 20 }}
                                                    value={rp[index] && rp[index].description1} 
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                <TextField 
                                                    error={errors.ratingPoint && errors.ratingPoint[index].des2} 
                                                    name='description'
                                                    multiline
                                                    id='1' 
                                                    label={t(`Description II in ${language?.fullName?.toLowerCase() === 'english' ? 'English' : 'Danish'}`)} 
                                                    variant="outlined"
                                                    onChange={(e) => handleRatingPoint(e, index)} 
                                                    style={{ width: '100%', marginBottom: 20, marginLeft: 10 }}
                                                    value={(rp[index] && rp[index].description2)}   
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: 1, backgroundColor: Colors.mainBlue, margin: '0px 0px', width: '100%' }}></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                    <LoadingBtn onClick={submit} loading={loading}>
                        {t("Save")}
                    </LoadingBtn>
                </div>
            </Box>
        </Modal>
    )
}

export default AddRatingPoint

const useStyles = makeStyles({
    container: {
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        minHeight: 200, 
        backgroundColor: '#fff', 
        transform: 'translate(-50%, -50%)',
        '@media (min-width: 1025px)': {
            width: '50%',
        },
        '@media (max-width: 1024px) and (min-width: 481px)': {
            width: '80%',
        },
        '@media (max-width: 480px)': {
            width: '100%',
        },
    },
    headerContainer: {
        display: 'flex', 
        flexDirection: 'row',
        minHeight: 60,
        borderBottom: `1px solid ${Colors.mainBlue}`
    },
    stepOneContainer: {
        width: '10%', 
        backgroundColor: Colors.mainBlue, 
        display: 'flex', 
        flexDirection: 'row', 
        color: '#fff', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    submitButton: {
        backgroundColor: `${Colors.mainBlue} !important`,
        color: '#fff !important',
        paddingLeft: '40px !important',
        paddingRight: '40px !important'
    }
});
