import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import i18n from '../i18n';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import LanguageIcon from '@material-ui/icons/Language';

import { setLanguage, setQuestionsLanguage } from '../redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from '../styles/globalStyles';
import { makeStyles } from "@material-ui/styles";

function LanguageChange({ company, useLanguage, language, handleLanguage }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const globalState = useSelector(state => state.global)
    const [selectedLanguage, setSelectedLanguage] = useState()

    const auth = useSelector(state => state.auth)
    const { user } = auth;
    const dispatch = useDispatch()

    const classes = useStyles();

    const handleLanguageChange = (language) => {
        if(useLanguage) {
            handleLanguage(language)
            dispatch(setQuestionsLanguage(language))
            setAnchorEl(null)
        } else {
            i18n.changeLanguage(language?.shortName)
            dispatch(setLanguage(language))
            setAnchorEl(null)
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if(useLanguage) {
            setSelectedLanguage(language);
        } else {
            setSelectedLanguage(globalState?.language);
        }
    }, [language, globalState])

    return (
    
        <div>
            <Button style={{ background: user?.company?.branding?.color ? user?.company?.branding?.color : Colors.mainBlue, color: '#fff', display: 'flex', justifyContent: 'space-between', padding: '5px 10px', minWidth: 150 }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <LanguageIcon fontSize='small' className={classes.languageclass} />
                <p className={classes.languageclass} >{selectedLanguage?.fullName}</p>
                {!Boolean(anchorEl) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </Button>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {company?.settings?.languages?.map((language, index) => {
                    return <MenuItem style={{ fontWeight: language === selectedLanguage ? '600' : '400'}} key={index} onClick={() => handleLanguageChange(language)}>{language?.fullName}</MenuItem>
                })}
            </Menu>
        </div>
    )
}

const useStyles = makeStyles({
    languageclass: {
        color: '#fff',
        fontSize: 15,
    },
})

export default LanguageChange
