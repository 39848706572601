import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';


export default function CustomSearchBar({ rows, onFilter }) {
    const { t } = useTranslation();

    const filter = (searchValue) => {
        const filtered = rows.filter(entry => Object.values(entry).some(val => typeof val === "string" && (val.toLowerCase()).includes(searchValue.toLowerCase())));
        // only reason we used this was because it can search within objects as well, so this might be better?
        // const filtered = rows.filter(entry => Object.values(entry).some(val => typeof val === "string" && (val.toLowerCase()).includes(searchValue.toLowerCase()) || (typeof val === "object" && Object.values(val).some(dotVal => typeof dotVal === "string" && (dotVal.toLowerCase()).includes(searchValue.toLowerCase())))));
        onFilter(filtered);
    }

    return (
        <form className={'Custom-Search-Bar-Form'} noValidate autoComplete="off">
            <TextField className='Custom-Search-Bar' id="standard-basic" label={t('Search')} onChange={(e) => filter(e.target.value)} size='small' />
        </form>
    );
}